<template>
  <div>

    <div class="col-12 mb-3 mt-2">
      <h1>1. Je choisis ma campagne : {{smsTemplateStore.sms_template.name}}</h1>
      👉 Personnalisez vos choix
    </div>

    <div class="row" style="margin-bottom:60px">
      <div class="col">

        <div class="accordion accordion-border" id="accordionBorder" style="margin-right:15px" data-toggle-hover="true">

          <div class="accordion-item" v-for="(sample, index) in sms_samples">
            <h2 class="accordion-header" id="headingBorderOne" @click="setSample(sample, index)">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#accordionBorderOne'+index" aria-expanded="false" :aria-controls="'accordionBorderOne'+index">
                Modèle {{index+1}}
              </button>
            </h2>
            <div :id="'accordionBorderOne'+index" class="accordion-collapse collapse" aria-labelledby="headingBorderOne" data-bs-parent="#accordionBorder" style="">
              <div class="accordion-body">
                <p v-html="getContentEditable(sample.body.replace(/(?:\r\n|\r|\n)/g, '<br>'))"></p>
              </div>

              <div class="right" style="margin: -12px 20px 10px 0">
                <a @click="$router.push(getLinkSample(sample))" class="btn-sm btn-primary round waves-effect">Suivant &#187;</a>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="fixed phone_body">
        <p class="phone_body_message" v-html="body"></p>
      </div>
    </div>

  </div>
</template>

<script>
    import DashboardLayout from "@/layouts/dashboard";
    import { useSmsTemplateStore } from "@/stores/smsTemplate";
    import { useAccountStore } from "@/stores/account";
    import useVuelidate from "@vuelidate/core";
    import moment from "moment";
    import VueRouter from 'vue-router'

    export default {
        name: "SmsTemplateDetail",
        components: {
            DashboardLayout,
        },
        data() {
            return {
                sms_template: {},
                sms_samples:[],
                activeIndex:0,
                body:""
            };
        },
        async mounted() {
            await this.getTemplate();
        },
        setup() {
            const smsTemplateStore = useSmsTemplateStore();
            const accountStore = useAccountStore();
            return {
                smsTemplateStore,
                accountStore
            };
        },
        methods: {
            async getTemplate() {
                var id = this.$route.params.id;
                if(parseInt(id) > 3) { id = 0; }
                await this.smsTemplateStore.getSmsTemplate({
                    id: id,
                    vm: this,
                });
                this.sms_template = this.smsTemplateStore.sms_template;
                this.sms_samples = this.sms_template.sms_samples;
                if (this.sms_samples[0]) {
                    this.setSample(this.sms_samples[0], 0);
                }
            },
            getContentEditable(content) {
                content = content.replaceAll("{{prénom}}", '<span class="badge-hand badge badge-light-success">Prénom</span>');
                content = content.replaceAll("[date]", '<span class="badge badge-light-warning">Date</span>');
                content = content.replaceAll("[heure]", '<span class="badge badge-light-warning">Heure</span>');
                content = content.replaceAll("[numéro]", '<span class="badge badge-light-warning">Numéro</span>');
                content = content.replaceAll("{{lien}}", '<span class="badge-hand badge badge-light-success">Lien URL</span>');
                content = content.replaceAll("{{école}}", '<span class="badge-hand badge badge-light-success">Ecole</span>');
                content = content + '<span> </span>';
                return content;
            },
            setSample(sample, index) {
                var body = sample.body;
                body = body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                body = body.replace(/{{prénom}}/g, "Arnaud");
                body = body.replace(/{{école}}/g, this.accountStore.account.name);
                body = body.replace(/{{lien}}/g, "https://bit.ly/3IP53Ax");
                body = body.replace(/{{assistant}}/g, this.accountStore.account.assistant_name);
                body = body.replace(/\[heure\]/g, "8h00");
                body = body.replace(/\[date\]/g, moment().format("DD/MM/YYYY"));
                this.body = body;
                this.activeIndex = index;
            },
            getLinkSample(item){
                return "/campagne/sms/template/"+ this.$route.params.id +"/sample/"+ item.id + "/settings/0";
            }
        },
    };
</script>

<style scoped>
  .sms_template {
    border: 2px solid transparent;
    cursor: pointer;
    margin-right:10px
  }
  .active, .sms_template:hover {
    border: 2px solid #7d31f3;
    cursor: pointer;
  }
  .phone_body {
    background-image: url("@/assets/images/phone_body.png");
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    height:550px;
    width:280px;
  }
  .phone_body_message {
    margin:50px 15px 15px 15px;
    padding:12px 14px 12px 14px;
    border-radius: 18px;
    background: #e5e5e9;
    color: black;
    font-family: Arial;
  }
  .phone_body_link {
    width:210px;
    margin-left:20px
  }
  .fixed-height {
    height:550px;
    max-height:550px;
    overflow-y: scroll;
  }
</style>
