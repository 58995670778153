<template>
    <div>
        <div class="row">
            <div class="col-12 mb-2">
                <h1>Visuel</h1>
                👉 Personnalisez vos choix
            </div>
        </div>
        <form class="row" @submit.prevent="">
            <div class="col-md-9">
                <div class="row mb-3">
                    <div class="col-12">
                        <label>Logo de la chatbox</label><br/>
                        <div
                                v-for="index in 7"
                                :key="index"
                                class="avatar avatar-border"
                                :class="licenceStore.chatbot_personnalisation || userStore.is_leadia_admin ? '' : (index == 1 ? '' : 'grayscale') "
                                :style="{ backgroundColor: widgetLogoColor }"
                                @click="setLogo(index-1)"
                        >
                            <img
                                    :src="getLogo(index-1)"
                                    alt="user_avatar"
                                    height="50"
                                    width="50"
                                    :class="{ logoActive: getLogo(index-1) === widgetLogo }"
                                    :style="{ borderColor: widgetLogoColor }"
                            />
                        </div>

                        <div class="avatar-loader-holder"
                             v-if="(avatar || (thumbAvatarUrl && thumbAvatarUrl != '/avatars/thumb/missing.png'))">
                            <div class="avatar avatar-border"
                                 :style="{ backgroundColor: widgetLogoColor }"
                                 @click="setLogo(-1)">
                                <img
                                        :src="avatar ? avatar : thumbAvatarUrl"
                                        alt="user_avatar"
                                        height="50"
                                        width="50"
                                        :class="{ logoActive: thumbAvatarUrl === widgetLogo }"
                                        :style="{ borderColor: widgetLogoColor }"
                                />
                            </div>
                            <div class="delete-link" @click="deleteAvatar()">
                                Supprimer
                            </div>
                        </div>

                        <div v-if="(licenceStore.chatbot_personnalisation || userStore.is_leadia_admin) && !(avatar || (thumbAvatarUrl && thumbAvatarUrl != '/avatars/thumb/missing.png'))"
                             class="avatar-loader"
                             @dragover.prevent @drop.prevent>
                            <label style="">
                                <div
                                        @drop="setAvatar"
                                        class="dropzone dropzone-area dz-clickable dropzone-round"
                                        id="dpz-single-file-avatar"
                                >
                                    <div class="dz-message"></div>
                                    <input
                                            type="file"
                                            id="upload-avatar"
                                            @change="setAvatar"
                                            style="display: none"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mb-2">
                        <label>Couleur du logo</label><br/>
                        <div class="row">
                            <div class="col-3">
                                <input
                                        type="color"
                                        v-model="widgetLogoColor"
                                        class="color-box"
                                        placeholder=""
                                />
                            </div>
                            <div class="col-6">
                                <input
                                        type="text"
                                        v-model="widgetLogoColor"
                                        class="form-control arrondi"
                                        placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <label>Couleur du bandeau</label><br/>
                        <div class="row">
                            <div class="col-3">
                                <input
                                        type="color"
                                        v-model="widgetColorBandeau"
                                        class="color-box"
                                        placeholder=""
                                />
                            </div>
                            <div class="col-6">
                                <input
                                        type="text"
                                        v-model="widgetColorBandeau"
                                        class="form-control arrondi"
                                        placeholder=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-10 mb-2">
                        <label>Position</label>
                        <select class="form-control" v-model="position">
                            <option value="left">Gauche</option>
                            <option value="right">Droite</option>
                        </select>
                    </div>
                    <div class="col-10 mb-2">
                        <label>Position | Marge de côté</label>
                        <input
                                v-model="positionBorder"
                                class="form-control"
                                type="number"
                                max="200"
                                placeholder="Position | Marge de côté"
                        />
                    </div>
                    <div class="col-10 mb-2">
                        <label>Position | Marge en bas</label>
                        <input
                                v-model="positionBottom"
                                class="form-control"
                                type="number"
                                max="200"
                                placeholder="Position | Marge en bas"
                        />
                    </div>
                    <div class="col-10 mb-2" v-if="accountStore.account.id == 124 || accountStore.account.id == 207">
                        <label>Delai avant affichage</label>
                        <select class="form-control" v-model="display_delay">
                            <option value="0">Pas de delai</option>
                            <option value="15000">15 secondes</option>
                            <option value="30000">30 secondes</option>
                            <option value="45000">45 secondes</option>
                            <option value="60000">60 secondes</option>
                        </select>
                    </div>
                    <div class="mb-2">
                        <button type="submit" class="btn btn-primary" @click="actionSave">Enregistrer</button>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <component :is="'style'">
                    @import 'https://flow.staging.lead-ia.com/stylesheet/3470e324-6937-431b-ac7c-1e1ec1e5408a.css?cache=v2';

                    mybot .lead-ia-profile_div .lead-ia-img-profile {
                    background-color: {{widgetLogoColor}};
                    }
                    mybot .lead-ia-message .bot_p_img {
                    background-color: {{widgetLogoColor}};
                    }
                    mybot .lead-ia-chatCont {
                    background-color: {{widgetColorBandeau}};
                    border-radius: 22px;
                    }
                    leadia .lead-ia-close {
                    background-color: {{widgetColorBandeau}};
                    z-index: 999999;
                    }
                    leadia .lead-ia-chat-header {
                    background-color: {{widgetColorBandeau}};
                    }
                    @keyframes sk-bouncedelay {
                    50% {
                    background-color: {{widgetColorBandeau}};
                    }
                    }
                </component>
                <component :is="'style'" v-if="position == 'right' ">
                    leadia .lead-ia-close {
                    bottom: {{positionBottom}}px;
                    right: {{positionBorder}}px;
                    left: auto;
                    }
                    leadia .lead-ia-chatbot_status {
                    left: auto;
                    right: {{positionBorder}}px;
                    bottom: {{positionBottom}}px;
                    }
                    leadia .lead-ia-profile_div {
                    left: auto;
                    right: {{positionBorder}}px;
                    bottom: {{positionBottom + 60}}px;
                    }
                    leadia .lead-ia-chatCont {
                    left: auto;
                    right: {{positionBorder}}px;
                    bottom: {{positionBottom + 60}}px;
                    }
                </component>
                <component :is="'style'" v-if="position == 'left' ">
                    leadia .lead-ia-close {
                    bottom: {{positionBottom}}px;
                    right: auto;
                    left: {{positionBorder}}px;
                    }
                    leadia .lead-ia-chatbot_status {
                    left: {{positionBorder}}px;
                    right: auto;
                    bottom: {{positionBottom}}px;
                    }
                    leadia .lead-ia-profile_div {
                    left: {{positionBorder}}px;
                    right: auto;
                    bottom: {{positionBottom + 60}}px;
                    }
                    leadia .lead-ia-chatCont {
                    left: {{positionBorder}}px;
                    right: auto;
                    bottom: {{positionBottom + 60}}px;
                    }
                </component>

                <leadia>
                    <mybot v-if="!isDisplay" v-on:click="show">
                        <div id="lead-ia-profile_div"
                             class="lead-ia-profile_div lead-ia-profile_div-right lead-ia-d-flex">

                            <div class="lead-ia-profile_picture">
                                <img
                                        v-if="!licenceStore.chatbot_personnalisation && !userStore.is_leadia_admin"
                                        v-bind:src="getLogo(1)"
                                        v-bind:style="{ backgroundColor: widgetLogoColor}"
                                        width="90" height="90"
                                        class="lead-ia-img-profile">

                                <img
                                        v-if="licenceStore.chatbot_personnalisation || userStore.is_leadia_admin"
                                        v-bind:src="avatar && widgetLogo == thumbAvatarUrl ? avatar : widgetLogo"
                                        v-bind:style="{ backgroundColor: widgetLogoColor}"
                                        width="90" height="90"
                                        class="lead-ia-img-profile"></div>

                            <div class="lead-ia-msg_intro">
                                <div class="lead-ia-chat-txt">
                                    <strong>{{widgetText}}</strong><br></div>
                                <div class="lead-ia-chat-launcher">{{widgetText2}}</div>
                            </div>
                        </div>
                        <div class="lead-ia-close lead-ia-d-flex"
                             v-bind:style="{ backgroundColor: widgetColorBandeau}"></div>
                    </mybot>
                    <mybot v-if="isDisplay">

                        <div class="lead-ia-chatCont lead-ia-d-flex" id="lead-ia-chatCont">
                            <div class="lead-ia-chat-header" v-bind:style="{ backgroundColor: widgetColorBandeau}">
                                <div class="lead-ia-client-logo" v-bind:style="{ backgroundColor: widgetLogoColor}">
                                    <img class="bot_p_img" v-if="!licenceStore.chatbot_personnalisation && !userStore.is_leadia_admin"
                                         v-bind:src="getLogo(1)">
                                    <img class="bot_p_img" v-if="licenceStore.chatbot_personnalisation || userStore.is_leadia_admin"
                                         v-bind:src="avatar && widgetLogo == thumbAvatarUrl ? avatar : widgetLogo">
                                </div>

                                <div class="lead-ia-client-infos">
                                    <div class="lead-ia-client-name">{{chatSchoolname}}</div>
                                    <div class="lead-ia-client-status">En ligne</div>
                                </div>
                            </div>
                            <div id="lead-ia-result_div" class="lead-ia-resultDiv">

                                <span class="lead-ia-message">

                                <img class="bot_p_img"
                                     v-if="!licenceStore.chatbot_personnalisation && !userStore.is_leadia_admin"
                                     v-bind:src="getLogo(1)">
                                <img class="bot_p_img"
                                     v-if="licenceStore.chatbot_personnalisation || userStore.is_leadia_admin"
                                     v-bind:src="avatar && widgetLogo == thumbAvatarUrl ? avatar : widgetLogo">

                                <p
                                        class="lead-ia-botResult question-undefined">Hello 😉 Une question&nbsp;? Une recherche&nbsp;? Je suis {{assistantName}} et je vais vous aider pour ça 🙂</p></span>

                                <span
                                        class="lead-ia-message">        <img class="bot_p_img"
                                                                             v-if="!licenceStore.chatbot_personnalisation && !userStore.is_leadia_admin"
                                                                             v-bind:src="getLogo(1)">
                                <img class="bot_p_img"
                                     v-if="licenceStore.chatbot_personnalisation || userStore.is_leadia_admin"
                                     v-bind:src="avatar && widgetLogo == thumbAvatarUrl ? avatar : widgetLogo">
<p
        class="lead-ia-botResult lead-ia-suggestion"><div
                                            class="lead-ia-sugg-title">Je voudrais savoir si vous êtes…</div><span
                                            class="sugg-options answer-3.1.STG/1">Étudiant 📚</span><span
                                            class="sugg-options answer-3.1.STG/2">Salarié en activité 💼</span><span
                                            class="sugg-options answer-3.1.STG/3">Demandeur d'emploi 🔎</span><span
                                            class="sugg-options answer-3.1.STG/4">Une entreprise 🏢</span><span
                                            class="sugg-options answer-3.1.STG/5">Un parent 👨‍👩‍👧‍👦</span></p></span>
                            </div>
                            <span id="lead-ia-cgu" class="lead-ia-cgu lead-ia-d-flex">Développé par <img
                                    src="https://assets.lead-ia.com/logo.png"></span>
                            <div class="lead-ia-chatForm lead-ia-d-none">
                                <div class="lead-ia-suggestions"></div>
                                <input type="text" id="lead-ia-chat-input" autocomplete="off" placeholder="Aa"
                                       class="lead-ia-form-control bot-txt">
                                <button type="submit" class="lead-ia-chat-submit">Envoyer</button>
                            </div>
                        </div>

                        <div class="lead-ia-close lead-ia-d-flex"
                             v-on:click="hide"
                             v-bind:style="{ backgroundColor: widgetColorBandeau}"></div>
                    </mybot>
                </leadia>
            </div>
        </form>
    </div>
</template>

<script>
    import {useAccountStore} from "@/stores/account";
    import {useLicenceStore} from "@/stores/licence";
    import {useUserStore} from "@/stores/user";

    export default {
        name: "visuel",
        data() {
            return {
                widgetLogoColor: "",
                template: "bandeau",
                widgetColorBandeau: "",
                position: "right",
                positionBorder: "",
                positionBottom: "",
                widgetLogo: "",
                thumb_avatar_url: "",
                get_chat_schoolname: "",
                assistant_name: "",
                isDisplay: false,
                positionBorder: false,
                positionBottom: false,
                displayDelay: "0",
                male_gender: false,
                prefix_fr: "",
            };
        },
        setup() {
            const accountStore = useAccountStore();
            const licenceStore = useLicenceStore();
            const userStore = useUserStore();
            return {
                accountStore,
                licenceStore,
                userStore
            };
        },
        watch: {
            "accountStore.account": function (newVal, oldVal) {
                this.getData();
            },
        },
        async mounted() {
            await this.getData();
        },
        methods: {
            show: function (e) {
                this.isDisplay = true;
            },
            hide: function (e) {
                this.isDisplay = false;
            },
            async deleteAvatar() {
                if (this.thumbAvatarUrl === this.widgetLogo) {
                    const data = {
                        avatar: "",
                        widget_logo: "https://assets.lead-ia.com/logos/bot_logo_0.png",
                        vm: this,
                    };
                    await this.accountStore.updateAccount(data);
                } else {
                    const data = {
                        avatar: "",
                        vm: this,
                    };
                    await this.accountStore.updateAccount(data);
                }
                this.avatar = null;
                this.thumbAvatarUrl = null;
            },
            async setLogo(index) {
                if (index == -1) {
                    const data = {
                        widget_logo: this.thumbAvatarUrl,
                        vm: this,
                    };
                    await this.accountStore.updateAccount(data);
                } else {
                    const data = {
                        widget_logo: "https://assets.lead-ia.com/logos/bot_logo_" + index + ".png",
                        vm: this,
                    };
                    await this.accountStore.updateAccount(data);
                }
            },
            setAvatar(e) {
                const data = {
                    vm: this,
                };
                this.file_avatar = e.target.files[0];
                const reader = new FileReader();
                let rawImg;

                reader.onloadend = () => {
                    rawImg = reader.result;
                    this.avatar = rawImg;

                    const avatar = {
                        filetype: this.file_avatar.type,
                        filename: this.file_avatar.name,
                        filesize: this.file_avatar.size,
                        base64: this.avatar.substring(22),
                    };
                    data["avatar"] = JSON.stringify(avatar);
                    this.accountStore.updateAccount(data);
                };
                reader.readAsDataURL(this.file_avatar);
            },
            async getData() {
                this.widgetLogo = this.accountStore.account.widget_logo;
                this.widgetLogoColor = "#" + this.accountStore.account.widget_logo_color;
                this.template = this.accountStore.account.template;
                this.widgetColorBandeau = "#" + this.accountStore.account.widget_color_bandeau;
                this.position = this.accountStore.account.position;
                this.positionBorder = this.accountStore.account.position_border;
                this.positionBottom = this.accountStore.account.position_bottom;
                this.display_delay = this.accountStore.account.display_delay;
                this.thumbAvatarUrl = this.accountStore.account.thumb_avatar_url;
                this.chatSchoolname = this.accountStore.account.name;
                this.assistantName = this.accountStore.account.assistant_name;
                this.widgetText = this.accountStore.account.widget_text;
                this.widgetText2 = this.accountStore.account.widget_text_2;
                this.male_gender = this.accountStore.account.male_gender;
                this.prefix_fr = this.accountStore.account.prefix_fr;
            },
            getLogo(index) {
                return (
                    "https://assets.lead-ia.com/logos/bot_logo_" + index + ".png"
                );
            },
            async actionSave(e) {
                e.preventDefault();
                const data = {
                    widgetLogoColor: this.widgetLogoColor,
                    template: this.template,
                    widgetColorBandeau: this.widgetColorBandeau,
                    position: this.position,
                    positionBorder: this.positionBottom,
                    positionBottom: this.positionBorder,
                    display_delay: this.display_delay,
                    vm: this,
                };

                if (this.image) {
                    const image = {
                        filetype: this.file.type,
                        filename: this.file.name,
                        filesize: this.file.size,
                        base64: this.image.substring(22),
                    };
                    data["image"] = JSON.stringify(image);
                }

                await this.accountStore.updateAccount(data);
            },
        },
    };
</script>

<style scoped>
    .alert {
        padding: 15px;
    }

    .color-box {
        border-radius: 50px !important;
        border: none;
    }

    label {
        margin-bottom: 5px;
    }

    .avatar {
        margin-right: 15px;
    }

    .arrondi {
        border-radius: 50px;
    }

    .logoActive {
        border: 3px solid;
        border-color: rgba(115, 103, 240, 0.7) !important;
    }

    .avatar-loader {
        display: inline-flex;
        cursor: pointer;
        color: #FFF;
        text-align: center;
        text-align: center;
        vertical-align: middle;
        height: 50px;
    }

    .avatar-loader-holder {
        display: inline-flex;
        text-align: center;
        text-align: center;
        vertical-align: middle;
        height: 50px;
    }

    .dropzone-round {
        border-radius: 50px;
        min-height: 50px;
        height: 50px;
        min-width: 50px;
        width: 50px;
        padding-left: 20px
    }

    .dropzone-round .dz-message {
        left: 10px !important;
    }

    .delete-link {
        font-size: 10px;
        transform: translate(-65px, 50px);
        display: inline-block;
        cursor: pointer;
    }
</style>
