<template>
  <CoverLayout>
    <a class="brand-logo">
      <div class="brand-logo">
        <img src="@/assets/images/logo.png" alt="Lead-ia" class="logo" />
      </div>
    </a>
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
              class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
                class="img-fluid"
                src="@/app-assets/images/pages/forgot-password-v2.svg"
                alt="Login V2"
        />
      </div>
    </div>
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title fw-bold mb-1">J'ai perdu mon mot de passe</h2>
        <p class="card-text mb-2">
          Re-initialiser mon mot de passe
        </p>
        <form class="auth-login-form mt-2" @submit.prevent="reset">
          <div class="mb-1">
            <label>Email</label>
            <input
                    v-model="v$.form.email.$model"
                    class="form-control"
                    placeholder="Email"
                    type="email"
            />
            <div
                    class="input-errors"
                    v-for="(error, index) of v$.form.email.$errors"
                    :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>


          <button
                  class="btn btn-primary w-100 waves-effect waves-float waves-light"
                  :disabled="v$.form.$invalid"
          >
            Renvoyez un mot de passe
          </button>
        </form>
        <p class="text-center mt-2">
          <router-link
                  class="d-flex align-items-center"
                  :to="{ path: '/' }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                 stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left">
              <polyline points="15 18 9 12 15 6"></polyline></svg> Retour
          </router-link>
        </p>
      </div>
    </div>
  </CoverLayout>
</template>

<script>
    import useVuelidate from "@vuelidate/core";
    import { required, email, minLength, helpers } from "@vuelidate/validators";
    import CoverLayout from "@/layouts/cover";
    import { useAuthStore } from "@/stores/auth";

    export default {
        name: "PasswordPage",
        components: { CoverLayout },
        setup() {
            const authStore = useAuthStore();
            return {
                v$: useVuelidate(),
                authStore,
            };
        },
        data() {
            return {
                submitted: false,
                form: {
                    email: "",
                },
            };
        },
        validations() {
            return {
                form: {
                    email: {
                        required: helpers.withMessage("Email requis", required),
                        email: helpers.withMessage("Email invalide", email),
                    },
                },
            };
        },
        methods: {
            async reset(e) {
                e.preventDefault();
                this.form.vm = this;
                await this.authStore.reset(this.form);
            },
        },
    };
</script>

<style scoped>
  .logo {
    width: 200px;
  }
  .auth-wrapper.auth-cover .brand-logo {
    top: 0;
    left: 0;
  }
</style>
