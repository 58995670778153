<template>
  <div class="card card-congratulations">
    <div class="card-body text-center">
      <img
        src="@/app-assets/images/elements/decore-left.png"
        class="congratulations-img-left"
        alt="card-img-left"
      />
      <img
        src="@/app-assets/images/elements/decore-right.png"
        class="congratulations-img-right"
        alt="card-img-right"
      />
      <div class="avatar avatar-xl bg-primary shadow">
        <div class="avatar-content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-award font-large-1"
          >
            <circle cx="12" cy="8" r="7"></circle>
            <polyline
              points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"
            ></polyline>
          </svg>
        </div>
      </div>
      <div class="text-center welcome-text">
        <h1 class="mb-1 text-white">Bienvenue {{ userStore.current_user.firstname }}!</h1>
        <!--p class="card-text m-auto w-75">-</p-->
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/user.ts";
export default {
  name: "Congratulation",
    setup() {
        const userStore = useUserStore();
        return {
            userStore
        };
    },
};
</script>

<style scoped>
.avatar.avatar-xl .avatar-content {
  height: 50px;
  width: 50px;
  font-size: 2.5rem;
}
.card {
  height: 30%;
}
.welcome-text {
  padding-top:30px;
}
</style>
