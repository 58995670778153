<template>

    <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            ref="upgradeModal"
            style="margin-top:50px"
    >
        <div class="modal-dialog modal-lg">
            <div class="modal-content center">
                <div class="modal-header bg-transparent-popup" style="height:0;padding:0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="margin-top:40px;margin-right:15px"></button>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div  class="bg-transparent-popup">
                            <img src="@/assets/images/img_upgrade.png" class="mb-1 image_upgrade" />
                        </div>
                    </div>

                    <div class="col-6" style="padding-right:40px">
                        <p class="upgrade_content">
                            Mince ! Il semble que votre abonnement ne vous permette pas d'avoir accès à ce template 🙁
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
            class="modal fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            ref="paymentModal"
            data-bs-backdrop="static" data-bs-keyboard="false"
            style="margin-top:50px"
    >
        <div class="modal-dialog modal-lg">
            <div class="modal-content center">
                <div class="modal-header bg-transparent-popup" style="height:0;padding:0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="margin-top:40px;margin-right:15px"></button>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div  class="bg-transparent-popup">
                            <img src="@/assets/images/sms-6.svg" class="mb-1 image_upgrade" />
                        </div>
                    </div>

                    <div class="col-6" style="padding-right:40px">
                        <p class="upgrade_content">
                            Pour accéder aux templates, vous devez acheter un abonnement Magic SMS.
                            <br>
                            <br>
                            Cliquez ici 👉 <button type="submit" @click="onModalHidden()" class="btn btn-primary">Acheter maintenant</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

  <div>
      <div class="col-12 mb-3 mt-2">
        <h1>Templates</h1>
        👉 Choisissez votre template de campagne
      </div>
      <div class="col-12 mb-1">

        <div class="row match-height">
          <div class="col-md-4 mb-2" v-for="(sms_template, index) in templateStore.sms_templates">

              <div class="sms_template_cont">
              <div class="sms_template_simple p-2" @click="this.launch(sms_template.id, index, $router)" >
                  <img :src="sms_template.large_url">
                  <span class="badge locked-template" v-if="index > 2"> UPGRADE </span>
                  <br>
                  <br>
                  <b>{{ sms_template.name }}</b>
                  <p class="" style="margin-top:5px; font-size:13px">
                      {{ sms_template.description }}
                  </p>
              </div>
              </div>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
    import DashboardLayout from "@/layouts/dashboard";
    import { useSmsTemplateStore } from "@/stores/smsTemplate";
    import { useUserStore } from "@/stores/user";
    import { useAccountStore } from "@/stores/account";
    import { useLicenceStore } from "@/stores/licence";
    import { Modal } from "bootstrap";

    export default {
        name: "templates",
        components: {
            DashboardLayout,
        },
        data() {
            return {
                modalValidation: null,
                paymentValidation: null,
            };
        },
        async mounted() {
            await this.templateStore.getSmsTemplates({ vm: this });
            this.modalValidation = new Modal(this.$refs.upgradeModal);
            this.paymentValidation = new Modal(this.$refs.paymentModal);
        },
        setup() {
            const templateStore = useSmsTemplateStore();
            const userStore = useUserStore();
            const accountStore = useAccountStore();
            const licenceStore = useLicenceStore();
            return {
                templateStore,
                userStore,
                accountStore,
                licenceStore
            };
        },
        methods: {
            onModalHidden() {
                this.paymentValidation.hide();
                this.$router.push("/pricing-sms");
            },
            launch(id, index, router) {
                if(this.licenceStore.sms) {
                    if(index > 2) {
                        this.modalValidation.show();
                    } else {
                        router.push('/campagne/sms/template/' + id + '/magic');
                    }
                }else{
                    this.paymentValidation.show();
                }
            },
        }
    };
</script>

<style scoped>
    .sms_template_simple {
        cursor: pointer;
        border-radius:15px;
        height:265px;
    }
    .sms_template_cont {
        cursor: pointer;
        border-radius:15px;
        height:265px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
    .sms_template_simple img {
        width:60px;
        height:60px;
        border:2px solid #fff;
        -moz-box-shadow: 0px 6px 5px #ccc;
        -webkit-box-shadow: 0px 6px 5px #ccc;
        box-shadow: 0px 6px 5px #ccc;
        -moz-border-radius:190px;
        -webkit-border-radius:190px;
        border-radius:190px;
    }
    .sms_template_simple:hover {
        /*box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;*/
        box-shadow: inset 0 0 0 1000px rgba(191, 146, 251, 0.2);
        border: 1px solid #a962fa !important;
    }
    .locked-template {
        float:right;
        font-size: 9px;
        background-color : rgba(178, 173, 232, 0.12);
        color : #a962fa !important;
        border: 1px solid #a962fa !important;
    }
    .image_upgrade {
        width: 350px;
        height: 350px;
    }
    .center {
        text-align: center !important;
    }
    .bg-transparent-popup {
        background-color : rgba(178, 173, 232, 0.12);
    }

    .upgrade_content {
        margin-top: 135px;
        font-size: 16px;
        padding: 10px 10px 40px 10px;
    }

</style>
