<template>
  <div class="browser-states">
    <div class="d-flex flex-row">
      <img :src="getImage(name)" class="rounded me-1" height="40" :alt="name" />
      <h6 class="align-self-center mb-0">{{ name }}</h6>
    </div>
    <div class="d-flex align-items-center" style="position: relative">
      <span class="percent">{{ percent }}%</span>
      <div class="fw-bold text-body-heading">
        <circle-progress
          :percent="percent"
          size="35"
          border-width="5"
          border-bg-width="5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useDashboardStore } from "@/stores/dashboard";

import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";

export default {
  name: "Display",
  components: { CircleProgress },
  props: {
    percent: Number,
    name: String,
  },
  setup() {
    const dashboardStore = useDashboardStore();
    return { dashboardStore };
  },
  computed: {},
  methods: {
    getImage(name) {
      let image = "";
      if (name == "Google Ads") {
        image = "ico-google.png";
      } else if (name == "Facebook") {
        image = "ico-facebook.png";
      } else if (name == "Bing Ads") {
        image = "ico-bing.png";
      } else if (name == "Youtube") {
        image = "ico-youtube.png";
      }
      return require("@/assets/images/" + image);
    },
  },
};
</script>

<style scoped>
.percent {
  font-weight: bold;
  margin-right: 10px;
}
.card-browser-states .browser-states {
  margin-top: 0.8rem;
}
</style>
