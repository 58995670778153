<template>
  <div>
    <div class="modal fade" ref="deleteModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Supprimer</h5>
            <button
                type="button"
                class="btn-close"
                @click="modalDelete.hide()"
                aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Etes-vous sûr de vouloir supprimer l'accès de cet utilisateur ?
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                @click="modalDelete.hide()"
            >
              Annuler
            </button>
            <button
                type="button"
                class="btn btn-primary"
                @click="actionDelete()"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="alert alert-success p-1">
          👉 Note: Nous ajoutons votre CRM sur demande à support@lead-ia.com
        </div>
      </div>
    </div>
    <div class="row crm-row">
      <div class="crm-container" v-for="(crm, index) in crmList" :key="index">
        <div class="crm">
          <img :src="getLogo(crm)" />
        </div>
      </div>
    </div>
    <section id="datatable">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div
                id="DataTables_Table_0_wrapper"
                class="dataTables_wrapper dt-bootstrap5 no-footer"
            >
              <div
                  class="d-flex justify-content-end align-items-center mx-0 row m-1"
              >
                <div class="col-sm-12 col-md-8 float-right">
                  <div style="text-align: right">
                    <button
                            class="btn btn-primary"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#formAddWebhook"
                    >
                      Ajouter un webhook
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table
                    class="datatables-basic table dataTable no-footer dtr-column"
                    role="grid"
                >
                  <thead>
                  <tr role="row">
                    <th v-for="(header, index) in headers" :key="index">
                      <span>{{ header.text }}</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      role="row"
                      v-for="(item, index) in sortedItems"
                      :key="index"
                  >
                    <th
                        style="white-space: nowrap;"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#formAddWebhook"
                    >
                      {{ item.url }}
                    </th>
                    <th>
                      <span
                          class="badge"
                          :class="{ 'badge-light-success': item.on_create, 'badge-light-secondary': !item.on_create }"
                      >{{ item.on_create ? "actif" : "inactif" }}</span>
                    </th>
                    <th>
                      <span
                          class="badge"
                          :class="{ 'badge-light-success': item.on_update, 'badge-light-secondary': !item.on_update }"
                      >{{ item.on_update ? "actif" : "inactif" }}</span>
                    </th>
                    <th>
                      <span
                          class="badge"
                          :class="{ 'badge-light-success': item.on_completed, 'badge-light-secondary': !item.on_completed }"
                      >{{ item.on_completed ? "actif" : "inactif" }}</span>
                    </th>

                    <th>
                      <div class="d-flex ">
                        <div class="avatar bg-light-primary">
                          <div class="avatar-content">
                            <vue-feather
                                type="edit"
                                @click="openItem(item)"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#formEditWebhook"
                            ></vue-feather>
                          </div>
                        </div>
                        <div class="avatar bg-light-danger">
                          <div
                              class="avatar-content"
                              @click="openModalDelete(item.id)"
                          >
                            <vue-feather type="trash"></vue-feather>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-between mx-0 row">
                <div class="col-sm-12 col-md-6">
                  <div
                      class="dataTables_info"
                      id="DataTables_Table_0_info"
                      role="statut"
                      aria-live="polite"
                  >
                    Affichage de {{ offset + 1 }} à {{ end }} entrées sur
                    {{ totalItems }}
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="DataTables_Table_0_paginate"
                  >
                    <ul class="pagination">
                      <li
                          v-if="offset > 0"
                          @click="previousPage()"
                          class="paginate_button page-item previous disabled"
                          id="DataTables_Table_0_previous"
                      >
                        <a
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                        >&nbsp;</a
                        >
                      </li>
                      <li
                          role="presentation"
                          class="page-item"
                          :class="{ active: index == offset / perPage }"
                          v-for="(page, index) in Math.round(nbPages)"
                      >
                        <button
                            v-if="index <= 5"
                            role="menuitemradio"
                            type="button"
                            class="page-link"
                            @click="setPage(index)"
                        >
                          {{ index + 1 }}
                        </button>
                      </li>
                      <li
                          v-if="offset + perPage < totalItems"
                          @click="nextPage()"
                          class="paginate_button page-item next disabled"
                          id="DataTables_Table_0_next"
                      >
                        <a
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                        >&nbsp;</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <form
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="formAddWebhook"
        @submit.prevent="actionSave"
        ref="addWebhookToggle"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Ajouter un webhook</h5>
        <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="mb-3">
          <label>URL *</label>
          <input type="text" class="form-control" v-model="url" />
          <div
              class="input-errors"
              v-for="(error, index) of v$.url.$errors"
              :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div>
          <p>Import du Lead : </p>
        </div>
        <div class="mb-3">
          <label>lors de la création</label>
          <div class="form-check form-check-primary form-switch">
            <input type="checkbox" v-model="onCreate" class="form-check-input switch" />
          </div>
        </div>
        <div class="mb-3">
          <label>lors de la mise à jour</label>
          <div class="form-check form-check-primary form-switch">
            <input type="checkbox" v-model="onUpdate" class="form-check-input switch" />
          </div>
        </div>
        <div class="mb-3">
          <label>1 minute après la complétion (le lead est identifié comme complet lors que nous avons l'email et le numéro de téléphone)</label>
          <div class="form-check form-check-primary form-switch">
            <input type="checkbox" v-model="onCompleted" class="form-check-input switch" />
          </div>
        </div>
        <div class="mb-3">
          <button class="btn btn-primary">Ajouter</button>
          <button
              type="button"
              class="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
          >
            Annuler
          </button>
        </div>
      </div>
    </form>
    <form
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="formEditWebhook"
        @submit.prevent="actionUpdate"
        ref="editWebhookToggle"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Modifier un webhook</h5>
        <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="mb-3">
          <label>Url *</label>
          <input type="text" class="form-control" v-model="url" />
          <div
              class="input-errors"
              v-for="(error, index) of v$.url.$errors"
              :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3">
          <label>Lors de la création</label>
          <div class="form-check form-check-primary form-switch">
            <input type="checkbox" v-model="onCreate" class="form-check-input switch" />
          </div>
        </div>
        <div class="mb-3">
          <label>Lors de la mise à jour</label>
          <div class="form-check form-check-primary form-switch">
            <input type="checkbox" v-model="onUpdate" class="form-check-input switch" />
          </div>
        </div>
        <div class="mb-3">
          <label>1 minute après la complétion</label>
          <div class="form-check form-check-primary form-switch">
            <input type="checkbox" v-model="onCompleted" class="form-check-input switch" />
          </div>
        </div>
        <div class="mb-3">
          <button class="btn btn-primary">Enregistrer</button>
          <button
              type="button"
              class="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
          >
            Annuler
          </button>
        </div>
      </div>
    </form>
  </div>
</template>


<script>

import { useAuthStore } from "@/stores/auth";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { Modal } from "bootstrap";
import {useWebhookStore} from "@/stores/webhook";

export default {
  name: "settingIntegration",
  data() {
    return {
      items: this.webhookStore.webhooks,
      headers: [
        { text: "Url", value: "url" },
        { text: "A la Création", value: "onCreate" },
        { text: "A la Mise à Jour", value: "onUpdate" },
        { text: "A la Completion", value: "onCompleted" },
        { text: "Actions", value: "actions" },
      ],
      modalDelete: null,
      id: null,
      url: null,
      onCreate: false,
      onUpdate: false,
      onCompleted: false,
      webhookHeaders: null,
      crmList: ["oscar", "eudonet", "hubspot", "salesforce", "ms-dynamics", "brevo", "t4s"],
    };
  },
  "webhookStore.webhooks": function (newFilter, oldFilter) {
    this.items = this.webhookStore.webhooks;
  },
  validations() {
    return {
      url: {
        required: helpers.withMessage("Url requis", required),
      },
    };
  },
  setup() {
    const authStore = useAuthStore();
    const webhookStore = useWebhookStore()
    return {
      v$: useVuelidate(),
      authStore,
      webhookStore,
    };
  },
  async mounted() {
    this.items = await this.webhookStore.getWebhooks({ vm: this });
    this.modalDelete = new Modal(this.$refs.deleteModal);
  },
  watch: {
    "webhookStore.webhooks": function (newFilter, oldFilter) {
      this.items = this.webhookStore.webhooks;
    },
  },
  methods: {
    getLogo(crm) {
      return require("@/assets/images/crm/" + crm + ".png");
    },
    openModalDelete(id) {
      this.id = id;
      this.modalDelete.show();
    },
    openItem({ id, url, on_create: onCreate, on_update: onUpdate, on_completed: onCompleted, headers }) {
      this.id = id;
      this.url = url;
      this.onCreate = onCreate;
      this.onUpdate = onUpdate;
      this.onCompleted = onCompleted;
      this.webhookHeaders = headers;
    },
    async setItem({ id, url, onCreate, onUpdate, onCompleted, headers}) {
      const data = {
        id, url, onCreate, onUpdate, onCompleted, headers,
        vm: this,
      };
      await this.webhookStore.updateWebhook(data);
    },
    async actionSave(e) {
      e.preventDefault();
      await this.v$.$validate();
      if (this.v$.$error) {
        this.$toast.open({
          message: "Un ou plusieurs champs requis ne sont pas renseignés.",
          type: "error",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
        return;
      }
      const data = {
        url: this.url,
        onCreate: this.onCreate,
        onUpdate: this.onUpdate,
        onCompleted: this.onCompleted,
        headers: this.webhookHeaders,
        vm: this,
      };
      await this.webhookStore.createWebhook(data);
      console.log("webhook created with data", data);
      this.items = await this.webhookStore.getWebhooks({ vm: this });
    },
    async actionUpdate(e) {
      e.preventDefault();
      const data = {
        id: this.id,
        url: this.url,
        onCreate: this.onCreate,
        onUpdate: this.onUpdate,
        onCompleted: this.onCompleted,
        headers: this.webhookHeaders,
        vm: this,
      };
      const _ = await this.webhookStore.updateWebhook(data);
      this.items = await this.webhookStore.getWebhooks({ vm: this });
    },
    async actionDelete() {
      await this.webhookStore.deleteWebhook({ id: this.id, vm: this });
      this.items = await this.webhookStore.getWebhooks({ vm: this });
      this.modalDelete.hide();
    },
  },
};
</script>

<style scoped>
.crm-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 30px;
}
.crm-container {
  flex: 1;
  display: flex;
  height: 120px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}
.crm {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  z-index: 2;
}

@media screen and (max-width: 991.98px) {
  .crm {
    width: 100px;
  }
}

.crm img {
  width: 100%;
  //max-height: 98px;
  z-index: 555;
}
.avatar {
  margin: 5px;
}
.avatar .avatar-content {
  width: 30px;
  height: 30px;
}

button {
  margin-right: 20px;
}
</style>
