<template>
  <div class="accordion-item">
    <div class="accordion-header" id="headingMarginOne">
      <button
        class="accordion-button accordion-button-hide collapsed"
        type="button"
        aria-expanded="false"
        aria-controls="accordionMarginOne"
        style="padding: 0"
      >
        <div
          class="alert alert-primary"
          style="width: 100%; margin-right: 20px"
        >
          {{ element.name }}
        </div>
      </button>
      <div class="campus-buttons" style="min-width: 200px">


        <div class="avatar accordion-round accordion-button collapsed"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#accordionMarginOne' + element.id"
                  aria-expanded="false"
                  aria-controls="accordionMarginOne">
        </div>

        <div class="avatar bg-light-info">
          <div class="avatar-content">
            <vue-feather
              type="copy"
              @click="$emit('duplicateCampus', element)"
            ></vue-feather>
          </div>
        </div>
        <div class="avatar bg-light-danger">
          <div
            class="avatar-content"
            @click="$emit('removeCampus', element.id)"
          >
            <vue-feather type="trash"></vue-feather>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="'accordionMarginOne' + element.id"
      class="accordion-collapse collapse"
      aria-labelledby="headingMarginOne"
      data-bs-parent="#accordionMargin"
      style=""
    >
      <div class="accordion-body">
        <div class="tag-input mb-1">
          <label>Visiteurs de l’étranger</label><br />
          <input
            v-model="newTag.email_list_foreign"
            @keydown.enter.prevent="
              clickHandlerAdd($event, 'email_list_foreign')
            "
            @keydown.prevent.tab="clickHandlerAdd($event, 'email_list_foreign')"
            type="text"
            class="form-control email_list_foreign"
          />
          <ul class="tags">
            <li
              v-for="(tag, index) in element['email_list_foreign']"
              :key="index"
              class="tag"
            >
              {{ tag.text }}
              <button
                type="button"
                class="delete"
                @click="
                  $emit('removeTag', {
                    id: element.id,
                    index: index,
                    liste: 'email_list_foreign',
                  })
                "
              >
                x
              </button>
            </li>
          </ul>
        </div>
        <div class="tag-input mb-1">
          <label>Formation diplômante</label><br />
          <input
            v-model="newTag.email_list"
            @keydown.enter.prevent="clickHandlerAdd($event, 'email_list')"
            @keydown.prevent.tab="clickHandlerAdd($event, 'email_list')"
            type="text"
            class="form-control email_list"
          />
          <ul class="tags">
            <li
              v-for="(tag, index) in element['email_list']"
              :key="index"
              class="tag"
            >
              {{ tag.text }}
              <button
                type="button"
                class="delete"
                @click="
                  $emit('removeTag', {
                    id: element.id,
                    index: index,
                    liste: 'email_list',
                  })
                "
              >
                x
              </button>
            </li>
          </ul>
        </div>
        <div class="tag-input mb-1">
          <label>Formation professionnelles</label><br />
          <input
            v-model="newTag.email_list_pro"
            @keydown.enter.prevent="clickHandlerAdd($event, 'email_list_pro')"
            @keydown.prevent.tab="clickHandlerAdd($event, 'email_list_pro')"
            type="text"
            class="form-control"
          />
          <ul class="tags">
            <li
              v-for="(tag, index) in element['email_list_pro']"
              :key="index"
              class="tag"
            >
              {{ tag.text }}
              <button
                type="button"
                class="delete"
                @click="
                  $emit('removeTag', {
                    id: element.id,
                    index: index,
                    liste: 'email_list_pro',
                  })
                "
              >
                x
              </button>
            </li>
          </ul>
        </div>
        <div class="tag-input mb-1">
          <label>Relation entreprises</label><br />
          <input
            v-model="newTag.email_list_entreprise"
            @keydown.enter.prevent="clickHandlerAdd($event, 'email_list_entreprise')"
            @keydown.prevent.tab="clickHandlerAdd($event, 'email_list_entreprise')"
            type="text"
            class="form-control"
          />
          <ul class="tags">
            <li
              v-for="(tag, index) in element['email_list_entreprise']"
              :key="index"
              class="tag"
            >
              {{ tag.text }}
              <button
                type="button"
                class="delete"
                @click="
                  $emit('removeTag', {
                    id: element.id,
                    index: index,
                    liste: 'email_list_entreprise',
                  })
                "
              >
                x
              </button>
            </li>
          </ul>
        </div>

        <div class="mb-1">
          <label>Numéro de mobile (SMS RAPPEL IMMÉDIAT)</label><br />

          <input
                  type="tel"
                  pattern="0[6-7][0-9]{8}"
                  class="form-control"
                  v-model="element.phone"
          />

        </div>

        <div class="mb-1">
          <label>Région</label><br />
          <select class="form-select form-select-lg" v-model="element.region">

            <option value=null>Aucune</option>
            <option value="Auvergne-Rhône-Alpes">Auvergne-Rhône-Alpes</option>
            <option value="Bourgogne-Franche-Comté">Bourgogne-Franche-Comté</option>
            <option value="Bretagne">Bretagne</option>
            <option value="Centre-Val de Loire">Centre-Val de Loire</option>
            <option value="Corse">Corse</option>
            <option value="Grand Est">Grand Est</option>
            <option value="Hauts-de-France">Hauts-de-France</option>
            <option value="Ile-de-France">Ile-de-France</option>
            <option value="Normandie">Normandie</option>
            <option value="Nouvelle-Aquitaine">Nouvelle-Aquitaine</option>
            <option value="Occitanie">Occitanie</option>
            <option value="Pays de la Loire">Pays de la Loire</option>
            <option value="Provence Alpes Côte d’Azur">Provence Alpes Côte d’Azur</option>
            <option value="Régions d’Outre-Mer">Régions d’Outre-Mer</option>

          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "campusRow",
  props: {
    element: Object,
  },
  data() {
    return {
      newTag: {
        email_list_foreign: "",
        email_list: "",
        email_list_pro: "",
        email_list_entreprise: "",
        phone: "",
      },
    };
  },
  methods: {
    clickHandlerAdd: function (e, liste) {
      e.target.classList.remove("error");
      console.log(liste);
      if (!this.validateEmail(this.newTag[liste])) {
        e.target.classList.add("error");
        return;
      }
      this.$emit("addTag", {
        id: this.element.id,
        value: this.newTag[liste],
        liste: liste,
      });
      this.newTag[liste] = "";
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validatePhone(phone) {
      var re = /^0(6|7)[0-9]{8}/;
      return re.test(phone);
    },
  },
};
</script>

<style scoped>
.avatar {
  margin: 0 10px;
}
.accordion-header {
  display: flex;
}
.accordion-button {
  margin: 0 10px;
}
.tag-input {
  position: relative;
}
ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding: 0;
  top: 0.5;
  bottom: 0;
  left: 10px;
  max-width: 75%;
  overflow-x: auto;
}
.tag {
  background: rgba(115, 103, 240, 0.12) !important;
  padding: 2px 4px 2px 4px;
  margin: 8px 4px 0px 0px;
  font-size: 0.9em;
  border-radius: 4px;
  color: #7367f0;
  white-space: nowrap;
  transition: 0.1s ease background;
}
.delete {
  color: #7367f0;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.accordion-item {
  border: 1px solid #dfdee4 !important;
  border-radius: 10px !important;
  padding: 20px;
  margin: 15px 0;
}
.accordion-button-hide::after {
  width: 0rem;;
}
.accordion-round {
  border: 1px solid #dfdee4;
  border-radius: 30px !important;
  background: #fff;
  width: 35px;
  height: 35px;
  padding:10px;
}
</style>
