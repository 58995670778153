<template>
  <div>
    <DashboardLayout>
      <div class="row mb-3">
        <div class="col-lg-8">
          <h1>Bannieres</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Création d'une bannière</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="actionSave">
                <div class="row mb-2">
                  <div class="col-6" @dragover.prevent @drop.prevent>
                    <label class="form-label" style="width: 100%"
                      >Image de la bannière
                      <div
                        @drop="dragFile"
                        class="dropzone dropzone-area dz-clickable"
                        id="dpz-single-file"
                      >
                        <div class="dz-message">Télécharger une image</div>
                        <input
                          type="file"
                          id="upload"
                          @change="uploadFile"
                          style="display: none"
                        />
                      </div>
                      <div v-if="file">
                        {{ file.name }}
                      </div>
                      <div
                        class="input-errors"
                        v-for="(error, index) of v$.image.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="alert alert-success">
                  👉 Note: Dimensions : 1080*569px / Poids : 2Mo max. / Format : JPEG, JPEG ou PNG
                </div>
                <div class="mb-2">
                  <label class="form-label">Titre de la bannière</label>
                  <textarea
                    v-model="title"
                    class="form-control"
                    placeholder="Titre"
                  ></textarea>
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.title.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="mb-2">
                  <label class="form-label">Texte principal</label>
                  <textarea
                    v-model="text"
                    class="form-control"
                    placeholder="Text principal"
                  ></textarea>
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.text.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="mb-2">
                  <label class="form-label">Lien web</label>
                  <input
                    v-model="link"
                    class="form-control"
                    placeholder="Lien web"
                  />
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.link.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="mb-2">
                  <label class="form-label"
                    >Position dans la conversation</label
                  >
                  <select v-model="position" class="form-control">
                    <option value="TOP">Début de conversation</option>
                    <option value="MIDDLE">Milieu de conversation</option>
                    <option value="BOTTOM">Fin de conversation</option>
                  </select>
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.position.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="mb-2" v-if="position != 'TOP'">
                  <label class="form-label">Cible visiteur</label>
                  <multiselect :close-on-select="false"
                    track-by="code"
                    :options="optionsTarget"
                    v-model="target"
                    label="name"
                    placeholder="Sélectionnez dans la liste"
                    multiple
                    :taggable="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
                <div class="mb-2" v-if="position != 'TOP'">
                  <label class="form-label">Cible {{this.singularSectionName()}}</label>
                  <multiselect :close-on-select="false"
                    track-by="id"
                    :options="programStore.sections"
                    v-model="targetCampus"
                    placeholder="Sélectionnez dans la liste"
                    label="name"
                    multiple
                    :taggable="false"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
                <div class="mb-2">
                  <label class="form-label">Période d'activité</label>
                  <Datepicker
                    position="right top"
                    placeholder=""
                    lang="fr"
                    range
                    v-model="periode"
                    @input="updatePeriode()"
                    @change="updatePeriode()"
                    class="datepick"
                  ></Datepicker>
                </div>
                <div class="mb-2">
                  <button
                    type="submit"
                    class="btn btn-primary waves-effect waves-float waves-light"
                  >
                    Enregistrer
                  </button>
                  <button
                    type="cancel"
                    class="btn btn-outline-primary waves-effect waves-float waves-light"
                    @click="$router.push('/campagne/banniere')"
                  >
                    Annuler
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <img v-if="image" :src="image" />
            <img
              v-else
              class="card-img-top img-fluid"
              :src="campagneStore.banniere.large_url"
            />
            <div class="card-body">
              <small class="text-muted"
                >{{ formatDate(created_at, "LLL") }}
              </small>
              <h4 class="card-title">
                <a class="blog-title-truncate text-body-heading">{{ title }}</a>
              </h4>
              <p class="card-text blog-content-truncate">
                {{ text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";
import { useCampagneStore } from "@/stores/campagne";
import { useProgramStore } from "@/stores/program";
import { useLayoutStore } from "@/stores/layout";
import { useAccountStore } from "@/stores/account";
import { useLicenceStore } from "@/stores/licence";
import Multiselect from "vue-multiselect";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf, url } from "@vuelidate/validators";

export default {
  name: "BanniereDetail",
  components: {
    DashboardLayout,
    Multiselect,
  },
  data() {
    var optionsTarget = [];
    optionsTarget.push({ name: "Étudiant", code: "Étudiant" });
    optionsTarget.push({ name: "Sans emploi", code: "Sans emploi" });
    optionsTarget.push({ name: "Salarié en activité", code: "Salarié en activité" });
    optionsTarget.push({ name: "Parent", code: "Parent" });

    if(this.licenceStore.chatbot_entreprise){
      optionsTarget.push({ name: "Entreprise", code: "Entreprise" });
    }

    return {
      created_at: new Date(),
      title: "",
      text: "",
      link: "",
      position: "MIDDLE",
      optionsTarget: optionsTarget,
      initialTarget: this.campagneStore.banniere.target,
      target: [],
      optionsTargetCampus: this.programStore.sections,
      initialTargetCampus: this.campagneStore.banniere.target_campus,
      targetCampus: [],
      periode: [moment(), moment().add(7, "d")],
    };
  },
  validations() {
    return {
      image: {
        required: requiredIf(function () {
          return this.$route.name === "BanniereDetailAdd";
        }),
      },
      title: {
        required: helpers.withMessage("Titre requis", required),
      },
      text: {
        required: helpers.withMessage("Texte principal requis", required),
      },
      link: {
        url: helpers.withMessage("Le lien web doit être une url valide", url),
      },
      position: {
        required: helpers.withMessage("Position requise", required),
      },
    };
  },
  watch: {
    periode(newVal, oldVal) {
      console.log(newVal);
    },
    initialTarget: {
      immediate: true,
      handler(values) {
        if (!values || !this.initialTarget || this.initialTarget.length === 0)
          return;
        this.target = this.optionsTarget.filter((x) => {
          return values.includes(x["name"]);
        });
      },
    },
    initialTargetCampus: {
      immediate: true,
      handler(values) {
        if (
          !values ||
          !this.initialTargetCampus ||
          this.initialTargetCampus.length === 0
        )
          return;
        this.targetCampus = this.optionsTargetCampus.filter((x) =>
          values.includes(x["name"])
        );
      },
    },
  },
  computed: {},
  async mounted() {
    console.log(this.$route.name)
    this.layoutStore.setActive("banniere");
    if (this.$route.name === "BanniereDetail") {
      await this.getBanniere();
    } else {
      this.campagneStore.banniere = {};
    }
  },
  setup() {
    const campagneStore = useCampagneStore();
    const programStore = useProgramStore();
    const layoutStore = useLayoutStore();
    const accountStore = useAccountStore();
    const licenceStore = useLicenceStore();
    return {
      v$: useVuelidate(),
      campagneStore,
      programStore,
      layoutStore,
      accountStore,
      licenceStore
    };
  },
  methods: {
      singularSectionName() {
          if(this.accountStore.account.section_naming == "campus") return "campus";
          if(this.accountStore.account.section_naming == "school") return "école";
          if(this.accountStore.account.section_naming == "center") return "centre de formation";
          return "campus";
      },
    async getBanniere() {
      await this.campagneStore.getBanniere({
        id: this.$route.params.id,
        vm: this,
      });
      this.title = this.campagneStore.banniere.title;
      this.text = this.campagneStore.banniere.text;
      this.link = this.campagneStore.banniere.link;
      this.position = this.campagneStore.banniere.position;
      this.created_at = this.campagneStore.banniere.created_at;

      this.target = [];
      var t = JSON.parse(this.campagneStore.banniere.target);
      for (var i = 0; i < t.length; i++) { this.target.push({ name: t[i], code: t[i] }) }

      this.targetCampus = [];
      var tc = JSON.parse(this.campagneStore.banniere.target_campus);
      for (var i = 0; i < tc.length; i++) { this.targetCampus.push({ name: tc[i], code: tc[i] }) }

      this.periode[0] = moment(this.campagneStore.banniere.date_start);
      this.periode[1] = moment(this.campagneStore.banniere.date_end);
    },
    async actionSave(e) {
      e.preventDefault();
      await this.v$.$validate();
        if (this.v$.$error) {
            this.$toast.open({
                message: "Un ou plusieurs champs requis ne sont pas renseignés.",
                type: "error",
                position: "top-right",
                duration: 4000,
                dismissible: true,
            });
            return;
        }
      console.log(this.$route.name);
      console.log(this.periode);
      console.log(this.file);

      const data = {
        title: this.title,
        text: this.text,
        link: this.link,
        position: this.position,
        target: JSON.stringify(this.target.map((item) => {
          return item.name;
        })),
        targetCampus: JSON.stringify(this.targetCampus.map((item) => {
          return item.name;
        })),
        date_start: moment(this.periode[0]).format("YYYY-MM-DD"),
        date_end: moment(this.periode[1]).format("YYYY-MM-DD"),
        vm: this,
      };

      if (this.image) {
        const image = {
          filetype: this.file.type,
          filename: this.file.name,
          filesize: this.file.size,
          base64: this.image.substring(22),
        };
        data["image"] = JSON.stringify(image);
      }

      if (this.$route.name === "BanniereAdd") {
        await this.campagneStore.createBanniere(data);
      } else {
        data["id"] = this.$route.params.id;
        console.log(data);
        await this.campagneStore.updateBanniere(data);
      }
      await this.$router.push("/campagne/banniere/");
    },
  },
};
</script>

<style scoped>
button {
  margin-right: 20px;
}
.datepick {
  text-align: left;
}
</style>
