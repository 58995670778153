<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12 col-md-6">
          <div v-if="!this.dashboardStore.sms_repartition['sent'] || this.dashboardStore.sms_repartition['sent'].length === 0">
            Pas de données disponibles
          </div>
          <apexchart
                  v-else
                  type="radialBar"
                  max-height="100%"
                  height="300px"
                  :options="chartOptions"
                  :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import { watchEffect, ref, reactive, defineComponent } from "vue";
    import { useDashboardStore } from "@/stores/dashboard";
    import { toRaw } from "vue";

    export default defineComponent({
        name: "leads",
        data() {
            return {
                sms_repartition: null,
                activeIndex: 0,
                chartData: {},
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'Status des SMS envoyés'
                        },
                    },
                }
            };
        },
        mount() {
            this.sms_repartition = this.dashboardStore.sms_repartition;
        },
        setup() {
            const id = ref(0);
            const variants = ref(["primary", "danger", "warning", "success", "info"]);
            const variant = ref("primary");

            const dashboardStore = useDashboardStore();
            const labels = reactive(["Étudiant","Sans emploi","Salarié en activité","Parent"]);
            const series = reactive([]);
            let total = 0;
            let totalRep = 0;

            function getData(sms_repartition) {

                var resu = {};
                resu["Étudiant"] = 0;
                resu["Sans emploi"] = 0;
                resu["Salarié en activité"] = 0;
                resu["Parent"] = 0;

                if(sms_repartition && sms_repartition["clicked"])
                sms_repartition["clicked"].forEach((element) => {
                    resu[element[0]] = element[1];
                });

                /*** Circle values from dashboardStore.repartition ***/
                totalRep = Object.entries(resu)
                    .map((item) => {
                        return item[1];
                    })
                    .reduce((sum, current) => sum + current, 0);

                const repartition = Object.values(resu)

                for (let i = 0; i < repartition.length; i++) {
                    if (repartition[i] > 0 && totalRep > 0) {
                        series[i] = Math.round((repartition[i] / totalRep) * 100);
                    } else {
                        series[i] = 0;
                    }
                }
            }

            const chartOptions = {
                title: {
                    text: "Ouvertures par population",
                    align: 'center',
                    margin: 18,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '12px',
                        color:  '#605e5e'
                    },
                },
                chart: {
                    type: "radialBar",
                    height: '300px',
                    parentHeightOffset: 0,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 360,
                        hollow: {
                            margin: 5,
                            size: "30%",
                            background: "transparent",
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                fontSize: "22px",
                            },
                            value: {
                                fontSize: "16px",
                            },
                            total: {
                                show: true,
                                label: "Clics",
                                formatter: function (w) {
                                    return totalRep;
                                },
                            },
                        },
                        track: {
                            background: "#eee",
                            strokeWidth: "80%",
                            margin: 5,
                        },
                    },
                },
                stroke: {
                    lineCap: "round",
                },
                colors: ["#7367f0", "#ea5455", "#ff9f43", "#43e7b0"],
                labels: labels,
            };


            getData(dashboardStore.sms_repartition);
            watchEffect(() => {
                getData(dashboardStore.sms_repartition);
            });

            return {
                id,
                variants,
                variant,
                dashboardStore,
                chartOptions,
                series,
                total,
                totalRep
            };
        },
        computed: {
        },
        methods: {
            isActive(index) {
                if (index === this.activeIndex) {
                    return "active";
                }
            },
        },
    });
</script>

<style scoped>
</style>
