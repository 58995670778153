<template>
  <div>
    <DashboardLayout>
      <div class="row mb-2">
        <div class="col-lg-8">
          <h1>Contacts</h1>
        </div>
      </div>
      <div class="card">
        <div class="row mx-2 my-1">
          <div class="col-sm-4">
            <h2>Filtres</h2>
          </div>
        </div>
        <div class="row mx-2 mb-2 filter-box">
          <div class="col-sm-4">
            <multiselect
              :close-on-select="false"
              track-by="code"
              :options="optionsStatut"
              v-model="activeStatut"
              label="name"
              placeholder="Choisir un statut"
              multiple
              :taggable="false"
              :show-labels="false"
            >
            </multiselect>
          </div>
          <div class="col-sm-4" v-if="programStore.sections.length > 1">
            <multiselect
              :close-on-select="false"
              track-by="code"
              :options="optionsCampus"
              v-model="activeCampus"
              label="name"
              :placeholder="'Choisir ' + this.singularSectionNameWithPronom()"
              multiple
              :taggable="false"
              :show-labels="false"
            >
            </multiselect>
          </div>
          <div class="col-sm-4">
            <multiselect
              :close-on-select="false"
              track-by="code"
              :options="optionsLevel"
              v-model="activeLevel"
              label="name"
              placeholder="Choisir un niveau"
              multiple
              :taggable="false"
              :show-labels="false"
            >
            </multiselect>
          </div>
        </div>
      </div>
      <section id="datatable">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div
                id="DataTables_Table_0_wrapper"
                class="dataTables_wrapper dt-bootstrap5 no-footer"
              >
                <div
                  class="d-flex justify-content-between align-items-center mx-0 row"
                >
                  <div class="col-sm-12 col-md-4">
                    <div class="dataTables_length">
                      <label
                        >Afficher<select
                          class="form-select"
                          v-model="perPage"
                          style="display: inline"
                        >
                          <option
                            v-for="(show, index) in showList"
                            :value="show"
                            :key="index"
                          >
                            {{ show }}
                          </option>
                        </select>
                        résultats par page</label
                      >
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div style="text-align: right; padding-top: 10px">
                      <label>
                        <input
                          v-model="filterSearch"
                          class="form-control search-input"
                          placeholder="Recherche..."
                          style="
                            width: auto;
                            display: inline;
                            margin-right: 50px;
                          "
                        />
                      </label>
                      <button
                        class="btn btn-primary"
                        :disabled="emailExtracted"
                        @click="sendReport()"
                      >
                        Extraction via email
                      </button>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table
                    class="datatables-basic table dataTable no-footer dtr-column"
                    role="grid"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          v-for="(header, index) in headers"
                          :key="index"
                          :class="{
                            sorting: header.sort,
                            sorting_asc: header.sort === 'asc',
                            sorting_desc: header.sort === 'desc',
                          }"
                          @click="sortItems(index, header.sortBy)"
                        >
                          <span>{{ header.text }}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        role="row"
                        v-for="(item, index) in sortedItems"
                        :key="index"
                        class="cursor-pointer"
                      >
                        <th
                          @click="$router.push('/contact/' + item.id)"
                          style="min-width: 230px"
                        >
                          <span
                            :class="
                              licenceStore.chatbot_tracking ||
                              userStore.is_leadia_admin
                                ? ''
                                : 'grayscale'
                            "
                            style="display: inline-block; vertical-align: top"
                          >
                            <div style="margin-top: 4px">
                              <div
                                class="avatar"
                                :class="
                                  item.meta['tags']
                                    ? 'success'
                                    : getColor(item.meta.score)
                                "
                              >
                                <div
                                  class="avatar-content"
                                  v-if="
                                    notEmptyObject(item) && item.meta.firstname
                                  "
                                >
                                  {{ item.meta.firstname.charAt(0) }}
                                  {{
                                    item.meta.lastname
                                      ? item.meta.lastname.charAt(0)
                                      : ""
                                  }}
                                </div>
                              </div>
                            </div>
                          </span>

                          <span style="display: inline-block">
                            <div style="margin-left: 20px">
                              <b style="vertical-align: middle">{{
                                showName(item)
                              }}</b>
                              <country-flag
                                style="vertical-align: middle"
                                v-if="item.country != 'fr'"
                                :country="item.country"
                                size="small"
                              />
                              <br />
                              <span class="email elipsis">{{
                                item.meta.email
                              }}</span>
                            </div>
                          </span>
                        </th>
                        <th @click="$router.push('/contact/' + item.id)">
                          <span>{{
                            formatDate(item.created_at, "DD/MM/YYYY HH:mm")
                          }}</span>
                        </th>
                        <th @click="$router.push('/contact/' + item.id)">
                          <span v-html="formatPhone(item.meta.phone)"></span>
                        </th>
                        <th @click="$router.push('/contact/' + item.id)">
                          {{ item.diploma_level }}
                        </th>
                        <th
                          @click="$router.push('/contact/' + item.id)"
                          v-if="optionsCampus.length > 1"
                        >
                          {{ item.meta.campus }}
                        </th>
                        <th>
                          <multiselect
                            :open-direction="index < 4 ? 'bottom' : undefined"
                            :close-on-select="true"
                            :options="optionsLeadStatut"
                            :modelValue="item.meta.lead_status || 'Nouveau'"
                            placeholder="Choisir un statut"
                            @select="onSelectLeadStatus"
                            :taggable="false"
                            :show-labels="false"
                            :id="item.id"
                            :maxHeight="350"
                          ></multiselect>
                        </th>
                        <th @click="$router.push('/contact/' + item.id)">
                          <span
                            class="badge"
                            :class="classColor('badge-light', item.statut)"
                            >{{ item.statut }}</span
                          >
                        </th>
                        <th
                          style="text-align: center"
                          @click="$router.push('/contact/' + item.id)"
                        >
                          <div
                            v-if="
                              !licenceStore.chatbot_tracking &&
                              !userStore.is_leadia_admin
                            "
                            style="
                              font-size: 30px;
                              position: absolute;
                              margin: -15px 0 0 10px;
                            "
                          >
                            *
                          </div>
                          <div
                            v-if="
                              licenceStore.chatbot_tracking ||
                              userStore.is_leadia_admin
                            "
                          >
                            <div
                              v-if="
                                item.meta['displays'] &&
                                item.meta['displays'].length == 1
                              "
                              class="d-flex flex-row"
                            >
                              <img
                                v-if="item.meta['displays'][0] == 1"
                                src="@/assets/images/ico-google.png"
                                class="rounded me-1"
                                height="38"
                              />
                              <img
                                v-if="item.meta['displays'][0] == 2"
                                src="@/assets/images/ico-facebook.png"
                                class="rounded me-1"
                                height="38"
                              />
                              <img
                                v-if="item.meta['displays'][0] == 3"
                                src="@/assets/images/ico-bing.png"
                                class="rounded me-1"
                                height="38"
                              />
                              <img
                                v-if="item.meta['displays'][0] == 4"
                                src="@/assets/images/ico-youtube.png"
                                class="rounded me-1"
                                height="38"
                              />
                            </div>

                            <Popper
                              v-if="
                                item.meta['displays'] &&
                                item.meta['displays'].length > 1
                              "
                              hover
                            >
                              <div class="avatar bg-light-secondary ms-1">
                                <div class="avatar-content">
                                  +{{ item.meta["displays"].length }}
                                </div>
                              </div>
                            </Popper>
                          </div>
                        </th>
                        <th
                          style="text-align: center"
                          @click="$router.push('/contact/' + item.id)"
                        >
                          <div
                            :class="
                              licenceStore.chatbot_tracking ||
                              userStore.is_leadia_admin
                                ? ''
                                : 'blur-grayscale'
                            "
                            v-if="!item.meta.tag"
                            v-html="formatScore(item.meta.score)"
                          ></div>

                          <div v-if="item.meta.tag" class="avatar success">
                            <div class="avatar-content">
                              <img
                                src="@/assets/images/ico-qr.png"
                                height="28"
                              />
                            </div>
                          </div>
                          <div style="font-size: 11px" v-if="item.meta.tag">
                            {{ item.meta["tag"] }}
                          </div>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="d-flex justify-content-between mx-0 row">
                  <div class="col-sm-12 col-md-6">
                    <div
                      class="dataTables_info"
                      id="DataTables_Table_0_info"
                      role="statut"
                      aria-live="polite"
                    >
                      Affichage de {{ offset + 1 }} à {{ end }} entrées sur
                      {{ totalItems }}
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="DataTables_Table_0_paginate"
                    >
                      <ul class="pagination">
                        <li
                          v-if="offset > 0"
                          @click="previousPage()"
                          class="paginate_button page-item previous disabled"
                          id="DataTables_Table_0_previous"
                        >
                          <a
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            >&nbsp;</a
                          >
                        </li>
                        <li
                          role="presentation"
                          class="page-item"
                          :class="{ active: index == offset / perPage }"
                          v-for="(page, index) in Math.round(nbPages)"
                        >
                          <button
                            v-if="index <= 5"
                            role="menuitemradio"
                            type="button"
                            class="page-link"
                            @click="setPage(index)"
                          >
                            {{ index + 1 }}
                          </button>
                        </li>
                        <li
                          v-if="offset + perPage < totalItems"
                          @click="nextPage()"
                          class="paginate_button page-item next disabled"
                          id="DataTables_Table_0_next"
                        >
                          <a
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                            >&nbsp;</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";
import { useContactStore } from "@/stores/contact";
import { useProgramStore } from "@/stores/program";
import { useAccountStore } from "@/stores/account";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";
import Multiselect from "vue-multiselect";
import _ from "lodash";

export default {
  name: "Contacts",
  components: {
    DashboardLayout,
    Multiselect,
  },
  data() {
    return {
      emailExtracted: false,
      items: this.contactStore.contacts,
      headers: [],
      filterSearch: "",
      activeStatut: [],
      optionsStatut: [
        { name: "Étudiant", code: "Étudiant" },
        { name: "Sans emploi", code: "Sans emploi" },
        { name: "Salarié en activité", code: "Salarié en activité" },
        { name: "Parent", code: "Parent" },
      ],
      activeLevel: [],
      optionsLevel: [
        { name: "4e", code: "4e" },
        { name: "3e", code: "3e" },
        { name: "CAP/BEP", code: "CAP/BEP" },
        { name: "2nde", code: "2nde" },
        { name: "1ere Pro", code: "1ere Pro" },
        { name: "1ere", code: "1ere" },
        { name: "Terminale/BAC Pro", code: "Terminale/BAC Pro" },
        {
          name: "Terminale/BAC Technologique",
          code: "Terminale/BAC Technologique",
        },
        { name: "Terminale/BAC", code: "Terminale/BAC" },
        { name: "BAC+1", code: "BAC+1" },
        { name: "BAC+2", code: "BAC+2" },
        { name: "BAC+3", code: "BAC+3" },
        { name: "BAC+4", code: "BAC+4" },
        { name: "BAC+5", code: "BAC+5" },
      ],
      activeCampus: [],
      optionsCampus: [],
      optionsLeadStatut: [
        "Nouveau",
        "Traité",
        "Répondeur",
        "Pas intéressé",
        "Intéressé",
      ],
    };
  },
  watch: {
    "programStore.sections": function (newFilter, oldFilter) {
      var optionsCampus = [];
      this.programStore.sections.forEach((obj) => {
        optionsCampus.push({ name: obj.name, code: obj.name });
      });
      this.optionsCampus = optionsCampus;
      this.activeLevel = [];
      this.activeStatut = [];
      this.activeCampus = [];
      this.filterSearch = "";

      var headers = [];
      headers.push({
        text: "Nom",
        value: "name",
        sort: "asc",
        sortBy: "meta.firstname",
      });
      headers.push({
        text: "Date",
        value: "created_at",
        sort: "asc",
        sortBy: "created_at",
      });
      headers.push({
        text: "Telephone",
        value: "meta.phone",
        sortBy: "meta.firstname",
      });
      headers.push({
        text: "Niveau d'etude",
        value: "diploma_level",
        sortBy: "diploma_level",
      });
      if (optionsCampus.length > 1) {
        headers.push({
          text: this.singularSectionName(),
          value: "meta.campus",
          sortBy: "meta.campus",
        });
      }
      headers.push({ text: "Statut Lead", value: "lead_statut" });
      headers.push({ text: "Statut", value: "statut" });
      headers.push({ text: "Ads" });
      headers.push({
        text: "Lead Score",
        value: "meta.score",
        sort: "asc",
        sortBy: "meta.score",
      });
      this.headers = headers;
    },
    "contactStore.contacts": function (newContacts, oldcontacts) {
      this.items = newContacts;
    },
    activeStatut: function (newFilter, oldFilter) {
      this.filterItems();
    },
    activeLevel: function (newFilter, oldFilter) {
      this.filterItems();
    },
    activeCampus: function (newFilter, oldFilter) {
      this.filterItems();
    },
    filterSearch: function (newFilter, oldFilter) {
      this.filterItems();
    },
  },
  async mounted() {
    await this.contactStore.getContacts({ vm: this });
    await this.programStore.getSections({ vm: this });
  },
  setup() {
    const contactStore = useContactStore();
    const programStore = useProgramStore();
    const accountStore = useAccountStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return {
      contactStore,
      programStore,
      accountStore,
      licenceStore,
      userStore,
    };
  },
  methods: {
    singularSectionNameWithPronom() {
      if (this.accountStore.account.section_naming == "campus")
        return "un campus";
      if (this.accountStore.account.section_naming == "school")
        return "une école";
      if (this.accountStore.account.section_naming == "center")
        return "un centre de formation";
      return "un campus";
    },
    singularSectionName() {
      if (this.accountStore.account.section_naming == "campus") return "Campus";
      if (this.accountStore.account.section_naming == "school") return "École";
      if (this.accountStore.account.section_naming == "center")
        return "Centre de formation";
      return "Campus";
    },
    showName(item) {
      const result =
        item.meta.firstname +
        " " +
        (item.meta.lastname ? item.meta.lastname : "");
      if (result.length > 15) {
        return result.substring(0, 15) + "...";
      }
      return result;
    },
    filterItems() {
      let filteredItems = this.contactStore.contacts;
      this.setPage(0);

      if (this.activeStatut.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          return (
            item.statut &&
            this.activeStatut.findIndex((v) => v.code === item.statut) >= 0
          );
        });
      }
      if (this.activeCampus.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          return (
            item.meta.campus &&
            this.activeCampus.findIndex(
              (v) => v.code.toLowerCase() === item.meta.campus.toLowerCase()
            ) >= 0
          );
        });
      }
      if (this.activeLevel.length > 0) {
        filteredItems = filteredItems.filter((item) => {
          return (
            item.diploma_level &&
            this.activeLevel.findIndex((v) => v.code === item.diploma_level) >=
              0
          );
        });
      }

      if (this.filterSearch !== "") {
        filteredItems = filteredItems.filter((item) => {
          return (
            (item.meta.firstname &&
              item.meta.firstname
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.filterSearch
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )) ||
            (item.meta.lastname &&
              item.meta.lastname
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.filterSearch
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ))
          );
        });
      }
      this.items = filteredItems;
    },
    sendReport() {
      this.emailExtracted = true;
      this.contactStore
        .sendReport({
          vm: this,
        })
        .then(() => {
          this.emailExtracted = false;
        });
    },
    safeGet(obj, prop, defaultValue) {
      try {
        return obj[prop];
      } catch (e) {
        return defaultValue;
      }
    },
    onSelectLeadStatus(selectedItem, id) {
      this.contactStore.updateLeadStatus({
        vm: this,
        id,
        leadStatus: selectedItem,
      });
    },
  },
};
</script>

<style scoped>
.email {
  font-size: 0.75rem;
  font-weight: 300;
}
.dataTable {
  font-size: 0.875rem;
  font-weight: 400;
}
.profile-title {
  display: inline;
}
.elipsis {
  max-width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.tooltip {
  width: 200px;
  background: #59c7f9;
  color: #ffffff;
  text-align: center;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  top: calc(100% + 11px);
  left: 50%;
  transform: translate-x(-50%);
}
.tooltip-box {
  position: relative;
}
.triangle {
  border-width: 0 6px 6px;
  border-color: transparent;
  border-bottom-color: #59c7f9;
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translate-x(-50%);
}
.filter-box {
  min-height: 70px !important;
}
</style>
