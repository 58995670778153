import { defineStore } from "pinia";
import axios from "axios";
import { useLoaderStore } from "@/stores/loader";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";

export const useContactStore = defineStore("contact", {
  state: () => ({
    contacts: [] as any[],
    contact: {},
  }),
  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },
  actions: {
    async getContacts(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "visitors.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.contacts = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async getContact(data: any) {
      const url =
        process.env.VUE_APP_ROOT_API +
        "visitors/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .get(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.contact = response.data.data;
      }
    },
    async updateLeadStatus({ vm, id, leadStatus }: any) {
      const contact = this.contacts.find((contact: any) => contact.id === id);
      if (!contact) {
        return;
      }

      // optimistically update the contact status
      const previousStatus = contact.meta.lead_status;
      contact.meta.lead_status = leadStatus;

      try {
        await axios.put(
          `visitors/${id}.json`,
          {
            lead_status: leadStatus,
          },
          {
            baseURL: process.env.VUE_APP_ROOT_API,
            params: {
              account_uuid: this.accountGetter.uuid,
            },
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        );
      } catch (error) {
        // rollback the status if the request fails
        contact.meta.lead_status = previousStatus;
        vm.$toast.open({
          message: "Une erreur est survenue lors de la requete",
          type: "error",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
        console.error(error);
      }
    },
    async sendReport(data: any) {
      const response = await axios
        .post(
          process.env.VUE_APP_ROOT_API + "report.json",
          {
            account_uuid: this.accountGetter.uuid,
            id: data.id,
          },
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        data.vm.$toast.open({
          message:
            "Votre demande est transmise. Vous recevrez un email dans quelques minutes.",
          type: "success",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
      }
    },
  },
  persist: {
    enabled: true,
  },
});
