<template>
  <div>
    <div class="row">
      <div class="col-12">
      </div>
    </div>
    <div class="row" :class="licenceStore.chatbot_tracking || userStore.is_leadia_admin ? '' : 'grayscale'">
      <div class="col-md-6">
        <div class="card">
          Nombre de pages visitées
          <div class="demo-inline-spacing">
            <div class="form-check form-check-primary">
              <input
                      type="radio"
                      name="radio0"
                      class="form-check-input"
                      value="1"
                      v-model="pages"
              />
              <label class="form-check-label">1</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                      type="radio"
                      name="radio0"
                      class="form-check-input"
                      value="2"
                      v-model="pages"
              />
              <label class="form-check-label">2</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                      type="radio"
                      name="radio0"
                      class="form-check-input"
                      value="3"
                      v-model="pages"
              />
              <label class="form-check-label">3</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                      type="radio"
                      name="radio0"
                      class="form-check-input"
                      value="4"
                      v-model="pages"
              />
              <label class="form-check-label">4</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                      type="radio"
                      name="radio0"
                      class="form-check-input"
                      value="5"
                      v-model="pages"
              />
              <label class="form-check-label">5</label>
            </div>
          </div>
        </div>
        <div class="card">
          Présence du téléphone
          <div class="demo-inline-spacing">
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio1"
                class="form-check-input"
                value="1"
                v-model="phone"
              />
              <label class="form-check-label">1</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio1"
                class="form-check-input"
                value="2"
                v-model="phone"
              />
              <label class="form-check-label">2</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio1"
                class="form-check-input"
                value="3"
                v-model="phone"
              />
              <label class="form-check-label">3</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio1"
                class="form-check-input"
                value="4"
                v-model="phone"
              />
              <label class="form-check-label">4</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio1"
                class="form-check-input"
                value="5"
                v-model="phone"
              />
              <label class="form-check-label">5</label>
            </div>
          </div>
        </div>
        <div class="card">
          Questions du contact
          <div class="demo-inline-spacing">
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio2"
                class="form-check-input"
                value="1"
                v-model="questions"
              />
              <label class="form-check-label">1</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio2"
                class="form-check-input"
                value="2"
                v-model="questions"
              />
              <label class="form-check-label">2</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio2"
                class="form-check-input"
                value="3"
                v-model="questions"
              />
              <label class="form-check-label">3</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio2"
                class="form-check-input"
                value="4"
                v-model="questions"
              />
              <label class="form-check-label">4</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio2"
                class="form-check-input"
                value="5"
                v-model="questions"
              />
              <label class="form-check-label">5</label>
            </div>
          </div>
        </div>
        <div class="card">
          Nombre de session (Discussion)
          <div class="demo-inline-spacing">
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio3"
                class="form-check-input"
                value="1"
                v-model="sessions"
              />
              <label class="form-check-label">1</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio3"
                class="form-check-input"
                value="2"
                v-model="sessions"
              />
              <label class="form-check-label">2</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio3"
                class="form-check-input"
                value="3"
                v-model="sessions"
              />
              <label class="form-check-label">3</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio3"
                class="form-check-input"
                value="4"
                v-model="sessions"
              />
              <label class="form-check-label">4</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio3"
                class="form-check-input"
                value="5"
                v-model="sessions"
              />
              <label class="form-check-label">5</label>
            </div>
          </div>
        </div>
        <div class="card">
          Duree des visites
          <div class="demo-inline-spacing">
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio4"
                class="form-check-input"
                value="1"
                v-model="link"
              />
              <label class="form-check-label">1</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio4"
                class="form-check-input"
                value="2"
                v-model="link"
              />
              <label class="form-check-label">2</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio4"
                class="form-check-input"
                value="3"
                v-model="link"
              />
              <label class="form-check-label">3</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio4"
                class="form-check-input"
                value="4"
                v-model="link"
              />
              <label class="form-check-label">4</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio4"
                class="form-check-input"
                value="5"
                v-model="link"
              />
              <label class="form-check-label">5</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          Statut Étudiant
          <div class="demo-inline-spacing">
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio5"
                class="form-check-input"
                value="1"
                v-model="etudiant"
              />
              <label class="form-check-label">1</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio5"
                class="form-check-input"
                value="2"
                v-model="etudiant"
              />
              <label class="form-check-label">2</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio5"
                class="form-check-input"
                value="3"
                v-model="etudiant"
              />
              <label class="form-check-label">3</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio5"
                class="form-check-input"
                value="4"
                v-model="etudiant"
              />
              <label class="form-check-label">4</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio5"
                class="form-check-input"
                value="5"
                v-model="etudiant"
              />
              <label class="form-check-label">5</label>
            </div>
          </div>
        </div>
        <div class="card">
          Statut Salarié en activité
          <div class="demo-inline-spacing">
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio6"
                class="form-check-input"
                value="1"
                v-model="salarie"
              />
              <label class="form-check-label">1</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio6"
                class="form-check-input"
                value="2"
                v-model="salarie"
              />
              <label class="form-check-label">2</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio6"
                class="form-check-input"
                value="3"
                v-model="salarie"
              />
              <label class="form-check-label">3</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio6"
                class="form-check-input"
                value="4"
                v-model="salarie"
              />
              <label class="form-check-label">4</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio6"
                class="form-check-input"
                value="5"
                v-model="salarie"
              />
              <label class="form-check-label">5</label>
            </div>
          </div>
        </div>
        <div class="card">
          Statut Sans emploi
          <div class="demo-inline-spacing">
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio7"
                class="form-check-input"
                value="1"
                v-model="sansemploi"
              />
              <label class="form-check-label">1</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio7"
                class="form-check-input"
                value="2"
                v-model="sansemploi"
              />
              <label class="form-check-label">2</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio7"
                class="form-check-input"
                value="3"
                v-model="sansemploi"
              />
              <label class="form-check-label">3</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio7"
                class="form-check-input"
                value="4"
                v-model="sansemploi"
              />
              <label class="form-check-label">4</label>
            </div>
            <div class="form-check form-check-primary">
              <input
                type="radio"
                name="radio7"
                class="form-check-input"
                value="5"
                v-model="sansemploi"
              />
              <label class="form-check-label">5</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";
import useVuelidate from "@vuelidate/core";

export default {
  name: "settingLead",
  data() {
    return {
      pages: this.accountStore.account.score_weights.pages,
      phone: this.accountStore.account.score_weights.phone,
      questions: this.accountStore.account.score_weights.questions,
      sessions: this.accountStore.account.score_weights.sessions,
      link: this.accountStore.account.score_weights.link,
      etudiant: this.accountStore.account.score_weights.etudiant,
      salarie: this.accountStore.account.score_weights.salarie,
      sansemploi: this.accountStore.account.score_weights.sansemploi,
    };
  },
  setup() {
    const accountStore = useAccountStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return {
      v$: useVuelidate(),
      accountStore,
      licenceStore,
      userStore
    };
  },
  watch: {
    phone: function (oldVal, newVal) {
      this.actionUpdate();
    },
    questions: function (oldVal, newVal) {
      this.actionUpdate();
    },
    sessions: function (oldVal, newVal) {
      this.actionUpdate();
    },
    link: function (oldVal, newVal) {
      this.actionUpdate();
    },
    etudiant: function (oldVal, newVal) {
      this.actionUpdate();
    },
    salarie: function (oldVal, newVal) {
      this.actionUpdate();
    },
    sansemploi: function (oldVal, newVal) {
      this.actionUpdate();
    },
    pages: function (oldVal, newVal) {
      this.actionUpdate();
    },
  },
  methods: {
    actionUpdate() {
      const score_weights = {
        pages: this.pages,
        phone: this.phone,
        questions: this.questions,
        sessions: this.sessions,
        link: this.link,
        etudiant: this.etudiant,
        salarie: this.salarie,
        sansemploi: this.sansemploi,
      };
      this.accountStore.updateAccount({
        score_weights: score_weights,
        vm: this,
      });
    },
  },
};
</script>

<style scoped></style>
