<template>
  <div class="card">
    <div class="row">
      <div class="col-12">
        <div class="card-body pb-0" style="position: relative">
          <div v-if="!chartData.labels || chartData.labels.length === 0">
            Pas de données disponibles
          </div>
          <BarChart
            v-else
            :chartData="chartData"
            :options="options"
            width="100%"
            height="350"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/dashboard/card";
import { computed, defineComponent, ref } from "vue";
import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { useDashboardStore } from "@/stores/dashboard";

Chart.register(...registerables);
export default defineComponent({
  name: "App",
  components: { BarChart, Card },
  data() {

      const footer = (tooltipItems) => {

          if (tooltipItems[0].parsed.y && tooltipItems[1].parsed.y)
          return 'Taux de clic: ' + Math.round(tooltipItems[0].parsed.y * 100 / tooltipItems[1].parsed.y) + '%' ;

          return "";
      };

    return {
      chartData: {},

        options: {
            plugins: {
                title: {
                    display: true,
                    text: 'SMS Envoyés / Clics'
                },
                tooltip: {
                    mode: 'index',
                    callbacks: {
                        footer: footer,
                    }
                }
            },
            interaction: {
                intersect: false,
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: false,
                    ticks: {
                        precision: 0
                    }
                }
            }
        }
    };
  },
  setup() {
    const dashboardStore = useDashboardStore();
    return { dashboardStore };
  },
  watch: {
    "dashboardStore.sms_analytics": function (newCore, oldCore) {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
        if(JSON.stringify(this.dashboardStore.sms_analytics) == '{}'){
            return;
        }

        const label = Object.keys(this.dashboardStore.sms_analytics["sent"]).map((core) => {
          return core;
        });

        var data1 = [];
        var data2 = [];
        var sent = this.dashboardStore.sms_analytics["sent"];
        Object.keys(sent).forEach(function (key) {
            data1.push(sent[key])
        });

        var clicked = this.dashboardStore.sms_analytics["clicked"];
        Object.keys(clicked).forEach(function (key) {
            data2.push(clicked[key])
        });

      this.chartData = {
        labels: label,
          datasets: [
              {
                  label: 'Clics',
                  data: data2,
                  backgroundColor: ["#9188f1"],
              },
              {
                  label: 'SMS Envoyés',
                  data: data1,
                  backgroundColor: ["#bbb6f1"],
              }
          ]
      };
    },
  },
});
</script>

<style scoped>
.row {
  min-height: 408px;
}
.card {
  margin-bottom: 0;
}
</style>
