<template>
  <div class="card card-statistics">
    <div class="card-body statistics-body">
      <h2 class="card-title">Statistiques</h2>
      <div class="row" :class="licenceStore.chatbot_tracking || userStore.is_leadia_admin ? '' : 'blur'">
        <div class="col-xl-4 col-sm-4 col-md-12 col-12 mb-2 mb-md-1">
          <div class="d-flex flex-row box">
            <div class="avatar bg-light-warning me-2">
              <div class="avatar-content">
                <vue-feather type="message-square"></vue-feather>
              </div>
            </div>
            <div class="my-auto">
              <h4 class="fw-bolder mb-0">
                {{ dashboardStore.summary.smart_faq }}
              </h4>
              <p class="card-text font-small-3 mb-0">Smart/FAQ</p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-md-12 col-12 mb-2 mb-md-1">
          <div class="d-flex flex-row box">
            <div class="avatar bg-light-info me-2">
              <div class="avatar-content">
                <vue-feather type="check-circle"></vue-feather>
              </div>
            </div>
            <div class="my-auto">
              <h4 class="fw-bolder mb-0">
                {{ responseFound }}
              </h4>
              <p class="card-text font-small-3 mb-0">Réponses trouvées</p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-md-12 col-12 mb-2 mb-md-1">
          <div class="d-flex flex-row box">
            <div class="avatar bg-light-danger me-2">
              <div class="avatar-content">
                <vue-feather type="mail"></vue-feather>
              </div>
            </div>
            <div class="my-auto">
              <h4 class="fw-bolder mb-0">
                {{ dashboardStore.summary.responses_sent }}
              </h4>
              <p class="card-text font-small-3 mb-0">Questions transmises</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDashboardStore } from "@/stores/dashboard";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";

export default {
  name: "Statistics",
  setup() {
    const dashboardStore = useDashboardStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return { dashboardStore,
        licenceStore, userStore};
  },
  computed: {
    responseFound() {
      if (this.dashboardStore.summary.responses_found === "n/a") return 0;
      return this.dashboardStore.summary.responses_found + '%';
    },
  },
};
</script>

<style scoped>
.box {
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 20px;
  min-height: 90px;
}
.avatar .avatar-content {
  width: 52px !important;
  height: 52px !important;
}
.avatar {
  height: 52px;
}
</style>
