<template>
  <div class="card">
    <div class="card-body">
      <div class="row" v-if="contact.meta">
        <div class="col-sm-6 col-12">
          <div class="profile-container d-flex align-items-center mb-4">
            <div class="avatar bg-light-danger rounded avatar-xl" :class="licenceStore.chatbot_tracking || userStore.is_leadia_admin ? '' : 'grayscale'">
              <span v-if="notEmptyObject(contact)" class="avatar-content"
                >{{ contact.meta.firstname.charAt(0) || "" }}
                {{ contact.meta.lastname ? contact.meta.lastname.charAt(0) : "" }}</span
              >
            </div>
            <div class="profile-title ms-1">
              <h2 class="text" v-if="notEmptyObject(contact)">
                {{ contact.meta.firstname || "" }}
                {{ contact.meta.lastname || "" }}
              </h2>
              <p class="email" v-if="notEmptyObject(contact)">
                {{ contact.meta.email || "" }}
              </p>
              <ul v-if="notEmptyObject(contact)">


                <li v-for="d in contact.displays" v-if="licenceStore.chatbot_tracking || userStore.is_leadia_admin">
                  <Popper content="" v-if="d['display_id'] == 1">
                    <div class="rond bg-light-secondary bg-logo bg-googleads">
                      &nbsp;
                    </div>
                  </Popper>
                  <Popper content="" v-if="d['display_id'] == 2">
                    <div class="rond bg-light-secondary bg-logo bg-facebook">
                      &nbsp;
                    </div>
                  </Popper>
                  <Popper content="" v-if="d['display_id'] == 3">
                    <div class="rond bg-light-secondary bg-logo bg-bing">
                      &nbsp;
                    </div>
                  </Popper>
                  <Popper content="" v-if="d['display_id'] == 4">
                    <div class="rond bg-light-secondary bg-logo bg-youtube">
                      &nbsp;
                    </div>
                  </Popper>
                </li>

                <li v-if="contact.is_question">
                  <Popper content="">
                    <div class="rond bg-light-secondary bg-logo bg-message">
                      &nbsp;
                    </div>
                  </Popper>
                </li>
                <li v-if="contact.is_banner_click">
                  <Popper content="">
                    <div class="rond bg-light-secondary bg-logo bg-banner">
                      &nbsp;
                    </div>
                  </Popper>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mb-2 mb-xl-0">
              <div class="d-flex flex-row" v-if="contact.meta['tags']">
                <div  class="avatar success">
                  <div class="avatar-content">
                    <img src="@/assets/images/ico-qr.png" height="28" />
                  </div>
                </div>
              </div>

              <div class="d-flex flex-row" v-if="!contact.meta['tags']">
                <div
                  v-if="notEmptyObject(contact)"
                  v-html="formatScore(contact.score, 'rounded me-1')"
                  :class="licenceStore.chatbot_tracking || userStore.is_leadia_admin ? '' : 'blur-grayscale'"
                ></div>
                <div class="my-auto">
                  <h3 class="mb-0">Lead score</h3>
                  <p class="card-text font-small-3 mb-0">Congrats!</p>
                </div>
              </div>
            </div>
            <div class="col-6 mb-2 mb-xl-0">
              <div class="d-flex flex-row" v-if="contact.is_mobile">
                <div class="avatar bg-light-success rounded me-2">
                  <div class="avatar-content">
                    <vue-feather type="smartphone"></vue-feather>
                  </div>
                </div>
                <div class="my-auto">
                  <h3 class="mb-0">Mobile traffic</h3>
                  <p class="card-text font-small-3 mb-0"></p>
                </div>
              </div>
              <div class="d-flex flex-row" v-else>
                <div class="avatar bg-light-success rounded me-2">
                  <div class="avatar-content">
                    <vue-feather type="monitor"></vue-feather>
                  </div>
                </div>
                <div class="my-auto">
                  <h3 class="mb-0">Desktop traffic</h3>
                  <p class="card-text font-small-3 mb-0"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1 col-12"></div>
        <div class="col-sm-5 col-12">
          <div class="infos">
            <ul>
              <li class="intitule">Statut:&nbsp;</li>
              <li>
                <span
                  class="badge badge-light-success"
                  v-if="notEmptyObject(contact)"
                >
                  {{ contact.statut || "" }}
                </span>
              </li>
            </ul>
            <ul>
              <li class="intitule">Téléphone:&nbsp;</li>
              <li v-if="notEmptyObject(contact)">
                <span v-html="formatPhone(contact.meta.phone || '')"></span>
              </li>
            </ul>
            <ul>
              <li class="intitule">Niveau d'étude:&nbsp;</li>
              <li v-if="notEmptyObject(contact)">
                {{ contact.diploma_level || "" }}
              </li>
            </ul>
            <ul>
              <li class="intitule">Diplôme en préparation:&nbsp;</li>
              <li v-if="notEmptyObject(contact)">
                {{ contact.meta.level_other || '' }}
              </li>
            </ul>
            <ul v-if="programStore.sections.length > 1">
              <li class="intitule">{{this.singularSection()}}:&nbsp;</li>
              <li v-if="notEmptyObject(contact)">
                {{ contact.campus || "" }}
              </li>
            </ul>
            <ul>
              <li class="intitule">Demande de contact:&nbsp;</li>
              <li>{{ contactRequest }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useProgramStore } from "@/stores/program";
import { useAccountStore } from "@/stores/account";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";

export default {
  name: "contactDetail",
  props: {
    contact: Object,
  },
  setup() {
    const programStore = useProgramStore();
    const accountStore = useAccountStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return {
      programStore,
      accountStore,
      licenceStore,
      userStore
    };
  },
    methods: {
        singularSection() {
            if(this.accountStore.account.section_naming == "campus") return "Campus";
            if(this.accountStore.account.section_naming == "school") return "École";
            if(this.accountStore.account.section_naming == "center") return "Centre de formation";
            return "campus";
        },
    },
  computed: {
    contactRequest() {
      if (this.contact.is_contact_request) {
        return "Oui";
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
.avatar {
  max-height: 40px;
}
.avatar .avatar-content {
  width: 40px;
  height: 40px;
}
.infos {
  margin: 0 0 0 20px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 10px;
}
.infos ul {
  list-style: none;
  padding-left: 0 !important;
}
.infos ul li {
  display: inline;
}
.intitule {
  font-weight: 500;
}
.profile-container .avatar {
  max-height: 100px;
}

.profile-container .avatar .avatar-content {
  width: 100px;
  height: 100px;
}

h2 {
  font-size: 1.2rem;
}

h3 {
  font-size: 1.1rem;
  font-weight: 600;
}

.email {
  font-weight: 300;
  font-size: 0.85rem;
}

.profile-container ul {
  text-align: left;
  list-style: none;
  padding: 0;
}

.profile-container ul li {
  display: inline-block;
  margin-right: 5px;
}

.rond {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.bg-logo {
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.bg-bing {
  background-image: url("@/assets/images/ico-bing.png") !important;
}
.bg-facebook {
  background-image: url("@/assets/images/ico-facebook.png") !important;
}
.bg-youtube {
  background-image: url("@/assets/images/ico-youtube.png") !important;
}
.bg-googleads {
  background-image: url("@/assets/images/ico-google.png") !important;
}
.bg-message {
  background-image: url("@/assets/images/ico-message.png") !important;
}
.bg-banner {
  background-image: url("@/assets/images/ico-banner.png") !important;
}
  .profile-container {
    margin-bottom: 5.5em !important;
  }
</style>
