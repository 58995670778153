<template>
  <div class="row">
    <div class="col-12 mb-2 mt-2">
      <h1>Dashboard</h1>
      👉 Visualisez la performance de vos campagnes
    </div>
  </div>

  <div v-if="sms_settings">
    <div v-if="sms_settings.length > 0 && sms_setting.id">
      <div class="row">
        <div style="width:450px">
          <v-select
                  label="name"
                  style="margin-top:8px"
                  :options="sms_settings"
                  v-model="sms_setting"
                  class="search-bar"
                  :on-change="updateDate()"
                  :clearable="false"
          ></v-select>
        </div>
        <div class="col"></div>
        <div class="" style="width:320px">
          <Datepicker
                  position="right"
                  placeholder="30 derniers jours"
                  lang="fr"
                  range
                  v-model="selectedDate"
                  @input="updateDate()"
                  @change="updateDate()"
                  class="datepick"
          ></Datepicker>
        </div>
      </div>

      <div class="row pt-2" >

        <div class="row mb-6" style="margin:0">
          <div class="col-12 border_round border">
            <SmsRate></SmsRate>
          </div>
        </div>

        <div class="row mt-2" style="margin:0;padding:0">
          <div class="col-6">
            <div class="border_round border pb-2" style="height:350px">
              <SmsStatut></SmsStatut>
            </div>
          </div>
          <div class="col-6">
            <div class="border_round border" style="height:350px">
              <SmsPie></SmsPie>
            </div>
          </div>
        </div>

        <div class="row mb-6 mt-2" style="margin:0">
          <div class="col-12 border_round border">
            <SmsBubble></SmsBubble>
          </div>
        </div>
      </div>

    </div>
    <div v-else>
      <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary">
        <div class="alert-body">
          <p><span>Vous n'avez pas encore de campagne SMS. <br>Cliquez sur <span style="padding:5px" class="btn btn-primary btn-sm" @click="$router.push('/campagne/sms/templates')">Templates</span> pour créer votre première
                  campagne.</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import moment from "moment";
    import { useAccountStore } from "@/stores/account";
    import { useDashboardStore } from "@/stores/dashboard";
    import { useSmsSettingStore } from "@/stores/smsSetting";
    import SmsRate from "@/components/dashboard/smsRate";
    import SmsBubble from "@/components/dashboard/smsBubble";
    import SmsPie from "@/components/dashboard/smsPie";
    import SmsStatut from "@/components/dashboard/smsStatut";
    import VueCookies from 'vue-cookies'
    import { useLoaderStore } from "@/stores/loader";

    export default {
        name: "dashboard",
        components: {
            SmsRate,
            SmsPie,
            SmsStatut,
            SmsBubble,
        },
        data() {
            return {
                key: "",
                sms_setting: {},
                sms_settings: null,
                selectedDate: [moment().subtract(20, "d"), moment()],
            };
        },
        async mounted() {
            this.loadConfig();
            //await this.updateDate();
        },
        watch: {
            selectedDate(newSelectedDate, oldSelectedDate) {
                this.dashboardStore.setDatesSms({ selectedDate: newSelectedDate });
                this.updateDate();
            },
            "smsSettingStore.sms_settings": function (newVal, oldVal) {
                if (newVal.length > 0){
                this.clearConfig;
                this.loadConfig();
                }
            },
        },
        setup() {
            const accountStore = useAccountStore();
            const dashboardStore = useDashboardStore();
            const smsSettingStore = useSmsSettingStore();
            return {
                accountStore,
                dashboardStore,
                smsSettingStore
            };
        },
        methods: {
            clearConfig() {
                VueCookies.remove('sms_setting_id');
                VueCookies.remove('sms_setting_start_date');
                VueCookies.remove('sms_setting_end_date');
            },
            saveConfig() {
                if(this.sms_setting.id) {
                    VueCookies.set('sms_setting_id', this.sms_setting.id, "1h");
                    VueCookies.set('sms_setting_start_date', this.dashboardStore.startSms, "1h");
                    VueCookies.set('sms_setting_end_date', this.dashboardStore.endSms, "1h");
                }
            },
            loadConfig() {
                this.sms_settings = this.smsSettingStore.sms_settings;
                this.sms_setting = this.sms_settings[0];

                if (this.sms_settings && this.sms_settings.length > 0) {
                    this.sms_settings = this.smsSettingStore.sms_settings;
                    var sms_setting = this.sms_settings[0];

                    if (VueCookies.get('sms_setting_id')) {
                        this.sms_settings.forEach(function (s) {
                            if (s.id == VueCookies.get('sms_setting_id')) {
                                sms_setting = s;
                            }
                        });
                    }
                    this.sms_setting = sms_setting;
                } else {
                    VueCookies.remove('sms_setting_id');
                }

                var start = moment().subtract(20, "d");
                var end = moment();

                if(VueCookies.get('sms_setting_start_date') && VueCookies.get('sms_setting_end_date') ){
                    start = VueCookies.get('sms_setting_start_date')
                    end = VueCookies.get('sms_setting_end_date')
                }

                this.selectedDate = [start, end];
                this.dashboardStore.setDatesSms({ selectedDate: this.selectedDate });
            },
            async updateDate() {
                if(this.sms_setting && this.sms_setting.id && this.selectedDate && this.key != ("" + this.sms_setting.id + this.selectedDate) ){
                    this.key = "" + this.sms_setting.id + this.selectedDate;

                    this.saveConfig();
                    const loaderStore = useLoaderStore();
                    await loaderStore.setLoader(true);
                    await this.dashboardStore.getSmsAnalytics({ vm: this, sms_setting_id: this.sms_setting.id });
                    await this.dashboardStore.getSmsRepartition({ vm: this, sms_setting_id: this.sms_setting.id });
                    await this.dashboardStore.getSmsStatus({ vm: this, sms_setting_id: this.sms_setting.id });
                    await this.dashboardStore.getSmsDaily({ vm: this, sms_setting_id: this.sms_setting.id });
                    await loaderStore.setLoader(false);
                }
            },
        },
    };
</script>

<style scoped>
  .datepick {
    --v-calendar-datepicker-icon-color: #7367f0;
    --v-calendar-active-bg-color: #7367f0;
    --v-calendar-active-text-color:#fff;
    --v-calendar-day-hover-bg-color: #c0bbf4;
    --v-calendar-range-bg-color: #eeedfd;
    --v-calendar-range-text-color: #666;
  }
  .border_round {
    border-radius:12px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    padding:5px;
  }
</style>
