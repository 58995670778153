<template>
  <div class="card banniere" v-if="notEmptyObject(banniere)">
    <div
      class="modal fade"
      ref="duplicateModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dupliquer</h5>
            <button
              type="button"
              class="btn-close"
              @click="modalDuplicate.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Etes-vous sûr de vouloir dupliquer cet élément ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="modalDuplicate.hide()"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="actionDuplicate()"
            >
              Dupliquer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="deleteModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Supprimer</h5>
            <button
              type="button"
              class="btn-close"
              @click="modalDelete.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Etes-vous sûr de vouloir supprimer cette bannière ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="modalDelete.hide()"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="actionDelete()"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <span
      class="position"
      :class="{
        top: banniere.position === 'TOP',
        middle: banniere.position === 'MIDDLE',
        bottom: banniere.position === 'BOTTOM',
      }"
      >{{ formatPosition(banniere.position) }}</span
    >
    <div
      class="image"
      :style="{ backgroundImage: `url(${banniere.large_url})` }"
    ></div>
    <div class="form-check form-check-primary form-switch">
      <input
        v-model="banniere.enabled"
        type="checkbox"
        class="form-check-input switch checked"
        @change="updateBanniere($event, banniere.id)"
      />
    </div>
    <div class="card-body">
      <div class="d-flex">
        <div class="avatar me-50">
          <img
            :src="banniere.user.thumb_url"
            alt="Avatar"
            width="24"
            height="24"
          />
        </div>
        <div class="author-info" style="width: 100%">
          <small
            ><span style="float: left" class="text-body"
              >{{ banniere.user.firstname }} {{ banniere.user.lastname }}</span
            ></small
          >
          <small class="text-muted"
            ><span style="float: right;">{{
              formatDate(banniere.created_at, "DD/MM/YYYYY")
            }}</span></small
          >
        </div>
      </div>
      <div class="content-container">
        <div>
          <h4 class="card-title">
            <a
              class="blog-title-truncate text-body-heading"
              @click="$router.push('/campagne/banniere/' + banniere.id)"
              >{{ banniere.title }}</a
            >
          </h4>
          <p class="card-text blog-content-truncate">
            {{ banniere.text }}
          </p>
        </div>
        <div>
          <hr />
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div class="">
              <span class="text-success" v-if="banniere.enabled">Activé</span>
              <span class="text-danger" v-else>Désactivé</span>
            </div>
            <div class="">
              <div class="avatar bg-light-primary">
                <div class="avatar-content">
                  <vue-feather
                    type="edit"
                    @click="$router.push('/campagne/banniere/' + banniere.id)"
                  ></vue-feather>
                </div>
              </div>
              <div class="avatar bg-light-info">
                <div class="avatar-content">
                  <vue-feather
                    type="copy"
                    @click="modalDuplicate.show()"
                  ></vue-feather>
                </div>
              </div>
              <div class="avatar bg-light-danger">
                <div class="avatar-content">
                  <vue-feather
                    type="trash"
                    @click="modalDelete.show()"
                  ></vue-feather>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCampagneStore } from "@/stores/campagne";
import { Modal } from "bootstrap";

export default {
  name: "banniere",
  props: {
    banniere: Object,
  },
  data() {
    return {
      modalDuplicate: null,
      modalDelete: null,
    };
  },
  setup() {
    const campagneStore = useCampagneStore();
    return {
      campagneStore,
    };
  },
  async mounted() {
    this.modalDuplicate = new Modal(this.$refs.duplicateModal);
    this.modalDelete = new Modal(this.$refs.deleteModal);
  },
  methods: {
    async actionDuplicate() {
      await this.campagneStore.duplicateBanniere({
        id: this.banniere.id,
        vm: this,
      });
      await this.campagneStore.getBannieres({ vm: this });
      this.modalDuplicate.hide();
    },
    async actionDelete() {
      await this.campagneStore.deleteBanniere({
        id: this.banniere.id,
        vm: this,
      });
      await this.campagneStore.getBannieres({ vm: this });
      this.modalDelete.hide();
    },
    async updateBanniere(event, id) {
      this.campagneStore.updateBanniere({
        id: id,
        enabled: this.banniere.enabled,
        vm: this,
      });
    },
  },
};
</script>

<style scoped>
.avatar {
  margin-right: 5px;
}
.avatar .avatar-content {
  width: 30px;
  height: 30px;
}
.form-check {
  position: absolute;
  top: 5px;
  left: 5px;
}
.position {
  position: absolute;
  right: 20px;
  top: 10px;
  border-radius: 20px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: 500;
}
.top {
  background-color: #fff3e8;
  color: #ff9f43;
}
.middle {
  background-color: #e5f8ed;
  color: #28c76f;
}
.bottom {
  background-color: #d6f5fc;
  color: #00cfe8;
}
.card-text {
  min-height: 100px;
}
h4 {
  min-height: 20px;
}
.image {
  width: 100%;
  height: 200px;
  background-size: cover;
}
.card .card-title {
  margin-top: 10px;
}
.content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
