<template>
  <div>
    <DashboardLayout>
      <div class="row mb-2">
        <div class="col-lg-8">
          <h1>Settings</h1>
        </div>
      </div>

      <div class="card">
        <div class="card-body py-2 my-25">
          <!-- header section -->

          <form @submit.prevent="actionSave">

            <div class="d-flex">
              <a href="#" class="me-25">

                <img v-if="image" :src="image" width="100" height="100" />
                <img
                        v-else
                        id="account-upload-img" class="uploadedAvatar rounded me-50"
                        :src="userStore.current_user.thumb_url"
                        width="100" height="100"
                />

              </a>
              <!-- upload and reset button -->
              <div class="d-flex align-items-end mt-75 ms-1">
                <div>
                  <label for="account-upload" class="btn btn-sm btn-primary mb-75 me-75 waves-effect waves-float waves-light">Upload</label>
                  <input type="file" id="account-upload" accept="image/*" hidden="" @change="uploadFile">
                  <!--p class="mb-0">Allowed file types: png, jpg, jpeg.</p-->
                </div>
              </div>
              <!--/ upload and reset button -->
            </div>
            <!--/ header section -->


            <div class="row">
              <div class="col-12 col-sm-6 mb-1">
                <label class="form-label" for="accountFirstName">Prénom</label>
                <input type="text" class="form-control" id="accountFirstName" name="firstName" v-model="firstname">
                <div
                        class="input-errors"
                        v-for="(error, index) of v$.firstname.$errors"
                        :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>

              </div>
              <div class="col-12 col-sm-6 mb-1">
                <label class="form-label" for="accountLastName">Nom</label>
                <input type="text" class="form-control" id="accountLastName" name="lastName" v-model="lastname">
                <div
                        class="input-errors"
                        v-for="(error, index) of v$.lastname.$errors"
                        :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>

              </div>
              <div class="col-12 col-sm-6 mb-1">
                <label class="form-label" for="accountEmail">Email</label>
                <input type="email" class="form-control" id="accountEmail" name="email" disabled :value="userStore.current_user.email">
              </div>
              <div class="col-12 col-sm-6 mb-1">
                <label class="form-label">Service</label>
                <select class="form-select" v-model="service">
                  <option value="Marketing/Communication">Marketing/Communication</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Pédagogie">Pédagogie</option>
                  <option value="Direction générale">Direction générale</option>
                </select>
              </div>

              <div class="col-12">
                <button type="submit" class="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light">Enregistrer</button>
              </div>
            </div>

          </form>
          <!--/ form -->
        </div>
      </div>

    </DashboardLayout>
  </div>
</template>

<script>
    import DashboardLayout from "@/layouts/dashboard";
    import { useUserStore } from "@/stores/user";
    import useVuelidate from "@vuelidate/core";
    import { helpers, required, requiredIf, url } from "@vuelidate/validators";


    export default {
        name: "user",
        components: {
            DashboardLayout
        },
        data() {
            return {
                firstname: "",
                lastname: "",
                service: "Marketing/Communication",
                email: "",
                image: "",
            };
        },
        validations() {
            return {
                firstname: {
                    required: helpers.withMessage("Prénom requis", required),
                },
                lastname: {
                    required: helpers.withMessage("Nom requis", required),
                }
            };
        },
        async mounted() {
            await this.userStore.getCurrentUser({ vm: this });

            this.firstname = this.userStore.current_user.firstname;
            this.lastname = this.userStore.current_user.lastname;
            if(this.userStore.current_user.service) { this.service = this.userStore.current_user.service};
            this.email = this.userStore.current_user.email;
            this.image = this.userStore.current_user.image;


        },
        setup() {
            const userStore = useUserStore();
            return {
                v$: useVuelidate(),
                userStore,
            };
        },
        methods: {
            async actionSave(e) {
                e.preventDefault();
                await this.v$.$validate();
                if (this.v$.$error) {
                    this.$toast.open({
                        message: "Un ou plusieurs champs requis ne sont pas renseignés.",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                    return;
                }

                const data = {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    service: this.service,
                    vm: this,
                };

                if (this.image) {
                    const image = {
                        filetype: this.file.type,
                        filename: this.file.name,
                        filesize: this.file.size,
                        base64: this.image.substring(22),
                    };
                    data["image"] = JSON.stringify(image);
                }

                await this.userStore.updateCurrentUser(data);
                //await this.$router.push("/account_setting");
            },
        },
    };
</script>


