import { defineStore } from "pinia";
import axios from "axios";
import { useLoaderStore } from "@/stores/loader";
import { useAccountStore } from "@/stores/account";

export const useVersionStore = defineStore("version", {
  state: () => ({
    versions: [],
    version: {},
  }),
  actions: {
    async getVersions(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
          .get(
              process.env.VUE_APP_ROOT_API +
              "health.json",
              {
                headers: {
                  "token-type": "Bearer",
                },
              }
          )
          .catch(function (error) {
            console.log(error);
            data.vm.$toast.open({
              message: "Une erreur est survenue lors de la requete",
              type: "error",
              position: "top-right",
              duration: 4000,
              dismissible: true,
            });
          });
      if (response) {
        this.versions = response.data;
      }
      await loaderStore.setLoader(false);
    }
  },
  persist: {
    enabled: true,
  },
});
