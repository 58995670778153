<template>
  <div style="padding: 20px 20px 0 20px">
    <div class="col-12">
      <nav
        class="header-navbar navbar navbar-expand-lg align-items-center navbar-light navbar-shadow"
      >
        <v-select
          v-if="accountStore.accounts.length > 1"
          label="name"
          :options="accountStore.accounts"
          v-model="accountStore.account"
          class="search-bar"
          @change="selectAccount()"
          :clearable="false"
        ></v-select>
        <div class="navbar-container d-flex content">
          <div class="bookmark-wrapper d-flex align-items-center">
            <div class="bookmark-wrapper d-flex align-items-center" v-if="licenceStore.sms">
              <img src="@/assets/images/sms.png" style="height:32px" />

              <ul class="nav navbar-nav ml-auto" style="flex-direction: column;padding-left:8px">
                <!--li class="nav-item" v-if="userStore.current_user.role == 'Admin'">
                  <span class="licence-status licence-status-active" v-if="accountStore.account.current_product">Licence : {{ accountStore.account.current_product.name }}</span>
                  <span class="licence-status licence-status-none" v-else>Licence : Pas de contrat</span>
                </li-->
                <li class="link-sms nav-item">
                  <router-link :to="{ path: '/campagne/sms' }">
                    {{ getSmsCredit() }}
                  </router-link>
                </li>
              </ul>
            </div>

          </div>

          <ul class="nav navbar-nav align-items-center ml-auto">
            <li class="nav-item dropdown dropdown-user">
              <a
                class="nav-link dropdown-toggle dropdown-user-link"
                id="dropdown-user"
                data-bs-target="#collapseTarget"
                data-bs-toggle="collapse"
              >
                <div class="user-nav d-sm-flex d-none">
                  <span class="user-name font-weight-bolder"
                    >{{ userStore.current_user.firstname }} {{ userStore.current_user.lastname }}</span
                  ><span class="user-status">{{ authStore.role }}</span>
                </div>
                <span class="avatar"
                  ><img
                    class="round"
                    :src="userStore.current_user.thumb_url"
                    alt="avatar"
                    height="40"
                    width="40" /><!--span class="avatar-status-online"></span
                --></span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                id="collapseTarget"
              >

                <a class="dropdown-item" @click="$router.push('/account_setting')"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-settings mr-50"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path
                      d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                    ></path>
                  </svg>
                  Settings</a
                ><a class="dropdown-item" @click="logout()"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-power mr-50"
                  >
                    <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                    <line x1="12" y1="2" x2="12" y2="12"></line>
                  </svg>
                  Logout</a
                >
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { useLayoutStore } from "@/stores/layout";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { useAccountStore } from "@/stores/account";
import { useDashboardStore } from "@/stores/dashboard";
import { useCampagneStore } from "@/stores/campagne";
import { useSmsSettingStore } from "@/stores/smsSetting";
import { useDisplayStore } from "@/stores/display";
import { useLicenceStore } from "@/stores/licence";
import { useWebhookStore } from "@/stores/webhook";
import { useProductStore } from "@/stores/product";
import { useContactStore } from "@/stores/contact";
import { useProgramStore } from "@/stores/program";
import { useEntrepriseStore } from "@/stores/entreprise";
import { useSmartReplyStore } from "../../stores/smartReply";
import VueCookies from 'vue-cookies'

export default {
  name: "dashboardTopbar",
  data() {
    return {
      account: {},
    };
  },
  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const layoutStore = useLayoutStore();
    const accountStore = useAccountStore();
    const dashboardStore = useDashboardStore();
    const campagneStore = useCampagneStore();
    const smsSettingStore = useSmsSettingStore();
    const displayStore = useDisplayStore();
    const licenceStore = useLicenceStore();
    const webhookStore = useWebhookStore();
    const productStore = useProductStore();
    const contactStore = useContactStore();
    const programStore = useProgramStore();
    const entrepriseStore = useEntrepriseStore();
    const smartReplyStore = useSmartReplyStore();
    return {
      authStore,
      userStore,
      layoutStore,
      accountStore,
      dashboardStore,
      campagneStore,
      smsSettingStore,
      displayStore,
      licenceStore,
      webhookStore,
      productStore,
      contactStore,
      programStore,
      entrepriseStore,
      smartReplyStore,
    };
  },
  watch: {
    'accountStore.account': function (newVal, oldVal) {
      if (newVal.id && oldVal.id && newVal.id !== oldVal.id) {
        this.accountStore.$patch({
          uuid: newVal.uuid,
          id: newVal.id,
        });
        VueCookies.set('lead_uuid' , newVal.uuid, "5h")
        VueCookies.set('lead_id' , newVal.id, "5h")
        this.selectAccount();
      }
    },
  },
  async created() {
    if (this.accountStore.accounts.length === 0) {
      await this.accountStore.getAccounts({ vm: this });
    }
    if (!this.accountStore.uuid && !VueCookies.get('lead_uuid')) {
      // If Lead-IA academy is found, select it
      const defaultAccount = this.accountStore.accounts.find(a => a.id === 124) || this.accountStore.accounts[0];
      await this.accountStore.$patch({
        uuid: defaultAccount.uuid,
        id: defaultAccount.id,
      });
    } else {
        if(VueCookies.get('lead_uuid')){
            await this.accountStore.$patch({
                uuid: VueCookies.get('lead_uuid'),
                id: VueCookies.get('lead_id'),
            });
        }
    }
    await this.selectAccount();
  },
  methods: {
    getSmsCredit() {
        var resu = 0;
        if(this.accountStore.account.sms_credits_balance) resu = this.accountStore.account.sms_credits_balance;
        return "Crédits SMS disponibles : " + resu;
    },
    async selectAccount() {
      /*
      const filter = this.accountStore.accounts.filter((account) => {
        return account.uuid === this.account.uuid;
      });
      this.accountStore.$patch({
        id: filter[0].id,
      });

       */
      await this.accountStore.getAccount({
        id: this.accountStore.id,
        vm: this,
      });

      await this.authStore.getMe({ vm: this });
      await this.userStore.getCurrentUser({ vm: this });
      await this.programStore.getSections({ vm: this });
      await this.campagneStore.getBannieres({ vm: this }); // counter
      await this.smsSettingStore.getSmsSettings({ vm: this }); // counter
      await this.licenceStore.getLicences({ vm: this });
      await this.webhookStore.getWebhooks({ vm: this });

      if (this.$route.name === "HomePage") {
        await this.dashboardStore.getSummary({ vm: this });
        await this.dashboardStore.getPrediction({ vm: this });
        await this.dashboardStore.getCore({ vm: this });
        await this.dashboardStore.getDisplays({ vm: this });
        await this.dashboardStore.getCourses({ vm: this });
        await this.dashboardStore.getRepartition({ vm: this });
      } else if (this.$route.name === "Banniere") {

      } else if (this.$route.name === "Dashboard") {

      } else if (this.$route.name === "Account") {
        await this.userStore.getUsers({ vm: this });
      } else if (this.$route.name === "Contacts") {
        await this.contactStore.getContacts({ vm: this });
      } else if (this.$route.name === "Entreprise") {
        await this.entrepriseStore.getEntreprises({ vm: this });
      } else if (this.$route.name === "Pricing") {
        await this.productStore.getProducts({ vm: this });
      } else if (this.$route.name === "Program") {
         await this.programStore.getCourses({ vm: this });
         await this.programStore.getDomains({ vm: this });
      } else if (
        this.$route.name === "SmartEtudiant" ||
        this.$route.name === "SmartEntreprise"
      ) {
        await this.smartReplyStore.getQuestions({ vm: this });
      }
    },
    async logout() {
      await this.authStore.logout();

      VueCookies.remove('lead_sms_credits');
      VueCookies.remove('lead_uuid');
      VueCookies.remove('lead_id');
      this.$router.push("/signin");
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-container {
  display: flex;
  justify-content: space-between;
}
.search-bar {
  width: 100%;
  margin-left: 15px;
}
.user-name {
  margin-bottom:3px !important;
}
.user-status{
  margin-bottom:5px !important;
}
.licence-status-active {
  color: green;
}
.licence-status-none {
  color: red;
}
  .link-sms.nav-item a {
    color: #5E5873;
  }
</style>
