<template>
  <div class="modal fade" ref="validationModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body center modal-success">
          <img src="@/assets/images/sms.png" class="mb-1" />
          <h3>Dernière confirmation avant lancement</h3>

          <div class="row" v-if="!showUserPreduction()">
            <div class="center">
              <p>Votre campagne est sur le point de commencer pour vos nouveaux leads correspondants aux critères choisis.</p>
              <p v-if="showUserPreduction()">Votre campagne est sur le point de commencer pour {{getContactString(this.visitor_ids.length)}} ci-dessous:</p>
            </div>
          </div>

          <div class="row" v-if="showUserPreduction()">
            <div class="center">
              <p class="centered">Votre campagne est sur le point de commencer pour {{getContactString(this.visitor_ids.length)}} ci-dessous:</p>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                        id="DataTables_Table_0_wrapper"
                        class="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div class="table-responsive" style="margin-top:30px" v-if="showUserPreduction()">
                    <table class="datatables-basic table dataTable no-footer dtr-column" role="grid">
                      <thead>
                      <tr role="row">
                        <th style="width:5px"></th>
                        <th style="width:50px">Nom</th>
                        <th style="width:50px">Date</th>
                        <th style="width:60px">Téléphone</th>
                        <th style="width:60px">Niveau d'etude</th>
                        <th style="width:40px">Campus</th>
                        <th style="width:80px">Statut</th>
                        <th style="width:25px;text-align: center">Ads</th>
                        <th style="width:25px;text-align: center">Score</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr role="row" v-for="item in getPrediction()">
                        <td style="width:5px">

                          <div v-if="this.smsBlacklistStore.sms_blacklists.includes(item.meta.phone)">

                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 aria-hidden="true" role="img" tag="i" class="v-icon notranslate v-theme--light iconify iconify--tabler"
                                 style="font-size: 30px; height: 30px; width: 30px;" width="1em" height="1em" viewBox="0 0 24 24">
                              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M12 9v2m0 4v.01M5 19h14a2 2 0 0 0 1.84-2.75L13.74 4a2 2 0 0 0-3.5 0l-7.1 12.25A2 2 0 0 0 4.89 19"></path>
                            </svg>

                          </div>
                          <div class="form-check form-check-primary" v-if="!this.smsBlacklistStore.sms_blacklists.includes(item.meta.phone)">
                            <input v-model="visitor_ids" :value="item.id" type="checkbox" class="form-check-input">
                          </div>
                        </td>
                        <td style="width:50px">
                          {{ showName(item) }}
                        </td>
                        <td style="width:50px" v-if="item">
                          {{ formatDate(getQualifiedAt(item), "DD/MM/YYYY HH:mm") }}
                        </td>
                        <td style="width:60px">

                          <span v-if="!this.smsBlacklistStore.sms_blacklists.includes(item.meta.phone)" v-html="formatPhone(item.meta.phone)"></span>
                          <small v-if="this.smsBlacklistStore.sms_blacklists.includes(item.meta.phone)">Numéro bloqué</small>

                        </td>
                        <td style="width:60px">{{item.diploma_level}}</td>
                        <td style="width:40px">{{getSections(item)}}</td>
                        <td style="width:80px">
                          <span
                                  class="badge"
                                  v-if="item && item.statut"
                                  :class="classColor('badge-light', item.statut)"
                          >{{ item.statut }}</span
                          >

                        </td>
                        <td style="width:25px;text-align: center">
                          <div v-if="item.account_visitor_displays && item.account_visitor_displays.length > 0" class="d-flex flex-row">
                            <img v-if="getAds(item) == 1" src="@/assets/images/ico-google.png" class="rounded me-1" height="38" />
                            <img v-if="getAds(item) == 2" src="@/assets/images/ico-facebook.png" class="rounded me-1" height="38" />
                            <img v-if="getAds(item) == 3" src="@/assets/images/ico-bing.png" class="rounded me-1" height="38" />
                            <img v-if="getAds(item) == 4" src="@/assets/images/ico-youtube.png" class="rounded me-1" height="38" />
                          </div>
                        </td>
                        <td style="width:25px;text-align: center">
                          <div v-html="formatScore(getScore(item))"></div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="cancelValidation()"
          >
            Annuler
          </button>

          <button
                  style="margin-left:10px"
                  v-if="hasEnoughCredit(this.visitor_ids.length)"
                  type="button"
                  class="btn btn-primary"
                  @click="acceptValidation()"
          >
            Ok - je lance
          </button>

          <button
                  style="margin-left:10px"
                  v-if="!hasEnoughCredit(this.visitor_ids.length)"
                  type="button"
                  class="btn btn-primary"
                  @click="false"
                  data-enabled="false"
          >
            Acheter des crédits SMS pour lancer
          </button>

          <br>
          <Popper v-if="showUserPreduction()" class="hand" :content="getSmsCounterText(this.visitor_ids.length)" hover>
            <small><u>Info consommation de crédits SMS</u></small>
          </Popper>

        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="col-12 mb-3 mt-2">
      <h1>3. Je choisis le profil de ma cible</h1>
      👉 Personnalisez vos choix
    </div>

    <form class="row" @submit.prevent="actionSave">

      <div class="col-8 mb-0">
        <label class="form-label">Nom de la campagne:</label>
        <p>{{sms_setting.name}}</p>
      </div>

      <div class="col-8 mb-0">
        <label class="form-label">Période de la campagne:</label>
        <p>{{ date_start_at }} - {{ date_end_at}}</p>
      </div>

      <div class="col-8 mb-2">
        <label class="form-label">Limiter aux leads avec un score entre:</label>
        <div class="row" style="padding-top:40px">
          <Slider v-model="score" />
        </div>
      </div>

      <div class="col-8 mb-2">
        <label class="form-label">Limiter aux leads avec les statuts suivants:</label>
        <multiselect :close-on-select="false"
                track-by="code"
                :options="optionsTarget"
                v-model="target"
                label="name"
                placeholder="Sélectionnez dans la liste"
                multiple
                :taggable="false"
                :show-labels="false"
        >
        </multiselect>
      </div>

      <div class="col-8 mb-2">
        <label class="form-label">Limiter aux leads s'interessant aux {{this.pluralSectionName()}}:</label>
        <multiselect :close-on-select="false"
                track-by="id"
                :options="optionsTargetCampus"
                v-model="targetCampus"
                placeholder="Sélectionnez dans la liste"
                label="name"
                multiple
                :taggable="false"
                :show-labels="false"
        >
        </multiselect>
      </div>

      <div class="col-8 mb-2">
        <label class="form-label">Limiter aux leads provenant de:</label>
        <multiselect :close-on-select="false"
                track-by="id"
                :options="optionsTargetDisplay"
                v-model="targetDisplay"
                placeholder="Sélectionnez dans la liste"
                label="name"
                multiple
                :taggable="false"
                :show-labels="false"
        >
        </multiselect>
      </div>

      <div class="col-8 mb-2">
        <label class="form-label">Limiter aux leads avec les niveaux:</label>
        <multiselect :close-on-select="false"
                track-by="code"
                :options="optionsTargetLevel"
                v-model="targetLevel"
                label="name"
                placeholder="Sélectionnez dans la liste"
                multiple
                :taggable="false"
                :show-labels="false"
        >
        </multiselect>
      </div>

      <div class="col-8 mb-2">
        <label class="form-label">Limiter aux leads ayant visités:</label>
        <multiselect :close-on-select="false"
                track-by="id"
                :options="optionsTargetCourse"
                v-model="targetCourse"
                placeholder="Sélectionnez dans la liste"
                label="name"
                multiple
                :taggable="false"
                :show-labels="false"
        >
        </multiselect>
      </div>

      <div class="col-8 mb-4">
        <label class="form-label">Couvrir aussi mes leads existants depuis le:</label>

        <div class="row">
          <div class="col">
            <Datepicker
                    position="top"
                    placeholder=""
                    lang="fr"
                    v-model="date_coverage_start_at"
                    class="datepick">
            </Datepicker>
          </div>
          <!--div class="col" style="margin-top:6px">
            <a v-if="date_coverage_start_at.toString() != date_starting_at.toString()" @click="prediction()" class="btn btn-outline-primary phone_body_link">Verifier la couverture</a>
          </div-->
        </div>
      </div>

      <div class="col-8 mb-6">
        <a @click="launch()" style="margin-left:10px;float:right" class="btn btn-primary phone_body_link">Lancer</a>
        <a @click="saveWithNotice()" style="margin-left:10px;float:right" class="btn btn-outline-primary phone_body_link">Sauvegarder</a>
        <a @click="precedent()" style="margin-left:10px;float:right" class="btn btn-outline-primary phone_body_link">Retour</a>
      </div>
    </form>
  </div>
</template>

<script>
    import DashboardLayout from "@/layouts/dashboard";
    import { useSmsSettingStore } from "@/stores/smsSetting";
    import { useSmsBlacklistStore } from "@/stores/smsBlacklist";
    import { useSmsDebitStore } from "@/stores/smsDebit";
    import { useAccountStore } from "@/stores/account";
    import { useProgramStore } from "@/stores/program";
    import { useDisplayStore } from "@/stores/display";
    import useVuelidate from "@vuelidate/core";
    import Multiselect from "vue-multiselect";
    import moment from "moment";
    import { Modal } from "bootstrap";
    import { helpers, required, requiredIf, url } from "@vuelidate/validators";
    import Slider from '@vueform/slider';

    export default {
        name: "SmsSettingDetail",
        components: {
            DashboardLayout,
            Multiselect,
            Slider
        },
        data() {
            return {
                visitor_ids: [],
                date_coverage_start_at: moment(),
                date_starting_at: moment(),
                //modalSuccess: null,
                modalValidation: null,
                date_start_at: "",
                date_end_at: "",
                sms_setting:{},
                score_min: 0,
                score_max: 100,
                score: [0, 100],
                target: [],
                optionsTarget: [
                    { name: "Étudiant", code: "Étudiant" },
                    { name: "Sans emploi", code: "Sans emploi" },
                    { name: "Salarié en activité", code: "Salarié en activité" },
                    { name: "Parent", code: "Parent" }
                ],
                targetCourse: [],
                optionsTargetCourse: this.programStore.courses,
                targetCampus: [],
                optionsTargetCampus: this.programStore.sections,
                targetDisplay: [],
                optionsTargetDisplay: this.displayStore.displays,
                targetLevel: [],
                optionsTargetLevel: [
                    { name: "4e", code: "4e" },
                    { name: "3e", code: "3e" },
                    { name: "CAP/BEP", code: "CAP/BEP" },
                    { name: "2nde", code: "2nde" },
                    { name: "1ere Pro", code: "1ere Pro" },
                    { name: "1ere", code: "1ere" },
                    { name: "Terminale/BAC Pro", code: "Terminale/BAC Pro" },
                    { name: "Terminale/BAC Technologique", code: "Terminale/BAC Technologique" },
                    { name: "Terminale/BAC", code: "Terminale/BAC" },
                    { name: "BAC+1", code: "BAC+1" },
                    { name: "BAC+2", code: "BAC+2" },
                    { name: "BAC+3", code: "BAC+3" },
                    { name: "BAC+4", code: "BAC+4" },
                    { name: "BAC+5", code: "BAC+5" },
                ],
            };
        },
        validations() {
            return {
                name: {
                    required: helpers.withMessage("Nom de campagne requis", required),
                },
                content: {
                    required: helpers.withMessage("Contenu de SMS requis", required),
                },
            };
        },
        async mounted() {
            await this.getSmsSetting();
            await this.getDisplays();
            await this.getCourses();
            await this.getBlacklists();
            //this.modalSuccess = new Modal(this.$refs.successModal);
            this.modalValidation = new Modal(this.$refs.validationModal);
        },
        setup() {
            const smsSettingStore = useSmsSettingStore();
            const smsBlacklistStore = useSmsBlacklistStore();
            const smsDebitStore = useSmsDebitStore();
            const programStore = useProgramStore();
            const displayStore = useDisplayStore();
            const accountStore = useAccountStore();
            return {
                v$: useVuelidate(),
                smsBlacklistStore,
                smsSettingStore,
                programStore,
                displayStore,
                accountStore,
                smsDebitStore
            };
        },
        methods: {
            pluralSectionName() {
                if(this.accountStore.account.section_naming == "campus") return "campus suivants";
                if(this.accountStore.account.section_naming == "school") return "écoles suivantes";
                if(this.accountStore.account.section_naming == "center") return "centres de formation suivant";
                return "campus";
            },
            getContactString(count) {
                if(count > 1) return " " + count + " contacts";
                return " " + count + " contact";
            },
            getAccountVisitor(item){
                var resu = null;
                var account_id = this.accountStore.account.id;
                item.account_visitors.forEach(function (v) {
                    if(account_id == v.account_id) resu =  v;
                });
                return resu;
            },
            getQualifiedAt(item){
                var resu = "";
                var account_id = this.accountStore.account.id;
                item.account_visitors.forEach(function (v) {
                    if(account_id == v.account_id) resu =  v.qualified_at;
                });
                return resu;
            },
            getSections(item){
                var resu =  [];
                var account_id = this.accountStore.account.id;
                item.sections.forEach(function (v) {
                    if(account_id == v.account_id) resu.push(v.name);
                });
                return resu.filter((value, index, array) => array.indexOf(value) === index).join(", ");
            },
            getAds(item){
                var resu = null;
                var account_visitor_id = this.getAccountVisitor(item).id;
                item.account_visitor_displays.forEach(function (v) {
                    if(account_visitor_id == v.account_visitor_id) resu =  v.display_id;
                });
                return resu;
            },
            getScore(item){
                var resu =  [];
                if(item.account_visitors){
                    item.account_visitors.forEach(function (v) {
                        resu.push(v.score);
                    });
                }
                if(resu[0]) return resu[0];
                return "";
            },
            showName(item) {
                const result = item.meta.firstname + " " + (item.meta.lastname ? item.meta.lastname : "");
                if (result.length > 20) {
                    return result.substring(0, 20) + "...";
                }
                return result;
            },
            hasEnoughCredit(multi){
              var sms_counter = multi * this.getSmsCounter();
              if(sms_counter == 0) return true;
              if(this.accountStore.account.sms_credits_balance >= sms_counter) return true;
              return false;
            },
            getSmsCounterText(multi){
                var sms_counter = multi * this.getSmsCounter();
                if(sms_counter == 0)
                    return "Aucun crédit de SMS ne sera utilisé au lancement de cette campagne";
                return "Un total de "+sms_counter+ (sms_counter > 1 ? " crédits de SMS seront utilisés" : " crédit de SMS sera utilisé") + " au lancement pour ces utilisateurs"
            },
            getEmojiOrAccent() {
                if (this.sms_setting && this.sms_setting.content){
                    var string = this.sms_setting.content;
                    return (string.match(/\p{Emoji}/u) || string.match(/[ãäåÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖÙÚÛÜÝÞßàáçêëìíîïðñòóôõöùúûüýþÿ]/)) ? true : false;
                }
                return true;
            },
            getSmsCounter() {
                if (this.sms_setting && this.sms_setting.content) {
                    var length = this.sms_setting.content.length;

                    if (this.getEmojiOrAccent()) {
                        if (length > 462) return 8;
                        if (length > 396) return 7;
                        if (length > 330) return 6;
                        if (length > 264) return 5;
                        if (length > 198) return 4;
                        if (length > 132) return 3;
                        if (length > 70) return 2;
                        return 1;
                    }
                    if (length > 1072) return 8;
                    if (length > 918) return 7;
                    if (length > 755) return 6;
                    if (length > 612) return 5;
                    if (length > 459) return 4;
                    if (length > 306) return 3;
                    if (length > 160) return 2;
                    return 1;
                }
                return 1;
            },

            showUserPreduction(){
                if(this.smsSettingStore.prediction.data && this.smsSettingStore.prediction.data.length > 0) return true;
                return false;
            },
            getPredictionCount(){
                if(Array.isArray(this.smsSettingStore.prediction.data)) return this.smsSettingStore.prediction.data.length;
                return this.smsSettingStore.prediction.data;
            },
            getPrediction(){
                return this.smsSettingStore.prediction.data.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
            },
            //actionSuccess() {
            //    this.modalSuccess.hide();
            //},
            cancelValidation() {
                this.modalValidation.hide();
            },
            async getSmsSetting() {
                const response = await this.smsSettingStore.getSmsSetting({
                    id: this.$route.params.id,
                    vm: this,
                });
                this.sms_setting = this.smsSettingStore.sms_setting;
                this.date_starting_at = moment(this.sms_setting.date_start_at);
                this.date_start_at = moment(this.sms_setting.date_start_at).format('DD/MM/YYYY');
                this.date_end_at = moment(this.sms_setting.date_end_at).format('DD/MM/YYYY');
                this.date_coverage_start_at = moment(this.sms_setting.date_coverage_start_at);
                this.score = [this.sms_setting.score_min, this.sms_setting.score_max];

                this.target = [];
                if (this.smsSettingStore.sms_setting && this.smsSettingStore.sms_setting.target){
                var t = JSON.parse(this.smsSettingStore.sms_setting.target);
                for (var i = 0; i < t.length; i++) { this.target.push({ name: t[i], code: t[i] }) }
                }

                this.targetLevel = [];
                if (this.smsSettingStore.sms_setting && this.smsSettingStore.sms_setting.diploma_levels){
                var t = JSON.parse(this.smsSettingStore.sms_setting.diploma_levels);
                for (var i = 0; i < t.length; i++) { this.targetLevel.push({ name: t[i], code: t[i] }) }
                }

                this.targetCampus = [];
                for (var i = 0; i < this.smsSettingStore.sms_setting.sms_setting_sections.length; i++) {
                    for (var y = 0; y < this.programStore.sections.length; y++) {
                        if (this.smsSettingStore.sms_setting.sms_setting_sections[i].section_id == this.programStore.sections[y].id ) {
                            this.targetCampus.push(this.programStore.sections[y]);
                        }
                    }
                }

                this.targetCourse = [];
                for (var i = 0; i < this.smsSettingStore.sms_setting.sms_setting_courses.length; i++) {
                    for (var y = 0; y < this.programStore.courses.length; y++) {
                        if (this.smsSettingStore.sms_setting.sms_setting_courses[i].course_id == this.programStore.courses[y].id ) {
                            this.targetCourse.push(this.programStore.courses[y]);
                        }
                    }
                }

                this.targetDisplay = [];
                for (var i = 0; i < this.smsSettingStore.sms_setting.sms_setting_displays.length; i++) {
                    for (var y = 0; y < this.displayStore.displays.length; y++) {
                        if (this.smsSettingStore.sms_setting.sms_setting_displays[i].display_id == this.displayStore.displays[y].id ) {
                            this.targetDisplay.push(this.displayStore.displays[y]);
                        }
                    }
                }

            },
            async getDisplays() {
                await this.displayStore.getDisplays({vm: this});
                this.optionsTargetDisplay = this.displayStore.displays;
            },
            async getCourses() {
                await this.programStore.getCourses({vm: this});
                this.optionsTargetCourse = this.programStore.courses;
            },
            async getBlacklists() {
                await this.smsBlacklistStore.getSmsBlacklists({vm: this});
            },
            async predict() {
                await this.save();
                await new Promise(r => setTimeout(r, 100));
                await this.smsSettingStore.getSmsSettingPrediction({
                    id: this.$route.params.id,
                    vm: this,
                });

                var visitor_ids = [];
                var sms_blacklists = this.smsBlacklistStore.sms_blacklists;
                if(this.smsSettingStore.prediction.data){
                    this.smsSettingStore.prediction.data.forEach(function (v) {
                        if(!sms_blacklists.includes(v.meta.phone)){
                          visitor_ids.push(v.id);
                        }
                    });
                }
                this.visitor_ids = visitor_ids.filter((item, index) => visitor_ids.indexOf(item) === index);
            },
            async saveWithNotice() {
                await this.save();
                this.$toast.open({
                    message: "Campagne sauvegardée avec succès.",
                    type: "success",
                    position: "top-right",
                    duration: 4000,
                    dismissible: true,
                });
            },
            async save() {
                const d = {
                    id: this.$route.params.id,
                    date_coverage_start_at: moment(this.date_coverage_start_at).format("YYYY-MM-DD"),
                    score_min: this.score[0],
                    score_max: this.score[1],
                    target: JSON.stringify(this.target.map((item) => {
                        return item.name;
                    })),
                    diploma_levels: JSON.stringify(this.targetLevel.map((item) => {
                        return item.name;
                    })),
                    section_ids: this.targetCampus.map((item) => {
                        return item.id;
                    }),
                    display_ids:this.targetDisplay.map((item) => {
                        return item.id;
                    }),
                    course_ids: this.targetCourse.map((item) => {
                        return item.id;
                    }),
                    vm: this,
                };
                await this.smsSettingStore.updateSmsSetting(d);
            },
            //async prediction() {
            //    await this.predict();
            //    this.modalSuccess.show();
            //},
            async launch() {
                await this.predict();
                this.modalValidation.show();
            },
            async precedent() {
                await this.$router.push("/campagne/sms/template/0/sample/0/settings/"+this.sms_setting.id);
            },
            async acceptValidation() {

                this.$toast.open({
                    message: "Votre campagne SMS est maintenant active",
                    type: "success",
                    position: "top-right",
                    duration: 4000,
                    dismissible: true,
                });

                const data = {
                    id: this.$route.params.id,
                    extra_visitors: this.visitor_ids,
                    status: "active",
                    vm: this,
                };

                await this.smsSettingStore.updateSmsSetting(data);
                
                const data2 = {
                    sms_setting_id: this.$route.params.id,
                    amount: this.visitor_ids.length * this.getSmsCounter(),
                    vm: this,
                };

                await this.smsDebitStore.createSmsDebit(data2);
                this.accountStore.account.sms_credits_balance = this.accountStore.account.sms_credits_balance - this.visitor_ids.length * this.getSmsCounter();

                this.modalValidation.hide();
                this.$router.push("/campagne/sms/historique");
            },
        },
    };
</script>

<style scoped>
  .datepick {
    --v-calendar-datepicker-icon-color: #7367f0;
    --v-calendar-active-bg-color: #7367f0;
    --v-calendar-active-text-color:#fff;
    --v-calendar-day-hover-bg-color: #c0bbf4;
    --v-calendar-range-bg-color: #eeedfd;
    --v-calendar-range-text-color: #666;
  }
  .v-calendar .content {
    top: -120px;
  }
  .table-container {
    padding-bottom:30px;
  }
  table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
  }
  table thead {
    /* head takes the height it requires,
    and it's not scaled when table is resized */
    flex: 0 0 auto;
    width: calc(100%);
  }
  table tbody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
    height:350px;
    border-bottom: 1px;
    border-color: #dfdfdf;
  }
  table tbody tr {
    width: 100%;
  }
  table thead, table tbody tr {
    display: table;
    table-layout: fixed;
  }
  .hand {
    cursor: pointer !important;
  }
  th, td {
    text-align: left;
  }
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .modal-xl {
    max-width: 1180px;
  }
  .modal-dialog {
    margin: 1.75rem auto;
  }
</style>
<style src="@vueform/slider/themes/default.css"></style>