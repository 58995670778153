<template>
  <div class="card">
    <div class="card-header row">
      <div class="col-md-4">
        <span class="card-title">Parcours</span>
      </div>
      <div class="col-md-8">
        <div class="titles">
          <span
            @click="generateItems()"
            class="badge badge-light-secondary cursor-pointer"
            >Tous&nbsp;({{ nbCourses + nbPages }})</span
          >
          <span
            @click="generateItems('programmes')"
            class="badge bg-light-primary cursor-pointer"
            >Programmes&nbsp;({{ nbCourses }})</span
          >
          <span
            @click="generateItems('pages')"
            class="badge bg-light-info cursor-pointer"
            >pages&nbsp;({{ nbPages }})</span
          >
        </div>
      </div>
    </div>
    <div class="card-body">
      <ul class="timeline">
        <li class="timeline-item" v-for="(item, index) in filteredItems">
          <span
            class="timeline-point timeline-point-indicator"
            :style="{ backgroundColor: getDurationColor(item[2]) }"
          ></span>
          <div class="timeline-event">
            <div
              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
            >
              <span
                v-if="item[6] === 'programme'"
                class="badge badge-light-primary"
                >programme visité</span
              >
              <span
                v-else-if="item[6] === 'page'"
                class="badge badge-light-info"
                >page visitée</span
              >

              <span class="timeline-event-time">
                {{ formatFromNow(item[4]) }}
              </span>
            </div>
            <p>
              <img
                      v-if="item[3].includes('gclid')"
                      src="@/assets/images/ico-google.png"
                      class="icon_ads"
                      width="18"
              />
              <img
                      v-if="item[3].includes('fbclid')"
                      src="@/assets/images/ico-facebook.png"
                      class="icon_ads"
                      width="18"
              />
              <img
                      v-if="item[3].includes('msclkid')"
                      src="@/assets/images/ico-bing.png"
                      class="icon_ads"
                      width="18"
              />
              <img
                      v-if="item[3].includes('utm_source=youtube')"
                      src="@/assets/images/ico-youtube.png"
                      class="icon_ads"
                      width="18"
              />
              <a class="program_url" :href="item[3]" target="_blank"
                >{{ FormatShortString(item[0]) }}</a
              >
            </p>
            <div class="d-flex flex-row">
              <div class="d-flex flex-row me-4">
                <div
                  class="avatar me-1"
                  :class="getClass('bg-light-', item[2])"
                >
                  <div class="avatar-content">
                    <vue-feather type="clock"></vue-feather>
                  </div>
                </div>
                <div class="my-auto">
                  <span class="intitule mb-0">Temps passé</span>
                  <p class="valeur mb-0">{{ item[2] }}</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div
                  class="avatar me-1 alert-secondary"
                >
                  <div class="avatar-content">
                    <vue-feather type="eye"></vue-feather>
                  </div>
                </div>
                <div class="my-auto">
                  <span class="intitule mb-0">Nombre de visites</span>
                  <p class="valeur mb-0">{{ item[1] }}</p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="row">
        <ul class="pagination">
          <li
            v-if="offset > 0"
            @click="setPrev()"
            class="paginate_button page-item previous disabled"
            id="DataTables_Table_0_previous"
          >
            <a
              aria-controls="DataTables_Table_0"
              data-dt-idx="0"
              tabindex="0"
              class="page-link"
              >&nbsp;</a
            >
          </li>
          <li
            role="presentation"
            class="page-item"
            :class="{ active: index === offset / 5 }"
            v-for="(page, index) in Math.ceil(items.length / 5)"
          >
            <button
              role="menuitemradio"
              type="button"
              class="page-link"
              @click="setOffset(index)"
            >
              {{ index + 1 }}
            </button>
          </li>
          <li
            v-if="offset + 5 < items.length"
            @click="setNext()"
            class="paginate_button page-item next disabled"
            id="DataTables_Table_0_next"
          >
            <a
              aria-controls="DataTables_Table_0"
              data-dt-idx="1"
              tabindex="0"
              class="page-link"
              >&nbsp;</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useContactStore } from "@/stores/contact";

export default {
  name: "timeline",
  props: {
    courses: Array,
    pages: Array,
  },
  data() {
    return {
      active: "",
      items: [],
      variants: ["success", "warning", "danger"],
      colors: ["#28c76f", "#ff9f43", "#ea5455"],
      offset: 0,
      pagination: 0,
    };
  },
  watch: {
    courses: [
      {
        handler: "generateItems",
        handler: "generateItems",
      },
    ],
    pages: [
      {
        handler: "generateItems",
      },
    ],
  },
  setup() {
    const contactStore = useContactStore();
    return {
      contactStore,
    };
  },
  async mounted() {
    await this.generateItems();
  },
  computed: {
    filteredItems() {
      return this.items.slice(this.offset, this.offset + 5);
    },
    nbCourses() {
      if (Array.isArray(this.courses) && this.courses.length > 0) {
        return this.courses.length;
      }
      return 0;
    },
    nbPages() {
      if (Array.isArray(this.pages) && this.pages.length > 0) {
        return this.pages.length;
      }
      return 0;
    },
  },
  methods: {
    generateItems(type) {
      if (!this.courses) return;
      if (!this.pages) return;
      this.offset = 0;
      const courses = this.courses;
      for (let i = 0; i < courses.length; i++) {
        courses[i][6] = "programme";
      }
      const pages = this.pages;
      for (let i = 0; i < pages.length; i++) {
        pages[i][6] = "page";
      }
      if (type === "programmes") {
        this.items = courses;
      } else if (type === "pages") {
        this.items = pages;
      } else {
          function compare(a, b) {
              let comparison = 0;
              if (Date.parse(a[4]) > Date.parse(b[4]) ) {
                  comparison = 1;
              } else {
                  comparison = -1;
              }
              return comparison;
          }

          this.items = courses.concat(pages).sort(compare);
      }
    },
    setPrev() {
      this.offset -= 5;
    },
    setNext() {
      this.offset += 5;
    },
    setOffset(index) {
      this.offset = index * 5;
    },
    getDurationColor(value) {
          const val = parseInt(value.split("m")[0]) * 60 + parseInt(value.split("m")[1].split("s")[0]);
          if (val < 60) {
              return "#ea5455";
          }
          if (val < 180) {
              return "#ff9f43";
          }
          return "#28C76F";
    },
    getClass(classe, value) {
        const val = parseInt(value.split("m")[0]) * 60 + parseInt(value.split("m")[1].split("s")[0]);
        if (val < 60) {
            return classe + "danger";
        }
        if (val < 180) {
            return classe + "warning";
        }
        return classe + "success";
    },
  },
};
</script>

<style scoped>
.titles {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.titles span {
  margin-right: 5px;
}
.timeline {
  font-size: 0.9rem;
}
.timeline .avatar {
  width: 35px;
  height: 35px;
}
.timeline .avatar .avatar-content {
  width: 35px;
  height: 35px;
}
.timeline .feather {
  width: 18px !important;
  height: 18px !important;
}
.tous:active {
  background-color: #e5f8ed !important;
}
.icon_ads {
  margin-right: 5px;
}
.program_url{
  vertical-align: middle;
}
</style>
