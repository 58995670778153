import { createApp } from "vue";
import App from "./App.vue";
import pinia from "@/stores";
import router from "./router";
import VueFeather from "vue-feather";
import VueApexCharts from "vue3-apexcharts";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import VueDatepickerUi from "vue-datepicker-ui";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import Popper from "vue3-popper";
import vSelect from 'vue-select'

import windowMixin from "@/mixins/windows.js";
import datatableMixin from "@/mixins/datatable.js";
import commonMixin from "@/mixins/common.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'vue-select/dist/vue-select.css';
import CountryFlag from 'vue-country-flag-next';
import mitt from 'mitt';
import { appsignal } from "@/appsignal";
import { errorHandler } from "@appsignal/vue";

//require("@core/scss/core.scss");
require("@/app-assets/vendors/css/vendors.min.css");
require("@/app-assets/css/bootstrap.min.css");
require("@/app-assets/css/bootstrap-extended.min.css");
require("@/app-assets/css/colors.min.css");
require("@/app-assets/css/components.min.css");

require("@/app-assets/css/pages/authentication.min.css");
require("@/app-assets/css/plugins/forms/form-validation.min.css");
require("@/app-assets/css/pages/app-chat-list.min.css");
require("@/app-assets/vendors/css/file-uploaders/dropzone.min.css");
require("@/app-assets/css/plugins/forms/form-file-uploader.css");
require("@/app-assets/css/pages/page-pricing.css");
require("@/app-assets/vendors/css/tables/datatable/dataTables.bootstrap5.min.css");
require("../node_modules/vue-multiselect/dist/vue-multiselect.css");
require("@/app-assets/css/core/menu/menu-types/horizontal-menu.min.css");
require("@/app-assets/css/core/menu/menu-types/vertical-menu.min.css");
require("@/assets/scss/style.scss");

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(pinia);
app.use(router);
app.use(VueToast);
app.use(VueApexCharts);
app.component(VueFeather.name, VueFeather);
app.component("Datepicker", VueDatepickerUi);
app.component("Popper", Popper);
app.component('v-select', vSelect)
app.component('country-flag', CountryFlag)
app.mixin(windowMixin);
app.mixin(datatableMixin);
app.mixin(commonMixin);

router.isReady().then(() => {
  app.config.errorHandler = errorHandler(appsignal, app);
  app.mount("#app");
});
