import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";
import moment from "moment";

export const useDisplayStore = defineStore("display", {
  state: () => ({
      displays: [],
  }),

  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },

  actions: {
    async getDisplays(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "displays.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.displays = response.data;
      }
      await loaderStore.setLoader(false);
    },
  },
  persist: {
    enabled: true,
  },
});
