<template>
  <div>
    <DashboardLayout>
      <div class="row mb-2">
        <div class="col-lg-8">
          <h1>Entreprises</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <DetailEntreprise
            :entreprise="entrepriseStore.entreprise"
          ></DetailEntreprise>
        </div>
        <!--div class="col-md-4">
          <Plan></Plan>
        </div-->
      </div>
      <div class="row">
        <div class="col-md-6">
          <Timeline
            :courses="entrepriseStore.entreprise.courses"
            :pages="entrepriseStore.entreprise.pages"
          ></Timeline>
        </div>
        <div class="col-md-6">
          <Chat
            v-if="entrepriseStore.entreprise.questions && entrepriseStore.entreprise.questions.length > 0"
            :contact="entrepriseStore.entreprise"
          ></Chat>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";
import { useEntrepriseStore } from "@/stores/entreprise";
import { useLayoutStore } from "@/stores/layout";
import DetailEntreprise from "@/components/detailEntreprise";
import Plan from "@/components/plan";
import Timeline from "@/components/timeline";
import Chat from "@/components/chat";

export default {
  name: "EntrepriseDetail",
  components: {
    DashboardLayout,
    DetailEntreprise,
    Plan,
    Timeline,
    Chat,
  },
  data() {
    return {};
  },
  computed: {},
  async created() {
    this.layoutStore.setActive('entreprise')
    await this.entrepriseStore.getEntreprise({
      id: this.$route.params.id,
      vm: this,
    });
  },
  setup() {
    const entrepriseStore = useEntrepriseStore();
    const layoutStore = useLayoutStore();
    return {
      entrepriseStore,
      layoutStore,
    };
  },
  methods: {},
};
</script>

<style scoped></style>
