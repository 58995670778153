import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";
import moment from "moment";

export const useLicenceStore = defineStore("licence", {
    state: () => ({
        chatbot: false,
        chatbot_id: null,
        chatbot_days_left: 0,
        chatbot_end: moment().format("DD-MM-YYYY"),
        chatbot_date_end: moment(),
        chatbot_leads_500: false,
        chatbot_leads_1500: false,
        chatbot_leads_5000: false,
        chatbot_interaction: false,
        chatbot_personnalisation: false,
        chatbot_tracking: false,
        chatbot_entreprise: false,
        chatbot_crm: false,
        chatbot_sms: false,
        chatbot_support: false,
        chatbot_statut_type: "",
        sms_statut_type: "",
        sms: false,
        sms_days_left: 0,
        sms_end: moment().format("DD-MM-YYYY"),
    }),

    getters: {
        authGetter(state) {
            const authStore = useAuthStore();
            return {
                token: authStore.token,
                client: authStore.client,
                uid: authStore.uid,
            };
        },
        accountGetter(state) {
            const accountStore = useAccountStore();
            return {
                uuid: accountStore.uuid,
            };
        },
    },

    actions: {
        getCurrentLicence(data, licence_type) {
            for (var j = 0; j < data.length; j++){
                var a = data[j];
                var s = moment(a["date_start"]);
                var e = moment(a["date_end"]).add(1, 'days');

                if(a["licence_type"] == licence_type && moment().isSameOrAfter(s) && moment().isSameOrBefore(e) ){
                    return a;
                }
            };
            return null;
        },
        getLatestLicence(data, licence_type) {
            var res = null;
            for (var j = 0; j < data.length; j++){
                var a = data[j];
                if(a["licence_type"] == licence_type){
                    res = a;
                }
            };
            return res;
        },
        getLicenceDaysLeft(a) {
            var now = moment(new Date()); //todays date
            var end = moment(a["date_end"]); // another date
            return end.diff(now, 'days')
        },
        async getLicences(data: any) {
            const loaderStore = useLoaderStore();
            await loaderStore.setLoader(true);
            const response = await axios
                .get(
                    process.env.VUE_APP_ROOT_API +
                    "licences.json?account_uuid=" +
                    this.accountGetter.uuid,
                    {
                        headers: {
                            "token-type": "Bearer",
                            uid: this.authGetter.uid,
                            client: this.authGetter.client,
                            "access-token": this.authGetter.token,
                        },
                    }
                )
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.chatbot = false;
                this.chatbot_days_left = 0;
                this.chatbot_leads_500 = false;
                this.chatbot_leads_1500 = false;
                this.chatbot_leads_5000 = false;
                this.chatbot_interaction = false;
                this.chatbot_personnalisation = false;
                this.chatbot_tracking = false;
                this.chatbot_entreprise = false;
                this.chatbot_crm = false;
                this.chatbot_sms = false;
                this.chatbot_support = false;
                this.chatbot_statut_type = "";
                this.sms_statut_type = "";
                this.sms = false;
                var l = this.getLatestLicence(response.data, "chatbot");
                if (l != null) {
                    this.chatbot_id = l["id"];
                    this.chatbot_days_left = this.getLicenceDaysLeft(l);
                    this.chatbot_end = moment(l["date_end"]).format("DD-MM-YYYY");
                    this.chatbot_date_end = moment(l["date_end"]);
                }
                var a = this.getCurrentLicence(response.data, "chatbot");
                if (a != null) {
                    this.chatbot = true;
                    this.chatbot_statut_type =  a["statut_type"];
                    for (var i = 0; i < a["licence_modules"].length; i++){
                        var m = a["licence_modules"][i];

                        if(m["module_type"] == "leads_500"){
                            this.chatbot_leads_500 = true;
                        }
                        if(m["module_type"] == "leads_1500"){
                            this.chatbot_leads_1500 = true;
                        }
                        if(m["module_type"] == "leads_5000"){
                            this.chatbot_leads_5000 = true;
                        }
                        if(m["module_type"] == "tracking"){
                            this.chatbot_tracking = true;
                        }
                        if(m["module_type"] == "interaction"){
                            this.chatbot_interaction = true;
                        }
                        if(m["module_type"] == "personnalisation"){
                            this.chatbot_personnalisation = true;
                        }
                        if(m["module_type"] == "entreprise"){
                            this.chatbot_entreprise = true;
                        }
                        if(m["module_type"] == "crm"){
                            this.chatbot_crm = true;
                        }
                        if(m["module_type"] == "sms"){
                            this.chatbot_sms = true;
                        }
                        if(m["module_type"] == "support"){
                            this.chatbot_support = true;
                        }
                    };
                }

                var s = this.getCurrentLicence(response.data, "sms");
                if(s != null) {
                    this.sms = true;
                    this.sms_days_left = this.getLicenceDaysLeft(s);
                    this.sms_end = moment(s["date_end"]).format("DD-MM-YYYY");
                    this.sms_statut_type =  s["statut_type"];
                }
            }
            await loaderStore.setLoader(false);
        },

        async updateLicence(data: any) {
            const loaderStore = useLoaderStore();
            await loaderStore.setLoader(true);
            const url =
                process.env.VUE_APP_ROOT_API +
                "licences/" +
                data.id +
                ".json?account_uuid=" +
                this.accountGetter.uuid;
            const dataToSend = {};
            if (data.id) dataToSend["id"] = data.id;
            if (data.date_end) dataToSend["date_end"] = data.date_end;

            const response = await axios
                .put(url, dataToSend, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            await loaderStore.setLoader(false);
            if (response) {
                data.vm.$toast.open({
                    message: "Vos modifications sont enregistrées",
                    type: "success",
                    position: "bottom-right",
                    duration: 2000,
                    dismissible: true,
                });

                this.chatbot_end = moment(response.data.data.date_end).format("DD-MM-YYYY");
                this.chatbot_date_end = moment(response.data.data.date_end);
                return response.data;
            }
        },
    },
    persist: {
        enabled: true,
    },
});