import { defineStore } from "pinia";
import { useAuthStore } from "./auth";
import { useLoaderStore } from "./loader";
import axios from "axios";

export const useAccountStore = defineStore("account", {
  state: () => ({
    accounts: [],
    uuid: "",
    id: "",
    account: {},
  }),

  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
  },

  actions: {
    async getAccounts(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(process.env.VUE_APP_ROOT_API + "accounts.json", {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$flashMessage.show({
            status: "error",
            title: "Error",
            text: "Une erreur est survenue lors de la requete",
            blockClass: "flashmessage-error",
            time: 10000,
          });
        });
      if (response) {
        this.accounts = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async getAccount(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "accounts/" +
        data.id +
        ".json?account_uuid=" +
        this.uuid;
      const response = await axios
        .get(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
          window.location.href = '/signin';
        });
      if (response) {
        this.account = response.data.data;
      }
      await loaderStore.setLoader(false);
    },
    async updateAccount(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "accounts/" +
        this.id +
        ".json?account_uuid=" +
        this.uuid;
      const dataToSend = {};
      /*** General ***/
      if (data.name) dataToSend["name"] = data.name;
      if (data.url) dataToSend["site_url"] = data.url;
      if (data.cgu || data.cgu == "") dataToSend["cgu"] = data.cgu;
      if (data.sections) dataToSend["sections"] = data.sections;

      /*** Accueil ***/
      if (data.assistantName) dataToSend["assistant_name"] = data.assistantName;
      if (data.widgetText) dataToSend["widget_text"] = data.widgetText;
      if (data.widgetText2) dataToSend["widget_text_2"] = data.widgetText2;
      if (data.widgetTextMobile == true || data.widgetTextMobile == false)  dataToSend["widget_text_mobile"] = data.widgetTextMobile;
      if (data.polite) dataToSend["polite"] = data.polite;
      if (data.section_naming) dataToSend["section_naming"] = data.section_naming;
      /*** Visuel ***/
      if (data.widget_logo) dataToSend["widget_logo"] = data.widget_logo;
      if (data.chatSchoolname) dataToSend["chatSchoolname"] = data.chatSchoolname;
      if (data.widgetLogoColor)
        dataToSend["widget_logo_color"] = data.widgetLogoColor;
      if (data.template) dataToSend["template"] = data.template;
      if (data.widgetColorBandeau)
        dataToSend["widget_color_bandeau"] = data.widgetColorBandeau;
      if (data.position) dataToSend["position"] = data.position;
      if (data.positionBottom)
        dataToSend["position_border"] = data.positionBottom;
      if (data.display_delay)
        dataToSend["display_delay"] = data.display_delay;
      if (data.positionBorder)
        dataToSend["position_bottom"] = data.positionBorder;
      if (data.image || data.image == "") dataToSend["image"] = data.image;
      if (data.avatar || data.avatar == "") dataToSend["avatar"] = data.avatar;
      if (data.continents) dataToSend["continents"] = data.continents;
      if (data.filter) dataToSend["filter"] = data.filter;
      if (data.widgetBounceType) dataToSend["widget_bounce_type"] = data.widgetBounceType;

      if (data.enable_mobile == true || data.enable_mobile == false) dataToSend["enable_mobile"] = data.enable_mobile;
      if (data.male_gender) dataToSend["male_gender"] = data.male_gender;
      if (data.enable_magma == true || data.enable_magma == false) dataToSend["enable_magma"] = data.enable_magma;
      if (data.hidden == true || data.hidden == false) dataToSend["hidden"] = data.hidden;
      if (data.call_back == true || data.call_back == false) dataToSend["call_back"] = data.call_back;
      if (data.callback_am_start_time) dataToSend["callback_am_start_time"] = data.callback_am_start_time;
      if (data.callback_am_end_time) dataToSend["callback_am_end_time"] = data.callback_am_end_time;
      if (data.callback_pm_start_time) dataToSend["callback_pm_start_time"] = data.callback_pm_start_time;
      if (data.callback_pm_end_time) dataToSend["callback_pm_end_time"] = data.callback_pm_end_time;

      if (data.enable_flow_middle_school == true || data.enable_flow_middle_school == false) dataToSend["enable_flow_middle_school"] = data.enable_flow_middle_school;
      if (data.enable_flow_high_school == true || data.enable_flow_high_school == false) dataToSend["enable_flow_high_school"] = data.enable_flow_high_school;
      if (data.enable_flow_college == true || data.enable_flow_college == false) dataToSend["enable_flow_college"] = data.enable_flow_college;

      if (data.parent_enabled == true || data.parent_enabled == false) dataToSend["parent_enabled"] = data.parent_enabled;
      if (data.student_enabled == true || data.student_enabled == false) dataToSend["student_enabled"] = data.student_enabled;
      if (data.jobless_enabled == true || data.jobless_enabled == false) dataToSend["jobless_enabled"] = data.jobless_enabled;
      if (data.worker_enabled == true || data.worker_enabled == false) dataToSend["worker_enabled"] = data.worker_enabled;
      if (data.entreprise_enabled == true || data.entreprise_enabled == false) dataToSend["entreprise_enabled"] = data.entreprise_enabled;
      if (data.entreprise_apprentice == true || data.entreprise_apprentice == false) dataToSend["entreprise_apprentice"] = data.entreprise_apprentice;
      if (data.entreprise_staff == true || data.entreprise_staff == false) dataToSend["entreprise_staff"] = data.entreprise_staff;
      dataToSend["account_uuid"] = this.uuid;
      /*** Lead Score ***/
      if (data.score_weights) dataToSend["score_weights"] = data.score_weights;
      console.log(dataToSend);
      const response = await axios
        .put(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.account = response.data.data;
        data.vm.$toast.open({
          message: "Paramètres sauvegardés avec succès",
          type: "success",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
      }
      await loaderStore.setLoader(false);
    },
  },
  persist: {
    enabled: true,
  },
});
