<template>
  <div>
    <div class="row">
      <div class="col-12">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-2">
        <label class="form-label">Date de fin de période d'essai</label>

        <Datepicker
                position="right top"
                placeholder=""
                lang="fr"
                v-model="date_end"
                @input="updatePeriode()"
                @change="updatePeriode()"
                class="datepick"
        ></Datepicker>
      </div>

      <hr>
      <div class="mt-2 mb-2 right">
        <button type="submit" @click="actionSave" class="btn btn-primary">Enregistrer</button>
      </div>
    </div>
  </div>
</template>

<script>
    import { useAccountStore } from "@/stores/account";
    import { useLicenceStore } from "@/stores/licence";
    import { useUserStore } from "@/stores/user";
    import useVuelidate from "@vuelidate/core";
    import moment from "moment";

    export default {
        name: "settingLead",
        data() {
            return {
                date_end: this.licenceStore.chatbot_date_end
            };
        },
        setup() {
            const accountStore = useAccountStore();
            const licenceStore = useLicenceStore();
            const userStore = useUserStore();
            return {
                v$: useVuelidate(),
                accountStore,
                licenceStore,
                userStore
            };
        },
        watch: {
        },
        methods: {
            actionSave() {
                this.licenceStore.updateLicence({
                    id: this.licenceStore.chatbot_id,
                    date_end: moment(this.date_end).add(1, "d"),
                    vm: this,
                });
            },
        },
    };
</script>

<style scoped></style>
