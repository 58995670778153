<template>

  <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          ref="upgradeModal"
          style="margin-top:50px"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content center">
        <div class="modal-header bg-transparent-popup" style="height:0;padding:0">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="margin-top:40px;margin-right:15px"></button>
        </div>

        <div class="container">

          <p class="modal-title">Importer les programmes via CSV</p>
          <div class="row" style="margin-bottom:20px">
            <div class="col">
              <select class="form-select" aria-label="Select item" v-model="category">
                <option value="">Choisir un champ...</option>
                <option v-for="item in items" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col text-start">
              <div class="form-floating">
                Type de formation<br><small>('Diplomante', 'Continue / pro' ou 'Tout')</small>
              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom:20px">
            <div class="col">
              <select class="form-select" aria-label="Select item" v-model="name">
                <option value="">Choisir un champ...</option>
                <option v-for="item in items" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col text-start">
              <div class="form-floating" style="padding-top:10px">
                Nom de la formation *
              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom:20px">
            <div class="col">
              <select class="form-select" aria-label="Select item" v-model="admission" >
                <option value="">Choisir un champ...</option>
                <option v-for="item in items" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col text-start">
              <div class="form-floating">
                Ciblage / Admission <br><small>('Terminale/BAC', 'BAC+1' etc, séparés par des virgules)</small>
              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom:20px">
            <div class="col">
              <select class="form-select" aria-label="Select item" v-model="section" >
                <option value="">Choisir un champ...</option>
                <option v-for="item in items" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col text-start">
              <div class="form-floating">
                Campus <br><small>(Nom du ou des campus séparés par des virgules)</small>
              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom:20px">
            <div class="col">
              <select class="form-select" aria-label="Select item" v-model="domain" >
                <option value="">Choisir un champ...</option>
                <option v-for="item in items" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col text-start">
              <div class="form-floating">
                Domaine de la formation <br><small>(Nom du ou des domaines séparés par des virgules)</small>
              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom:20px">
            <div class="col">
              <select class="form-select" aria-label="Select item" v-model="link" >
                <option value="">Choisir un champ...</option>
                <option v-for="item in items" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col text-start">
              <div class="form-floating" style="padding-top:10px">
                Lien web *
              </div>
            </div>
          </div>


          <hr>
          <div style="text-align: left;">
            <small>* Champs requis</small>
          </div>
          <div style="text-align: right;" class="mb-2 mt-2">
            <button
                    class="btn btn-outline-primary"
                    @click="closeModal()"
            >
              Annuler
            </button>
            <button
                    style="margin-left:5px"
                    class="btn btn-primary"
                    @click="submitCSV()"
            >
              Créer les programmes
            </button>


          </div>
        </div>

      </div>
    </div>
  </div>

  <label for="file-input" class="btn btn-outline-primary" style="margin-left:5px;margin-top:6px">
    Import via CSV
  </label>
  <input id="file-input" type="file" @change="onFileSelected" style="display: none;">

</template>

<script>
    import Papa from 'papaparse';
    import { Modal } from "bootstrap";
    import { useProgramStore } from "@/stores/program";
    import { useLoaderStore } from "@/stores/loader";

    const URL_REGEXP = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/i;
    const ADMISSION_LEVELS = new Set(["BAC+5","BAC+4","BAC+3","BAC+2","BAC+1","Terminale/BAC","Terminale/BAC Technologique","Terminale/BAC Pro","1ere","1ere Pro","2nde","CAP/BEP","3e","4e"])

    export default {
        name: "import",
        props: {},
        data() {
            return {
                modalValidation: null,
                category: "",
                name: "",
                section: "",
                admission: "",
                domain: "",
                link: "",
                items: [],
                results: [],
                sections: {},
                domains: {},
            };
        },
        watch: {
        },
        setup() {
            const programStore = useProgramStore();
            return {
                programStore
            };
        },
        async mounted() {
            this.modalValidation = new Modal(this.$refs.upgradeModal);

            await this.programStore.getSections({ vm: this });
            await this.programStore.getDomains({ vm: this });
        },
        computed: {
        },
        methods: {
            getEncoding(str) {
                const accentRegExp = /[éèàêô]/gi;
                if(accentRegExp.test(str)) {
                    return "UTF-8";
                } else {
                    if(this.isISO88591(str)) {
                      return "ISO-8859-1";
                    } else {
                      return "macintosh";
                    }
                }
            },
            isISO88591(str) {
                  for (let i = 0; i < str.length; i++) {
                      const code = str.charCodeAt(i);
                      if (code > 255) {
                          return false;
                      }
                  }
                  return true;
            },
            async getFileString(file) {
                const data = await this.readFileAsArrayBuffer(file);
                const decoder = new TextDecoder();
                return this.getEncoding(decoder.decode(data));
            },
            readFileAsArrayBuffer(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = () => reject(reader.error);
                    reader.readAsArrayBuffer(file);
                });
            },
            onFileSelected(event) {
                this.category = "";
                this.name = "";
                this.section = "";
                this.admission = "";
                this.domain = "";
                this.link = "";

                const file = event.target.files[0];
                if (file.type === 'text/csv') {

                    this.getFileString(file).then(encoding => {
                        this.modalValidation.show();
                        this.parseCSV(file, encoding);
                    }).catch(error => {
                        console.error(`Error getting file encoding: ${error}`);
                    });
                } else {
                    alert("Oups, le fichier choisi n'est pas valide. Il faut nécessairement soumettre un fichier au format CSV.");
                }
            },
            parseCSV(file, encoding) {
                Papa.parse(file, {
                    encoding: encoding,
                    complete: (results) => {
                        var i = [];
                        Object.keys(results.data[0]).forEach(function(k){
                            i.push({id: k, name: k})
                        });
                        this.items = i;
                        this.results = results;
                    },
                    header: true
                });
            },
            closeModal() {
                this.modalValidation.hide();
            },
            async submitCSV() {
                const loaderStore = useLoaderStore();
                await loaderStore.setLoader(true);

                // DEBUG la boucle de la mort
                await this.createPrograms()

               await this.sleep(1000);
               await loaderStore.setLoader(false);
               this.emitter.emit("import", "reload");
               this.modalValidation.hide();
            },
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            async createPrograms() {
              const programInfos = this.results.data.map(r => this.getProgramInfo(r)).filter(Boolean)

              await this.programStore.createCourses(programInfos);
            },
            getProgramInfo(csvRow) {
              console.log({csvRow, this: this})
                // le JSON envoyé au serveur
                const course = this.extractProgramInfo(csvRow);
                if (!course) {
                  return null
                }

                const domains = this.extractDomainNames(csvRow);
                const sections = this.extractSectionNames(csvRow);

                return {
                    course,
                    domains,
                    sections
                }
            },
            extractProgramInfo(csvRow) {
                // le JSON envoyé au serveur
                const programInfo = {};

                // validation des données entrantes
                if(
                    // nom obligatoire
                    !csvRow[this.name]
                    // lien valide
                    || (!csvRow[this.link] || !URL_REGEXP.test(csvRow[this.link]))
                ) {
                  return null;
                }

                // extraction de la catégorie
                const categoryName = csvRow[this.category].trim().toLowerCase()
                switch(categoryName) {
                  case 'diplomante':
                    programInfo.category = 'initial';
                    break;
                  case 'continue / pro':
                    programInfo.category = 'professional';
                    break;
                  case 'tout':
                  default:
                    programInfo.category = 'tout';
                    break;
                }

                // extraction du nom
                programInfo.name = csvRow[this.name];

                // extraction du lien
                programInfo.url = csvRow[this.link];

                // extraction du niveau scolaire d'admission
                let admissionLevels = []
                if(csvRow[this.admission]) {
                  admissionLevels = csvRow[this.admission]
                      .split(",")
                      .map(str => str.trim())
                      .map(str => str.toUpperCase().startsWith("BAC") ? str.replace(/\s+/g, '').toUpperCase() : str) // ?
                      .filter(level => ADMISSION_LEVELS.has(level))
                }
                programInfo.admission = admissionLevels;

                return programInfo
            },
            extractDomainNames(csvRow) {
                // on reçoit une liste séparée par des virgules
                const rawDomainInfoAsString = csvRow[this.domain]
                return rawDomainInfoAsString
                    ? rawDomainInfoAsString.split(",").map(item => item.trim()).filter(Boolean)
                    : []
            },
            extractSectionNames(csvRow) {
                // on reçoit une liste séparée par des virgules
                const rawSectionInfoAsString = csvRow[this.section]
                return rawSectionInfoAsString
                    ? rawSectionInfoAsString.split(",").map(item => item.trim()).filter(Boolean).map(item => item.toUpperCase())
                    : []
            },
        },
    };
</script>

<style scoped>
  .modal-title {
    padding-bottom:40px;
    padding-top:40px;
    font-size:20px;
  }
</style>
