import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";

export const useChatgptStore = defineStore("chatgpt", {
    state: () => ({
        current_template_id: null,
        description: null,
        key: null,
        wkey: null,
        templates: [],
        whizz: "",
        parallel_template: {},
        parallel_whizz: {}
    }),

    getters: {
        authGetter(state) {
            const authStore = useAuthStore();
            return {
                token: authStore.token,
                client: authStore.client,
                uid: authStore.uid,
            };
        },
        accountGetter(state) {
            const accountStore = useAccountStore();
            return {
                uuid: accountStore.uuid,
            };
        },
    },

    actions: {
        async getMagicTemplate(data: any) {
            const loaderStore = useLoaderStore();
            await loaderStore.setLoader(true);

            this.description = data.description;
            const dataToSend = {
                "keys": data.keys,
                "description": data.description,
                "school": data.school,
                "counter": data.counter,
                "tone": data.tone,
                "headline": data.headline
            };

            const response = await axios
                .post(process.env.VUE_APP_ROOT_API +
                    "chatgpt/templates.json?account_uuid=" +
                    this.accountGetter.uuid, dataToSend, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    /*data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });*/
                });
            if (response) {
                this.templates = response.data["sms_templates"];
            }
            await loaderStore.setLoader(false);
        },
        async getMagicTemplateParallel(data: any) {
            const loaderStore = useLoaderStore();
            await loaderStore.setLoader(true);

            this.description = data.description;
            const dataToSend = {
                "keys": data.keys,
                "description": data.description,
                "school": data.school,
                "counter": data.counter,
                "tone": data.tone,
                "headline": data.headline
            };

            const response = await axios
                .post(process.env.VUE_APP_ROOT_API +
                    "chatgpt/templates_q.json?account_uuid=" +
                    this.accountGetter.uuid, dataToSend, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                });
            if (response) {
                this.key = response.data["key"];
            }
            await loaderStore.setLoader(false);
        },
        async getMagicWhizzParallel(data: any) {
            const dataToSend = {
                "sentence": data.sentence
            };

            const response = await axios
                .post(process.env.VUE_APP_ROOT_API +
                    "chatgpt/templates_w.json?account_uuid=" +
                    this.accountGetter.uuid, dataToSend, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                });
            if (response) {
                this.wkey = response.data["key"];
            }
        },
        async getMagicResultParallel() {
            const url =
                process.env.VUE_APP_ROOT_API +
                "chatgpt/templates_r.json?key=" + this.key;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                });
            if (response) {
                this.parallel_template = response.data;
            }
        },
        async getMagicResultParallelWhizz() {
            const url =
                process.env.VUE_APP_ROOT_API +
                "chatgpt/templates_r.json?key=" + this.wkey;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                });
            if (response) {
                this.parallel_whizz = response.data;
            }
        }
    },
    persist: {
        enabled: true,
    },
});