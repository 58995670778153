<template>
  <div>
    <DashboardLayout>

      <div class="col-lg-12" v-if="!licenceStore.chatbot_tracking">
        <div class="alert alert-warning" style="padding:10px">⚠️ Actuellement, vous ne disposez pas du module 'tracking'. Les informations concernant ce contact sont limitées.</div>
      </div>

      <div class="row mb-1">
        <div class="col-lg-8">
          <h1>Contacts</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <DetailContact :contact="contactStore.contact"></DetailContact>
        </div>
      </div>
      <div class="row" :class="licenceStore.chatbot_tracking ? '' : ' blur-grayscale'">
        <div class="col-lg-6 col-md-12">
          <Timeline
            :courses="contactStore.contact.courses"
            :pages="contactStore.contact.pages"
          ></Timeline>
        </div>
        <div class="col-lg-6 col-md-12">
          <Chat
            v-if="
              contactStore.contact.questions &&
              contactStore.contact.questions.length > 0
            "
            :contact="contactStore.contact"
          ></Chat>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";
import { useContactStore } from "@/stores/contact";
import { useLayoutStore } from "@/stores/layout";
import { useAccountStore } from "@/stores/account";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";
import DetailContact from "@/components/detailContact.vue";
import Plan from "@/components/plan";
import Timeline from "@/components/timeline";
import Chat from "@/components/chat";

export default {
  name: "ContactDetail",
  components: {
    DashboardLayout,
    DetailContact,
    Plan,
    Timeline,
    Chat,
  },
  data() {
    return {};
  },
  computed: {},
  async created() {
    this.layoutStore.setActive("contact");
    await this.contactStore.getContact({
      id: this.$route.params.id,
      vm: this,
    });
  },
  setup() {
    const contactStore = useContactStore();
    const layoutStore = useLayoutStore();
    const accountStore = useAccountStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return {
      contactStore,
      layoutStore,
      accountStore,
      licenceStore,
      userStore
    };
  },
  methods: {
      singularSectionName() {
          if(this.accountStore.account.section_naming == "campus") return "Campus";
          if(this.accountStore.account.section_naming == "school") return "École";
          if(this.accountStore.account.section_naming == "center") return "Centre de formation";
          return "campus";
      },

  },
};
</script>

<style scoped></style>
