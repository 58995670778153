<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="profile-img-container d-flex align-items-center mb-4">
            <div class="avatar avatar-xl bg-light-success rounded me-2">
              <div class="avatar-content" v-if="notEmptyObject(entreprise)">
                {{ entreprise.meta.company.charAt(0) || "" }}
              </div>
            </div>
            <div class="profile-title">
              <h2 class="text" v-if="notEmptyObject(entreprise)">
                {{ entreprise.meta.company || "" }}
              </h2>
              <p class="text"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mb-2 mb-xl-0">
              <div class="d-flex flex-row">
              </div>
            </div>
            <div class="col-6 mb-2 mb-xl-0">
              <div v-if="entreprise.is_mobile" class="d-flex flex-row">
                <div class="avatar avatar-small bg-light-success rounded me-2">
                  <div class="avatar-content">
                    <vue-feather type="smartphone"></vue-feather>
                  </div>
                </div>
                <div class="my-auto">
                  <h4 class="fw-bolder mb-0">Mobile traffic</h4>
                  <p class="card-text font-small-3 mb-0"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1 col-12"></div>
        <div class="col-sm-5 col-12">
          <div class="infos">
            <ul>
              <li>Email: </li>
              <li v-if="notEmptyObject(entreprise)">
                {{ entreprise.meta.email || "" }}
              </li>
            </ul>
            <ul>
              <li>Téléphone: </li>
              <li v-if="notEmptyObject(entreprise)">
                <span v-html="formatPhone(entreprise.meta.phone || '')"></span>
              </li>
            </ul>
            <ul>
              <li>Role: </li>
              <li v-if="notEmptyObject(entreprise)">
                {{ entreprise.meta.position || "" }}
              </li>
            </ul>
            <ul v-if="programStore.sections.length > 1">
              <li class="intitule">{{this.singularSectionName()}}:&nbsp;</li>
              <li v-if="notEmptyObject(entreprise)">
                {{ entreprise.campus || "" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useProgramStore } from "@/stores/program";
import { useAccountStore } from "@/stores/account";

export default {
  name: "EntrepriseDetail",
  props: {
    entreprise: Object,
  },
  setup() {
    const programStore = useProgramStore();
    const accountStore = useAccountStore();
    return {
      programStore,
      accountStore
    };
  },
    methods: {
        singularSectionName() {
            if(this.accountStore.account.section_naming == "campus") return "Campus";
            if(this.accountStore.account.section_naming == "school") return "École";
            if(this.accountStore.account.section_naming == "center") return "Centre de formation";
            return "campus";
        },
    },
};
</script>

<style scoped>
.avatar-small {
  max-height: 40px;
}
.infos {
  margin: 0 20px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 10px;
}
.infos ul {
  list-style: none;
}
.infos ul li {
  display: inline;
}
</style>
