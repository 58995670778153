<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h2 class="card-title">Taux de captation</h2>
    </div>
    <div class="card-body p-0" style="position: relative">
      <div class="my-2" style="min-height: 300px;">
      <div
        class="nodata"
        v-if="
          !dashboardStore.summary.captation_rate ||
          dashboardStore.summary.captation_rate === 'n/a'
        "
      >
        Pas de données disponibles
      </div>
      <apexchart
        v-else
        type="radialBar"
        height="300"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    </div>
    <div class="row border-top text-center mx-0">
      <div class="col-6 border-end py-1">
        <p class="card-text text-muted mb-0">Discussions</p>
        <h3 class="fw-bolder mb-0">{{ dashboardStore.summary.discussions }}</h3>
      </div>
      <div class="col-6 py-1">
        <p class="card-text text-muted mb-0">Contacts qualifiés</p>
        <h3 class="fw-bolder mb-0">{{ dashboardStore.summary.contacts }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { useDashboardStore } from "@/stores/dashboard";

export default {
  name: "captation",
  data() {
    return {
      series: [this.dashboardStore.summary.captation_rate],
      chartOptions: {
        colors: ["#7de1ab"],
        chart: {
          height: 300,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -150,
            endAngle: 150,
            hollow: {
              margin: 0,
              size: "68%",
              background: "#fff",
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
            },
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "2.9rem",
                fontWeight: "bold",
                offsetY: 0,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#60c378"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: [""],
      },
    };
  },
  setup() {
    const dashboardStore = useDashboardStore();
    return { dashboardStore };
  },
  watch: {
    "dashboardStore.summary.captation_rate": function (newRate, olRate) {
      this.series = [newRate];
    },
  },
};
</script>

<style scoped>
.vue-apexcharts {
  margin-bottom: 0px;
}
.nodata {
  height: 280px;
  padding: 10px 20px;
}
.card {
  /*min-height: 408px !important;*/
}
.card-text{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
