<template>
  <div>
    <form class="row" @submit.prevent="actionSave">
      <div class="col-12 mb-3">
        <h1>Activations</h1>
        👉 Personnalisez vos choix
      </div>

      <div class="col-12 mb-2">
        <label>Activer l'affichage de la chatbox</label>
        <div class="form-check form-check-primary form-switch">
          <input
                  type="checkbox"
                  v-model="visible"
                  class="form-check-input switch"
          />
        </div>
      </div>

      <div class="col-12 mb-2">
        <label>Rappel immédiat </label>
        <div class="form-check form-check-primary form-switch">
          <input
                  type="checkbox"
                  v-model="call_back"
                  class="form-check-input switch"
          />
        </div>

        <div class="row mt-4" v-if="call_back">
          <div class="col-6 mb-2">

          <div class="row" style="margin-bottom:5px">
            <Slider v-model="morning" :min="8" :max="12"  />
          </div>
            Le matin de {{ morning[0] }}h à {{ morning[1] }}h
          </div>

          <div class="col-6 mb-2">

          <div class="row" style="margin-bottom:5px">
            <Slider v-model="afternoon" :min="12" :max="20" />
          </div>
            L'après-midi de {{ afternoon[0] }}h à {{ afternoon[1] }}h
          </div>
        </div>
      </div>

      <div class="col-12 mb-2">
        <label>Connecter à votre communauté Magma</label>
        <div class="form-check form-check-primary form-switch">
          <input
                  type="checkbox"
                  v-model="enable_magma"
                  class="form-check-input switch"
          />
        </div>
      </div>

      <div class="col-12 mb-2">
        <label>Activer la chatbox sur les appareils mobiles</label>
        <div class="form-check form-check-primary form-switch">
          <input
            type="checkbox"
            v-model="enable_mobile"
            class="form-check-input switch"
          />
        </div>
      </div>

      <div class="col-12 mb-3">
        <label>Désactiver la chatbox sur ces pages</label>
        <textarea class="form-control" v-model="filter"></textarea>
      </div>
      <div class="col-12 mb-3" :class="licenceStore.chatbot_tracking || userStore.is_leadia_admin ? '' : 'grayscale'">
        <label>Activer la chatbox pour les visiteurs de ces régions </label>
        <multiselect :close-on-select="false"
          track-by="code"
          :options="options"
          v-model="active"
          label="name"
          placeholder="Choisir une région"
          multiple
          :taggable="false"
          :show-labels="false"
        >
        </multiselect>
      </div>
      <div class="col-12 mb-3">
        <button type="submit" class="btn btn-primary">Enregistrer</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";
import Multiselect from "vue-multiselect";
import DashboardLayout from "@/layouts/dashboard";
import useVuelidate from "@vuelidate/core";
import Slider from '@vueform/slider';

export default {
  name: "activation",
  components: {
    Multiselect,
    Slider
  },
  data() {
    return {
      options: [
        { name: "Afrique", code: "Afrique" },
        { name: "Amérique du Nord", code: "Amérique du Nord" },
        { name: "Amérique du Sud", code: "Amérique du Sud" },
        { name: "Europe", code: "Europe" },
        { name: "France", code: "France" },
        { name: "Asie", code: "Asie" },
        { name: "Océanie", code: "Océanie" },
      ],
      active: [],
      enable_mobile: this.accountStore.account.enable_mobile,
      enable_magma: this.accountStore.account.enable_magma,
      visible: !this.accountStore.account.hidden,
      call_back: this.accountStore.account.call_back,
      callback_am_start_time: this.accountStore.account.callback_am_start_time,
      callback_am_end_time: this.accountStore.account.callback_am_end_time,
      callback_pm_start_time: this.accountStore.account.callback_pm_start_time,
      callback_pm_end_time: this.accountStore.account.callback_pm_end_time,
      filter: this.accountStore.account.filter,
      continents: JSON.parse(this.accountStore.account.continents),
      morning_min: 8,
      morning_max: 12,
      morning: [this.accountStore.account.callback_am_start_time,this.accountStore.account.callback_am_end_time],
      afternoon_min: 12,
      afternoon_max: 20,
      afternoon: [this.accountStore.account.callback_pm_start_time, this.accountStore.account.callback_pm_end_time],
    };
  },
  setup() {
    const accountStore = useAccountStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return {
      v$: useVuelidate(),
      accountStore,
      licenceStore,
      userStore,
    };
  },
    watch: {
        continents: {
            immediate: true,
            handler(values) {
                for (var i = 0; i < this.continents.length; i++) { this.active.push( { name: this.continents[i], code: this.continents[i] }) }
            },
        },
    },
  methods: {
    async actionSave(e) {
      e.preventDefault();
      await this.v$.$validate();
        if (this.v$.$error) {
            this.$toast.open({
                message: "Un ou plusieurs champs requis ne sont pas renseignés.",
                type: "error",
                position: "top-right",
                duration: 4000,
                dismissible: true,
            });
            return;
        }

      var continents = [];
      for (var i = 0; i < this.active.length; i++) { continents.push(this.active[i].name) }

      const data = {
        continents: JSON.stringify(continents),
        enable_mobile: this.enable_mobile,
        enable_magma: this.enable_magma,
        hidden: !this.visible,
        call_back: this.call_back,
        callback_am_start_time: this.morning[0],
        callback_am_end_time: this.morning[1],
        callback_pm_start_time: this.afternoon[0],
        callback_pm_end_time: this.afternoon[1],
        filter: this.filter,
        vm: this,
      };
      await this.accountStore.updateAccount(data);
    },
  },
};
</script>

<style scoped>
  .slider-tooltip, .slider-tooltip-top {
    display: none !important;
  }
</style>
