import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";
import { omitBy, isNil } from "lodash";

const webhooksUrl = (accountUuid: string) => {
  return `${process.env.VUE_APP_ROOT_API.replace("v1", "v2")}accounts/${accountUuid}/webhooks`
}

const webhookUrl = (accountUuid: string, webhookId: string) => {
  return `${webhooksUrl(accountUuid)}/${webhookId}`
}

export const useWebhookStore = defineStore("webhook", {
  state: () => ({
    webhooks: [],
    webhook: {},
  }),

  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },

  actions: {
    async getWebhooks(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(webhooksUrl(this.accountGetter.uuid),
          {
            headers: {
              "Content-Type": "application/json",
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.webhooks = response.data;
      }
      await loaderStore.setLoader(false);
      return this.webhooks;
    },
    async createWebhook(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);

      const {url, onCreate, onUpdate, onCompleted, headers} = data

      const dataToSend = omitBy({ url, on_create: onCreate, on_update: onUpdate, on_completed: onCompleted, headers }, isNil);

      const response = await axios
        .post(webhooksUrl(this.accountGetter.uuid), dataToSend, {
          headers: {
            "Content-Type": "application/json",
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });

      if (response) {
        this.webhook = response.data;
      }
      await loaderStore.setLoader(false);

      if (response) {
        data.vm.$toast.open({
          message: "Votre nouveau webhook a été ajouté avec succès.",
          type: "success",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
      }
    },
    async updateWebhook(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const {url, onCreate, onUpdate, onCompleted, headers, id} = data

      const dataToSend = omitBy({ url, on_create: onCreate, on_update: onUpdate, on_completed: onCompleted, headers }, isNil);

      const response = await axios
        .put(webhookUrl(this.accountGetter.uuid, id), dataToSend, {
          headers: {
            "Content-Type": "application/json",
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.webhook = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async deleteWebhook(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);

      const response = await axios
        .delete(webhookUrl(this.accountGetter.uuid, data.id), {
          headers: {
            "Content-Type": "application/json",
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.webhook = {};
      }
      await loaderStore.setLoader(false);
    },
  },
  persist: {
    enabled: true,
  },
});
