import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";

export const useSmsSettingStore = defineStore("smsSetting", {
  state: () => ({
      sms_settings: [],
      sms_setting: {},
      prediction: {},
  }),

  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },

  actions: {
    async sendSmsTest(data: any) {
          const loaderStore = useLoaderStore();
          await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "vonage/test.json?account_uuid=" +
              this.accountGetter.uuid;
          const dataToSend = {};

          if (data.name) dataToSend["name"] = data.name;
          if (data.phone) dataToSend["phone"] = data.phone;
          if (data.sender) dataToSend["sender"] = data.sender;
          if (data.content || data.content == "") dataToSend["content"] = data.content;
          if (data.link || data.link == "") dataToSend["link"] = data.link;

          console.log(dataToSend);
          const response = await axios
              .post(url, dataToSend, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });
          await loaderStore.setLoader(false);
          if (response) {
              return response.data;
          }
      },
    async getSmsSettings(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "sms_settings.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.sms_settings = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async getSmsSetting(data: any) {
      //const loaderStore = useLoaderStore();
      //await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "sms_settings/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .get(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.sms_setting = response.data;
      }
      //await loaderStore.setLoader(false);
    },
    async getSmsSettingPrediction(data: any) {

        const loaderStore = useLoaderStore();
        await loaderStore.setLoader(true);

        const dataToSend = {
            id: data.id,
            account_uuid: this.accountGetter.uuid,
        };

          const url =
              process.env.VUE_APP_ROOT_API + "sms_settings/prediction.json";

          const response = await axios
              .post(url, dataToSend, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });

          if (response) {
              this.prediction = response.data;
          }
          await loaderStore.setLoader(false);
      },
    async createSmsSetting(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "sms_settings.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {};
      if (data.id) dataToSend["id"] = data.id;
      if (data.name) dataToSend["name"] = data.name;
      if (data.status) dataToSend["status"] = data.status;
      if (data.enabled) dataToSend["enabled"] = data.enabled;
      if (data.sender) dataToSend["sender"] = data.sender;
      if (data.content) dataToSend["content"] = data.content;
      if (data.target) dataToSend["target"] = data.target;
      if (data.link || data.link == "") dataToSend["link"] = data.link;
      if (data.hour_start_at) dataToSend["hour_start_at"] = data.hour_start_at;
      if (data.hour_end_at) dataToSend["hour_end_at"] = data.hour_end_at;
      if (data.date_start_at) dataToSend["date_start_at"] = data.date_start_at;
      if (data.date_end_at) dataToSend["date_end_at"] = data.date_end_at;
      if (data.date_coverage_start_at) dataToSend["date_coverage_start_at"] = data.date_coverage_start_at;
      if (data.score_min) dataToSend["score_min"] = data.score_min;
      if (data.score_max) dataToSend["score_max"] = data.score_max;
      if (data.diploma_levels) dataToSend["diploma_levels"] = data.diploma_levels;
      if (data.sms_template_id) dataToSend["sms_template_id"] = data.sms_template_id;

      console.log(dataToSend);
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        this.sms_setting = response.data;
        return response.data;
      }
    },
    async updateSmsSetting(data: any) {
          const loaderStore = useLoaderStore();
          await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "sms_settings/" +
              data.id +
              ".json?account_uuid=" +
              this.accountGetter.uuid;
          const dataToSend = {};
            if (data.id) dataToSend["id"] = data.id;
            if (data.name) dataToSend["name"] = data.name;
            if (data.status) dataToSend["status"] = data.status;
            if (data.enabled) dataToSend["enabled"] = data.enabled;
            if (data.sender) dataToSend["sender"] = data.sender;
            if (data.content) dataToSend["content"] = data.content;
            if (data.target) dataToSend["target"] = data.target;
            if (data.sender) dataToSend["sender"] = data.sender;
            if (data.extra_visitors) dataToSend["extra_visitors"] = data.extra_visitors;
            if (data.link || data.link == "") dataToSend["link"] = data.link;
            if (data.hour_start_at) dataToSend["hour_start_at"] = data.hour_start_at;
            if (data.hour_end_at) dataToSend["hour_end_at"] = data.hour_end_at;
            if (data.date_coverage_start_at) dataToSend["date_coverage_start_at"] = data.date_coverage_start_at;
            if (data.date_start_at) dataToSend["date_start_at"] = data.date_start_at;
            if (data.date_end_at) dataToSend["date_end_at"] = data.date_end_at;
            if (data.score_min) dataToSend["score_min"] = data.score_min;
            if (data.score_max) dataToSend["score_max"] = data.score_max;
            if (data.diploma_levels) dataToSend["diploma_levels"] = data.diploma_levels;
            if (data.section_ids) dataToSend["section_ids"] = data.section_ids;
            if (data.course_ids) dataToSend["course_ids"] = data.course_ids;
            if (data.display_ids) dataToSend["display_ids"] = data.display_ids;

          console.log(dataToSend);
          const response = await axios
              .put(url, dataToSend, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });
          await loaderStore.setLoader(false);
          if (response) {
              this.sms_setting = response.data;
              return response.data;
          }
      },
    async deleteSmsSetting(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "sms_settings/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .delete(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.sms_setting = response;
      }
      await loaderStore.setLoader(false);
    },
    async joinSmsSettingSection(data: any) {
      //const loaderStore = useLoaderStore();
      //await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "sms_setting_sections.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {
        sms_setting_id: data.sms_setting_id,
        section_id: data.section_id,
      };
      console.log(dataToSend);
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
        });
      //await loaderStore.setLoader(false);
      if (response) {
        return response.data;
      }
    },
    async deleteSmsSettingSection(data: any) {
          //const loaderStore = useLoaderStore();
          //await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "sms_setting_sections/" +
              data.id +
              ".json?account_uuid=" +
              this.accountGetter.uuid;
          const response = await axios
              .delete(url, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });
          //await loaderStore.setLoader(false);
    },
    async joinSmsSettingDisplay(data: any) {
          //const loaderStore = useLoaderStore();
          //await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "sms_setting_displays.json?account_uuid=" +
              this.accountGetter.uuid;
          const dataToSend = {
              sms_setting_id: data.sms_setting_id,
              display_id: data.display_id,
          };
          console.log(dataToSend);
          const response = await axios
              .post(url, dataToSend, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
              });
          //await loaderStore.setLoader(false);
          if (response) {
              return response.data;
          }
      },
    async deleteSmsSettingDisplay(data: any) {
          //const loaderStore = useLoaderStore();
          //await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "sms_setting_displays/" +
              data.id +
              ".json?account_uuid=" +
              this.accountGetter.uuid;
          const response = await axios
              .delete(url, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });
          //await loaderStore.setLoader(false);
      },
    async joinSmsSettingCourse(data: any) {
          //const loaderStore = useLoaderStore();
          //await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "sms_setting_courses.json?account_uuid=" +
              this.accountGetter.uuid;
          const dataToSend = {
              sms_setting_id: data.sms_setting_id,
              course_id: data.course_id,
          };
          console.log(dataToSend);
          const response = await axios
              .post(url, dataToSend, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
              });
          //await loaderStore.setLoader(false);
          if (response) {
              return response.data;
          }
      },
    async deleteSmsSettingCourse(data: any) {
          //const loaderStore = useLoaderStore();
          //await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "sms_setting_courses/" +
              data.id +
              ".json?account_uuid=" +
              this.accountGetter.uuid;
          const response = await axios
              .delete(url, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });
          //await loaderStore.setLoader(false);
      },
  },
  persist: {
    enabled: true,
  },
});
