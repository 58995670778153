<template>

  <div class="modal fade" ref="showModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Détails de votre campagne</h5>
          <button
                  type="button"
                  class="btn-close"
                  @click="modalShow.hide()"
                  aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">

          <!--div class="col-8 mb-0" v-if="item.sms_template">
            <label class="form-label">Template:</label>
            <p>{{item.sms_template.name}}</p>
          </div-->

          <div class="col-12 mb-0">
            <label class="form-label">Nom de la campagne:</label>
            <p>{{item.name}}</p>
          </div>

          <div class="col-12 mb-0" v-if="item.link != ''">
            <label class="form-label">Lien URL (à inserer dans le SMS)</label>
            <p>{{item.link}}</p>
          </div>

          <div class="col-12 mb-0">
            <label class="form-label">Période de la campagne:</label>
            <p>Du {{ formatDateItem(item.date_start_at) }} au {{ formatDateItem(item.date_end_at) }}</p>
          </div>

          <div class="col-12 mb-0">
            <label class="form-label">Tranche horaire d'envoi:</label>
            <p>De {{ formatHourItem(item.hour_start_at) }} à {{ formatHourItem(item.hour_end_at) }}</p>
          </div>

          <!--div class="col-8 mb-0">
            <label class="form-label">Nom de l'envoyeur du SMS:</label>
            <p>{{item.sender}}</p>
          </div-->

          <div class="col-10 mb-0">
            <label class="form-label">Message:</label>
            <p v-html="getContent(item)"></p>
          </div>

          <div class="col-12 mb-0">
            <label class="form-label">Limitée aux leads avec un score entre:</label>
            <p>{{ item.score_min }} et {{ item.score_max }}</p>
          </div>

          <div class="col-12 mb-0" v-if="item.target && item.target != '[]' ">
            <label class="form-label">Limitée aux leads avec les statuts suivants:</label>
            <p>{{ getStatuts(item) }}</p>
          </div>

          <div class="col-12 mb-0" v-if="item.sms_setting_sections && item.sms_setting_sections.length > 0 ">
            <label class="form-label">Limitée aux leads s'interessant aux campus suivants:</label>
            <p>{{ getSections(item) }}</p>
          </div>

          <div class="col-12 mb-0" v-if="item.sms_setting_displays && item.sms_setting_displays.length > 0 ">
            <label class="form-label">Limitée aux leads provenant de:</label>
            <p>{{ getDisplays(item) }}</p>
          </div>

          <div class="col-12 mb-0" v-if="item.diploma_levels && item.diploma_levels != '[]' ">
            <label class="form-label">Limitée aux leads avec les niveaux:</label>
            <p>{{ getLevels(item) }}</p>
          </div>

          <div class="col-12 mb-0" v-if="item.sms_setting_courses && item.sms_setting_courses.length > 0 ">
            <label class="form-label">Limitée aux leads ayant visités:</label>
            <p>{{ getCourses(item) }}</p>
          </div>

        </div>
        <div class="modal-footer">
          <button
                  type="button"
                  class="btn btn-primary"
                  @click="modalShow.hide()"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" ref="stopModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Stopper</h5>
          <button
                  type="button"
                  class="btn-close"
                  @click="modalStop.hide()"
                  aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Etes-vous sûr de vouloir stopper cette campagne ?
        </div>
        <div class="modal-footer">
          <button
                  type="button"
                  class="btn btn-secondary"
                  @click="modalStop.hide()"
          >
            Annuler
          </button>
          <button
                  type="button"
                  class="btn btn-primary"
                  @click="actionStop()"
          >
            Stopper
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" ref="deleteModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Supprimer</h5>
          <button
                  type="button"
                  class="btn-close"
                  @click="modalDelete.hide()"
                  aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Etes-vous sûr de vouloir supprimer cette campagne ?
        </div>
        <div class="modal-footer">
          <button
                  type="button"
                  class="btn btn-secondary"
                  @click="modalDelete.hide()"
          >
            Annuler
          </button>
          <button
                  type="button"
                  class="btn btn-primary"
                  @click="actionDelete()"
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
          class="modal fade"
          ref="duplicateModal"
          tabindex="-1"
          aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Dupliquer</h5>
          <button
                  type="button"
                  class="btn-close"
                  @click="modalDuplicate.hide()"
                  aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Etes-vous sûr de vouloir dupliquer cet élément ?
        </div>
        <div class="modal-footer">
          <button
                  type="button"
                  class="btn btn-secondary"
                  @click="modalDuplicate.hide()"
          >
            Annuler
          </button>
          <button
                  type="button"
                  class="btn btn-primary"
                  @click="actionDuplicate()"
          >
            Dupliquer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="col-12 mb-2 mt-2">
      <h1>Vos campagnes</h1>
      👉 Managez vos campagnes
    </div>

    <div v-if="items">
      <div v-if="items.length > 0">
        <section id="datatable">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div
                        id="DataTables_Table_0_wrapper"
                        class="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div
                          class="d-flex justify-content-between align-items-center mx-0 row"
                  >
                    <div class="col-sm-12 col-md-4">
                      <div class="dataTables_length">
                        <label
                        >Afficher<select
                                class="form-select"
                                v-model="perPage"
                                style="display: inline"
                        >
                          <option
                                  v-for="(show, index) in showList"
                                  :value="show"
                                  :key="index"
                          >
                            {{ show }}
                          </option>
                        </select>
                          résultats par page</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table
                            class="datatables-basic table dataTable no-footer dtr-column"
                            role="grid"
                    >
                      <thead>
                      <tr role="row">
                        <th
                                v-for="(header, index) in headers"
                                :key="index"
                                :class="{
                            sorting: header.sort,
                            sorting_asc: header.sort === 'asc',
                            sorting_desc: header.sort === 'desc',
                          }"
                                @click="sortItems(index, header.sortBy)"
                        >
                          <span>{{ header.text }}</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                              role="row"
                              v-for="(item, index) in sortedItems"
                              :key="index"
                              class="cursor-pointer"
                      >
                        <th style="white-space: nowrap;" >
                          {{ formatDateItem(item.created_at) }}
                        </th>
                        <th>
                          <div v-if="item.name.length <= 15">
                            {{ item.name }}
                          </div>
                          <Popper :content="item.name" hover v-if="item.name.length > 15">
                            {{ showName(item) }}
                          </Popper>
                        </th>
                        <th>
                          <span v-if="item.sms_template">{{ item.sms_template.name }}</span>
                          <span v-else>-</span>
                        </th>
                        <th>
                          {{ item.click_rate }}
                        </th>
                        <th>
                          <span :class="displayLabelStatus(item)">{{ displayStatus(item) }}</span>
                        </th>

                        <th>
                          <div class="" style="width: 140px">

                            <div class="d-flex ">

                              <div class="avatar bg-light-primary"
                                   style="margin-left: 5px">
                                <div
                                        class="avatar-content"
                                        @click="openModalShow(item)">
                                  <vue-feather type="eye"></vue-feather>
                                </div>
                              </div>

                              <div v-if="isItemEditable(item)" class="avatar bg-light-primary" style="margin-left: 5px">
                                <div class="avatar-content">
                                  <vue-feather
                                          type="edit"
                                          @click="openItem(item)"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#formEditUser"
                                  ></vue-feather>
                                </div>
                              </div>

                              <div v-if="isItemDuplicatable(item)" class="avatar bg-light-info" style="margin-left: 5px">
                                <div class="avatar-content">
                                  <vue-feather
                                          type="copy"
                                          @click="openModalDuplicate(item)"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#formEditUser"
                                  ></vue-feather>
                                </div>
                              </div>

                              <div
                                      class="avatar bg-light-warning"
                                      style="margin-left: 5px"
                                      v-if="displayStatus(item) == 'En cours' ">
                                <div
                                        class="avatar-content"
                                        @click="openModalStop(item.id)">
                                  <vue-feather type="pause"></vue-feather>
                                </div>
                              </div>

                              <div
                                      class="avatar bg-light-danger"
                                      style="margin-left: 5px"
                                      v-if="displayStatus(item) != 'En cours' ">
                                <div
                                        class="avatar-content"
                                        @click="openModalDelete(item.id)">
                                  <vue-feather type="trash"></vue-feather>
                                </div>
                              </div>


                            </div>
                          </div>
                        </th>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-between mx-0 row">
                    <div class="col-sm-12 col-md-6">
                      <div
                              class="dataTables_info"
                              id="DataTables_Table_0_info"
                              role="status"
                              aria-live="polite"
                      >
                        Affichage de {{ offset + 1 }} à {{ end }} entrées sur
                        {{ totalItems }}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div
                              class="dataTables_paginate paging_simple_numbers"
                              id="DataTables_Table_0_paginate"
                      >
                        <ul class="pagination">
                          <li
                                  v-if="offset > 0"
                                  @click="previousPage()"
                                  class="paginate_button page-item previous disabled"
                                  id="DataTables_Table_0_previous"
                          >
                            <a
                                    aria-controls="DataTables_Table_0"
                                    data-dt-idx="0"
                                    tabindex="0"
                                    class="page-link"
                            >&nbsp;</a
                            >
                          </li>
                          <li
                                  role="presentation"
                                  class="page-item"
                                  :class="{ active: index == offset / perPage }"
                                  v-for="(page, index) in Math.ceil(nbPages)"
                          >
                            <button
                                    v-if="index <= 5"
                                    role="menuitemradio"
                                    type="button"
                                    class="page-link"
                                    @click="setPage(index)"
                            >
                              {{ index + 1 }}
                            </button>
                          </li>
                          <li
                                  v-if="offset + perPage < totalItems"
                                  @click="nextPage()"
                                  class="paginate_button page-item next disabled"
                                  id="DataTables_Table_0_next"
                          >
                            <a
                                    aria-controls="DataTables_Table_0"
                                    data-dt-idx="1"
                                    tabindex="0"
                                    class="page-link"
                            >&nbsp;</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-else>
        <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary">
          <div class="alert-body">
            <p><span>Vous n'avez pas encore de campagne SMS. <br>Cliquez sur <span style="padding:5px" class="btn btn-primary btn-sm" @click="$router.push('/campagne/sms/templates')">Templates</span> pour créer votre première
                  campagne.</span></p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
    import { Modal } from "bootstrap";
    import DashboardLayout from "@/layouts/dashboard";
    import { useSmsSettingStore } from "@/stores/smsSetting";
    import { useProgramStore } from "@/stores/program";
    import { useDisplayStore } from "@/stores/display";
    import { toRaw } from "vue";
    import moment from "moment";
    import _ from "lodash";

    export default {
        name: "historique",
        components: {
            DashboardLayout,
        },
        watch: {
            "smsSettingStore.sms_settings": function (oldVal, newVal) {
                this.filterItems();
            },
            "perPage": function (oldVal, newVal) {
                this.filterItems();
            },
        },
        data() {
            return {
                id: "",
                item: {},
                modalDuplicate: null,
                modalDelete: null,
                modalStop: null,
                modalShow: null,
                displays: {},
                courses: {},
                sections: {},
                items: this.smsSettingStore.sms_settings,
                headers: [
                    { text: "Date", value: "created_at", sort: "asc", sortBy: "created_at"  },
                    { text: "Campagne", value: "name", sort: "asc", sortBy: "name" },
                    { text: "Template", value: "sms_template.name", sortBy: "sms_template.name"},
                    { text: "Taux Click %", value: "click_rate", sort: "asc", sortBy: "click_rate"  },
                    { text: "Statut", value: "status", sortBy: "status"  },
                    { text: "Actions", value: "actions" },
                ],
            };
        },
        async mounted() {
            await this.smsSettingStore.getSmsSettings({ vm: this });
            await this.displayStore.getDisplays({vm: this});
            await this.programStore.getCourses({vm: this});


            this.items = this.smsSettingStore.sms_settings;
            for (var y = 0; y < this.programStore.sections.length; y++) {
                this.sections[this.programStore.sections[y].id] = this.programStore.sections[y].name;
            }
            for (var y = 0; y < this.programStore.courses.length; y++) {
                this.courses[this.programStore.courses[y].id] = this.programStore.courses[y].name;
            }
            for (var y = 0; y < this.displayStore.displays.length; y++) {
                this.displays[this.displayStore.displays[y].id] = this.displayStore.displays[y].name;
            }

            this.modalDelete = new Modal(this.$refs.deleteModal);
            this.modalStop = new Modal(this.$refs.stopModal);
            this.modalShow = new Modal(this.$refs.showModal);
            this.modalDuplicate = new Modal(this.$refs.duplicateModal);
        },
        setup() {
            const smsSettingStore = useSmsSettingStore();
            const programStore = useProgramStore();
            const displayStore = useDisplayStore();
            return {
                smsSettingStore,
                displayStore,
                programStore
            };
        },
        methods: {
            getContent(item) {
                var content = "";
                if (item && item.content){
                    content = item.content;
                    content = content.replaceAll("{{prénom}}", '<span class="badge-hand badge badge-light-success">Prénom</span>');
                    content = content.replaceAll("{{lien}}", '<span class="badge-hand badge badge-light-success">Lien URL</span>');
                    content = content.replaceAll("{{stop}}", '<span class="badge-hand badge badge-light-primary">STOP SMS</span>');
                }
                return content;
            },
            getDisplays(item) {
                var d = []
                for (var y = 0; y < item.sms_setting_displays.length ; y++) {
                    d.push(this.displays[item.sms_setting_displays[y].display_id])
                }
                return d.join(", ");
            },
            getCourses(item) {
                var d = []
                for (var y = 0; y < item.sms_setting_courses.length ; y++) {
                    d.push(this.courses[item.sms_setting_courses[y].course_id])
                }
                return d.join(", ");
            },
            getSections(item) {
                var d = []
                for (var y = 0; y < item.sms_setting_sections.length ; y++) {
                    d.push(this.sections[item.sms_setting_sections[y].section_id])
                }
                return d.join(", ");
            },
            getLevels(item) {
                return JSON.parse(item.diploma_levels).join(", ");
            },
            getStatuts(item) {
                return JSON.parse(item.target).join(", ");
            },
            showName(item) {
                const result = item.name;
                if (result.length > 15) {
                    return result.substring(0, 15) + "...";
                }
                return result;
            },
            formatDateItem(item) {
                return moment(item).format("DD/MM/YYYY");
            },
            formatHourItem(item) {
                return moment(item).format("HH:00");
            },
            filterItems() {
                let filteredItems = this.smsSettingStore.sms_settings;
                this.setPage(0);
                this.items = filteredItems;
            },
            isItemDuplicatable(item) {
                return (item.sms_template_id ? true : false)
            },
            isItemEditable(item) {
                if (item.status == 'draft') {
                    return true;
                }

                const currentDate = moment();
                const startDate = moment(item.date_start_at);
                const endDate = moment(item.date_end_at);
                if (startDate.isAfter(currentDate)) {
                    return true;
                } else {
                    return false;
                }
            },
            displayLabelStatus(item) {
                if (item.status == 'draft') {
                    return "badge badge-light-info";
                }
                if (item.status == 'inactive') {
                    return "badge badge-light-warning";
                }

                const currentDate = moment();
                const startDate = moment(item.date_start_at);
                const endDate = moment(item.date_end_at);
                if (startDate.isAfter(currentDate)) {
                    return "badge badge-light-warning";
                } else if (startDate.isSameOrBefore(currentDate) && endDate.isSameOrAfter(currentDate) ) {
                    return "badge badge-light-success";
                } else {
                    return "badge badge-light-danger";
                }
            },
            displayStatus(item) {
                if (item.status == 'draft') {
                    return "Brouillon";
                }
                if (item.status == 'inactive') {
                    return "Stopped";
                }

                const currentDate = moment();
                const startDate = moment(item.date_start_at);
                const endDate = moment(item.date_end_at);
                if (startDate.isAfter(currentDate)) {
                    return "Débute le " + moment(item.date_start_at).format("DD-MM-YYYY");
                } else if (startDate.isSameOrBefore(currentDate) && endDate.isSameOrAfter(currentDate) ) {
                    return "En cours";
                } else {
                    return "Terminée";
                }
            },
            async openItem(item) {
                if(item.status == 'draft'){
                    await this.$router.push("/campagne/sms/template/"+item.sms_template_id+"/sample/0/settings/"+item.id);
                }
            },
            openModalDelete(id) {
                this.id = id;
                this.modalDelete.show();
            },
            openModalStop(id) {
                this.id = id;
                this.modalStop.show();
            },
            async openModalShow(item) {
                this.id = item.id;
                await this.smsSettingStore.getSmsSetting({ id: this.id, vm: this });
                this.item = this.smsSettingStore.sms_setting;
                this.modalShow.show();
            },
            openModalDuplicate(item) {
                this.id = item.id;
                this.modalDuplicate.show();
            },
            async actionShow() {
                this.modalShow.hide();
            },
            async actionStop() {
                const d = {
                    id: this.id,
                    status: 'inactive',
                    vm: this,
                };
                await this.smsSettingStore.updateSmsSetting(d);
                await this.smsSettingStore.getSmsSettings({ vm: this });
                this.items = this.smsSettingStore.sms_settings;
                this.modalStop.hide();

                this.$toast.open({
                    message: "Campagne stoppée avec succès.",
                    type: "success",
                    position: "top-right",
                    duration: 4000,
                    dismissible: true,
                });
            },
            async actionDelete() {
                await this.smsSettingStore.deleteSmsSetting({ id: this.id, vm: this });
                await this.smsSettingStore.getSmsSettings({ vm: this });
                this.items = this.smsSettingStore.sms_settings;
                this.modalDelete.hide();

                this.$toast.open({
                    message: "Campagne détruite avec succès.",
                    type: "success",
                    position: "top-right",
                    duration: 4000,
                    dismissible: true,
                });
            },
            async actionDuplicate() {
                await this.smsSettingStore.getSmsSetting({ id: this.id, vm: this });
                var orig = this.smsSettingStore.sms_setting;

                const data = {
                    status: 'draft',
                    name: "Copie de " + this.smsSettingStore.sms_setting.name,
                    score_min: this.smsSettingStore.sms_setting.score_min,
                    score_max: this.smsSettingStore.sms_setting.score_max,
                    content: this.smsSettingStore.sms_setting.content,
                    hour_start_at: this.smsSettingStore.sms_setting.hour_start_at,
                    hour_end_at: this.smsSettingStore.sms_setting.hour_end_at,
                    date_coverage_start_at: moment(this.smsSettingStore.sms_setting.date_start_at).format("YYYY-MM-DD"),
                    date_start_at: moment(this.smsSettingStore.sms_setting.date_start_at).format("YYYY-MM-DD"),
                    date_end_at: moment(this.smsSettingStore.sms_setting.date_end_at).format("YYYY-MM-DD"),
                    target: this.smsSettingStore.sms_setting.target,
                    diploma_levels: this.smsSettingStore.sms_setting.diploma_levels,
                    sender: this.smsSettingStore.sms_setting.sender,
                    link: this.smsSettingStore.sms_setting.link,
                    sms_template_id: this.smsSettingStore.sms_setting.sms_template_id,
                    vm: this,
                };

                var resu = await this.smsSettingStore.createSmsSetting(data);
                const d = {
                    id: resu.id,
                    section_ids: orig.sms_setting_sections.map((item) => {
                        return item.section_id;
                    }),
                    display_ids: orig.sms_setting_displays.map((item) => {
                        return item.display_id;
                    }),
                    course_ids: orig.sms_setting_courses.map((item) => {
                        return item.course_id;
                    }),
                    vm: this,
                };
                await this.smsSettingStore.updateSmsSetting(d);
                await this.smsSettingStore.getSmsSettings({ vm: this });
                this.items = this.smsSettingStore.sms_settings;
                this.modalDuplicate.hide();

                this.$toast.open({
                    message: "Campagne dupliquée avec succès.",
                    type: "success",
                    position: "top-right",
                    duration: 4000,
                    dismissible: true,
                });
            },
        },
    };
</script>

<style scoped>
  table.dataTable thead td {
    white-space: nowrap;
  }
  .row_ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width:140px;
  }
</style>
