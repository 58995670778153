<template>
  <BasicLayout>
    <a class="brand-logo" href="/">
      <img src="@/assets/images/logo.png" width="180" />
    </a>
    <div class="w-100 text-center">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <a
        class="btn btn-primary mb-2 btn-sm-block waves-effect waves-float waves-light"
        href="/"
        >Back to home</a
      >
    </div>
  </BasicLayout>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped></style>
