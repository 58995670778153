import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";

export const useSmsDebitStore = defineStore("smsDebit", {
  state: () => ({
  }),

  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },

  actions: {
    async createSmsDebit(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "sms_debits.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {};
      if (data.amount) dataToSend["amount"] = data.amount;
      if (data.sms_setting_id) dataToSend["sms_setting_id"] = data.sms_setting_id;

      console.log(dataToSend);
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        return response.data;
      }
    }
  },
  persist: {
    enabled: true,
  },
});
