<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-start">
        <div>
          <span class="badge bg-light-primary">Standard</span><br />
          10 juin 2021
        </div>
        <div class="d-flex justify-content-center">
          <sup class="h5 pricing-currency text-primary mt-1 mb-0">$</sup>
          <span class="fw-bolder display-5 mb-0 text-primary">99</span>
          <sub class="pricing-duration font-small-4 ms-25 mt-auto mb-2"
            >/month</sub
          >
        </div>
      </div>
      <ul class="mb-2">
        <li class="mb-50">10 Users</li>
        <li class="mb-50">Up to 10 GB storage</li>
        <li>Basic Support</li>
      </ul>
      <div class="d-grid w-100 mt-2">
        <button
          class="btn btn-primary waves-effect waves-float waves-light"
          data-bs-target="#upgradePlanModal"
          data-bs-toggle="modal"
        >
          Upgrade Plan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "plan",
};
</script>

<style scoped></style>
