import { defineStore } from "pinia";
import axios, { AxiosResponse } from "axios";
import { useAccountStore } from "./account";
import { useDashboardStore } from "./dashboard";
import { useCampagneStore } from "./campagne";
import { useProductStore } from "./product";
import { useContactStore } from "./contact";
import { useEntrepriseStore } from "./entreprise";
import { useProgramStore } from "./program";
import { useErrorStore } from "./error";
import { useLayoutStore } from "./layout";
import { useLoaderStore } from "./loader";
import { email } from "@vuelidate/validators";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    uid: "",
    token: "",
    client: "",
    firstName: "",
    lastName: "",
    role: "",
  }),

  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },

  actions: {
    async reset(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);

      const response = await axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "users/resend?email=" +
            data.email.replaceAll("\\+", "%2b").replaceAll("+", "%2b")
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "L’identifiant ou le mot de passe est incorrect",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        data.vm.$toast.open({
          message:
            "Un email avec des accès temporaires vient de vous être envoyé.",
          type: "success",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
        await data.vm.$router.push("/");
      }
    },

    async login(data: any) {
      const loaderStore = useLoaderStore();

      const loginPayload = {
        email: data.email,
        password: data.password,
      };

      let response: AxiosResponse<any, any>;
      try {
        loaderStore.setLoader(true);
        response = await axios.post("auth/sign_in", loginPayload, {
          baseURL: process.env.VUE_APP_ROOT_API,
        });
      } catch (error) {
        data.vm.$toast.open({
          message: "L’identifiant ou le mot de passe est incorrect",
          type: "error",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
        throw error; // RETHROW
      } finally {
        loaderStore.setLoader(false); // always remove loader even in case of failure
      }

      this.token = response.headers["access-token"];
      this.client = response.headers["client"];
      this.uid = response.data.data.uid;
      this.firstName = response.data.data.firstname;
      this.lastName = response.data.data.lastname;
      this.role = response.data.data.role;
      await data.vm.$router.push("/");
    },

    async getMe(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "users/me?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.uid,
              client: this.client,
              "access-token": this.token,
            },
          }
        )
        .catch(function (error) {
          if (401 === error.response.status) {
            window.location.href = "/signin";
          } else {
            data.vm.$toast.open({
              message: "Une erreur est survenue lors de la requete",
              type: "error",
              position: "top-right",
              duration: 4000,
              dismissible: true,
            });
          }
        });
      await loaderStore.setLoader(false);
      if (response) {
        this.role = response.data.data.role;
      }
    },

    async logout(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);

      const response = await axios
        .delete(process.env.VUE_APP_ROOT_API + "auth/sign_out", {
          headers: {
            "token-type": "Bearer",
            uid: this.uid,
            client: this.client,
            "access-token": this.token,
          },
        })
        .catch(function (error) {
          console.log(error);
        });

      this.$reset();
      const accountStore = useAccountStore();
      const dashboardStore = useDashboardStore();
      const campagneStore = useCampagneStore();
      const productStore = useProductStore();
      const contactStore = useContactStore();
      const entrepriseStore = useEntrepriseStore();
      const programStore = useProgramStore();
      const errorStore = useErrorStore();
      const layoutStore = useLayoutStore();
      await accountStore.$reset();
      await dashboardStore.$reset();
      await campagneStore.$reset();
      await productStore.$reset();
      await contactStore.$reset();
      await entrepriseStore.$reset();
      await programStore.$reset();
      await errorStore.$reset();
      await layoutStore.$reset();
      await loaderStore.setLoader(false);
    },
  },
  persist: {
    enabled: true,
  },
});
