<template>
  <div class="card mb-2">
    <div class="row">
      <div class="col-12 p-6">
        <div class="card-body pb-0" style="height: 300px">
          <div v-if="!this.dashboardStore.sms_status['sent'] || this.dashboardStore.sms_status['sent'].length === 0">
            Pas de données disponibles
          </div>
          <PieChart
                  v-else
                  :chartData="chartData"
                  :options="options"
                  height="282px"
                  style="height:282px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Card from "@/components/dashboard/card";
    import { computed, defineComponent, ref } from "vue";
    import { PieChart } from "vue-chart-3";
    import { Chart, registerables } from "chart.js";
    import { useDashboardStore } from "@/stores/dashboard";

    Chart.register(...registerables);
    export default defineComponent({
        name: "App",
        components: { PieChart, Card },
        data() {
            return {
                chartData: {},
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'Status des SMS envoyés',
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    let label = context.label;
                                    let value = context.formattedValue;

                                    if (!label)
                                        label = 'Unknown'

                                    let sum = 0;
                                    let dataArr = context.chart.data.datasets[0].data;
                                    dataArr.map(data => {
                                        sum += Number(data);
                                    });

                                    let percentage = (value * 100 / sum).toFixed(1) + '%';
                                    return label + ": " + value + " SMS  (" + percentage+ ")";
                                }
                            }
                        }
                    },
                }
            };
        },
        setup() {
            const dashboardStore = useDashboardStore();
            return { dashboardStore };
        },
        watch: {
            "dashboardStore.sms_status": function (newCore, oldCore) {
                this.getData();
            },
        },
        mounted() {
            this.getData();
        },
        computed: {
        },
        methods: {
            getData() {

                var s_wait = 0;
                var s_failed = 0;
                var c_click = 0;

                if(this.dashboardStore.sms_status && this.dashboardStore.sms_status["sent"]) {
                  var sentArray = this.dashboardStore.sms_status["sent"];
                  var arrayLength = sentArray.length;
                  for (var i = 0; i < arrayLength; i++) {
                      if (sentArray[i][0] == "accepted") s_wait += sentArray[i][1];
                      if (sentArray[i][0] == "delivered") s_wait += sentArray[i][1];
                      if (sentArray[i][0] == "buffered") s_wait += sentArray[i][1];
                      if (sentArray[i][0] == "expired") s_failed += sentArray[i][1];
                      if (sentArray[i][0] == "failed") s_failed += sentArray[i][1];
                      if (sentArray[i][0] == "rejected") s_failed += sentArray[i][1];
                      if (sentArray[i][0] == "unknown") s_failed += sentArray[i][1];
                  }
                }

                if(this.dashboardStore.sms_status && this.dashboardStore.sms_status["clicked"]) {
                  var clickArray = this.dashboardStore.sms_status["clicked"];
                  var arrayLength = clickArray.length;
                  for (var i = 0; i < arrayLength; i++) {
                      if (clickArray[i][0] == "accepted") c_click += clickArray[i][1];
                      if (clickArray[i][0] == "delivered") c_click += clickArray[i][1];
                      if (clickArray[i][0] == "buffered") c_click += clickArray[i][1];
                      if (clickArray[i][0] == "expired") c_click += clickArray[i][1];
                      if (clickArray[i][0] == "failed") c_click += clickArray[i][1];
                      if (clickArray[i][0] == "rejected") c_click += clickArray[i][1];
                      if (clickArray[i][0] == "unknown") c_click += clickArray[i][1];
                  }
                }


                this.chartData = {
                    labels: ["Ouverts", "En attente", "Echoués"],
                    datasets: [{
                        label: 'My First Dataset',
                        type: 'doughnut',
                        data: [c_click, s_wait - c_click, s_failed],
                        backgroundColor: [
                            '#9188f1',
                            '#bbb6f1',
                            'rgb(255, 205, 86)'
                        ],
                        hoverOffset: 4
                    }]
                };
            },
        },
    });
</script>

<style scoped>
  .row {

  }
  .card {
    margin-bottom: 0;
  }
</style>
