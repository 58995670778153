<template>
  <div class="loader" v-if="loaderStore.isActive">
    <div class="spinner-border text-primary">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import { useLoaderStore } from "@/stores/loader";

export default {
  name: "loader",
  setup() {
    const loaderStore = useLoaderStore();
    return {
      loaderStore,
    };
  },
};
</script>

<style scoped>
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0, 0.1);
}
.spinner-border {
  width: 4rem;
  height: 4rem;
}
</style>
