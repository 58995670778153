<template>
  <div class="card chat-widget">
    <div class="card-header">
      <div class="d-flex align-items-center">
        <h5 class="mb-0">Questions posées</h5>
      </div>
    </div>
    <section class="chat-app-window">
      <div class="user-chats ps ps--active-y">
        <div
          class="chats"
          v-for="(question, index) in contact.questions"
          :key="index"
        >
          <div class="chat chat-left">
            <div class="chat-avatar" v-if="notEmptyObject(contact)">
              <span class="avatar bg-light-primary cursor-pointer">
                {{ contact.meta.firstname.charAt(0) || "" }}
                {{ contact.meta.lastname.charAt(0) || "" }}
              </span>
            </div>
            <div class="chat-body">
              <div class="chat-content">
                <p>{{ question.question }}</p>
                <p class="right">{{ formatDate(question.created_at) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "chat",
  props: {
    contact: Object,
  },
};
</script>

<style scoped>
.avatar {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
