import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";
import moment from "moment";

export const useCampagneStore = defineStore("campagne", {
  state: () => ({
    bannieres: [],
    banniere: {},
  }),

  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },

  actions: {
    async getBannieres(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "bans.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.bannieres = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async getBanniere(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "bans/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .get(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.banniere = response.data.data;
      }
      await loaderStore.setLoader(false);
    },
    async createBanniere(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "bans.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {
        "account_uuid": this.accountGetter.uuid
      };
      if (data.title) dataToSend["title"] = data.title;
      if (data.text) dataToSend["text"] = data.text;
      if (data.link || data.link == "") dataToSend["link"] = data.link;
      if (data.position) dataToSend["position"] = data.position;
      if (data.target) dataToSend["target"] = data.target;
      if (data.targetCampus) dataToSend["target_campus"] = data.targetCampus;
      if (data.date_start) dataToSend["date_start"] = data.date_start;
      if (data.date_end) dataToSend["date_end"] = data.date_end;
      if (data.image) dataToSend["image"] = data.image;

      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });

      if (response) {
        this.banniere = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async updateBanniere(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const dataToSend = {};
      dataToSend["enabled"] = data.enabled;
      if (data.title) dataToSend["title"] = data.title;
      if (data.text) dataToSend["text"] = data.text;
      if (data.link || data.link == "") dataToSend["link"] = data.link;
      if (data.position) dataToSend["position"] = data.position;
      if (data.target) dataToSend["target"] = data.target;
      if (data.targetCampus) dataToSend["target_campus"] = data.targetCampus;
      if (data.date_start) dataToSend["date_start"] = data.date_start;
      if (data.date_end) dataToSend["date_end"] = data.date_end;
      if (data.image) dataToSend["image"] = data.image;

      const url =
        process.env.VUE_APP_ROOT_API +
        "bans/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      console.log(dataToSend);
      const response = await axios
        .put(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.banniere = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async duplicateBanniere(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "bans/" +
        data.id +
        "/duplicate.json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .get(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.banniere = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async deleteBanniere(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "bans/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .delete(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.banniere = response.data;
      }
      await loaderStore.setLoader(false);
    },
  },
  persist: {
    enabled: true,
  },
});
