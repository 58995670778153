<template>
  <div class="card">
    <div class="card-header align-items-start pb-0">
      <div>
        <span class="fw-bolder">{{ percent }}</span>
        <p class="card-text">{{ name }}</p>
      </div>
      <div class="avatar p-50" :class="variantClass">
        <div class="avatar-content">
          <vue-feather :type="icon"></vue-feather>
        </div>
      </div>
    </div>
    <LineChart
      :chartData="chartData"
      :options="options"
      width="150"
      height="100"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { useDashboardStore } from "@/stores/dashboard";
Chart.register(...registerables);

export default {
  name: "trafic",
  components: { LineChart },
  props: {
    name: String,
    type: String,
    icon: String,
    variant: String,
    color: String,
  },
  data() {
    return {
      total: 0,
      part: 0,
      chartData: {},
      options: {
        responsive: true,
        plugins: {
          title: {
            display: false,
            text: "",
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
    };
  },
  setup() {
    const dashboardStore = useDashboardStore();
    return { dashboardStore };
  },
  watch: {
    "dashboardStore.core": function (newCore, oldCore) {
      this.getData();
    },
  },
  computed: {
    variantClass() {
      return "bg-light-" + this.variant;
    },
    percent() {
      if (this.part === 0 || this.total === 0) return 0;
      return Math.round((this.part / this.total) * 100) + "%";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const label = this.dashboardStore.core.map((core) => {
        this.total += core.visitor_count;
        this.part += _.get(core, this.type);
        return "";
      });
      const data = this.dashboardStore.core.map((core) => {
        return _.get(core, this.type);
      });
      this.chartData = {
        labels: label,
        datasets: [
          {
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  display: false,
                },
              },
            },
            data: data,
            backgroundColor: [this.color],
            borderColor: [this.color],
            fill: false,
            cubicInterpolationMode: "monotone",
            pointStyle: "circle",
            pointRadius: 0,
            tension: 0.5,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.fw-bolder {
  font-size: 1.5rem;
}
</style>
