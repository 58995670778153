<template>
  <div>
    <div
      class="modal fade"
      ref="duplicateModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dupliquer</h5>
            <button
              type="button"
              class="btn-close"
              @click="modalDuplicate.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Etes-vous sûr de vouloir dupliquer cet élément ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="modalDuplicate.hide()"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="actionDuplicate()"
            >
              Dupliquer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="deleteModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Supprimer</h5>
            <button
              type="button"
              class="btn-close"
              @click="modalDelete.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Etes-vous sûr de vouloir supprimer ce programme ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="modalDelete.hide()"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="actionDelete()"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <DashboardLayout>
      <div class="row mb-2">
        <div class="col-lg-8">
          <h1>Programmes</h1>
        </div>
      </div>
      <div class="card">
        <div class="row mx-2 my-1">
          <div class="col-sm-4">
            <h2>Filtres</h2>
          </div>
        </div>
        <div class="row mx-2 mb-2 filter-box">
          <div class="col-sm-4">
            <multiselect :close-on-select="false"
                    track-by="code"
                    :options="optionsFormation"
                    v-model="activeFormation"
                    label="name"
                    placeholder="Choisir une formation"
                    multiple
                    :taggable="false"
                    :show-labels="false"
            >
            </multiselect>
          </div>
          <div class="col-sm-4">
            <multiselect :close-on-select="false"
                    track-by="code"
                    :options="optionsDomain"
                    v-model="activeDomain"
                    label="name"
                    placeholder="Choisir un domaine"
                    multiple
                    :taggable="false"
                    :show-labels="false"
            >
            </multiselect>
          </div>
          <div class="col-sm-4" v-if="programStore.sections.length > 1">
            <multiselect :close-on-select="false"
                    track-by="code"
                    :options="optionsCampus"
                    v-model="activeCampus"
                    label="name"
                    :placeholder="'Choisir '+this.singularSectionNameWithPronom()"
                    multiple
                    :taggable="false"
                    :show-labels="false"
            >
            </multiselect>
          </div>
        </div>
      </div>
      <section id="datatable">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div
                id="DataTables_Table_0_wrapper"
                class="dataTables_wrapper dt-bootstrap5 no-footer"
              >
                <div
                  class="d-flex justify-content-between align-items-center mx-0 row"
                >
                  <div class="col-sm-12 col-md-4">
                    <div class="dataTables_length">
                      <label
                        >Afficher<select
                          class="form-select"
                          v-model="perPage"
                          style="display: inline"
                        >
                          <option
                            v-for="(show, index) in showList"
                            :value="show"
                            :key="index"
                          >
                            {{ show }}
                          </option>
                        </select>
                        résultats par page</label
                      >
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div style="text-align: right">
                      <label
                        >
                        <input
                          v-model="filterSearch"
                          class="form-control search-input"
                          placeholder="Recherche..."
                          style="
                            width: auto;
                            display: inline;
                            margin-right: 50px;
                          "
                        />
                      </label>
                      <button
                        class="btn btn-primary"
                        @click="$router.push('/program/add')"
                      >
                        Créer un programme
                      </button>

                      <Import></Import>

                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                <table
                  class="datatables-basic table dataTable no-footer dtr-column"
                  role="grid"
                >
                  <thead>
                    <tr role="row">
                      <th
                        v-for="(header, index) in headers"
                        :key="index"
                        :class="{
                          sorting: header.sort,
                          sorting_asc: header.sort === 'asc',
                          sorting_desc: header.sort === 'desc',
                        }"
                        @click="sortItems(index, header.sortBy)"
                      >
                        <span>{{ header.text }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      role="row"
                      v-for="(item, index) in sortedItems"
                      :key="index"
                      class="cursor-pointer"
                    >
                      <th @click="$router.push('/program/' + item.id)">
                        <span class="badge" :class="formatCategory(item.category)">
                          {{ getType(item.category) }}
                        </span>
                      </th>
                      <th @click="$router.push('/program/' + item.id)" style="white-space: nowrap;">
                        {{ getDomain(item.domains) }}
                        <Popper
                          v-if="getDomainNumber(item.domains) > 0"
                          :content="getOtherDomains(item.domains)"
                          hover
                        >
                          <div class="avatar bg-light-secondary ms-1">
                            <div class="avatar-content">
                              +{{ getDomainNumber(item.domains) }}
                            </div>
                          </div>
                        </Popper>
                      </th>
                      <th @click="$router.push('/program/' + item.id)">
                        <span>{{ item.name }}</span>
                      </th>
                      <th @click="$router.push('/program/' + item.id)">
                        <span>{{
                          item.sections
                            .map(function (elem) {
                              return elem.name;
                            })
                            .join(", ")
                        }}</span>
                      </th>
                      <th>
                        <a
                          :href="item.url"
                          target="_blank"
                          class="badge badge-light-primary"
                        >
                          Lien web
                        </a>
                      </th>
                      <th>
                        <div class="" style="width: 100px">
                          <div class="avatar bg-light-info">
                            <div class="avatar-content">
                              <vue-feather
                                type="copy"
                                @click="openModalDuplicate(item.id)"
                              ></vue-feather>
                            </div>
                          </div>
                          <div
                            class="avatar bg-light-danger"
                            style="margin-left: 5px"
                          >
                            <div
                              class="avatar-content"
                              @click="openModalDelete(item.id)"
                            >
                              <vue-feather type="trash"></vue-feather>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
                </div>
                <div class="d-flex justify-content-between mx-0 row">
                  <div class="col-sm-12 col-md-6">
                    <div
                      class="dataTables_info"
                      id="DataTables_Table_0_info"
                      role="status"
                      aria-live="polite"
                    >
                      Affichage de {{ offset + 1 }} à {{ end }} entrées sur
                      {{ totalItems }}
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="DataTables_Table_0_paginate"
                    >
                      <ul class="pagination">
                        <li
                          v-if="offset > 0"
                          @click="previousPage()"
                          class="paginate_button page-item previous disabled"
                          id="DataTables_Table_0_previous"
                        >
                          <a
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            >&nbsp;</a
                          >
                        </li>
                        <li
                          role="presentation"
                          class="page-item"
                          :class="{ active: index == offset / perPage }"
                          v-for="(page, index) in Math.round(nbPages)"
                        >
                          <button
                            v-if="index <= 5"
                            role="menuitemradio"
                            type="button"
                            class="page-link"
                            @click="setPage(index)"
                          >
                            {{ index + 1 }}
                          </button>
                        </li>
                        <li
                          v-if="offset + perPage < totalItems"
                          @click="nextPage()"
                          class="paginate_button page-item next disabled"
                          id="DataTables_Table_0_next"
                        >
                          <a
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                            >&nbsp;</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import DashboardLayout from "@/layouts/dashboard";
import { useProgramStore } from "@/stores/program";
import { useAccountStore } from "@/stores/account";
import { useUserStore } from "@/stores/user";
import Multiselect from "vue-multiselect";
import { toRaw } from "vue";
import Import from "@/components/import";
import mitt from 'mitt';

export default {
  name: "Program",
  components: {
    DashboardLayout,
    Multiselect,
    Import
  },
  data() {
    return {
      id: "",
      modalDuplicate: null,
      modalDelete: null,
      items: this.programStore.courses,
      headers: [
        {
          text: "Type de formation",
          value: "",
          sort: "asc",
          sortBy: "type",
        },
        { text: "Domaine", value: "domains", sort: "asc", sortBy: "domains" },
        { text: "Nom", value: "name", sort: "asc", sortBy: "name" },
        { text: this.singularSectionName(), value: "", sort: "asc", sortBy: "sections"  },
        { text: "Lien web", value: "url" },
        { text: "Actions", value: "actions" },
      ],
      filterSearch: "",
      activeDomain: [],
      optionsDomain: [],
      activeCampus: [],
      optionsCampus: [],
      activeFormation: [],
      optionsFormation: [
                { name: "Diplomante", code: "initial" },
                { name: "Continue / pro", code: "professional" },
                { name: "Tout", code: "tout" },
            ]
  };
  },
  watch: {
    "programStore.sections": function (newFilter, oldFilter) {
      var optionsCampus = [];
      this.programStore.sections.forEach((obj) => {
        optionsCampus.push({ name: obj.name, code: obj.name });
      });
      this.optionsCampus = optionsCampus;
      this.activeCampus = [];
      this.activeFormation = [];
      this.activeDomain = [];
      this.filterSearch = "";
    },
    "programStore.domains": function (newFilter, oldFilter) {
      var optionsDomain = [];
      this.programStore.domains.forEach((obj) => {
        optionsDomain.push({ name: obj.name, code: obj.name });
      });
      this.optionsDomain = optionsDomain;
    },
    "programStore.courses": function (newFilter, oldFilter) {
      this.filterItems();
    },
    activeCampus: function (newFilter, oldFilter) {
      this.filterItems();
    },
    activeFormation: function (newFilter, oldFilter) {
      this.filterItems();
    },
    activeDomain: function (newFilter, oldFilter) {
      this.filterItems();
    },
    filterSearch: function (newFilter, oldFilter) {
      this.filterItems();
    },
  },
  async mounted() {
    await this.programStore.getCourses({ vm: this });
    await this.programStore.getSections({ vm: this });
    await this.programStore.getDomains({ vm: this });
    this.modalDuplicate = new Modal(this.$refs.duplicateModal);
    this.modalDelete = new Modal(this.$refs.deleteModal);

      this.emitter.on("import", isOpen => {
          this.programStore.getCourses({ vm: this });
          this.programStore.getSections({ vm: this });
          this.programStore.getDomains({ vm: this });
      });
  },
  setup() {
    const programStore = useProgramStore();
    const accountStore = useAccountStore();
    const userStore = useUserStore();
    return {
      programStore,
      accountStore,
      userStore
    };
  },
  methods: {
      singularSectionNameWithPronom() {
          if(this.accountStore.account.section_naming == "campus") return "un campus";
          if(this.accountStore.account.section_naming == "school") return "une école";
          if(this.accountStore.account.section_naming == "center") return "un centre de formation";
          return "un campus";
      },
      singularSectionName() {
          if(this.accountStore.account.section_naming == "campus") return "Campus";
          if(this.accountStore.account.section_naming == "school") return "École";
          if(this.accountStore.account.section_naming == "center") return "Centre de formation";
          return "Campus";
      },
      filterItems() {
          this.filterItems(false);
      },
      filterItems(r) {
          var filteredItems = this.programStore.courses;

          if (this.activeFormation.length > 0) {
              filteredItems = filteredItems.filter((item) => {
                  return item.category && this.activeFormation.findIndex(v => v.code.toLowerCase() === item.category.toLowerCase() ) >= 0;
              });
          }

          if (this.activeCampus.length > 0) {
              filteredItems = filteredItems.filter((item) => {
                  const x = item.sections.filter((section) => {
                      return (
                          this.activeCampus.findIndex(v => v.code.toLowerCase() === section.name.toLowerCase() ) >= 0
                      );
                  });
                  if (x.length > 0) {
                      return true;
                  }
              });
          }

          if (this.activeDomain.length > 0) {
              filteredItems = filteredItems.filter((item) => {
                  const x = item.domains.filter((domain) => {
                      return (
                          this.activeDomain.findIndex(v => v.code.toLowerCase() === domain.name.toLowerCase() ) >= 0
                      );
                  });
                  if (x.length > 0) {
                      return true;
                  }
              });
          }

          if (this.filterSearch !== "") {
              filteredItems = filteredItems.filter((item) => {
                  const x = item.domains.filter((domain) => {
                      return (
                          domain.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.filterSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                      );
                  });
                  return x.length > 0 ||
                      item.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.filterSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
              });
          }
          this.items = filteredItems;
      },
    getType(type) {
      if (type === "initial") {
        return "Diplomante";
      } else if (type === "professional") {
        return "Continue / Pro";
      } else if (type === "tout") {
        return "Tout";
      }
      return "";
    },
    getDomain(domains) {
      if (domains.length > 0) {
        return domains[0].name || "";
      }
    },
    getDomainNumber(domains) {
      return domains.length - 1;
    },
    getOtherDomains(domains) {
      if (domains.length > 1) {
        const result = toRaw(domains);
        let html = [];
        for (let i = 1; i < result.length; i++) {
          html.push(result[i].name);
        }
        return html.join(",");
      }
    },
    openModalDuplicate(id) {
      this.id = id;
      this.modalDuplicate.show();
    },
    openModalDelete(id) {
      this.id = id;
      this.modalDelete.show();
    },
    async actionDuplicate() {
      this.modalDuplicate.hide();
      await this.programStore.duplicateCourse({ id: this.id, vm: this });
      await this.programStore.getCourses({ vm: this });
    },
    async actionDelete() {
      this.modalDelete.hide();
      await this.programStore.deleteCourse({ id: this.id, vm: this });
      await this.programStore.getCourses({ vm: this });
    },
  },
};
</script>

<style>
.dataTable {
  font-size: 0.875rem;
  font-weight: 400;
}
:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.filter-box {
  min-height:70px !important;
}
</style>
