<template>
  <div>
    <form class="row" @submit.prevent="actionSave">
      <div class="col-12 mb-3">
        <h1>Qualifications</h1>
        👉 Personnalisez vos choix
      </div>


      <div class="col-6 mb-2">
        <label>Branche 'Étudiant'</label>
        <div class="form-check form-check-primary form-switch">
          <input type="checkbox" v-model="student_enabled" class="form-check-input switch" />
        </div>
      </div>
      <div class="col-6 mb-2">
        <label>Qualification niveau "Collège"</label>
        <div class="form-check form-check-primary form-switch">
          <input type="checkbox" v-model="enable_flow_middle_school" class="form-check-input switch" />
        </div>
      </div>

      <div class="col-6 mb-2">
        <label>Branche 'Sans emploi'</label>
        <div class="form-check form-check-primary form-switch">
          <input type="checkbox" v-model="jobless_enabled" class="form-check-input switch" />
        </div>
      </div>
      <div class="col-6 mb-2">
        <label>Qualification niveau "Lycée"</label>
        <div class="form-check form-check-primary form-switch">
          <input type="checkbox" v-model="enable_flow_high_school"  class="form-check-input switch" />
        </div>
      </div>

      <div class="col-6 mb-2">
        <label>Branche 'Salarié en activité'</label>
        <div class="form-check form-check-primary form-switch">
          <input type="checkbox" v-model="worker_enabled" class="form-check-input switch" />
        </div>
      </div>
      <div class="col-6 mb-2">
        <label>Qualification niveau "Supérieures"</label>
        <div class="form-check form-check-primary form-switch">
          <input
                  type="checkbox"
                  v-model="enable_flow_college"
                  class="form-check-input switch"
          />
        </div>
      </div>

      <div class="col-12 mb-2">
        <label>Branche 'Parent'</label>
        <div class="form-check form-check-primary form-switch">
          <input type="checkbox" v-model="parent_enabled" class="form-check-input switch" />
        </div>
      </div>

      <hr>
      <div class="col-6 mb-2" :class="licenceStore.chatbot_entreprise || userStore.is_leadia_admin ? '' : 'grayscale'" style="min-height:140px">
        <label>Branche 'Entreprise'</label>
        <div class="form-check form-check-primary form-switch">
          <input type="checkbox" v-model="entreprise_enabled" class="form-check-input switch" />
        </div>
      </div>
      <div class="col-6 mb-2" :class="licenceStore.chatbot_entreprise || userStore.is_leadia_admin ? '' : 'grayscale'">
      <div class="form-group" v-if="entreprise_enabled">
        <div class="col-6 mb-2">
          <label>- Recruter en alternance</label>
          <div class="form-check form-check-primary form-switch">
            <input type="checkbox" v-model="entreprise_apprentice" class="form-check-input switch" />
          </div>
        </div>
        <div class="col-6 mb-2">
          <label>- Former vos équipes</label>
          <div class="form-check form-check-primary form-switch">
            <input type="checkbox" v-model="entreprise_staff" class="form-check-input switch" />
          </div>
        </div>
      </div>
      </div>


      <div class="col-12 mb-3 mt-3">
        <button type="submit" class="btn btn-primary">Enregistrer</button>
      </div>
    </form>
  </div>
</template>

<script>
    import {useAccountStore} from "@/stores/account";
    import {useLicenceStore} from "@/stores/licence";
    import {useUserStore} from "@/stores/user";

    export default {
        name: "qualification",
        setup() {
            const accountStore = useAccountStore();
            const licenceStore = useLicenceStore();
            const userStore = useUserStore();
            return {
                accountStore,
                licenceStore,
                userStore
            };
        },
        watch: {
            "accountStore.account": function (newVal, oldVal) {
                this.getData();
            },
        },
        async mounted() {
            await this.getData();
        },
        data() {
            var student_enabled = false;
            var jobless_enabled = false;
            var worker_enabled = false;
            var parent_enabled = false;
            var entreprise_enabled = false;
            var entreprise_apprentice = false;
            var entreprise_staff = false;

            if (this.accountStore.account.account_statuts) {
                this.accountStore.account.account_statuts.forEach(function (item) {
                    if (item["statut"]["name"] == "Étudiant") {
                        student_enabled = true;
                    }
                    if (item["statut"]["name"] == "Sans emploi") {
                        jobless_enabled = true;
                    }
                    if (item["statut"]["name"] == "Salarié en activité") {
                        worker_enabled = true;
                    }
                    if (item["statut"]["name"] == "Parent") {
                        parent_enabled = true;
                    }
                    if (item["statut"]["name"] == "Entreprise") {
                        entreprise_enabled = true;
                        entreprise_apprentice = item["meta"]["apprentice"];
                        entreprise_staff = item["meta"]["staff"];
                    }
                });
            };

            return {
                enable_flow_middle_school: this.accountStore.account.enable_flow_middle_school,
                enable_flow_high_school: this.accountStore.account.enable_flow_high_school,
                enable_flow_college: this.accountStore.account.enable_flow_college,
                student_enabled: student_enabled,
                jobless_enabled: jobless_enabled,
                worker_enabled: worker_enabled,
                parent_enabled: parent_enabled,
                entreprise_enabled: entreprise_enabled,
                entreprise_apprentice: entreprise_apprentice,
                entreprise_staff: entreprise_staff,
            };
        },
        methods: {
            async getData() {
                var student_enabled = false;
                var jobless_enabled = false;
                var worker_enabled = false;
                var parent_enabled = false;
                var entreprise_enabled = false;
                var entreprise_apprentice = false;
                var entreprise_staff = false;

                if (this.accountStore.account.account_statuts) {
                    this.accountStore.account.account_statuts.forEach(function (item) {
                        if (item["statut"]["name"] == "Étudiant") {
                            student_enabled = true;
                        }
                        if (item["statut"]["name"] == "Sans emploi") {
                            jobless_enabled = true;
                        }
                        if (item["statut"]["name"] == "Salarié en activité") {
                            worker_enabled = true;
                        }
                        if (item["statut"]["name"] == "Parent") {
                            parent_enabled = true;
                        }
                        if (item["statut"]["name"] == "Entreprise") {
                            entreprise_enabled = true;
                            entreprise_apprentice = item["meta"]["apprentice"];
                            entreprise_staff = item["meta"]["staff"];
                        }
                    });
                };

                this.enable_flow_middle_school =  this.accountStore.account.enable_flow_middle_school;
                this.enable_flow_high_school = this.accountStore.account.enable_flow_high_school;
                this.enable_flow_college = this.accountStore.account.enable_flow_college;
                this.student_enabled = student_enabled;
                this.jobless_enabled = jobless_enabled;
                this.worker_enabled = worker_enabled;
                this.parent_enabled = parent_enabled;
                this.entreprise_enabled = entreprise_enabled;
                this.entreprise_apprentice = entreprise_apprentice;
                this.entreprise_staff = entreprise_staff;
            },
            async actionSave(e) {
                e.preventDefault();
                const data = {
                    enable_flow_middle_school: this.enable_flow_middle_school,
                    enable_flow_high_school: this.enable_flow_high_school,
                    enable_flow_college: this.enable_flow_college,
                    student_enabled: this.student_enabled,
                    jobless_enabled: this.jobless_enabled,
                    parent_enabled: this.parent_enabled,
                    worker_enabled: this.worker_enabled,
                    entreprise_enabled: this.entreprise_enabled,
                    entreprise_apprentice: this.entreprise_apprentice,
                    entreprise_staff: this.entreprise_staff,
                    vm: this,
                };
                await this.accountStore.updateAccount(data);
            },
        },
    };
</script>

<style scoped></style>
