<template>
  <div class="card mb-2">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h2 class="card-title">Contacts</h2>
    </div>
    <div class="row">
      <div class="col-2 left-container">
        <Card
          name="Prévisions annuelles"
          :value="foreseen"
          :info="'Sur la base des performances observées jusqu\'à 30 jours'"
          icon="trending-up"
          variant="success"
          background="transparent"
          marginLeft="50px"
          valueSize="1.8rem"
          valueSpace="0px"
        ></Card>
        <Card
          name="Moyenne quotidienne"
          :value="average"
          :info="'Dans l\'intervalle selectionné'"
          icon="user-check"
          variant="warning"
          background="transparent"
          marginLeft="50px"
          valueSize="1.8rem"
          valueSpace="0px"
        ></Card>
      </div>
      <div class="col-10">
        <div class="card-body pb-0" style="position: relative">
          <div v-if="!chartData.labels || chartData.labels.length === 0">
            Pas de données disponibles
          </div>
          <LineChart
            v-else
            :chartData="chartData"
            :options="options"
            width="100%"
            height="360"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/dashboard/card";
import { computed, defineComponent, ref } from "vue";
import { LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { useDashboardStore } from "@/stores/dashboard";

Chart.register(...registerables);
export default defineComponent({
  name: "App",
  components: { LineChart, Card },
  data() {
    return {
      chartData: {},
      options: {
        responsive: true,
        plugins: {
          title: {
            display: false,
            text: "",
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: true,
            beginAtZero: true,
              ticks: {
                  precision: 0
              }
          },
        },
      },
    };
  },
  setup() {
    const dashboardStore = useDashboardStore();
    return { dashboardStore };
  },
  watch: {
    "dashboardStore.core": function (newCore, oldCore) {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    foreseen() {
      if (this.dashboardStore.prediction.foreseen === "< 1") return 0;
      return this.dashboardStore.prediction.foreseen;
    },
    average() {
      if (this.dashboardStore.prediction.average === "< 1") return 0;
      return this.dashboardStore.prediction.average;
    },
  },
  methods: {
    getData() {
      const label = this.dashboardStore.core.map((core) => {
        return core.date;
      });
      const data = this.dashboardStore.core.map((core) => {
        return core.visitor_count;
      });
      this.chartData = {
        labels: label,
        datasets: [
          {
            data: data,
            backgroundColor: ["#9188f1"],
            borderColor: ["#9188f1"],
            fill: false,
            cubicInterpolationMode: "monotone",
            pointStyle: "circle",
            pointRadius: 0,
            tension: 0.3,
          },
        ],
      };
    },
  },
});
</script>

<style scoped>
.left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.row {
  min-height: 408px;
}
.card {
  margin-bottom: 0;
}
</style>
