<template>
  <div>
    <div class="modal fade" ref="deleteModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Supprimer</h5>
            <button
                    type="button"
                    class="btn-close"
                    @click="modalDelete.hide()"
                    aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Etes-vous sûr de vouloir supprimer l'accès de cet utilisateur ?
          </div>
          <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-secondary"
                    @click="modalDelete.hide()"
            >
              Annuler
            </button>
            <button
                    type="button"
                    class="btn btn-primary"
                    @click="actionDelete()"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="addUserModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ajouter un utilisateur</h5>
            <button
              type="button"
              class="btn-close"
              @click="modalAddUser.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <label>Vous serez facturé {{ this.payment.price }}€ HT pour l'ajout d'un utilisateur sur votre plan ({{this.payment.remaining_days}} jours restants).</label>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="modalAddUser.hide()"
            >
              Annuler
            </button>
            <a
              type="button"
              class="btn btn-primary"
              :href="this.payment.payment_url"
            >
              Créer
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--div class="alert alert-danger" style="padding:10px" v-if="!accountStore.account.current_product">
      Afin de pouvoir ajouter des utlisateurs, un contrat valide doit exister.
    </div>
    <div class="alert alert-warning" style="padding:10px" v-if="accountStore.account.current_product && accountStore.account.used_seats_count >= accountStore.account.current_product.meta.seats">
      Vous avez atteint le nombre d’utilisateur maximal pour votre forfait ! Pour rajouter un utilisateur, contactez un commercial.
    </div-->

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4">
            <h2>Filtres</h2>
          </div>
        </div>
        <div class="row m-2">
          <div class="col-sm-4">
            Nom
            <input class="form-control" v-model="filterName" />
          </div>
          <div class="col-sm-4">
            Role
            <select class="form-control" v-model="filterRole">
              <option value="">Choisir un role</option>
              <option
                v-for="(role, index) in userStore.roles"
                :key="index"
                :value="role.name"
              >
                {{ role.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-4">
            Statut
            <select class="form-control" v-model="filterStatut">
              <option value="">Choisir un status</option>
              <option value="Actif">Actif</option>
              <option value="En attente">En attente</option>
              <option value="Désactivé">Désactivé</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <section id="datatable">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div
              id="DataTables_Table_0_wrapper"
              class="dataTables_wrapper dt-bootstrap5 no-footer"
            >
              <div
                class="d-flex justify-content-between align-items-center mx-0 row"
              >
                <div class="col-sm-12 col-md-4">
                  <div class="dataTables_length">
                    <label
                      >Afficher<select
                        class="form-select"
                        v-model="perPage"
                        style="display: inline"
                      >
                        <option
                          v-for="(show, index) in showList"
                          :value="show"
                          :key="index"
                        >
                          {{ show }}
                        </option>
                      </select>
                      résultats par page</label
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-8">
                  <div style="text-align: right">
                    <label
                      >
                      <input
                        v-model="filterSearch"
                        class="form-control search-input"
                        placeholder="Recherche..."
                        style="width: auto; display: inline; margin-right: 50px"
                      />
                    </label>
                    <button v-if="userStore.current_user.role == 'Admin' "
                      class="btn btn-primary"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#formAddUser"
                    >
                      Ajouter un utilisateur
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
              <table
                class="datatables-basic table dataTable no-footer dtr-column"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th v-for="(header, index) in headers" :key="index">
                      <span>{{ header.text }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    role="row"
                    v-for="(item, index) in sortedItems"
                    :key="index"
                  >
                    <th
                      style="white-space: nowrap;"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#formAddUser"
                      @click="getUser(item)"
                    >
                      <div class="avatar">
                        <div class="avatar-content">
                          {{ item.firstname.charAt(0) }}
                          {{ item.lastname.charAt(0) }}
                        </div>
                      </div>
                      {{ item.firstname }} {{ item.lastname }}
                    </th>
                    <th>
                      <span>{{ item.email }}</span>
                    </th>
                    <th style="white-space: nowrap;">
                      <span v-html="formatRole(item.role)" />
                    </th>
                    <th>{{ item.service }}</th>
                    <th>
                      <span
                        class="badge"
                        :class="classColorStatut('badge-light', item.statut)"
                        >{{ item.statut }}</span
                      >
                    </th>
                    <th>
                      <div class="form-check form-check-primary form-switch">
                        <input type="checkbox"
                               v-model="item.reporting"
                               @click="setItem(item)"
                               class="form-check-input switch" />
                      </div>
                    </th>
                    <th>
                      <div class="d-flex ">
                        <div class="avatar bg-light-primary">
                          <div class="avatar-content"
                               v-if="authStore.role === 'Admin'">
                            <vue-feather
                                    type="edit"
                                    @click="openItem(item)"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#formEditUser"
                            ></vue-feather>
                          </div>
                        </div>
                        <div class="avatar bg-light-danger">
                          <div
                            class="avatar-content"
                            v-if="
                              authStore.uid !== item.id &&
                              authStore.role === 'Admin' &&
                              item.role !== 'Admin'
                            "
                            @click="openModalDelete(item.id)"
                          >
                            <vue-feather type="trash"></vue-feather>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
              </div>
              <div class="d-flex justify-content-between mx-0 row">
                <div class="col-sm-12 col-md-6">
                  <div
                    class="dataTables_info"
                    id="DataTables_Table_0_info"
                    role="statut"
                    aria-live="polite"
                  >
                    Affichage de {{ offset + 1 }} à {{ end }} entrées sur
                    {{ totalItems }}
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div
                    class="dataTables_paginate paging_simple_numbers"
                    id="DataTables_Table_0_paginate"
                  >
                    <ul class="pagination">
                      <li
                        v-if="offset > 0"
                        @click="previousPage()"
                        class="paginate_button page-item previous disabled"
                        id="DataTables_Table_0_previous"
                      >
                        <a
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="0"
                          tabindex="0"
                          class="page-link"
                          >&nbsp;</a
                        >
                      </li>
                      <li
                        role="presentation"
                        class="page-item"
                        :class="{ active: index == offset / perPage }"
                        v-for="(page, index) in Math.round(nbPages)"
                      >
                        <button
                          v-if="index <= 5"
                          role="menuitemradio"
                          type="button"
                          class="page-link"
                          @click="setPage(index)"
                        >
                          {{ index + 1 }}
                        </button>
                      </li>
                      <li
                        v-if="offset + perPage < totalItems"
                        @click="nextPage()"
                        class="paginate_button page-item next disabled"
                        id="DataTables_Table_0_next"
                      >
                        <a
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="1"
                          tabindex="0"
                          class="page-link"
                          >&nbsp;</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <form
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="formAddUser"
      @submit.prevent="actionSave"
      ref="addUserToggle"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Ajouter utilisateur</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="mb-3">
          <label>Prénom *</label>
          <input type="text" class="form-control" v-model="firstname" />
          <div
            class="input-errors"
            v-for="(error, index) of v$.firstname.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3">
          <label>Nom *</label>
          <input type="text" class="form-control" v-model="lastname" />
          <div
            class="input-errors"
            v-for="(error, index) of v$.lastname.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3">
          <label>Email *</label>
          <input type="text" class="form-control" v-model="email" />
          <div
            class="input-errors"
            v-for="(error, index) of v$.email.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <!--div class="mb-3">
          <label>Ecole</label>
          <input type="text" class="form-control" v-model="ecole" />
        </div-->
        <div class="mb-3">
          <label>Equipe</label>
          <input type="text" class="form-control" v-model="equipe" />
        </div>
        <div class="mb-3">
          <label>Service *</label>
          <select class="form-select" v-model="service">
            <option value="Marketing/Communication">Marketing/Communication</option>
            <option value="Commercial">Commercial</option>
            <option value="Pédagogie">Pédagogie</option>
            <option value="Direction générale">Direction générale</option>
          </select>
          <div
            class="input-errors"
            v-for="(error, index) of v$.service.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3">
          <label>Role *</label>
          <select class="form-select" v-model="role">
            <option value=2>Contributeur</option>
            <option value=3>Utilisateur</option>
          </select>
          <div
            class="input-errors"
            v-for="(error, index) of v$.role.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3">
          <button class="btn btn-primary">Ajouter</button>
          <button
            type="button"
            class="btn btn-outline-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Annuler
          </button>
        </div>
      </div>
    </form>
    <form
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="formEditUser"
      @submit.prevent="actionUpdate"
      ref="editUserToggle"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Modifier un utilisateur</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="mb-3">
          <label>Prénom *</label>
          <input type="text" class="form-control" v-model="firstname" />
          <div
            class="input-errors"
            v-for="(error, index) of v$.firstname.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3">
          <label>Nom *</label>
          <input type="text" class="form-control" v-model="lastname" />
          <div
            class="input-errors"
            v-for="(error, index) of v$.lastname.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div class="mb-3">
          <label>Service *</label>
          <select class="form-select" v-model="service">
            <option value="Marketing/Communication">Marketing/Communication</option>
            <option value="Commercial">Commercial</option>
            <option value="Pédagogie">Pédagogie</option>
            <option value="Direction générale">Direction générale</option>
          </select>
          <div
            class="input-errors"
            v-for="(error, index) of v$.service.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div class="mb-3">
          <button class="btn btn-primary">Enregistrer</button>
          <button
            type="button"
            class="btn btn-outline-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Annuler
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { useProgramStore } from "@/stores/program";
import { useAccountStore } from "@/stores/account";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { Modal } from "bootstrap";

export default {
  name: "settingAccount",
  components: {},
  data() {
    return {
      modalAddUser: null,
      payment: {},
      firstname: "",
      lastname: "",
      email: "",
      role: 2,
      service: "Marketing/Communication",
      statut: "",
      equipe: "",
      ecole: "",
      items: this.userStore.users,
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "created_at" },
        { text: "Role", value: "meta.phone" },
        { text: "Service", value: "diploma_level" },
        { text: "Statut", value: "statut" },
        { text: "Email de synthèse", value: "email" },
        { text: "Actions", value: "actions" },
      ],
      filterSearch: "",
      filterName: "",
      filterRole: "",
      filterStatut: "",
      modalDelete: null,
      id: null,
    };
  },
  validations() {
    return {
      firstname: {
        required: helpers.withMessage("Prénom requis", required),
      },
      lastname: {
        required: helpers.withMessage("Nom requis", required),
      },
      email: {
        required: helpers.withMessage("Email requis", required),
      },
      service: {
        required: helpers.withMessage("Service requis", required),
      },
      role: {
        required: helpers.withMessage("Role requis", required),
      },
    };
  },
  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const programStore = useProgramStore();
    const accountStore = useAccountStore();
    return {
      v$: useVuelidate(),
      authStore,
      userStore,
      programStore,
      accountStore,
    };
  },
  watch: {
    "userStore.users": function (newFilter, oldFilter) {
      this.filterItems();
    },
    filterSearch: function (newFilter, oldFilter) {
      this.filterItems();
    },
    filterName: function (newFilter, oldFilter) {
      this.filterItems();
    },
    filterRole: function (newFilter, oldFilter) {
      this.filterItems();
    },
    filterStatut: function (newFilter, oldFilter) {
      this.filterItems();
    },
  },
  async mounted() {
    await this.userStore.getUsers({ vm: this });
    await this.programStore.getDomains({ vm: this });
    await this.userStore.getRoles({ vm: this });
    this.modalAddUser = new Modal(this.$refs.addUserModal);
    this.modalDelete = new Modal(this.$refs.deleteModal);
  },
  methods: {
    openModalDelete(id) {
     this.id = id;
     this.modalDelete.show();
    },
    openItem(item) {
      this.id = item.id;
      this.firstname = item.firstname;
      this.lastname = item.lastname;
      this.service = item.service;
    },
    async setItem(item) {
        const data = {
            id: item.id,
            reporting: !item.reporting,
            vm: this,
        };
        await this.userStore.updateUser(data);
    },
    filterItems() {
      let filteredItems = this.userStore.users;
      if (this.filterSearch !== "") {
        filteredItems = filteredItems.filter((item) => {
          return (
            item.firstname
              .toLowerCase()
              .includes(this.filterSearch.toLowerCase()) ||
            item.lastname
              .toLowerCase()
              .includes(this.filterSearch.toLowerCase())
          );
        });
      }
      if (this.filterName !== "") {
        filteredItems = filteredItems.filter((item) => {
          return (
            item.firstname
              .toLowerCase()
              .includes(this.filterName.toLowerCase()) ||
            item.lastname.toLowerCase().includes(this.filterName.toLowerCase())
          );
        });
      }
      if (this.filterRole !== "") {
        filteredItems = filteredItems.filter((item) => {
          return item.role.toLowerCase() === this.filterRole.toLowerCase();
        });
      }
      if (this.filterStatut !== "") {
        filteredItems = filteredItems.filter((item) => {
          return item.statut.toLowerCase() === this.filterStatut.toLowerCase();
        });
      }
      this.items = filteredItems;
    },
    async getUser(user) {
      this.firstname = user.firstname;
      this.lastname = user.lastname;
      this.email = user.email;
      this.equipe = user.equipe;
      this.ecole = user.ecole;
      this.role = user.role;
      this.service = user.service;
      this.statut = user.statut;
    },
    async actionSave(e) {
      e.preventDefault();
      await this.v$.$validate();
        if (this.v$.$error) {
            this.$toast.open({
                message: "Un ou plusieurs champs requis ne sont pas renseignés.",
                type: "error",
                position: "top-right",
                duration: 4000,
                dismissible: true,
            });
            return;
        }
      const data = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        equipe: this.equipe,
        ecole: this.ecole,
        role: this.role,
        service: this.service,
        vm: this,
      };
      const response = await this.userStore.createUser(data);
      if (response.price) {
        this.payment = response;
        this.modalAddUser.show();
      }
      await this.userStore.getUsers({ vm: this });
    },
    async actionUpdate(e) {
          e.preventDefault();
          const data = {
              id: this.id,
              firstname: this.firstname,
              lastname: this.lastname,
              service: this.service,
              vm: this,
          };
          const response = await this.userStore.updateUser(data);
          await this.userStore.getUsers({ vm: this });
    },
    async actionDelete() {
      await this.userStore.deleteUser({ id: this.id, vm: this });
      await this.userStore.getUsers({ vm: this });
      this.modalDelete.hide();
    },
  },
};
</script>

<style scoped>
  .avatar {
    margin: 5px;
  }
  .avatar .avatar-content {
    width: 30px;
    height: 30px;
  }
button {
  margin-right: 20px;
}
.card-body {
  border: 1px solid #c5c5c5;
  border-radius: 6px;
}
.avatar {
  margin-left: 5px;
}
</style>
