import { defineStore } from "pinia";
import axios from "axios";
import { useLoaderStore } from "@/stores/loader";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import moment from "moment";

export const useDashboardStore = defineStore("dashboard", {
    state: () => ({
        summary: {},
        prediction: {},
        core: [],
        displays: [],
        courses: {},
        repartition: {},
        sms_analytics: {},
        sms_repartition: {},
        sms_status: {},
        sms_daily: {},
        scoring: [],
        start: moment().subtract(30, "d").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
        startSms: moment().subtract(20, "d").format("YYYY-MM-DD"),
        endSms: moment().format("YYYY-MM-DD"),
    }),

    getters: {
        authGetter(state) {
            const authStore = useAuthStore();
            return {
                token: authStore.token,
                client: authStore.client,
                uid: authStore.uid,
            };
        },
        accountGetter(state) {
            const accountStore = useAccountStore();
            return {
                uuid: accountStore.uuid,
            };
        },
    },

    actions: {
        async setDates(data: any) {
            this.start = moment(data.selectedDate[0]).format("YYYY-MM-DD");
            this.end = moment(data.selectedDate[1]).format("YYYY-MM-DD");
        },
        async setDatesSms(data: any) {
            this.startSms = moment(data.selectedDate[0]).format("YYYY-MM-DD");
            this.endSms = moment(data.selectedDate[1]).format("YYYY-MM-DD");
        },
        async getSmsAnalytics(data: any) {
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/sms_analytics.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&sms_setting_id=" + data.sms_setting_id;
            url += "&start=" + this.startSms;
            url += "&end=" + this.endSms;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.sms_analytics = response.data;
            }
        },
        async getSmsDaily(data: any) {
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/sms_daily.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&sms_setting_id=" + data.sms_setting_id;
            url += "&start=" + this.startSms;
            url += "&end=" + this.endSms;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.sms_daily = response.data;
            }
        },
        async getSmsRepartition(data: any) {
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/sms_repartition.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&sms_setting_id=" + data.sms_setting_id;
            url += "&start=" + this.startSms;
            url += "&end=" + this.endSms;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.sms_repartition = response.data;
            }
        },
        async getSmsStatus(data: any) {
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/sms_status.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&sms_setting_id=" + data.sms_setting_id;
            url += "&start=" + this.startSms;
            url += "&end=" + this.endSms;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.sms_status = response.data;
            }
        },
        async getSummary(data: any) {
            const loaderStore = useLoaderStore();
            await loaderStore.setLoader(true);
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/summary.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&start=" + this.start;
            url += "&end=" + this.end;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.summary = response.data;
            }
            //await loaderStore.setLoader(false);
        },
        async getPrediction(data: any) {
            // const loaderStore = useLoaderStore();
            // await loaderStore.setLoader(true);
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/prediction.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&start=" + this.start;
            url += "&end=" + this.end;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.prediction = response.data;
            }
            //await loaderStore.setLoader(false);
        },
        async getCore(data: any) {
            // const loaderStore = useLoaderStore();
            // await loaderStore.setLoader(true);
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/core.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&start=" + this.start;
            url += "&end=" + this.end;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.core = response.data;
            }
            //await loaderStore.setLoader(false);
        },
        async getDisplays(data: any) {
            // const loaderStore = useLoaderStore();
            // await loaderStore.setLoader(true);
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/displays.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&start=" + this.start;
            url += "&end=" + this.end;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.displays = response.data;
            }
            //await loaderStore.setLoader(false);
        },
        async getCourses(data: any) {
            // const loaderStore = useLoaderStore();
            // await loaderStore.setLoader(true);
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/courses.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&start=" + this.start;
            url += "&end=" + this.end;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.courses = response.data;
            }
            //await loaderStore.setLoader(false);
        },
        async getRepartition(data: any) {
            const loaderStore = useLoaderStore();
            // await loaderStore.setLoader(true);
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/repartition.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&start=" + this.start;
            url += "&end=" + this.end;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.repartition = response.data;
            }
            await loaderStore.setLoader(false);
        },
        async getScoring(data: any) {
            const loaderStore = useLoaderStore();
            //await loaderStore.setLoader(true);
            let url =
                process.env.VUE_APP_ROOT_API +
                "analytics/scoring.json?account_uuid=" +
                this.accountGetter.uuid;
            url += "&start=" + this.start;
            url += "&end=" + this.end;
            const response = await axios
                .get(url, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response) {
                this.scoring = response.data;
            }
            await loaderStore.setLoader(false);
        },
    },
    persist: {
        enabled: true,
    },
});
