<template>
  <CoverLayout>
    <a class="brand-logo">
      <div class="brand-logo">
        <img src="@/assets/images/logo.png" alt="Lead-ia" class="logo" />
      </div>
    </a>
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
        class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
          class="img-fluid"
          src="@/app-assets/images/pages/login-v2.svg"
          alt="Login V2"
        />
      </div>
    </div>
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title fw-bold mb-1">Bienvenue chez Lead-ia 👋</h2>
        <p class="card-text mb-2">
          Pour vous connecter à votre compte, veuillez saisir vos identifiants :
        </p>
        <form class="auth-login-form mt-2" @submit.prevent="signIn">
          <div class="mb-1">
            <label>Email</label>
            <input
              v-model="v$.form.email.$model"
              class="form-control"
              placeholder="Email"
              type="email"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.email.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mb-1">
            <div class="d-flex justify-content-between">
              <label class="form-label" for="login-password">Mot de passe</label
              >

              <router-link
                      class="d-flex align-items-center"
                      :to="{ path: '/password' }"
              >
                <small>J'ai perdu mon mot de passe</small>
              </router-link>


            </div>
            <input
              v-model="v$.form.password.$model"
              class="form-control"
              placeholder="Mot de passe"
              :type="passwordFieldType"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.password.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mb-1">
            <div class="form-check">
              <input
                class="form-check-input"
                id="showPassword"
                type="checkbox"
                tabindex="3"
                v-model="showPassword"
              />
              <label class="form-check-label" for="showPassword">
                Afficher le mot de passe</label
              >
            </div>
          </div>
          <button
            class="btn btn-primary w-100 waves-effect waves-float waves-light"
            :disabled="form.email != '' && v$.form.$invalid"
          >
            Connexion
          </button>
        </form>
        <!--p class="text-center mt-2">
          <span>New on our platform?</span
          ><a href="auth-register-cover.html"
            ><span>&nbsp;Create an account</span></a
          >
        </p-->
      </div>
    </div>
  </CoverLayout>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, helpers } from "@vuelidate/validators";
import CoverLayout from "@/layouts/cover";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "SigninPage",
  components: { CoverLayout },
  setup() {
    const authStore = useAuthStore();
    return {
      v$: useVuelidate(),
      authStore,
    };
  },
  data() {
    return {
      showPassword: false,
      submitted: false,
      form: {
        email: "",
        password: "",
      },
      passwordFieldType: "password",
    };
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage("Email requis", required),
          email: helpers.withMessage("Email invalide", email),
        },
        password: {
          required: helpers.withMessage("Mot de passe requis", required),
        },
      },
    };
  },
  watch: {
    showPassword: function (newVal, oldVal) {
      if (newVal) {
        this.passwordFieldType = "text";
      } else {
        this.passwordFieldType = "password";
      }
    },
  },
  methods: {
    async signIn(e) {
      e.preventDefault();
      this.form.vm = this;
      await this.authStore.login(this.form);
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>

<style scoped>
.logo {
  width: 200px;
}
.auth-wrapper.auth-cover .brand-logo {
  top: 0;
  left: 0;
}
</style>
