<template>
  <div
    class="card text-center"
    :style="{ backgroundColor: background, marginLeft: marginLeft }"
  >
    <div class="card-body">
      <div class="avatar p-50 mb-1" :class="variantClass">
        <div class="avatar-content">
          <vue-feather :type="icon"></vue-feather>
        </div>
      </div>
      <h2 class="fw-bolder" :style="{ fontSize: valueSize, marginTop: valueSpace }">
        {{ showValue }}
      </h2>
      <p class="card-text" v-if="info">
        <Popper class="hand" :content="this.info" hover>
          {{ name }}&nbsp;<small style="font-size:15px;vertical-align:middle;">ⓘ</small>
        </Popper>
      </p>
      <p class="card-text" v-if="!info">
        {{ name }}
      </p>
    </div>
  </div>
</template>

<script>
import Popper from "vue3-popper";

export default {
  name: "card",
  components: { Popper },
  props: {
    name: String,
    value: String,
    info: String,
    icon: String,
    variant: String,
    background: String,
    marginLeft: String,
    valueSize: String,
    valueSpace: String,
  },
  computed: {
    variantClass() {
      return "bg-light-" + this.variant;
    },
    showValue() {
      if (this.value === "n/a" || this.value === "n/a%") return 0;
      return this.value;
    },
    showInfo() {
      return this.info;
    },
  },
};
</script>

<style scoped>
.card-text {
  font-size: 0.9rem;
}
.card {
  margin-bottom: 0 !important;
  min-height: 209px;
  padding-top: 8px;
}
.fw-bolder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-text {
  /*text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;*/
  line-height: 1.25rem
}
.hand {
  cursor: pointer !important;
}
</style>
