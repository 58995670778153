import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";

export const useProductStore = defineStore("product", {
    state: () => ({
        products: [],
        starter: {},
        starter_sms: {},
        sms_100: {},
        sms_250: {},
        sms_500: {},
        sms_1000: {},
        sms_2500: {},
        sms_5000: {},
        standard: {},
        booster: {},
        lead_500: {},
        lead_1500: {},
        lead_5000: {},
        tracking: {},
        interaction: {},
        personnalisation: {},
        entreprise: {},
        crm: {},
        support: {},
        checkout: {},
    }),

    getters: {
        authGetter(state) {
            const authStore = useAuthStore();
            return {
                token: authStore.token,
                client: authStore.client,
                uid: authStore.uid,
            };
        },
        accountGetter(state) {
            const accountStore = useAccountStore();
            return {
                uuid: accountStore.uuid,
            };
        },
    },

    actions: {
        async getPlanCheckout(data: any) {

            const loaderStore = useLoaderStore();
            await loaderStore.setLoader(true);

            const dataToSend = {
                "account_uuid": this.accountGetter.uuid,
                "cancel_url": window.location.href,
                "success_url": window.location.href,
                "line_items": data.line_items,
                "payment_method_types": data.payment_method_types,
            };

            const url = process.env.VUE_APP_ROOT_API + "stripe/buy.json";
            const response = await axios
                .post(url, dataToSend, {
                    headers: {
                        "token-type": "Bearer",
                        uid: this.authGetter.uid,
                        client: this.authGetter.client,
                        "access-token": this.authGetter.token,
                    },
                })
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });

            if (response) {
                this.checkout = response.data;
            }
            await loaderStore.setLoader(false);
        },
        async getProducts(data: any) {
            const accountStore = useAccountStore();
            const loaderStore = useLoaderStore();
            await loaderStore.setLoader(true);
            const response = await axios
                .get(
                    process.env.VUE_APP_ROOT_API +
                    "products.json?account_uuid=" +
                    this.accountGetter.uuid,
                    {
                        headers: {
                            "token-type": "Bearer",
                            uid: this.authGetter.uid,
                            client: this.authGetter.client,
                            "access-token": this.authGetter.token,
                        },
                    }
                )
                .catch(function (error) {
                    console.log(error);
                    data.vm.$toast.open({
                        message: "Une erreur est survenue lors de la requete",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                });
            if (response && response.data) {
                this.products = response.data;

                var sms_100 = {};
                var sms_250 = {};
                var sms_500 = {};
                var sms_1000 = {};
                var sms_2500 = {};
                var sms_5000 = {};
                var starter_sms = {};
                var starter = {};
                var standard = {};
                var booster = {};
                var lead_500 = {};
                var lead_1500 = {};
                var lead_5000 = {};
                var tracking = {};
                var interaction = {};
                var personnalisation = {};
                var entreprise = {};
                var crm = {};
                var support = {};

                response.data.forEach(function (item) {
                    if(item.meta["licence"] && item.meta["licence"][0] == "sms"){
                        starter_sms = item;
                    }
                    if(item.meta["sms_credits"] == 100){
                        sms_100 = item;
                    }
                    if(item.meta["sms_credits"] == 250){
                        sms_250 = item;
                    }
                    if(item.meta["sms_credits"] == 500){
                        sms_500 = item;
                    }
                    if(item.meta["sms_credits"] == 1000){
                        sms_1000 = item;
                    }
                    if(item.meta["sms_credits"] == 2500){
                        sms_2500 = item;
                    }
                    if(item.meta["sms_credits"] == 5000){
                        sms_5000 = item;
                    }
                    if(item.name == "Starter" && item.meta["licence"][0] == "chatbot"){
                        starter = item;
                    }
                    if(item.name == "Regular" && item.meta["licence"][0] == "chatbot"){
                        standard = item;
                    }
                    if(item.name == "Booster" && item.meta["licence"][0] == "chatbot"){
                        booster = item;
                    }
                    if(item.name == "Lead 500" && item.meta["licence"][0] == "chatbot"){
                        lead_500 = item;
                    }
                    if(item.name == "Lead 1500" && item.meta["licence"][0] == "chatbot"){
                        lead_1500 = item;
                    }
                    if(item.name == "Lead 5000" && item.meta["licence"][0] == "chatbot"){
                        lead_5000 = item;
                    }
                    if(item.name == "Tracking" && item.meta["modules"][0] == "tracking"){
                        tracking = item;
                    }
                    if(item.name == "Interaction" && item.meta["modules"][0] == "interaction"){
                        interaction = item;
                    }

                    /* dirty / temporary hack to remove*/
                    if(accountStore.id == 110 || accountStore.id == 111) {
                        if(item.name == "Personnalisation S" && item.meta["modules"][0] == "personnalisation"){
                            personnalisation = item;
                        }
                    } else {
                        if(item.name == "Personnalisation" && item.meta["modules"][0] == "personnalisation"){
                            personnalisation = item;
                        }
                    }

                    if(item.name == "Entreprise" && item.meta["modules"][0] == "entreprise"){
                        entreprise = item;
                    }
                    if(item.name == "Connexion CRM" && item.meta["modules"][0] == "crm"){
                        crm = item;
                    }
                    if(item.name == "Support" && item.meta["modules"][0] == "support"){
                        support = item;
                    }
                });

                this.sms_100 = sms_100;
                this.sms_250 = sms_250;
                this.sms_500 = sms_500;
                this.sms_1000 = sms_1000;
                this.sms_2500 = sms_2500;
                this.sms_5000 = sms_5000;
                this.starter_sms = starter_sms;
                this.starter = starter;
                this.standard = standard;
                this.booster = booster;
                this.lead_500 = lead_500;
                this.lead_1500 = lead_1500;
                this.lead_5000 = lead_5000;
                this.tracking = tracking;
                this.interaction = interaction;
                this.personnalisation = personnalisation;
                this.entreprise = entreprise;
                this.crm = crm;
                this.support = support;
            }
            await loaderStore.setLoader(false);
        }
    },
    persist: {
        enabled: true,
    },
});




