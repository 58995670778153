import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";

export const useSmartReplyStore = defineStore("smartReply", {
  state: () => ({
    questions: [],
  }),

  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },

  actions: {
    async getQuestions(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "questions.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.questions = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async createQuestion(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url = process.env.VUE_APP_ROOT_API + "questions.json";
      const dataToSend = {
        account_uuid: this.accountGetter.uuid,
        usage: data.usage,
        text: data.text,
        answer: data.answer.replace(/./ugm, s => s.match(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/i) ? "&#" + s.codePointAt(0) + ";" : s),
      };
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        data.vm.$toast.open({
          message: "Question créée avec succès",
          type: "success",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
        return true;
      }
    },
    async updateQuestion(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url = process.env.VUE_APP_ROOT_API + "questions/" + data.id + ".json";
      const dataToSend = {
        account_id: data.id,
        account_uuid: this.accountGetter.uuid,
        answer: data.answer.replace(/./ugm, s => s.match(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/i) ? "&#" + s.codePointAt(0) + ";" : s),
      };
      const response = await axios
        .put(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        data.vm.$toast.open({
          message: "Question mise a jour avec succès",
          type: "success",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
        return true;
      }
    },
    async deleteQuestion(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "questions/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .delete(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        data.vm.$toast.open({
          message: "Question supprimée avec succès",
          type: "success",
          position: "top-right",
          duration: 4000,
          dismissible: true,
        });
        return true;
      }
    },
  },
  persist: {
    enabled: true,
  },
});
