<template>
  <div>
    <Loader></Loader>
    <LayoutSidebar></LayoutSidebar>
    <div
      class="transitionMarginLeft"
      v-bind:style="{ marginLeft: menuExtended }"
    >
      <LayoutTopbar></LayoutTopbar>
      <div class="content-wrapper">
        <div class="content-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutSidebar from "@/layouts/dashboard/sidebar.vue";
import LayoutTopbar from "@/layouts/dashboard/topbar.vue";
import { useLayoutStore } from "@/stores/layout.ts";
import Loader from "@/components/loader.vue";

export default {
  name: "adminMain",
  components: {
    LayoutSidebar,
    LayoutTopbar,
    Loader,
  },
  setup() {
    const layoutStore = useLayoutStore();
    return { layoutStore };
  },
};
</script>

<style lang="scss">
.transitionMarginLeft {
  transition: margin-left 0.3s;
  -moz-transition: margin-left 0.3s;
  -webkit-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  -ms-transition: margin-left 0.3s;
}
.pagination {
  justify-content: end;
  margin-right: 10px;
}
.main-menu.menu-light .navigation .navigation-header {
  margin: 0 2.2rem;
}
input,
select,
textarea,
file {
  margin-bottom: 15px;
}
.header {
  text-align: right;
}
.content-body {
  margin: 25px;
}
</style>
