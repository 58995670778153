<template>
  <router-view></router-view>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { useLayoutStore } from "@/stores/layout";

export default {
  name: "App",
  setup() {
    const authStore = useAuthStore();
    const layoutStore = useLayoutStore();
    return {
      authStore,
      layoutStore,
    };
  },
  created() {
    this.notification();
  },
};
</script>

<style>
  .blur {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    pointer-events: none;
  }
  .grayscale {
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    filter: grayscale(1);
    pointer-events: none;
  }
  .blur-grayscale {
    -webkit-filter: blur(3px) grayscale(1);
    -moz-filter: blur(2px) grayscale(1);
    -o-filter: blur(2px) grayscale(1);
    -ms-filter: blur(2px);
    filter: blur(2px) grayscale(1);
    pointer-events: none;
  }
</style>
