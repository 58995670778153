<template>
  <form class="row" @submit.prevent="actionSave">
    <div class="row">
      <div class="col-12 mb-2">
        <h1>Accueil</h1>
        👉 Personnalisez vos choix
      </div>
    </div>
    <div class="mb-2 col-6">
      <label>Nom de l'assistant virtuel</label>
      <input
        class="form-control"
        v-model="assistantName"
        placeholder="Nom de l'assistante virtuelle"
      />
      <div
        class="input-errors"
        v-for="(error, index) of v$.assistantName.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-2  col-6">
      <label>Genre de l'assistant</label>
      <select class="form-control" v-model="male_gender" :enabled="licenceStore.chatbot_personnalisation || userStore.is_leadia_admin">>
        <option value="false">Féminin</option>
        <option value="true">Masculin</option>
      </select>
    </div>
    <div class="mb-2">
      <label>Texte d'invitation 1</label>
      <input
        class="form-control"
        v-model="widgetText"
        placeholder="Texte d'invitation 1"
      />
      <div
        class="input-errors"
        v-for="(error, index) of v$.widgetText.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-2">
      <label>Texte d'invitation 2</label>
      <input
        class="form-control"
        v-model="widgetText2"
        placeholder="Texte d'invitation 2"
      />
      <div
        class="input-errors"
        v-for="(error, index) of v$.widgetText2.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <!--div class="mb-2">
      <label>Texte d'invitation sur mobile</label>
      <div class="form-check form-check-primary form-switch">
        <input
          type="checkbox"
          v-model="widgetTextMobile"
          class="form-check-input switch"
        />
      </div>
    </div-->
    <div class="mb-2">
      <label>Politesse</label>
      <select class="form-control" v-model="polite">
        <option value="1">Au choix de l'utilisateur</option>
        <option value="2">Tutoiement</option>
        <option value="3">Vouvoiement</option>
      </select>
    </div>
    <div class="mb-2">
      <button type="submit" class="btn btn-primary">Enregistrer</button>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, helpers } from "@vuelidate/validators";
import { useAccountStore } from "@/stores/account";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";

export default {
  name: "accueil",
  data() {
    return {
      assistantName: this.accountStore.account.assistant_name,
      male_gender: this.accountStore.account.male_gender,
      widgetText: this.accountStore.account.widget_text,
      widgetText2: this.accountStore.account.widget_text_2,
      widgetTextMobile: this.accountStore.account.widget_text_mobile,
      polite: this.accountStore.account.polite,
    };
  },
  validations() {
    return {
      assistantName: {
        required: helpers.withMessage("Nom de l'assistante requis", required),
      },
      widgetText: {
        required: helpers.withMessage("Texte d'invitation requis", required),
      },
      widgetText2: {
        required: helpers.withMessage("Texte d'invitation requis", required),
      },
    };
  },
  setup() {
    const accountStore = useAccountStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return {
      v$: useVuelidate(),
      accountStore,
      licenceStore,
      userStore
    };
  },
  methods: {
    licencePopup() {
      alert("licencePopup");
    },
    async actionSave(e) {
      e.preventDefault();
      await this.v$.$validate();
        if (this.v$.$error) {
            this.$toast.open({
                message: "Un ou plusieurs champs requis ne sont pas renseignés.",
                type: "error",
                position: "top-right",
                duration: 4000,
                dismissible: true,
            });
            return;
        }
      const data = {
        assistantName: this.assistantName,
        male_gender: this.male_gender,
        widgetText: this.widgetText,
        widgetText2: this.widgetText2,
        widgetTextMobile: this.widgetTextMobile,
        polite: this.polite,
        vm: this,
      };
      await this.accountStore.updateAccount(data);
    },
  },
};
</script>

<style scoped>

  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
</style>
