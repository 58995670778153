<template>
  <div>
    <DashboardLayout>
      <div class="row mb-2">
        <div class="col-lg-12" v-if="!licenceStore.chatbot_personnalisation">
          <div class="alert alert-warning" style="padding:10px">⚠️ Actuellement, vous ne disposez pas du module 'personnalisation'. La fonction Wizz Reply est indisponible dans le chatbot.</div>
        </div>
        <div class="col-12">
          <h1>Wizz Reply Étudiants 👨‍🎓</h1>
        </div>
      </div>
      <div class="card p-2" :class="licenceStore.chatbot_personnalisation || userStore.is_leadia_admin ? '' : 'grayscale'">
        <div class="col-12">
          <h2>Remplir les questions de la FAQ Étudiants</h2>
          <div class="alert alert-success p-1">
            👉 Note: Remplissez chaque espace pour répondre aux questions de la
            FAQ. Les reponses vides ne seront pas utilisées.
          </div>
        </div>
        <div
          class="col-12 mb-3"
          v-for="(question, index) in questions"
          :key="index"
        >
          <b>Question</b>
          <div v-if="question.is_default" class="alert alert-primary p-1">
            {{ question.text }}
          </div>
          <div v-else class="alert alert-success p-1">{{ question.text }}</div>
          <b>Réponse</b>
          <VueEditor v-model="question.answer" /><br />

          <button
                  style="float: right"
                  class="btn btn-primary ms-1"
                  @click="updateQuestion(question.id, question.answer)"
          >
            Enregistrer
          </button>

          <button
            v-if="!question.is_default"
            style="float: right"
            class="btn btn-primary ms-1"
            @click="deleteQuestion(question.id)"
          >
            Supprimer
          </button>

          <div v-if="accountStore.account.beta">
            <button
                    v-bind:disabled="this.polling !== null"
                    style="float: right"
                    class="btn btn-outline-primary ms-1"
                    @click="rephraseQuestion(index, question.answer)"
            >
              <span style="margin-left:0px;margin-right:8px" v-if="this.polling && this.polling_index == index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Rephraser
              <span class="badge badge-light-beta"> BETA </span>
            </button>
            <div v-if="isChrome25Plus">
              <button
                      v-if="question.tts == null "
                      style="float: right; padding-top:5px; padding-bottom:5px"
                      class="btn btn-primary btn-green ms-1"
                      @click="startTxtToSpeech(index, question.answer)"
              >
                <vue-feather type="mic-off"></vue-feather>
              </button>

              <button
                      v-if="question.tts == 'LIVE' "
                      style="float: right; padding-top:5px; padding-bottom:5px"
                      class="btn btn-primary ms-1"
                      @click="stopTxtToSpeech()"
              >
                <vue-feather type="mic"></vue-feather>
              </button>
            </div>
            <div v-if="!isChrome25Plus">
                <button style="float: right; padding-top:5px; padding-bottom:5px"
                        class="btn btn-primary btn-green ms-1"
                >
                  <Popper class="hand"
                          :popper-placement='right'
                          :content="'Fonctionnalité disponible seulement sur Google Chrome'" hover>
                  <vue-feather type="mic-off"></vue-feather>
                  </Popper>
                </button>
            </div>
          </div>

        </div>
        <form @submit.prevent="actionCreate" class="col-12 mb-3">
          <b>Question</b>
          <input v-model="text" class="form-control" />
          <div
            class="input-errors"
            v-for="(error, index) of v$.text.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
          <br />
          <b>Réponse</b>
          <VueEditor v-model="answer" />
          <div
            class="input-errors"
            v-for="(error, index) of v$.answer.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
          <br />

          <div v-if="accountStore.account.beta">
            <button type="submit" style="float: right" class="btn btn-primary ms-1">Enregistrer</button>
            <div
                    v-if="answer != '' && accountStore.account.beta"
                    v-bind:disabled="this.polling !== null"
                    style="float: right"
                    class="btn btn-outline-primary ms-1"
                    @click="rephraseMainQuestion()"
            >

              <span style="margin-left:0px;margin-right:8px" v-if="this.polling && this.polling_index == -1" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Rephraser
              <span class="badge badge-light-beta"> BETA </span>
            </div>

            <div v-if="isChrome25Plus">
              <div
                      v-if="tts == null "
                      style="float: right; padding-top:5px; padding-bottom:5px"
                      class="btn btn-primary btn-green ms-1"
                      @click="startTxtToSpeechMain()"
              >
                <vue-feather type="mic-off"></vue-feather>
              </div>

              <div
                      v-if="tts == 'LIVE' "
                      style="float: right; padding-top:5px; padding-bottom:5px"
                      class="btn btn-primary ms-1"
                      @click="stopTxtToSpeech()"
              >
                <vue-feather type="mic"></vue-feather>
              </div>
            </div>
            <div v-if="!isChrome25Plus">
              <div style="float: right; padding-top:5px; padding-bottom:5px"
                      class="btn btn-outline-primary ms-1"
              >
                <Popper class="hand"
                        :popper-placement='right'
                        :content="'Fonctionnalité disponible seulement sur Google Chrome'" hover>
                  <vue-feather type="mic"></vue-feather>
                </Popper>
              </div>
            </div>
          </div>

        </form>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, helpers } from "@vuelidate/validators";
import DashboardLayout from "@/layouts/dashboard";
import { useSmartReplyStore } from "@/stores/smartReply";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";
import { useAccountStore } from "@/stores/account";
import { VueEditor } from "vue3-editor";
import { useChatgptStore } from "@/stores/chatgpt";
import { ref } from 'vue'

export default {
  name: "smartEdudiant",
  components: {
    DashboardLayout,
    VueEditor,
  },
  data() {
    return {
      questions: [],
      text: "",
      answer: "",
      tts: null,
      runtimeTranscription_: "",
      transcription_: [],
      lang_: "fr-FR",
      recognition_: null,
      polling: null,
      polling_index: null
    };
  },
  validations() {
    return {
      text: {
        required: helpers.withMessage("Question requise", required),
      },
      answer: {
        required: helpers.withMessage("Réponse requise", required),
      },
    };
  },
  setup() {
      const isChrome25Plus = ref(false)

      // Check if browser is Chrome 25+
      const userAgent = window.navigator.userAgent
      if (userAgent.indexOf('Chrome/') >= 0) {
          const chromeVersion = parseInt(userAgent.split('Chrome/')[1])
          if (chromeVersion >= 25) {
              isChrome25Plus.value = true
          }
      }

    const smartReplyStore = useSmartReplyStore();
    const licenceStore = useLicenceStore();
    const accountStore = useAccountStore();
    const userStore = useUserStore();
    const chatgptStore = useChatgptStore();
    return {
      v$: useVuelidate(),
      smartReplyStore,
      licenceStore,
      accountStore,
      userStore,
      chatgptStore,
      isChrome25Plus
    };
  },
  watch: {
    "smartReplyStore.questions": function (oldVal, newVal) {
      this.questions = this.smartReplyStore.questions.filter((question) => {
        return question.usage === "student";
      });
    },
  },
  async mounted() {
    this.getQuestions();
  },
  methods: {
    stopTxtToSpeech() {
          const recognition = this.recognition_;
          if (recognition && recognition instanceof window.SpeechRecognition) {
              recognition.stop();
          }
    },
    startTxtToSpeech(index, answer) {
          // initialisation of voicereco
          window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
          const recognition = new window.SpeechRecognition();
          recognition.lang = this.lang_;
          recognition.interimResults = true;
          recognition.continuous = true;
          this.questions[index].tts = "LIVE";
          this.recognition_ = recognition;

          // event current voice reco word
          recognition.addEventListener("result", event => {
              const text = Array.from(event.results)
                  .map(result => result[0])
                  .map(result => result.transcript)
                  .join("");
              this.runtimeTranscription_ = text;
              this.questions[index].answer = answer + text;
          });
          // end of transcription
          recognition.addEventListener("end", () => {
              this.questions[index].tts = null;
          });
          recognition.start();
    },
    startTxtToSpeechMain() {
          // initialisation of voicereco
          window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
          const recognition = new window.SpeechRecognition();
          recognition.lang = this.lang_;
          recognition.interimResults = true;
          recognition.continuous = true;
          this.tts = "LIVE";
          this.recognition_ = recognition;

          // event current voice reco word
          recognition.addEventListener("result", event => {
              const text = Array.from(event.results)
                  .map(result => result[0])
                  .map(result => result.transcript)
                  .join("");
              this.runtimeTranscription_ = text;
              this.answer = text;
          });
          // end of transcription
          recognition.addEventListener("end", () => {
              this.tts = null;
          });
          recognition.start();
    },
    async updateQuestion(index, answer) {
      await this.stopTxtToSpeech();
      await this.smartReplyStore.updateQuestion({
        id: index,
        answer: answer,
        vm: this,
      });
      await this.getQuestions();
    },
    pollData (index) {
          this.polling_index = index;
          this.polling = setInterval(() => {
              this.chatgptStore.getMagicResultParallelWhizz({vm: this});
              if (this.chatgptStore.parallel_whizz["content"] && this.chatgptStore.parallel_whizz["content"][this.chatgptStore.wkey]){
                  var resu = this.chatgptStore.parallel_whizz["content"][this.chatgptStore.wkey]["content"];
                  if (resu != ""){
                    if(index == -1){
                        this.answer = resu;
                    } else {
                        this.questions[index].answer = resu;
                    }
                  }

                  if (this.chatgptStore.parallel_whizz["content"][this.chatgptStore.wkey].finish_reason === "stop") {
                      clearInterval(this.polling);
                      this.polling = null;
                      this.polling_index = null;
                  }
              }
          }, 500)
    },
    async rephraseMainQuestion() {
      await this.stopTxtToSpeech();
      await this.chatgptStore.getMagicWhizzParallel({
        sentence: this.answer,
        vm: this,
      });

      this.answer = "...";
      await this.chatgptStore.getMagicResultParallelWhizz();
      this.pollData(-1);
    },
    async rephraseQuestion(index, answer) {
      await this.stopTxtToSpeech();
      await this.chatgptStore.getMagicWhizzParallel({
        sentence: answer,
        vm: this,
      });

      this.questions[index].answer = "...";
      await this.chatgptStore.getMagicResultParallelWhizz();
      this.pollData(index);
    },
    async deleteQuestion(index) {
      await this.smartReplyStore.deleteQuestion({ id: index, vm: this });
      await this.getQuestions();
    },
    async getQuestions() {
      await this.smartReplyStore.getQuestions({ vm: this });
      this.questions = this.smartReplyStore.questions.filter((question) => {
        return question.usage === "student";
      });
    },
    async actionCreate(e) {
      await this.stopTxtToSpeech();
      e.preventDefault();
      await this.v$.$validate();
        if (this.v$.$error) {
            this.$toast.open({
                message: "Un ou plusieurs champs requis ne sont pas renseignés.",
                type: "error",
                position: "top-right",
                duration: 4000,
                dismissible: true,
            });
            return;
        }
      const data = {
        text: this.text,
        answer: this.answer,
        usage: "student",
        vm: this,
      };
      await this.smartReplyStore.createQuestion(data);
      await this.getQuestions();
      this.text = "";
      this.answer = "";
      this.v$.$reset();
    },
  },
};
</script>

<style scoped>
  .btn-green {
    background-color: #28C76F !important;
    border-color: #67c0c7 !important;
    color: #FFF !important;
  }
  .badge-light-beta {
    position: absolute;
    left: 5px;
    font-size: 8px !important;
    padding: 3px 5px 3px 5px !important;
    background-color: #d8ffc6 !important;
    color: #28C76F !important;
    vertical-align:middle;
    margin-top:-1px;
  }
</style>
