<template>
  <div>
    <DashboardLayout>
      <div>

        <div class="row mb-2" >
          <div class="col-lg-12" v-if="!licenceStore.chatbot_interaction">
            <div class="alert alert-warning" style="padding:10px">⚠️ Actuellement, vous ne disposez pas du module 'intéraction'. Le chatbot n'affiche pas vos bannières..</div>
          </div>

          <div class="col-lg-8">
            <h1>Bannières</h1>
          </div>
          <div class="col-lg-4 right" :class="licenceStore.chatbot_interaction || userStore.is_leadia_admin ? '' : 'grayscale'">
            <button
              class="btn btn-primary"
              @click="$router.push('/campagne/banniere/add')"
            >
              Créer une bannière
            </button>
          </div>
        </div>
        <div class="row match-height" :class="licenceStore.chatbot_interaction || userStore.is_leadia_admin ? '' : 'grayscale'">
          <div
            class="col-md-4"
            v-if="campagneStore.bannieres && campagneStore.bannieres.length > 0"
            v-for="(banniere, index) in campagneStore.bannieres"
          >
            <Banniere :banniere="banniere" :key="index"></Banniere>
          </div>
          <div
            v-else
            :style="{ height: window.height - 300 + 'px' }"
          >

            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary">
              <div class="alert-body">
                <p><span>Vous n'avez pas encore de bannière. <br>Cliquez sur <span style="padding:5px" class="btn btn-primary btn-sm" @click="$router.push('/campagne/banniere/add')">Créer une bannière</span> pour créer votre première
              bannière.</span></p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";
import Banniere from "@/components/banniere";
import { useCampagneStore } from "@/stores/campagne";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";

export default {
  name: "banniere",
  components: {
    DashboardLayout,
    Banniere,
  },
  async mounted() {
    await this.licenceStore.getLicences({ vm: this });
    await this.campagneStore.getBannieres({ vm: this });
  },
  setup() {
    const campagneStore = useCampagneStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return {
      campagneStore,
      licenceStore,
      userStore
    };
  },
  methods: {
    licencePopup() {
        alert("X");
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 100%;
}
.msg-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.msg {
  width: 60%;
  background-color: white;
  text-align: center;
  padding: 30px;
}
.plus {
  background-color: #21ae89;
  color: #fff;
  padding: 1px 5px;
  cursor: pointer;
}
</style>
