import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { useLoaderStore } from "@/stores/loader";

export const useProgramStore = defineStore("program", {
  state: () => ({
    courses: [],
    course: {},
    sections: [],
    domains: [],
  }),

  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },

  actions: {
    async getCourses(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "courses.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.courses = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async getCourse(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "courses/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .get(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.course = response.data.data;
      }
      await loaderStore.setLoader(false);
    },
      async createCourses(data: any) {
          const url =
              process.env.VUE_APP_ROOT_API +
              "courses/bulk.json?account_uuid=" +
              this.accountGetter.uuid;
          const response = await axios
              .post(url, data, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });
          //await loaderStore.setLoader(false);
          if (response) {
              this.course = response.data.data;
              return response.data.data;
          }
      },
    async createCourse(data: any) {
      //const loaderStore = useLoaderStore();
      //await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "courses.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {};
      if (data.id) dataToSend["id"] = data.id;
      if (data.admission) dataToSend["admission"] = data.admission;
      if (data.category) dataToSend["category"] = data.category;
      if (data.name) dataToSend["name"] = data.name;
      if (data.url) dataToSend["url"] = data.url;
      console.log(dataToSend);
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      //await loaderStore.setLoader(false);
      if (response) {
        this.course = response.data.data;
        return response.data.data;
      }
    },
      async updateCourse(data: any) {
          const loaderStore = useLoaderStore();
          await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "courses/" +
              data.id +
              ".json?account_uuid=" +
              this.accountGetter.uuid;
          const dataToSend = {};
          if (data.id) dataToSend["id"] = data.id;
          if (data.admission) dataToSend["admission"] = data.admission;
          if (data.category) dataToSend["category"] = data.category;
          if (data.name) dataToSend["name"] = data.name;
          if (data.url) dataToSend["url"] = data.url;
          console.log(dataToSend);
          const response = await axios
              .put(url, dataToSend, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });
          await loaderStore.setLoader(false);
          if (response) {
              this.course = response.data.data;
              return response.data.data;
          }
      },
    async duplicateCourse(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "courses/" +
        data.id +
        "/duplicate.json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .get(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.course = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async deleteCourse(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "courses/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .delete(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.course = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async getSections(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "sections.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        this.sections = response.data;
        return response.data;
      }
    },
    async getDomains(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "domains.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.domains = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async createDomain(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "domains.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {};
      if (data.id) dataToSend["id"] = data.id;
      if (data.admission) dataToSend["admission"] = data.admission;
      if (data.category) dataToSend["category"] = data.category;
      if (data.name) dataToSend["name"] = data.name;
      if (data.url) dataToSend["url"] = data.url;
      console.log(dataToSend);
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        return response.data.data;
      }
    },
    async joinCourseDomain(data: any) {
      //const loaderStore = useLoaderStore();
      //await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "course_domains.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {
        course_id: data.course_id,
        domain_id: data.domain_id,
      };
      console.log(dataToSend);
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
        });
      //await loaderStore.setLoader(false);
      if (response) {
        return response.data.data;
      }
    },
    async deleteCourseDomain(data: any) {
          //const loaderStore = useLoaderStore();
          //await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "course_domains/" +
              data.id +
              ".json?account_uuid=" +
              this.accountGetter.uuid;
          const response = await axios
              .delete(url, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });
          //await loaderStore.setLoader(false);
    },
    async joinSectionCourses(data: any) {
      //const loaderStore = useLoaderStore();
      //await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "section_courses.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {
        course_id: data.course_id,
        section_id: data.section_id,
      };
      console.log(dataToSend);
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
        });
      //await loaderStore.setLoader(false);
      if (response) {
        return response.data.data;
      }
    },
    async deleteSectionCourse(data: any) {
          //const loaderStore = useLoaderStore();
          //await loaderStore.setLoader(true);
          const url =
              process.env.VUE_APP_ROOT_API +
              "section_courses/" +
              data.id +
              ".json?account_uuid=" +
              this.accountGetter.uuid;
          const response = await axios
              .delete(url, {
                  headers: {
                      "token-type": "Bearer",
                      uid: this.authGetter.uid,
                      client: this.authGetter.client,
                      "access-token": this.authGetter.token,
                  },
              })
              .catch(function (error) {
                  console.log(error);
                  data.vm.$toast.open({
                      message: "Une erreur est survenue lors de la requete",
                      type: "error",
                      position: "top-right",
                      duration: 4000,
                      dismissible: true,
                  });
              });
          //await loaderStore.setLoader(false);
    },
    async createSection(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "sections.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {
        account_uuid: this.accountGetter.uuid,
      };
      if (data.name) dataToSend["name"] = data.name;
      if (data.display_order) dataToSend["display_order"] = data.display_order;
      if (data.region) dataToSend["region"] = data.region;
      if (data.email_list)
        dataToSend["email_list"] = data.email_list;
      if (data.email_list_foreign)
        dataToSend["email_list_foreign"] = data.email_list_foreign;
      if (data.email_list_pro)
        dataToSend["email_list_pro"] = data.email_list_pro;
      if (data.email_list_entreprise)
        dataToSend["email_list_entreprise"] = data.email_list_entreprise;
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        return response.data.data;
      }
    },
    async deleteSection(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "sections/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .delete(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.course = response.data;
      }
      await loaderStore.setLoader(false);
    },
  },
  persist: {
    enabled: true,
  },
});
