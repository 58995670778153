import moment from "moment";

export default {
  data() {
    return {
      image: "",
      avatar: "",
      file: null,
      file_avatar: null,
      colors: [
        {
          variant: "primary",
          color: "#7367f0",
          light: "#eeedfd",
          statut: "Étudiant",
          statutUser: "",
          category: "",
        },
        {
          variant: "warning",
          color: "#ea5455",
          light: "#fff3e8",
          statut: "Sans emploi",
          statutUser: "En attente",
          category: "Tout",
        },
        {
          variant: "success",
          color: "#ff9f43",
          light: "#e5f8ed",
          statut: "Salarié en activité",
          statutUser: "Actif",
          category: "Diplomante",
        },
        {
          variant: "danger",
          color: "#43e7b0",
          light: "#fceaea",
          statut: "Parent",
          statutUser: "",
          category: "",
        },
        {
          variant: "info",
          color: "#14e0f9",
          light: "#e0f9fc",
          statut: "Entreprise",
          statutUser: "",
          category: "continu / pro",
        },
        {
          variant: "secondary",
          color: "#82868b",
          light: "#edeeef",
          statut: "",
          statutUser: "Désactivé",
          category: "",
        },
      ],
    };
  },
  methods: {
    notEmptyObject(someObject) {
      return Object.keys(someObject).length;
    },
    formatPhone(value) {
      if (!value) return "";
      return value.replace(
        /^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
        "$1&nbsp;$2&nbsp;$3&nbsp;$4&nbsp;$5"
      );
    },
    formatDate(value, format = "LLL") {
      return moment(value).locale("fr").format(format);
    },
    formatFromNow(value) {
      return moment(value).locale("fr").fromNow();
    },
    formatScore(score, rounded = "") {
      let variant = "danger";
      if (score <= 100 && score > 75) {
        variant = "success";
      } else if (score <= 75 && score > 20) {
        variant = "warning";
      }
      return `<div class="avatar bg-${variant} ${rounded}">
                 <div class="avatar-content">
                     ${score}
                 </div>
             </div>`;
    },
    classColor(classe, statut) {
      const result = this.colors.filter((x) => {
        return x.statut === statut;
      });
      return classe + "-" + result[0].variant;
    },
    classColorStatut(classe, statut) {
      console.log(classe);
      console.log(statut);
      const result = this.colors.filter((x) => {
        return x.statutUser === statut;
      });
      if (result.length > 0) {
        return classe + "-" + result[0].variant;
      }
    },
    getColor(score) {
      let variant = "danger";
      if (score <= 100 && score > 75) {
        variant = "success";
      } else if (score <= 75 && score > 20) {
        variant = "warning";
      }
      return "bg-light-" + variant;
    },
    uploadFile(e) {
      this.file = e.target.files[0];
      const reader = new FileReader();
      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.image = rawImg;
      };
      reader.readAsDataURL(this.file);
    },
    dragFile(e) {
      this.file = e.dataTransfer.files[0];
      const reader = new FileReader();
      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.image = rawImg;
      };
      reader.readAsDataURL(this.file);
    },
    formatPosition(position) {
      if (position === "TOP") {
        return "Debut";
      } else if (position === "MIDDLE") {
        return "Milieu";
      } else if (position === "BOTTOM") {
        return "Fin";
      }
      return "";
    },
    FormatShortString(value) {
      if (value) {
        return value.split('', 50).reduce((o, c) => o.length === 49 ? `${o}${c}...` : `${o}${c}` , '');
      }
    },
    formatRole(role) {
      if (role === "Utilisateur") {
        return (
          '<img src="' +
          require("@/assets/images/ico-utilisateur.svg") +
          '"> Utilisateur'
        );
      } else if (role === "Contributeur") {
        return (
          '<img src="' +
          require("@/assets/images/ico-contributeur.svg") +
          '"> Contributeur'
        );
      } else if (role === "Admin") {
        return (
          '<img src="' + require("@/assets/images/ico-admin.svg") + '"> Admin'
        );
      }
    },
    formatCategory(cat) {
      console.log(cat)
      if(cat === 'initial') return 'badge-light-success'
      else if(cat === 'professional') return 'badge-light-info'
      else return 'badge-light-warning'
    },
    notification() {
      if (this.$route.query.pay && this.$route.query.pay === "success") {
        this.$toast.open({
          message: "Paiement effectué avec succès",
          type: "success",
          position: "top-right",
          duration: 10000,
          dismissible: true,
        });
      }
      if (this.$route.query.pay && this.$route.query.pay === "error") {
        this.$toast.open({
          message: "Votre paiement a échoué, vous n'avez pas été débité.",
          type: "error",
          position: "top-right",
          duration: 10000,
          dismissible: true,
        });
      }
      if (this.$route.query.user && this.$route.query.user === "validated") {
            this.$toast.open({
                message: "Votre compte est validé. Vos accès sont dans votre boite email",
                type: "success",
                position: "top-right",
                duration: 20000,
                dismissible: false,
            });
      }
    }
  },
};
