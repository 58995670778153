<template>
  <div>

    <div class="col-12 mb-3 mt-2">
      <h1>1. Je choisis ma campagne : {{smsTemplateStore.sms_template.name}}</h1>
    </div>

    <div class="row" style="margin-bottom:60px">
      <div class="row">
        <div class="col-6">

          <div class="row template-config">

            <div class="col-lg-12 right">
              <button class="btn btn-sm btn-outline-primary waves-effect waves-float waves-light clear-btn" @click="setNextSample()"> 💡 Idée </button>
            </div>

            <div class="col-12 mb-2">
              <label>Description du SMS</label>
              <div @keyup="onPressEnter()" @click="onPressEnter()" ref="sms" class="smsContent" spellcheck="false" @input="setCounters()"
                   contenteditable v-html="getContentEditable(sms_sample.replace(/(?:\r\n|\r|\n)/g, '<br>'))"></div>

              <button class="btn btn-sm btn-outline-primary waves-effect waves-float waves-light clear-btn" @click="clear()">️🗑 Supprimer </button>

              <span style="float:right;margin-top:5px">
                {{ sms_count + "/" + sms_counter_limit }}
              </span>
            </div>

            <div class="col-12 mb-1">
              <label>Mots clés</label>

              <input class="form-control" type="text" v-model.trim="tag" @keypress.prevent.stop.enter="addTag">

              <ul class="tags">
                <li class="tag" v-for="(tag, index) in tags" :key="index">
                  <span>{{ tag }}</span>
                  <span @click="tags.splice(index, 1)">  x</span>
                </li>
              </ul>
            </div>

            <div class="col-12 mb-1">
              Ajouter:
              <span class="badge-hand badge-btn badge badge-light-success" @click="addSchoolToContent()">Ecole</span>
              <span class="badge-hand badge-btn badge badge-light-success" @click="addLinkToContent()">Lien</span>
              <span class="badge-hand badge-btn badge badge-light-success" @click="addFirstnameToContent()">Prénom</span>

            </div>

          </div>

          <div class="row">
            <div class="col-lg-12 right">
              <select v-model="tone" style="width:150px;height:36px;margin-right:5px">
                <option value='Formel'>Formel</option>
                <option value='Amical'>Amical</option>
                <option value='Humoristique'>Humoristique</option>
              </select>

              <select v-model="counter" style="width:50px;height:36px;margin-right:5px">
                <option value=1>1</option>
                <option value=2>2</option>
                <option value=3>3</option>
                <option value=4>4</option>
                <option value=5>5</option>
                <option value=6>6</option>
              </select>

              <button class="btn btn-primary" style="margin-top:-2px" :disabled="this.polling" @click="addTemplates()">
                <span style="margin-left:0px;margin-right:8px" v-if="this.polling" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Rephraser
              </button>
            </div>
          </div>

        </div>
        <div class="col-6" v-if="this.templates.length == 0">
          <img class="center magic-hat" src="@/assets/images/magic-hat.png" alt="Lead-ia" />
          <p class="center magic-hat-sub">Appréciez la magie de l'IA !</p>
        </div>

        <div class="template-result col-6" v-if="this.templates.length != 0 && this.sms_sample !='' " >

          <div class="item-template" @click="setDescription($router)" >
            Ma description:
            <p style="margin-top:5px" v-html="getContentEditable(sms_sample.replace(/(?:\r\n|\r|\n)/g, '<br>'))"></p>
          </div>

          <div class="item-template" @click="setTemplate($router, sample)" v-for="(sample, index) in templates">
            Modèle {{index + 1}}:
            <p style="margin-top:5px" v-html="getContentEditable(sample.replace(/(?:\r\n|\r|\n)/g, '<br>'))"></p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
    import DashboardLayout from "@/layouts/dashboard";
    import { useChatgptStore } from "@/stores/chatgpt";
    import { useAccountStore } from "@/stores/account";
    import { useSmsTemplateStore } from "@/stores/smsTemplate";
    import useVuelidate from "@vuelidate/core";
    import moment from "moment";
    import VueRouter from 'vue-router'
    import Multiselect from "vue-multiselect";
    import Vue3TagsInput from 'vue3-tags-input';
    import { ref } from 'vue'

    export default {
        name: "SmsTemplateMagic",
        components: {
            DashboardLayout,
            Vue3TagsInput,
            Multiselect
        },
        data() {
            return {
                tag: '',
                tags: this.value || [],
                templates: [],
                sms_samples:[],
                sms_samples_count: 1,
                sms_sample: "",
                counter: 3,
                tone: 'Amical',
                sms_count: 0,
                sms_counter_limit: 800,
                generateActive: true,
                caret_position: 0,
                polling: null
            };
        },
        async mounted() {
            if(this.$route.name == "TemplateMagicReload") {
              this.templates = this.chatgptStore.templates;
            }
            await this.getTemplate(this.$route.name != "TemplateMagicReload");
            await this.setCounters();
        },
        setup() {
            const isChrome25Plus = ref(false)

            // Check if browser is Chrome 25+
            const userAgent = window.navigator.userAgent
            if (userAgent.indexOf('Chrome/') >= 0) {
                const chromeVersion = parseInt(userAgent.split('Chrome/')[1])
                if (chromeVersion >= 25) {
                    isChrome25Plus.value = true
                }
            }
            const chatgptStore = useChatgptStore();
            const accountStore = useAccountStore();
            const smsTemplateStore = useSmsTemplateStore();
            return {
                chatgptStore,
                accountStore,
                smsTemplateStore,
                isChrome25Plus
            };
        },
        beforeDestroy () {
            clearInterval(this.polling);
            this.polling = null;
        },
        methods: {
            async setCounters() {
                this.sms_count = this.getSmsCount();

                if(this.sms_count < 1){
                    this.generateActive = false;
                } else {
                    this.generateActive = true;
                }
            },
            countOccurrences(originalString, substring) {
                const escapedSubstring = substring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                const regex = new RegExp(escapedSubstring, 'g');
                const occurrences = originalString.split(regex).length - 1;
                return occurrences;
            },
            getCaretIndex(element) {
                let position = 0;
                const isSupported = typeof window.getSelection !== "undefined";
                if (isSupported) {
                    const selection = window.getSelection();
                    // Check if there is a selection (i.e. cursor in place)
                    if (selection.rangeCount !== 0) {
                        // Store the original range
                        const range = window.getSelection().getRangeAt(0);
                        // Clone the range
                        const preCaretRange = range.cloneRange();
                        // Select all textual contents from the contenteditable element
                        preCaretRange.selectNodeContents(element);
                        // And set the range end to the original clicked position
                        preCaretRange.setEnd(range.endContainer, range.endOffset);
                        // Return the text length from contenteditable start to the range end
                        position = preCaretRange.toString().length;
                    }
                }
                return position;
            },
            getSmsCount() {
                return this.getSmsEditedContent().length;
            },
            getSmsEditedContent() {
                var resu = "";
                if (this.$refs["sms"]) resu = this.$refs["sms"].textContent;
                return resu;
            },
            onPressEnter() {
                this.caret_position = parseInt(this.getCaretIndex(this.$refs["sms"]));
            },
            async addElementToContent(html) {
                var v = this.revertContentEditable(this.$refs["sms"].innerHTML);
                this.caret_position = this.caret_position + this.countOccurrences(v.slice(0, this.caret_position + (this.caret_position > 6 ? 6 : 0)), '{{') * 4;
                this.caret_position = this.caret_position + 2*(v.slice(0, this.caret_position).split("\r").length - 1);

                if(v[this.caret_position] == "{" ){ this.caret_position = this.caret_position - 1; }
                if(v[this.caret_position] == "{" ){ this.caret_position = this.caret_position - 1; }
                if(v[this.caret_position] == "}" ){ this.caret_position = this.caret_position + 1; }
                if(v[this.caret_position] == "}" ){ this.caret_position = this.caret_position + 1; }
                if (this.caret_position < 0 ) this.caret_position = 0;

                this.sms_sample = v.slice(0, this.caret_position) + html + v.slice(this.caret_position);
            },
            async addLinkToContent() {
                this.addElementToContent('{{lien}}')
            },
            async addFirstnameToContent() {
                this.addElementToContent('{{prénom}}')
            },
            async addSchoolToContent() {
                this.addElementToContent('{{école}}')
            },
            async getTemplate(reload) {
                var id = this.$route.params.id;
                if(parseInt(id) > 3) { id = 0; }
                if (reload) {
                  await this.smsTemplateStore.getSmsTemplate({
                      id: id,
                      vm: this,
                  });
                }
                this.sms_template = this.smsTemplateStore.sms_template;
                this.sms_samples = this.sms_template.sms_samples;

                if (reload && this.sms_samples.length > 0) {
                    this.setSample(this.sms_samples[(Math.random() * this.sms_samples.length) | 0]);
                }
                if (!reload && this.chatgptStore.description) {
                    this.sms_sample = this.chatgptStore.description;
                }
            },
            pollData () {
                this.polling = setInterval(() => {
                    this.chatgptStore.getMagicResultParallel();
                    this.templates = Object.values(this.chatgptStore.parallel_template["content"]).map(obj => obj.content);
                    while (this.templates.length < this.counter) {
                        this.templates.push("");
                    }

                    if(Object.values(this.chatgptStore.parallel_template["content"]).length == this.counter && Object.values(this.chatgptStore.parallel_template["content"]).every(obj => obj.finish_reason === "stop")) {
                        clearInterval(this.polling);
                        this.polling = null;
                    }
                }, 600)
            },
            async getTemplates() {
                this.$refs["sms"].focus();
                var description = this.revertContentEditable(this.$refs["sms"].innerHTML).replace(/{{école}}/g, this.accountStore.account.name);
                var keys = this.tags.join(",");
                var school = this.accountStore.account.name;
                var counter = this.counter;
                var tone = this.tone;
                var headline = '';

                this.templates = Array(counter).fill("");
                this.sms_sample = description;

                await this.chatgptStore.getMagicTemplateParallel({
                    description: description,
                    school: school,
                    keys: keys,
                    counter: counter,
                    tone: tone,
                    headline: headline,
                    vm: this,
                });
                await this.chatgptStore.getMagicResultParallel();
                this.pollData();
            },
            setSample(sample) {
                var body = sample.body;
                body = body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                /*body = body.replace(/{{prénom}}/g, "Arnaud");*/
                /*body = body.replace(/{{école}}/g, this.accountStore.account.name);*/
                /*body = body.replace(/{{lien}}/g, "https://bit.ly/3IP53Ax");*/
                /*body = body.replace(/{{assistant}}/g, this.accountStore.account.assistant_name);
                body = body.replace(/\[heure\]/g, "8h00");
                body = body.replace(/\[date\]/g, moment().format("DD/MM/YYYY"));*/
                this.sms_sample = body;
            },
            getContentEditable(content) {
                if(this.isChrome25Plus) {
                    content = content.replaceAll("{{prénom}}", '<span contenteditable="false" class="badge-hand badge badge-light-success">Prénom</span>');
                    content = content.replaceAll("{{lien}}", '<span contenteditable="false" class="badge-hand badge badge-light-success">Lien</span>');
                    content = content.replaceAll("{{école}}", '<span contenteditable="false" class="badge-hand badge badge-light-success">Ecole</span>');
                } else {
                    content = content.replaceAll("{{prénom}}", '<span class="badge-hand badge badge-light-success">Prénom</span>');
                    content = content.replaceAll("{{lien}}", '<span class="badge-hand badge badge-light-success">Lien</span>');
                    content = content.replaceAll("{{école}}", '<span class="badge-hand badge badge-light-success">Ecole</span>');
                }
                content = content.replaceAll("{{", '').replaceAll("}}", '');
                content = content.replaceAll("<div>", '').replaceAll("</div>", '');
                content = content.replace(/(?:\r\n|\r|\n)/g, '<br>');
                content = content + '<span> </span>';
                return content;
            },
            revertContentEditable(content) {
                if(this.isChrome25Plus) {
                    content = content.replaceAll('<span contenteditable="false" class="badge-hand badge badge-light-success">Prénom</span>', "{{prénom}}");
                    ;
                    content = content.replaceAll('<span contenteditable="false" class="badge-hand badge badge-light-success">Lien</span>', "{{lien}}");
                    content = content.replaceAll('<span contenteditable="false" class="badge-hand badge badge-light-success">Ecole</span>', "{{école}}");
                }else{
                    content = content.replaceAll('<span class="badge-hand badge badge-light-success">Prénom</span>', "{{prénom}}");;
                    content = content.replaceAll('<span class="badge-hand badge badge-light-success">Lien</span>', "{{lien}}");
                    content = content.replaceAll('<span class="badge-hand badge badge-light-success">Ecole</span>', "{{école}}");
                }
                content = content.replaceAll('<span>', "").replaceAll('</span>', "");
                content = content.replaceAll('<div>', "").replaceAll('</div>', "");
                content = content.replaceAll('<br>', "\r");
                return content;
            },
            addTemplates() {
                this.getTemplates();
            },
            getLinkSample(item){
                return "/campagne/sms/template/";
            },
            /*handleChangeTag(tags) {
                this.tags = tags;
            },*/
            addTag() {
                if (this.tag && ! this.tags.includes(this.tag)) {
                    this.tags.push(this.tag);
                    this.tag = '';
                }
            },
            setNextSample() {
                if(this.sms_samples_count >= this.sms_samples.length ){
                    this.sms_samples_count = 0;
                }
                this.setSample(this.sms_samples[this.sms_samples_count]);
                this.sms_samples_count = this.sms_samples_count + 1;
                this.chatgptStore.description = this.sms_sample;
            },
            clear() {
                /*this.tags = [];*/
                this.templates = [];
                /*this.sms_samples = [];*/
                this.sms_sample = "";
                this.sms_count = 0;
                this.$refs["sms"].textContent = "";
            },
            setDescription($router) {
                this.chatgptStore.sample = this.sms_sample;
                $router.push("/campagne/sms/template/"+ this.$route.params.id +"/sample/" + this.sms_samples[0].id + "/settings/-1");
            },
            setTemplate($router, sample) {
                this.chatgptStore.sample = sample;
                $router.push("/campagne/sms/template/"+ this.$route.params.id +"/sample/" + this.sms_samples[0].id + "/settings/-1");
            },
            clickHandlerAdd: function (e, liste) {
                e.target.classList.remove("error");
                this.$emit("addTag", {
                    id: this.element.id,
                    value: this.newTag[liste],
                    liste: liste,
                });
                this.newTag[liste] = "";
            },
        },
    };
</script>

<style scoped>
 .magic-hat {
   height:120px;
   display: block;
   margin-left: auto;
   margin-right: auto;
   margin-top:160px;
 }
 .magic-hat-sub {
   margin-top:10px;
   font-size: 1.3em;
   margin-bottom:200px;
 }
 .smsContent {
   border-radius: .357rem;
   border: 1px solid #D8D6DE;
   padding:12px;
   min-height:150px;
   background-color: #FFF !important;
 }
 .template-config {
   border: 1px solid #D8D6DE;
   background-color: #ecf4fb !important;
   padding: 15px 5px 15px 5px;
   margin-bottom:10px;
 }
 .template-config label {
   font-weight: bold;
 }
 .item-template {
   border: 1px solid #D8D6DE;
   padding:10px;
   margin:10px 0 14px 0;
 }
 .item-template:hover {
   border: 1px solid #a4a2aa;
   background-color: rgba(189, 187, 195, 0.09) !important;
   cursor: pointer;
 }
 .clear-btn {
   margin-top:5px;
   background-color: #FFF !important;
 }
  .template-result {
    margin-top:-10px;
    height:450px;
    overflow:auto;
  }
 .tag {
   background: rgba(115, 103, 240, 0.12) !important;
   padding: 2px 4px 2px 4px;
   margin: 8px 4px 0px 0px;
   font-size: 0.9em;
   border-radius: 4px;
   color: #7367f0;
   white-space: nowrap;
   transition: 0.1s ease background;
   list-style-type: none;
   cursor: pointer;
 }
 .tags {
   display: flex;
   flex-wrap: wrap;
   padding: 0;
 }
 .badge-hand {
   cursor: pointer !important;
   margin-right: 3px;
 }
</style>
