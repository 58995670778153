<template>
  <div class="card">
    <div class="row">
      <div class="col-12">
        <div class="card-body pb-0" style="position: relative">
          <div v-if="!chartData.labels || chartData.labels.length === 0">
            Pas de données disponibles
          </div>
          <BubbleChart
                  v-else
                  :chartData="chartData"
                  :options="options"
                  width="100%"
                  height="350"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Card from "@/components/dashboard/card";
    import { computed, defineComponent, ref } from "vue";
    import { BubbleChart } from "vue-chart-3";
    import { Chart, registerables } from "chart.js";
    import 'chartjs-adapter-date-fns';
    import { useDashboardStore } from "@/stores/dashboard";
    import moment from "moment";

    Chart.register(...registerables);
    export default defineComponent({
        name: "App",
        components: { BubbleChart, Card },
        data() {

            const footer = (tooltipItems) => {
                return "";
            };

            return {
                chartData: {},

                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'Répartition journalière des ouvertures'
                        },
                        tooltip: {
                            mode: 'index',
                            callbacks: {
                                footer: footer,
                                label: function(context) {
                                    var label = context.dataset.label || '';


                                    if (label) {

                                        var v = context.parsed._custom * 2;
                                        if(v>0) v = v - 8;
                                        label += " : "+Math.round(v) +"% des ouvertures";
                                    }
                                    return label;
                                }
                            }
                        },
                    },
                    scales: {
                        x: {
                            type: 'time',
                            autoSkip: false,
                            time: {
                                unit: 'hour',
                                parser: 'HH',
                                displayFormats: {hour: 'HH:mm'}
                            },
                            scaleLabel: {
                                display: true
                            },
                        },
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1
                            }
                        }
                    }
                }
            };
        },
        setup() {
            const dashboardStore = useDashboardStore();
            return { dashboardStore };
        },
        watch: {
            "dashboardStore.sms_daily": function (newCore, oldCore) {
                this.getData();
            },
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                if(JSON.stringify(this.dashboardStore.sms_daily) == '{}'){
                    return;
                }

                var resu = {};
                resu["Étudiant"] = 0;
                resu["Sans emploi"] = 0;
                resu["Salarié en activité"] = 0;
                resu["Parent"] = 0;

                var clicked = this.dashboardStore.sms_daily["clicked"];
                var total = 0;
                var biggest = 0;
                var arr = [];
                var data = {};
                Object.keys(clicked).forEach(function (k) {
                    Object.keys(clicked[k]).forEach(function (key) {
                        resu[k] = resu[k] + clicked[k][key];
                        total = total + clicked[k][key];
                        if(clicked[k][key] > 0) arr.push(key);
                        if(clicked[k][key] > biggest) biggest = clicked[k][key];
                    });
                });


                var biggest_rate = biggest * 100 / total;

                Object.keys(clicked).forEach(function (k) {
                    Object.keys(clicked[k]).forEach(function (key) {
                        if(data[k+"_"+key] == null) data[k+"_"+key] = {};

                        data[k+"_"+key]["clicked"] = clicked[k][key];
                        data[k+"_"+key]["ratio"] = 0;

                        if(data[k+"_"+key]["clicked"])
                        data[k+"_"+key]["ratio"] = 4 + data[k+"_"+key]["clicked"] * 50 / resu[k];
                    });
                });

                var ymax = 0;
                var data1 = [];
                var data2 = [];
                var data3 = [];
                var data4 = [];

                let i = 0;
                while (i < arr.length) {
                    if (data["Étudiant_"+arr[i]]){
                        data1.push({ x: new Date("2023-01-01 "+ arr[i] +":30"), y: data["Étudiant_"+arr[i]]['clicked'], r: data["Étudiant_"+arr[i]]['ratio'] });
                        if (ymax < data["Étudiant_"+arr[i]]['clicked']){
                          ymax = data["Étudiant_"+arr[i]]['clicked']
                        }
                    }

                    if (data["Sans emploi_"+arr[i]]){
                        data2.push({ x: new Date("2023-01-01 "+ arr[i] +":30"), y: data["Sans emploi_"+arr[i]]['clicked'], r: data["Sans emploi_"+arr[i]]['ratio'] });
                        if (ymax < data["Sans emploi_"+arr[i]]['clicked']){
                            ymax = data["Sans emploi_"+arr[i]]['clicked']
                        }
                    }

                    if (data["Salarié en activité_"+arr[i]]){
                        data3.push({ x: new Date("2023-01-01 "+ arr[i] +":30"), y: data["Salarié en activité_"+arr[i]]['clicked'], r: data["Salarié en activité_"+arr[i]]['ratio'] });
                        if (ymax < data["Salarié en activité_"+arr[i]]['clicked']){
                            ymax = data["Salarié en activité_"+arr[i]]['clicked']
                        }
                    }

                    if (data["Parent_"+arr[i]]){
                        data4.push({ x: new Date("2023-01-01 "+ arr[i] +":30"), y: data["Parent_"+arr[i]]['clicked'], r: data["Parent_"+arr[i]]['ratio'] });
                        if (ymax < data["Parent_"+arr[i]]['clicked']){
                            ymax = data["Parent_"+arr[i]]['clicked']
                        }
                    }

                    i++;
                }

                data1.push({ x: new Date("2023-01-01 21:00"), y: ymax*1.1, r: 0 });
                data2.push({ x: new Date("2023-01-01 21:00"), y: ymax*1.1, r: 0 });
                data3.push({ x: new Date("2023-01-01 21:00"), y: ymax*1.1, r: 0 });
                data4.push({ x: new Date("2023-01-01 21:00"), y: ymax*1.1, r: 0 });

                this.chartData = {
                    labels: [new Date("2023-01-01 08:00"),
                        new Date("2023-01-01 09:00"),
                        new Date("2023-01-01 10:00"),
                        new Date("2023-01-01 11:00"),
                        new Date("2023-01-01 12:00"),
                        new Date("2023-01-01 13:00"),
                        new Date("2023-01-01 14:00"),
                        new Date("2023-01-01 15:00"),
                        new Date("2023-01-01 16:00"),
                        new Date("2023-01-01 17:00"),
                        new Date("2023-01-01 18:00"),
                        new Date("2023-01-01 19:00"),
                        new Date("2023-01-01 20:00"),
                        new Date("2023-01-01 21:00")],

                    datasets: [{
                            label: ['Étudiant'],
                            data: data1,
                            backgroundColor: "#7367f0"
                        },
                        {
                            label: ['Sans emploi'],
                            data: data2,
                            backgroundColor: "#ea5455"
                        },
                        {
                            label: ['Salarié en activité'],
                            data: data3,
                            backgroundColor: "#ff9f43"
                        },
                        {
                            label: ['Parent'],
                            data: data4,
                            backgroundColor: "#43e7b0"
                        }]
                };



            },
        },
    });
</script>

<style scoped>
  .row {
    min-height: 400px;
  }
  .card {
    margin-bottom: 0;
  }
</style>
