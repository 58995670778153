<template>

  <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          ref="validationModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
                  type="button"
                  class="btn-close"
                  data-mdb-dismiss="modal"
                  aria-label="Close"
                  @click="modalValidation.hide()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <h1 style="font-size: 30px; margin-top:30px">Détail du prix</h1>
          </div>

          <table class="checkout-details">
            <tr>
              <th class="checkout-details-padding-sm" style="padding-bottom:30px">Abonnement annuel SMS 'Starter'</th>
              <th class="checkout-details-padding-sm" style="text-align:right;">{{ this.productStore.starter_sms.price / 100}} € H.T / an
                <div class="text-primary" style="font-size: 12px">(Coût mensuel x 12)</div></th>
            </tr>
            <tr>
              <th class="checkout-details-padding-mi">{{sms_credits_counter}} crédits supplémentaires</th>
              <th class="checkout-details-padding-mi" style="text-align:right;">{{ this.current_sms_credits.price / 100}} € H.T</th>
            </tr>
            <tr>
              <td class="checkout-details-padding-lg checkout-details-color">Total à payer:</td>
              <td style="text-align:right;" class="checkout-details-padding-lg checkout-details-color text-primary">{{ this.selectionTotal() }}  € H.T</td>
            </tr>
          </table>

        </div>
      </div>
    </div>
  </div>
  <div>
    <DashboardLayout>
      <div class="card">

        <div class="content-body">

          <div v-if="!licenceStore.sms">
            <div class="alert alert-danger" style="padding: 10px;">
              ⚠️ Actuellement, vous ne disposez pas de licence 'sms' ou celle-ci a déjà expiré.
            </div>
          </div>

          <div v-if="licenceStore.sms && licenceStore.sms_statut_type == 'trial' ">
            <div class="alert alert-success" style="padding:10px;">
              Votre licence 'sms' est en période d’essai juqu'au {{licenceStore.sms_end}}.<br>
              Vous pouvez dès à présent souscrire à un abonnement.
            </div>
          </div>

          <div v-if="licenceStore.sms && licenceStore.sms_statut_type == 'paid' && !enableCreditsOnly()">
            <div class="alert alert-success" style="padding:10px;">
              Votre licence 'sms' est valide juqu'au {{licenceStore.sms_end}}.<br>
              Vous pouvez dès à présent renouveler votre licence.
            </div>
          </div>

          <section id="pricing-plan">

            <div class="text-center mt-4" style="font-size: 18px">
              <h1 style="font-size: 40px">Magic SMS</h1>
              Découvrez notre nouvelle solution pour transformer vos leads en clients
            </div>

            <div v-if="enableCreditsOnly()" class="pricing-table mt-4" >
              <div class="row" id="table-contextual">

                <div class="col-sm-1"></div>
                <div class="col-sm-5 sms_pricing_border">
                  <div class="border_shadow">
                    <div class="card-body">
                      <div class="d-flex2 mb-0">
                        <div class="text-center">
                          <div class="card-body">

                            <img src="@/assets/images/sms.png" height="50" />
                            <h3 class="mt-1">Starter</h3>
                            <p class="card-text">Un outil essentiel pour convertir vos leads</p>
                            <p class="card-text" style="font-size: 25px">{{numberWithCommas(productStore.starter_sms.price / 12)}}€ H.T / mois</p>
                            <p class="card-text">Facturé annuellement {{numberWithCommas(productStore.starter_sms.price)}}€ H.T / an</p>

                              <h2 class="mt-2">Description de l'offre:</h2>
                              <ul class="features mt-1 mx-auto" style="width:fit-content">
                                  <li style="text-align:left!important">Modèles SMS personnalisés</li>
                                  <li style="text-align:left!important">Campagnes personnalisées</li>
                                  <li style="text-align:left!important">Analytics de vos campagnes</li>
                              </ul>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-5 ">
                  <div class="border_shadow">
                    <div class="card-body">
                      <div class="d-flex2 mb-0">
                        <div class="text-center">
                          <div class="card-body">

                            <h3 class="mt-1">Acheter des crédits supplémentaires</h3>
                            <p class="card-text">Crédits restants</p>
                            <p class="card-text sms_remaining_credits">{{getSmsCredit()}}</p>

                            <select v-model="sms_credits_counter" class="form-select form-select-lg" style="width:200px">
                              <option value="100">100</option>
                              <option value="250">250</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                              <option value="2500">2500</option>
                              <option value="5000">5000</option>
                            </select>

                            <p class="card-text mt-1">{{sms_credits_counter}} Crédits ajoutés</p>

                            <span
                                    class="pricing-basic-value fw-bolder text-primary"
                            >{{ this.current_sms_credits.price / 100 }} €</span>
                            <div
                                    class="pricing-duration text-body font-medium-1 fw-bold"
                            >H.T</div
                            >

                            <a class="w-100 mt-2 waves-effect btn btn-primary" @click="checkout()" >Acheter</a>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-1"></div>
              </div>
            </div>

            <div v-if="!enableCreditsOnly()" class="pricing-table mt-4" >
              <div class="row" id="table-contextual">

                  <div class="col-sm-3">
                  </div>
                  <div class="col-sm-6 sms_pricing_border">
                    <div class="border_shadow">
                      <div class="card-body">
                        <div class="d-flex2 mb-0">
                          <div class="text-center">
                            <div class="card-body">

                              <img src="@/assets/images/sms.png" height="50" />
                              <h3 class="mt-1">Starter</h3>
                              <p class="card-text">Un outil essentiel pour convertir vos leads</p>
                              <p class="card-text" style="font-size: 25px">{{numberWithCommas(productStore.starter_sms.price / 12)}}€ H.T / mois</p>
                              <p class="card-text">Abonnement annuel {{numberWithCommas(productStore.starter_sms.price)}}€ H.T / an<br>Ajouter des crédits supplémentaires à votre offre</p>

                              <select v-model="sms_credits_counter" class="form-select form-select-lg" style="width:200px">
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                                <option value="2500">2500</option>
                                <option value="5000">5000</option>
                              </select>

                              <p class="card-text mt-1">{{sms_credits_counter}} Crédits ajoutés<br>Facturé {{ current_sms_credits.price / 100 }}€ H.T en plus de votre abonnement</p>

                              <span class="pricing-basic-value fw-bolder text-default">Total à payer: </span>
                              <span class="pricing-basic-value fw-bolder text-primary"
                              >{{ selectionTotal() }}&nbsp;€</span>

                              <a class="w-100 mb-1 mt-2 waves-effect btn btn-primary" @click="checkout()" >Commander maintenant</a>

                              <a style="text-decoration: underline;" @click="launch()">Voir le detail du prix</a>

                              <h2 class="mt-2">Description de l'offre:</h2>
                              <ul class="features mt-1 mx-auto" style="width:fit-content">
                                <li style="text-align:left!important">Modèles SMS personnalisés</li>
                                <li style="text-align:left!important">Campagnes personnalisées</li>
                                <li style="text-align:left!important">Analytics de vos campagnes</li>
                              </ul>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                  </div>
              </div>
            </div>

            <div class="pricing-free-trial">
              <div class="row">
                <div class="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                  <div
                          class="pricing-trial-content d-flex justify-content-between"
                  >
                    <div class="text-center text-md-start mt-3">
                      <h3 class="text-primary" style="font-size: 1.3em;margin-top:20px">
                        Lead-ia vous accompagne TOUTE l'année pour RÉUSSIR !
                      </h3>

                      <a class="btn btn-primary mt-2 mt-lg-3 waves-effect waves-float waves-light"
                         :href="'mailto:sales@lead-ia.com?Body=Bonjour,%0AJe suis '+userStore.current_user.firstname+' de '+accountStore.account.name+', pourriez-vous me recontacter rapidement, s’il vous plaît ?&Subject=Demande%20d\'informations'">Contacter l’équipe commerciale</a>
                    </div>
                    <img
                            src="@/app-assets/images/illustration/pricing-Illustration.svg"
                            class="pricing-trial-img img-fluid"
                            alt="svg img"
                    />
                  </div>
                </div>
              </div>
            </div>

          </section>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
    import DashboardLayout from "@/layouts/dashboard";
    import { useProductStore } from "@/stores/product";
    import { useUserStore } from "@/stores/user";
    import { useAccountStore } from "@/stores/account";
    import { useLicenceStore } from "@/stores/licence";
    import { useAuthStore } from "@/stores/auth";
    import VueCookies from 'vue-cookies';
    import { Modal } from "bootstrap";

    export default {
        name: "product",
        components: {
            DashboardLayout
        },
        data() {
            return {
                modalValidation: null,
                starter_sms: this.productStore.starter_sms,
                current_sms_credits: this.productStore.sms_100,
                sms_credits_counter: 100,
            };
        },
        async mounted() {
            await this.productStore.getProducts({ vm: this });
            await this.licenceStore.getLicences({ vm: this });
            this.starter_sms = this.productStore.starter_sms;
            this.current_sms_credits = this.productStore.sms_100;

            if(VueCookies.get('lead_sms_credits')){
              if(VueCookies.get('lead_sms_credits') == 100) this.current_sms_credits = this.productStore.sms_100;
              if(VueCookies.get('lead_sms_credits') == 250) this.current_sms_credits = this.productStore.sms_250;
              if(VueCookies.get('lead_sms_credits') == 500) this.current_sms_credits = this.productStore.sms_500;
              if(VueCookies.get('lead_sms_credits') == 1000) this.current_sms_credits = this.productStore.sms_1000;
              if(VueCookies.get('lead_sms_credits') == 2500) this.current_sms_credits = this.productStore.sms_2500;
              if(VueCookies.get('lead_sms_credits') == 5000) this.current_sms_credits = this.productStore.sms_5000;
            }
            this.sms_credits_counter = this.current_sms_credits.meta["sms_credits"];
            this.modalValidation = new Modal(this.$refs.validationModal);
        },
        setup() {
            const accountStore = useAccountStore();
            const productStore = useProductStore();
            const authStore = useAuthStore();
            const userStore = useUserStore();
            const licenceStore = useLicenceStore();
            return {
                accountStore,
                productStore,
                authStore,
                userStore,
                licenceStore
            };
        },
        watch: {
            "sms_credits_counter": function (newFilter, oldFilter) {
                if(this.sms_credits_counter == 100) this.current_sms_credits = this.productStore.sms_100;
                if(this.sms_credits_counter == 250) this.current_sms_credits = this.productStore.sms_250;
                if(this.sms_credits_counter == 500) this.current_sms_credits = this.productStore.sms_500;
                if(this.sms_credits_counter == 1000) this.current_sms_credits = this.productStore.sms_1000;
                if(this.sms_credits_counter == 2500) this.current_sms_credits = this.productStore.sms_2500;
                if(this.sms_credits_counter == 5000) this.current_sms_credits = this.productStore.sms_5000;
                VueCookies.set('lead_sms_credits' , this.current_sms_credits.meta["sms_credits"], "10m")
            },
        },
        methods: {
            launch() {
                this.modalValidation.show();
            },
            getSmsCredit() {
                var resu = 0;
                if(this.accountStore.account.sms_credits_balance) resu = this.accountStore.account.sms_credits_balance;
                return resu;
            },
            enableCreditsOnly(){
                return this.licenceStore.sms_days_left > 15 && this.licenceStore.sms;
            },
            async checkout(){
                var line_items = [];
                if( ! this.enableCreditsOnly() ) {
                    line_items.push({price: this.productStore.starter_sms.stripe_price, quantity: 1});
                }
                line_items.push({price: this.current_sms_credits.stripe_price, quantity: 1});
                const data = {
                    line_items: JSON.stringify(line_items),
                    payment_method_types: ['sepa_debit','card'],
                    vm: this,
                };

                VueCookies.set('lead_sms_credits' , this.current_sms_credits.meta["sms_credits"], "10m")
                await this.productStore.getPlanCheckout(data);
                location.href = this.productStore.checkout.stripe_session_url;
            },
            numberWithCommas(x) {
                if (x == null){
                    return "";
                }
                x = x / 100;
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            selectionTotal() {
                return (this.productStore.starter_sms.price + this.current_sms_credits.price) / 100 ;
            }
        }
    };
</script>

<style scoped>
  .sms_remaining_credits {
    font-size: 20px;
  }
  .sms_pricing_border {
    background-color: #f7f6ff !important;
  }
  .form-select {
    display: inline;
  }
  ul.features {
    list-style: none;
  }
  ul.features li:before {
    content: '✓  ';
    margin-left:-15px
  }
  ul.features li {
    font-size: 16px;
  }
  .pricing-basic-value {
    font-size: 46px;
  }
  .text-default {
    font-weight: 200 !important;
    color: #5e5873 !important;
    font-size: 36px;
  }
  .checkout-details-padding-sm{
    padding:15px 10px 15px 10px;
  }
  .checkout-details-padding-mi{
    padding:20px 10px 40px 10px;
  }
  .checkout-details-padding-lg{
    padding:35px 20px 35px 20px;
  }
  .checkout-details {
    width:100%;
    font-size: 18px;
    margin-top:40px;
    margin-bottom:10px;
  }
  .checkout-details-color {
    font-size: 20px;
    background-color: #f7f6ff !important;
    padding-top:20px;
    padding-bottom:20px;
    font-weight: bold;
  }
</style>
