<template>
  <div
    class="pace-done vertical-layout blank-page navbar-floating footer-static menu-hide vertical-overlay-menu"
  >
    <Loader></Loader>
    <div class="app-content content">
      <div class="content-wrapper">
        <div class="content-body">
          <div class="auth-wrapper auth-cover">
            <div class="auth-inner row m-0">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/loader.vue";
export default {
  name: "coverLayout",
  components: {
    Loader,
  },
};
</script>

<style scoped>
.content-body {
  margin: 0px;
}
</style>
