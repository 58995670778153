<template>
  <form @submit.prevent="actionSave">
    <div
      class="modal fade"
      ref="addCampusModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ajouter {{this.singularSectionNameWithPronom()}}</h5>
            <button
              type="button"
              class="btn-close"
              @click="modalAddCampus.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <label>Nom {{this.singularSectionNameWithPronomName()}}</label>
            <input
              v-model="newCampus"
              class="form-control"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="modalAddCampus.hide()"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="addCampus(id)"
            >
              Créer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      ref="duplicateModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dupliquer un campus</h5>
            <button
              type="button"
              class="btn-close"
              @click="modalDuplicate.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <label>Nom du campus</label>
            <input
              v-model="doublonName"
              class="form-control"
              placeholder="Nom du campus"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="modalDuplicate.hide()"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="duplicateCampus()"
            >
              Dupliquer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
    <div class="mb-2">
      <label class="form-label">Nom de l'établissement</label>
      <input
        class="form-control"
        placeholder="Nom de l'établissement"
        aria-label="Nom de l'établissement"
        v-model="name"
      />
      <div
        class="input-errors"
        v-for="(error, index) of v$.name.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-2 col-6">
      <label class="form-label">Lien web</label>
      <input
        class="form-control"
        placeholder="Lien web"
        aria-label="Lien web"
        v-model="url"
      />
      <div
        class="input-errors"
        v-for="(error, index) of v$.url.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>

    <div class="mb-2 col-6">
      <label>J'administre des :</label>
      <select class="form-control" v-model="section_naming">
        <option value="campus">Campus</option>
        <option value="school">Écoles</option>
        <option value="center">Centres de formation</option>
      </select>
    </div>
    </div>

    <div class="mb-2">
      <h5>Mes {{this.pluralSectionName()}}</h5>
      <div class="alert alert-success">
        👉 Note « drag and drop » : sélectionnez {{this.singularSectionNameWithPronom()}} pour le déplacer et
        réordonner votre liste
      </div>
    </div>
    <div
      class="accordion accordion-margin"
      id="accordionMargin"
      data-toggle-hover="true"
    ></div>
    <div class="mb-2">
      <draggable
        v-model="list"
        group="people"
        @start="drag = true"
        @end="drag = false"
        item-key="id"
      >
        <template #item="{ element }">
          <CampusRow
            :element="element"
            @removeCampus="removeCampus"
            @duplicateCampus="openModalDuplicate"
            @addTag="addTag"
            @removeTag="removeTag"
          ></CampusRow>
        </template>
      </draggable>
      <button
        type="button"
        @click="modalAddCampus.show()"
        class="btn btn-primary"
      >
        Ajouter {{this.singularSectionNameWithPronom()}}
      </button>
    </div>
    <div class="mb-2">
      <label>Conformité au RGPD</label><br />
      <VueEditor v-model="cgu" />
    </div>
    <div class="mb-2 right">
      <button type="submit" class="btn btn-primary">Enregistrer</button>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, helpers } from "@vuelidate/validators";
import { useAccountStore } from "@/stores/account";
import { useProgramStore } from "@/stores/program";
import draggable from "vuedraggable";
import CampusRow from "@/components/campusRow";
import { Modal } from "bootstrap";
import { VueEditor } from "vue3-editor";

export default {
  name: "settingGeneral",
  components: {
    draggable,
    CampusRow,
    VueEditor,
  },
  data() {
    return {
      drag: false,
      name: "",
      url: "",
      cgu: "",
      list: [],
      section_naming: "campus",
      modalAddCampus: null,
      modalDuplicate: null,
      newCampus: "",
      doublon: {},
      doublonName: "",
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage(
          "Nom de l'établissement web requis",
          required
        ),
      },
      url: {
        required: helpers.withMessage("Lien web requis", required),
      },
    };
  },
  setup() {
    const accountStore = useAccountStore();
    const programStore = useProgramStore();
    return {
      v$: useVuelidate(),
      accountStore,
      programStore,
    };
  },
  watch: {
    list: function (oldVal, newVal) {
      console.log(newVal);
    },
    "programStore.sections": function (oldVal, newVal) {
      this.list = this.programStore.sections.map((campus, index) => {
        return {
          id: campus.id,
          name: campus.name,
          region: campus.region,
          display_order: index,
          email_list: JSON.parse(campus.email_list),
          email_list_entreprise: JSON.parse(campus.email_list_entreprise),
          email_list_foreign: JSON.parse(campus.email_list_foreign),
          email_list_pro: JSON.parse(campus.email_list_pro),
          phone: campus.phone,
        };
      });
    },
    "accountStore.account": function (oldVal, newVal) {
      this.name = this.accountStore.account.name;
      this.url = this.accountStore.account.site_url;
      this.cgu = this.accountStore.account.cgu;
      this.section_naming = this.accountStore.account.section_naming;
    },
  },
  async created() {
    await this.accountStore.getAccount({ id: this.accountStore.id, vm: this });
    this.name = this.accountStore.account.name;
    this.url = this.accountStore.account.site_url;
    this.cgu = this.accountStore.account.cgu;
    this.section_naming = this.accountStore.account.section_naming;
  },
  async mounted() {
    this.modalAddCampus = new Modal(this.$refs.addCampusModal);
    this.modalDuplicate = new Modal(this.$refs.duplicateModal);
    await this.programStore.getSections({ vm: this });
  },
  methods: {
    singularSectionNameWithPronom() {
      if(this.section_naming == "campus") return "un campus";
      if(this.section_naming == "school") return "une école";
      if(this.section_naming == "center") return "un centre de formation";
      return "un campus";
    },
    singularSectionNameWithPronomName() {
      if(this.section_naming == "campus") return "du campus";
      if(this.section_naming == "school") return "de l' école";
      if(this.section_naming == "center") return "du centre de formation";
      return "du campus";
    },
    singularSectionName() {
      if(this.section_naming == "campus") return "campus";
      if(this.section_naming == "school") return "école";
      if(this.section_naming == "center") return "centre de formation";
      return "Campus";
    },
    pluralSectionName() {
       if(this.section_naming == "campus") return "Campus";
       if(this.section_naming == "school") return "Écoles";
       if(this.section_naming == "center") return "Centres de formation";
       return "Campus";
    },
    openModalDuplicate(element) {
      this.doublon = element;
      this.modalDuplicate.show();
    },
    addTag(obj) {
      this.list = this.list.map((item) => {
        if (item.id === obj.id) {
          item[obj.liste].push({"text": obj.value});
        }
        return item;
      });
    },
    removeTag(obj) {
      this.list = this.list.map((item) => {
        if (item.id === obj.id) {
          item[obj.liste] = [
            ...item[obj.liste].slice(0, obj.index),
            ...item[obj.liste].slice(obj.index + 1),
          ];
        }
        return item;
      });
    },
    async addCampus() {
      await this.programStore.createSection({
        name: this.newCampus,
        display_order: this.programStore.sections.length + 1,
        email_list: [],
        email_list_foreign: [],
        email_list_pro: [],
        email_list_entreprise: [],
        phone: "",
        region: "",
        vm: this,
      });
      await this.programStore.getSections({ vm: this });
      this.modalAddCampus.hide();
      this.newCampus = "";
    },
    async removeCampus(id) {
      await this.programStore.deleteSection({ id: id, vm: this });
      await this.programStore.getSections({ vm: this });
    },
    async duplicateCampus() {
      this.modalDuplicate.hide();
      await this.programStore.createSection({
        name: this.doublonName,
        display_order: this.programStore.sections.length + 1,
        email_list: this.doublon.email_list,
        email_list_foreign: this.doublon.email_list_foreign,
        email_list_pro: this.doublon.email_list_pro,
        email_list_entreprise: this.doublon.email_list_entreprise,
        phone: this.doublon.phone,
        region: this.doublon.region,
        vm: this,
      });
      this.doublonName = "";
      this.doublon = {};
        
        const data = {
            sections: this.list,
            vm: this,
        };
      await this.accountStore.updateAccount(data);
      await this.programStore.getSections({ vm: this });
    },
    async actionSave(e) {
      e.preventDefault();
      await this.v$.$validate();
      if (this.v$.$error) {
          this.$toast.open({
              message: "Un ou plusieurs champs requis ne sont pas renseignés.",
              type: "error",
              position: "top-right",
              duration: 4000,
              dismissible: true,
          });
          return;
      }
      var sections =  this.list.map((section, index) => {
          section.display_order = index;
          return section;
      });

      const data = {
        name: this.name,
        url: this.url,
        cgu: this.cgu,
        section_naming: this.section_naming,
        sections: sections,
        vm: this,
      };
      await this.accountStore.updateAccount(data);
    },
  },
};
</script>

<style scoped>
.campus-buttons {
  display: inline;
}
.avatar {
  margin-left: 15px;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-position-x: 0;
  transition: transform 0.2s ease-in-out;
}
</style>
