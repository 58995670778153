export default {
  data() {
    return {
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };
  },
  computed: {
    menuExtended() {
      if (this.window.width <= 768) {
        return 0;
      } else {
        return "250px";
      }
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize(e) {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
