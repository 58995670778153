<template>
  <div class="card">
    <div
      class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start"
    >
      <h2 class="card-title mb-sm-0 mb-1">Répartition des leads</h2>
    </div>
    <div class="card-body" style="position: relative" :class="licenceStore.chatbot_tracking || userStore.is_leadia_admin ? '' : 'blur'">
      <div class="row">
        <div class="col-lg-4 col-md-6 left">
          <apexchart
            type="radialBar"
            height="400"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
        <div class="col-lg-8 col-md-6">
          <div class="buttons">
            <button
              v-for="(course, index) in courses"
              :key="index"
              class="btn"
              :class="getVariant(index)"
              @click="setCourse(index)"
            >
              {{ course.label }}
            </button>
          </div>
          <div class="row" v-if="courses[id]" style="margin-top:20px;">
            <div
              class="col-12"
              style="margin-bottom: 10px"
              v-for="(item, index) in courses[id]['items']"
              :key="index"
            >
              <div class="line">
                <span class="mb-50 title">{{ item.course }}</span>
                <span>{{ getItemPercent(item.value, id) }}</span>
              </div>
              <div
                class="progress"
                :class="variantProgressClass"
                style="height: 10px"
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="50"
                  aria-valuemax="100"
                  :style="getItemPercentClass(item.value, id)"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watchEffect, ref, reactive, defineComponent } from "vue";
import { useDashboardStore } from "@/stores/dashboard";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";
import { toRaw } from "vue";

export default defineComponent({
  name: "leads",
  data() {
    return {
      activeIndex: 0,
    };
  },
  setup() {
    const id = ref(0);
    const variants = ref(["primary", "danger", "warning", "success", "info"]);
    const variant = ref("primary");

    const dashboardStore = useDashboardStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    const courses = reactive([]);
    const labels = reactive([]);
    const series = reactive([]);
    let total = 0;
    let totalRep = 0;

    function getData(newCourses) {
      let i = 0;
      total = 0;
      for (const [key, value] of Object.entries(newCourses)) {
        labels[i] = key.split("_")[0];
        const sum = value
          .map((item) => {
            return item.value;
          })
          .reduce((sum, current) => sum + current, 0);
        total += sum;
        courses[i] = {
          label: key.split("_")[0],
          value: sum,
          items: toRaw(value),
        };
        i++;
      }

      /*** Circle values from dashboardStore.repartition ***/
      totalRep = Object.entries(dashboardStore.repartition)
        .map((item) => {
          return item[1];
        })
        .reduce((sum, current) => sum + current, 0);

      const repartition = Object.values(dashboardStore.repartition)

      for (let i = 0; i < repartition.length; i++) {
        if (repartition[i] > 0 && totalRep > 0) {
          series[i] = Math.round((repartition[i] / totalRep) * 100);
        } else {
          series[i] = 0;
        }
      }
    }

    const chartOptions = {
      chart: {
        height: 300,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                return totalRep;
              },
            },
          },
          track: {
            background: "#eee",
            strokeWidth: "80%",
            margin: 5,
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      colors: ["#7367f0", "#ea5455", "#ff9f43", "#43e7b0", "#14e0f9"],
      labels: labels,
    };

    getData(dashboardStore.courses);

    watchEffect(() => {
      getData(dashboardStore.courses);
    });

    return {
      id,
      variants,
      variant,
      dashboardStore,
      chartOptions,
      courses,
      series,
      total,
      totalRep,
      licenceStore,
      userStore
    };
  },
  computed: {
    variantProgressClass() {
      return "progress-bar-" + this.variant;
    },
  },
  methods: {
    setCourse(index) {
      this.activeIndex = index;
      this.id = index;
      this.variant = this.variants[index];
    },
    getVariant(index) {
      if (index === this.activeIndex) {
        return "bg-" + this.variants[index] + " active";
      } else {
        return "bg-light-" + this.variants[index];
      }
    },
    getItemPercent(value, courseId) {
      return Math.round((value / this.courses[courseId].value) * 100) + "%";
    },
    getItemPercentClass(value, courseId) {
      const percent = (value / this.courses[courseId].value) * 100;
      return "width: " + percent + "%";
    },
    isActive(index) {
      if (index === this.activeIndex) {
        return "active";
      }
    },
  },
});
</script>

<style scoped>
.left {
  border-right: 1px solid #ebe9f1;
}
.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.buttons button {
  margin: 5px;
  color: #5e5873;
}
.title {
  font-weight: 500;
}
.line {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bg-light-primary,
.bg-light-warning,
.bg-light-danger,
.bg-light-success,
.bg-light-info {
  color: #5e5873 !important;
}
.active {
  color: #fff !important;
}
</style>
