<template>
  <CoverLayout>
    <a class="brand-logo">
      <div class="brand-logo">
        <img src="@/assets/images/logo.png" alt="Lead-ia" class="logo" />
      </div>
    </a>
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
              class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
                class="img-fluid"
                src="@/app-assets/images/pages/under-maintenance.svg"
                alt="Maintenance"
        />
      </div>
    </div>
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title fw-bold mb-1">La page a été correctement générée</h2>
        <p class="card-text mb-2 hidden">
          {{ versions }}
        </p>
        <p class="text-center mt-2">
          <router-link
                  class="d-flex align-items-center"
                  :to="{ path: '/' }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                 stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left">
              <polyline points="15 18 9 12 15 6"></polyline></svg> Retour
          </router-link>
        </p>
      </div>
    </div>
  </CoverLayout>
</template>

<script>
    import CoverLayout from "@/layouts/cover";
    import {useVersionStore} from "@/stores/version";

    export default {
        name: "HealthCheckPage",
        components: { CoverLayout },
        async mounted() {
          await this.versionStore.getVersions({ vm: this });
        },
        setup() {
          const versionStore = useVersionStore();
          return {
            versionStore,
          };
        },
        data() {
          return {
            versions: this.versionStore.versions
          };
        },
    };
</script>

<style scoped>
  .logo {
    width: 200px;
  }
  .auth-wrapper.auth-cover .brand-logo {
    top: 0;
    left: 0;
  }
</style>
