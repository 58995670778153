<template>
  <div>
    <div class="col-12 mb-3 mt-2">
      <h1>2. Je configure ma campagne : {{smsTemplateStore.sms_template.name}}</h1>
      👉 Personnalisez vos choix
    </div>

    <form class="row" @submit.prevent="actionSave">

      <div class="col-7 mb-2">
        <label class="form-label">Nom de la campagne</label>
        <input
                v-model="name"
                class="form-control"
                placeholder="Ex: Journée portes ouvertes"
        />
        <div
                class="input-errors"
                v-for="(error, index) of v$.name.$errors"
                :key="index"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>

      <div class="col-7 mb-2">
        <label class="form-label">Lien (à inserer dans le SMS)</label>
        <input
                v-model="link"
                class="form-control"
                placeholder="https://"
        />
        <div
                class="input-errors"
                v-for="(error, index) of v$.link.$errors"
                :key="index"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>

      <div class="col-12 mb-2">
        <label class="form-label">Début de la campagne</label>
        <Datepicker
                position="bottom"
                placeholder=""
                lang="fr"
                v-model="date_start_at"
                class="datepick">
        </Datepicker>
      </div>

      <div class="col-12 mb-2">
        <label class="form-label">Fin de la campagne</label>
        <Datepicker
                placeholder=""
                lang="fr"
                v-model="date_end_at"
                class="datepick">
        </Datepicker>
      </div>

      <div class="col-12 mb-2">
        <label class="form-label">Tranche horaire d'envoi</label>
        <div class="row">
          <div class="col-3">
            <select v-model="hour_start_at" class="form-control">
              <option value="07:00">07:00</option>
              <option value="08:00">08:00</option>
              <option value="09:00">09:00</option>
              <option value="10:00">10:00</option>
              <option value="11:00">11:00</option>
              <option value="12:00">12:00</option>
            </select>
          </div>
          <div class="col-3">
            <select v-model="hour_end_at" class="form-control">
              <option value="13:00">13:00</option>
              <option value="14:00">14:00</option>
              <option value="15:00">15:00</option>
              <option value="16:00">16:00</option>
              <option value="17:00">17:00</option>
              <option value="18:00">18:00</option>
              <option value="19:00">19:00</option>
              <option value="20:00">20:00</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-2">
        <div class="row">
          <div class="col-7">

            <div class="mb-2">
              <label class="form-label">Nom de l'envoyeur du SMS (11 chars max.)</label>
              <input
                      v-model="sender"
                      maxlength = "11"
                      class="form-control"
                      placeholder="Mon école"
              />
              <div
                      class="input-errors"
                      v-for="(error, index) of v$.sender.$errors"
                      :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>


            <label class="form-label">Message</label>

            <span style="float:right;margin-bottom:4px" :class="sms_counter_class">
              {{getSmsCounterText()}}
            </span>

            <div>
              <div style="margin-bottom:5px" v-html="content_edited"
                   @keyup="onPressEnter()" @click="onPressEnter()" @input="setCounters()" ref="sms" contenteditable spellcheck="false" @keydown.enter="validate" class="smsContent"></div>
              Ajouter:
              <span class="badge-btn badge badge-light-success" @click="addSchoolToContent()">Ecole</span>
              <span class="badge-btn badge badge-light-success" @click="addLinkToContent()">Lien</span>
              <span class="badge-btn badge badge-light-success" @click="addFirstnameToContent()">Prénom</span>

              <!--span class="badge-btn badge badge-light-primary" @click="addStopToContent()">STOP au {{this.getStopNumber()}}</span-->

              <span style="float:right">
                {{ sms_count + "/" + sms_counter_limit }}
              </span>
            </div>
            <div
                    class="input-errors"
                    v-for="(error, index) of v$.content.$errors"
                    :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="col-5 dashed" v-if="sms_credits_balance >= sms_counter">
            <label class="form-label">Prénom</label>
            <input
                    v-model="firstname"
                    class="form-control"
                    maxlength = "30"
                    placeholder="Ex: Arnaud"
            />

            <label class="form-label">Téléphone</label>
            <input
                    v-model="phone"
                    class="form-control"
                    type="tel"
                    maxlength = "10"
                    @keypress="isNumber($event)"
                    placeholder="0600000000"
            />

            <div class="col-12 mt-1">
              <a v-if="phone!='' && phone.length == 10" @click="testSms()"
                 class="btn btn-primary phone_body_link">Tester maintenant</a>
              <a v-if="phone=='' || phone.length < 10"
                 class="btn btn-outline-primary phone_body_link">Remplir pour tester</a>
            </div>

          </div>
        </div>
      </div>

      <div class="mb-2">
        <!--a class="btn btn-outline-primary waves-effect waves-float waves-light"
           @click="$router.push('/campagne/sms/historique')"
        >
          Quitter
        </a-->

        <div class="row">
          <div class="col-7">

        <button
                type="submit"
                style="margin-left:10px;float:right"
                class="btn btn-primary waves-effect waves-float waves-light"
        >
          Suivant &#187;
        </button>

        <a @click="saveWithNotice()"
           style="margin-left:10px;float:right"
           class="btn btn-outline-primary waves-effect waves-float waves-light"
        >
          Sauvegarder
        </a>

        <a class="btn btn-outline-primary waves-effect waves-float waves-light"
           style="margin-left:10px;float:right"
           @click="precedent($router)" v-if="showPrecedent()"
        >
          Retour
        </a>

      </div>
      </div>
      </div>
    </form>
  </div>
</template>

<script>
    import DashboardLayout from "@/layouts/dashboard";
    import { useSmsTemplateStore } from "@/stores/smsTemplate";
    import { useSmsSettingStore } from "@/stores/smsSetting";
    import { useAccountStore } from "@/stores/account";
    import { useChatgptStore } from "@/stores/chatgpt";
    import useVuelidate from "@vuelidate/core";
    import Multiselect from "vue-multiselect";
    import moment from "moment";
    import { helpers, required, requiredIf, url } from "@vuelidate/validators";
    import { ref } from 'vue'

    export default {
        name: "SmsTemplateDetail",
        components: {
            DashboardLayout,
            Multiselect
        },
        data() {
            return {
                sms_sample:{},
                name: "",
                content: "",
                content_edited: "",
                sender: "",
                date_start_at: moment(),
                date_end_at: moment().add(7, "d"),
                hour_start_at: "09:00",
                hour_end_at: "18:00",
                firstname: "",
                phone: "",
                link: "",
                sms_credits_balance: 0,
                sms_template_id: 0,
                sms_count: 0,
                sms_counter: 0,
                sms_counter_limit: 0,
                sms_counter_class: "",
                caret_position: 0,
            };
        },
        validations() {
            var validations =  {
                name: {
                    required: helpers.withMessage("Nom de la campagne requis", required),
                },
                content: {
                    required: helpers.withMessage("Contenu du SMS requis", required),
                },
                sender: {
                    required: helpers.withMessage("Nom de l'envoyeur requis", required),
                },
                link: {
                    url: helpers.withMessage("Le lien web doit être une url valide", url),
                },
            };

            if(this.content_edited.includes('Lien')){
                 validations.link.required = helpers.withMessage("Le lien web est requis avec ce message", required);
            }
            return validations;
        },
        async mounted() {
            await this.getData();
            await this.setCounters();
        },
        setup() {
            const isChrome25Plus = ref(false)

            // Check if browser is Chrome 25+
            const userAgent = window.navigator.userAgent
            if (userAgent.indexOf('Chrome/') >= 0) {
                const chromeVersion = parseInt(userAgent.split('Chrome/')[1])
                if (chromeVersion >= 25) {
                    isChrome25Plus.value = true
                }
            }
            const smsTemplateStore = useSmsTemplateStore();
            const smsSettingStore = useSmsSettingStore();
            const accountStore = useAccountStore();
            const chatgptStore = useChatgptStore();
            return {
                v$: useVuelidate(),
                smsTemplateStore,
                smsSettingStore,
                accountStore,
                chatgptStore,
                isChrome25Plus
            };
        },
        watch: {
            "date_start_at": function (newVal, oldVal) {
                var date_start_at = moment(this.date_start_at);
                var date_end_at = moment(this.date_end_at);

                if (date_start_at.valueOf() > date_end_at.valueOf()) this.date_end_at = this.date_start_at;
            },
            "date_end_at": function (newVal, oldVal) {
                var date_start_at = moment(this.date_start_at);
                var date_end_at = moment(this.date_end_at);

                if (date_end_at.valueOf() < date_start_at.valueOf()) this.date_start_at = this.date_end_at;
            },
        },
        methods: {
            countOccurrences(originalString, substring) {
                const escapedSubstring = substring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                const regex = new RegExp(escapedSubstring, 'g');
                const occurrences = originalString.split(regex).length - 1;
                return occurrences;
            },
            getCaretIndex(element) {
                let position = 0;
                const isSupported = typeof window.getSelection !== "undefined";
                if (isSupported) {
                    const selection = window.getSelection();
                    // Check if there is a selection (i.e. cursor in place)
                    if (selection.rangeCount !== 0) {
                        // Store the original range
                        const range = window.getSelection().getRangeAt(0);
                        // Clone the range
                        const preCaretRange = range.cloneRange();
                        // Select all textual contents from the contenteditable element
                        preCaretRange.selectNodeContents(element);
                        // And set the range end to the original clicked position
                        preCaretRange.setEnd(range.endContainer, range.endOffset);
                        // Return the text length from contenteditable start to the range end
                        position = preCaretRange.toString().length;
                    }
                }
                return position;
            },
            onPressEnter() {
                this.caret_position = parseInt(this.getCaretIndex(this.$refs["sms"]));
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            getCurrentContent() {
                var string = "";
                if (this.$refs["sms"]) string = this.$refs["sms"].textContent;
                return string;
            },
            getSmsCounterText(){
              if(this.sms_counter > 1) return this.sms_counter + " crédits SMS"
              return this.sms_counter + " crédit SMS"
            },
            getEmojiOrAccent() {
                var string = "";
                if (this.$refs["sms"]) string = this.$refs["sms"].textContent;
                return (string.match(/\p{Emoji}/u) || string.match(/[ãäåÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖÙÚÛÜÝÞßàáçêëìíîïðñòóôõöùúûüýþÿ]/)) ? true : false;
            },
            getSmsEditedContent() {
                var resu = "";
                if (this.$refs["sms"]) resu = this.$refs["sms"].textContent;
                return resu;
            },
            getSmsCount() {
                return this.getSmsEditedContent().length;
            },
            getSmsCounter() {
                var length = this.getSmsCount();

                if(this.getEmojiOrAccent()){
                    if (length > 462) return 8;
                    if (length > 396) return 7;
                    if (length > 330) return 6;
                    if (length > 264) return 5;
                    if (length > 198) return 4;
                    if (length > 132) return 3;
                    if (length > 70) return 2;
                    return 1;
                }
                if (length > 1072) return 8;
                if (length > 918) return 7;
                if (length > 755) return 6;
                if (length > 612) return 5;
                if (length > 459) return 4;
                if (length > 306) return 3;
                if (length > 160) return 2;
                return 1;
            },
            getSmsCounterLimit() {
                if(this.getEmojiOrAccent()) {
                    var length = this.getSmsCount();
                    if (length > 462) return 528;
                    if (length > 396) return 462;
                    if (length > 330) return 396;
                    if (length > 264) return 330;
                    if (length > 198) return 264;
                    if (length > 132) return 198;
                    if (length > 70) return 132;
                    return 70;
                }
                var length = this.getSmsCount();
                if (length > 1072) return 1224;
                if (length > 918) return 1071;
                if (length > 755) return 918;
                if (length > 612) return 755;
                if (length > 459) return 612;
                if (length > 306) return 459;
                if (length > 160) return 306;
                return 160;
            },
            getSmsCounterClass() {
                var length = this.getSmsCount();
                if(this.getEmojiOrAccent()) {
                    if (length > 132) return "text-danger";
                    if (length > 70) return "text-warning";
                    return "text-success";
                }
                if (length > 306)  return "text-danger";
                if (length > 160)  return "text-warning";
                return "text-success";
            },
            getContentEditable(content) {
                if(this.isChrome25Plus) {
                    content = content.replaceAll("{{prénom}}", '<span contenteditable="false" class="badge-hand badge badge-light-success">Prénom</span>');
                    content = content.replaceAll("{{lien}}", '<span contenteditable="false" class="badge-hand badge badge-light-success">Lien</span>');
                    content = content.replaceAll("{{école}}", '<span contenteditable="false" class="badge-hand badge badge-light-success">Ecole</span>');
                } else {
                    content = content.replaceAll("{{prénom}}", '<span class="badge-hand badge badge-light-success">Prénom</span>');
                    content = content.replaceAll("{{lien}}", '<span class="badge-hand badge badge-light-success">Lien</span>');
                    content = content.replaceAll("{{école}}", '<span class="badge-hand badge badge-light-success">Ecole</span>');
                }
                content = content.replaceAll("{{", '').replaceAll("}}", '');
                content = content.replaceAll("<div>", '').replaceAll("</div>", '');
                content = content.replace(/(?:\r\n|\r|\n)/g, '<br>');
                content = content + '<span> </span>';
                return content;
            },
            revertContentEditable(content) {
                if(this.isChrome25Plus) {
                    content = content.replaceAll('<span contenteditable="false" class="badge-hand badge badge-light-success">Prénom</span>', "{{prénom}}");
                    ;
                    content = content.replaceAll('<span contenteditable="false" class="badge-hand badge badge-light-success">Lien</span>', "{{lien}}");
                    content = content.replaceAll('<span contenteditable="false" class="badge-hand badge badge-light-success">Ecole</span>', "{{école}}");
                }else{
                    content = content.replaceAll('<span class="badge-hand badge badge-light-success">Prénom</span>', "{{prénom}}");;
                    content = content.replaceAll('<span class="badge-hand badge badge-light-success">Lien</span>', "{{lien}}");
                    content = content.replaceAll('<span class="badge-hand badge badge-light-success">Ecole</span>', "{{école}}");
                }
                content = content.replaceAll('<span>', "").replaceAll('</span>', "");
                content = content.replaceAll('<div>', "").replaceAll('</div>', "");
                content = content.replaceAll('<br>', "\r");
                return content;
            },
            async addElementToContent(html) {
                var v = this.revertContentEditable(this.$refs["sms"].innerHTML)
                this.caret_position = this.caret_position + this.countOccurrences(v.slice(0, this.caret_position + (this.caret_position > 6 ? 6 : 0)), '{{') * 4;
                this.caret_position = this.caret_position + 2*(v.slice(0, this.caret_position).split("\r").length - 1);

                if(v[this.caret_position] == "{" ){ this.caret_position = this.caret_position - 1; }
                if(v[this.caret_position] == "{" ){ this.caret_position = this.caret_position - 1; }
                if(v[this.caret_position] == "}" ){ this.caret_position = this.caret_position + 1; }
                if(v[this.caret_position] == "}" ){ this.caret_position = this.caret_position + 1; }
                if (this.caret_position < 0 ) this.caret_position = 0;

                this.content_edited = this.getContentEditable(v.slice(0, this.caret_position) + html + v.slice(this.caret_position));
                this.setCounters();
            },
            async addLinkToContent() {
                this.addElementToContent('{{lien}}')
            },
            async addFirstnameToContent() {
                this.addElementToContent('{{prénom}}')
            },
            async addSchoolToContent() {
                this.addElementToContent('{{école}}')
            },
            async addStopToContent() {
                this.addElementToContent('{{stop}}')
            },
            async setCounters() {
                this.sms_count = this.getSmsCount();
                this.sms_counter = this.getSmsCounter();
                this.sms_counter_limit = this.getSmsCounterLimit();
                this.sms_counter_class = this.getSmsCounterClass();
            },
            getStopNumber() {
              // TODO move that to .env
              if(location.href.includes("localhost")) return "LOCAL";
              if(location.href.includes("staging")) return "0644637245";
              return "0644630629";
            },
            async testSms() {

                var edited_content = this.getSmsEditedContent().replaceAll("Prénom", "{{prénom}}").replaceAll("Lien", "{{lien}}");
                var data = {};

                data.name = this.firstname;
                data.phone = this.phone;
                data.content = edited_content;
                data.link = this.link;
                data.sender = (this.sender == "" ? "LEAD-IA" : this.sender);

                var resu = await this.smsSettingStore.sendSmsTest(data);
                this.accountStore.account.sms_credits_balance = resu.sms_credits_balance;
                this.sms_credits_balance = resu.sms_credits_balance;
                this.firstname = "";
                this.phone = "";

                this.$toast.open({
                    message: "Votre nouveau solde de crédits est de " +resu.sms_credits_balance+" SMS.",
                    type: "success",
                    position: "top-right",
                    duration: 8000,
                    dismissible: true,
                });
                this.$toast.open({
                    message: "SMS de test envoyé avec succès. Veuillez vérifier votre téléphone.",
                    type: "success",
                    position: "top-right",
                    duration: 8000,
                    dismissible: true,
                });

            },
            async getData() {
                if (this.$route.params.sms_setting_id == -1) {
                    await this.smsTemplateStore.getSmsTemplate({id: this.$route.params.id, vm: this});
                    this.sms_template_id = this.$route.params.id;

                    var sample_id = this.$route.params.sample;
                    var this_sms_sample = {};
                    this.smsTemplateStore.sms_template.sms_samples.forEach(function(sms_sample) {
                        if(sms_sample.id == sample_id){
                            this_sms_sample = sms_sample;
                        }
                    });

                    this.sms_credits_balance = this.accountStore.account.sms_credits_balance;
                    this.sms_sample = this_sms_sample;
                    this.content = this.chatgptStore.sample.replace(/{{école}}/g, this.accountStore.account.name) + "\n";
                    this.content_edited = this.getContentEditable(this.content);

                } else if (this.$route.params.sms_setting_id == 0) {

                    await this.smsTemplateStore.getSmsTemplate({id: this.$route.params.id, vm: this});
                    this.sms_template_id = this.$route.params.id;

                    var sample_id = this.$route.params.sample;
                    var this_sms_sample = {};
                    this.smsTemplateStore.sms_template.sms_samples.forEach(function(sms_sample) {
                        if(sms_sample.id == sample_id){
                            this_sms_sample = sms_sample;
                        }
                    });

                    this.sms_credits_balance = this.accountStore.account.sms_credits_balance;
                    this.sms_sample = this_sms_sample;
                    this.content = this_sms_sample.body.replace(/{{école}}/g, this.accountStore.account.name) + "\n";
                    this.content_edited = this.getContentEditable(this.content);

                } else {

                    await this.smsSettingStore.getSmsSetting({id: this.$route.params.sms_setting_id, vm: this});
                    await this.smsTemplateStore.getSmsTemplate({id: this.smsSettingStore.sms_setting.sms_template_id, vm: this});

                    this.sms_template_id = this.smsSettingStore.sms_setting.sms_template_id;
                    this.sms_credits_balance = this.accountStore.account.sms_credits_balance;
                    this.content = this.smsSettingStore.sms_setting.content + "\n";
                    this.content_edited = this.getContentEditable(this.smsSettingStore.sms_setting.content);
                    this.link = this.smsSettingStore.sms_setting.link;
                    this.name = this.smsSettingStore.sms_setting.name;
                    this.sender = this.smsSettingStore.sms_setting.sender;
                    this.date_start_at = this.smsSettingStore.sms_setting.date_start_at;
                    this.date_end_at = this.smsSettingStore.sms_setting.date_end_at;
                    this.hour_start_at = moment(this.smsSettingStore.sms_setting.hour_start_at).format("HH:00");
                    this.hour_end_at = moment(this.smsSettingStore.sms_setting.hour_end_at).format("HH:00");
                }
            },
            async saveWithNotice() {
                var resu = await this.save();
                if (resu.id){
                    this.$toast.open({
                        message: "Campagne sauvegardée avec succès.",
                        type: "success",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                }
                await this.$router.push("/campagne/sms/template/0/sample/0/settings/"+resu.id);
            },
            async save() {
                await this.v$.$validate();
                if (this.v$.$error) {
                    this.$toast.open({
                        message: "Un ou plusieurs champs requis ne sont pas renseignés.",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                    return;
                }

                var edited_content = this.getSmsEditedContent().replaceAll("Prénom", "{{prénom}}").replaceAll("Lien", "{{lien}}");
                if (this.$route.params.sms_setting_id > 0) { //
                    var sms_setting_id = this.$route.params.sms_setting_id;
                    const data = {
                        id: sms_setting_id,
                        name: this.name,
                        content: edited_content,
                        hour_start_at: this.hour_start_at,
                        hour_end_at: this.hour_end_at,
                        date_start_at: moment(this.date_start_at).format("YYYY-MM-DD"),
                        date_end_at: moment(this.date_end_at).format("YYYY-MM-DD"),
                        sender: this.sender,
                        link: this.link,
                        vm: this,
                    };
                    return await this.smsSettingStore.updateSmsSetting(data);
                } else {
                    const data = {
                        name: this.name,
                        content: edited_content,
                        hour_start_at: this.hour_start_at,
                        hour_end_at: this.hour_end_at,
                        date_coverage_start_at: moment(this.date_start_at).format("YYYY-MM-DD"),
                        date_start_at: moment(this.date_start_at).format("YYYY-MM-DD"),
                        date_end_at: moment(this.date_end_at).format("YYYY-MM-DD"),
                        target: "[]",
                        sender: this.sender,
                        link: this.link,
                        sms_template_id: this.sms_template_id,
                        vm: this,
                    };
                    return await this.smsSettingStore.createSmsSetting(data);
                }
            },
            async actionSave(e) {
                e.preventDefault();

                this.addElementToContent('<span></span>');
                await this.v$.$validate();

                if (this.v$.$error) {
                    this.$toast.open({
                        message: "Un ou plusieurs champs requis ne sont pas renseignés.",
                        type: "error",
                        position: "top-right",
                        duration: 4000,
                        dismissible: true,
                    });
                    return;
                }

                var resu = await this.save();
                if(this.$route.params.sms_setting_id == -1) {
                    this.chatgptStore.current_template_id = resu.id;
                }
                await this.$router.push("/campagne/sms/settings/"+resu.id);
            },
            async precedent($router) {
                await this.$router.push("/campagne/sms/template/"+ this.smsTemplateStore.sms_template.id + "/magic/reload");
            },
            showPrecedent() {
                //return (this.$route.params.sms_setting_id == -1 || this.$route.params.sms_setting_id == this.chatgptStore.current_template_id )
                return (this.$route.params.sms_setting_id == -1 || this.$route.params.sms_setting_id == this.chatgptStore.current_template_id )
            }
        },
    };
</script>

<style scoped>
  .datepick {
    --v-calendar-datepicker-icon-color: #7367f0;
    --v-calendar-active-bg-color: #7367f0;
    --v-calendar-active-text-color:#fff;
    --v-calendar-day-hover-bg-color: #c0bbf4;
    --v-calendar-range-bg-color: #eeedfd;
    --v-calendar-range-text-color: #666;
  }
  .dashed {
    padding-top:30px;
    border-style: dashed;
    color: #7367f0
  }
  .v-calendar .content {
    top: -120px;
  }
  .smsContent {
    border-radius: .357rem;
    border: 1px solid #D8D6DE;
    padding:12px;
    min-height:150px
  }
  .badge-btn {
    cursor: pointer;
    margin-left: 2px;
  }
</style>
<style>
  .badge-hand {
    cursor: pointer !important;
    user-select: all;
    -moz-user-select: all;
    -webkit-user-select: all;
  }
</style>
