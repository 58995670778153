<template>
  <div class="card card-browser-states">
    <div class="card-body">
      <h2 class="card-title"><span class="ads">Ads</span> <span class="badge badge-light-success"> BETA </span></h2>
      <Display
        :class="licenceStore.chatbot_tracking || userStore.is_leadia_admin ? '' : 'blur'"
        v-if="dashboardStore.displays.length > 0"
        v-for="(display, index) in dashboardStore.displays"
        :key="index"
        :percent="display.rate"
        :name="display.name"
      ></Display>
      <span v-else>Pas de données disponibles</span>
    </div>
  </div>
</template>

<script>
import Display from "@/components/dashboard/display";
import { useDashboardStore } from "@/stores/dashboard";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";

export default {
  name: "Displays",
  components: {
    Display,
  },
  setup() {
    const dashboardStore = useDashboardStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return { dashboardStore,
        licenceStore, userStore};
  },
  computed: {
    nbValues() {
      return this.dashboardStore.displays.length;
    },
    total() {
      return this.dashboardStore.displays
        .map((display) => {
          return display.visits;
        })
        .reduce((sum, current) => sum + current, 0);
    },
  },
  methods: {
    getPercent(visits) {
      return Math.round((visits / this.total) * 100);
    },
  },
};
</script>

<style scoped>
  .ads {
    vertical-align: middle;
  }
  .badge {
    font-size: 10px;
    margin-left: 6px;
    vertical-align: middle;
  }
</style>
