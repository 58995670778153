<template>
  <DashboardLayout>
    <div class="row mb-1">
      <div class="col-lg-8">
        <h1>Dashboard</h1>
      </div>
      <div class="col-lg-4 right">
        <Datepicker
          position="right"
          placeholder="30 derniers jours"
          lang="fr"
          range
          v-model="selectedDate"
          @input="updateDate()"
          @change="updateDate()"
          class="datepick"
        ></Datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="row match-height">
          <div class="col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-2">
            <Card
                    name="Desktop Traffic"
                    :value="Math.round(dashboardStore.summary.visitor_pc * 100 / (dashboardStore.summary.visitor_pc + dashboardStore.summary.visitor_mobile)) + '%'"
                    icon="monitor"
                    variant="primary"
                    background="white"
                    valueSize="1.4rem"
                    valueSpace="14px"
            ></Card>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-2">
            <Card
                    name="Mobile Traffic"
                    :value="Math.round(dashboardStore.summary.visitor_mobile * 100 / (dashboardStore.summary.visitor_pc + dashboardStore.summary.visitor_mobile)) + '%'"
                    icon="smartphone"
                    variant="success"
                    background="white"
                    valueSize="1.4rem"
                    valueSpace="14px"
            ></Card>
          </div>
        </div>
        <div class="row match-height">
          <div class="col-12">
            <Captation></Captation>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12">
        <div class="row match-height">
          <div class="col-12">
            <Contacts></Contacts>
          </div>
        </div>
        <div class="row match-height">
          <div class="col-xl-3 col-md-3 col-sm-6 col-xs-6 mb-2">
            <Card
              name="Téléphone"
              :value="dashboardStore.summary.phone_rate + '%'"
              icon="smartphone"
              variant="primary"
              background="white"
              valueSize="1.4rem"
              valueSpace="14px"
            ></Card>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-6 col-xs-6 mb-2">
            <Card
              name="Crédit score"
              :value="dashboardStore.summary.score"
              icon="activity"
              variant="danger"
              background="white"
              valueSize="1.4rem"
              valueSpace="14px"
            ></Card>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-6 col-xs-6 mb-2">
            <Card
              name="Temps médian"
              :value="dashboardStore.summary.median"
              icon="clock"
              variant="success"
              background="white"
              valueSize="1.4rem"
              valueSpace="14px"
            ></Card>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-6 col-xs-6 mb-2">
            <Card
              name="Moy. pages visitées"
              :value="dashboardStore.summary.page"
              icon="eye"
              variant="info"
              background="white"
              valueSize="1.4rem"
              valueSpace="14px"
            ></Card>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12" v-if="!licenceStore.chatbot_tracking">
      <div class="alert alert-warning" style="padding:10px">⚠️ Actuellement, vous ne disposez pas du module 'tracking'. L'affichage des données est limité.</div>
    </div>
    <div class="row match-height" :class="licenceStore.chatbot_tracking || userStore.is_leadia_admin ? '' : 'blur-grayscale'">
      <div class="col-lg-8 col-md-6 col-sm-12">
        <Statistics></Statistics>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <Displays></Displays>
      </div>
    </div>
    <div class="row" :class="licenceStore.chatbot_tracking || userStore.is_leadia_admin ? '' : 'blur-grayscale'">
      <div class="col-12">
        <Leads></Leads>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import moment from "moment";
import DashboardLayout from "@/layouts/dashboard";
import { useDashboardStore } from "@/stores/dashboard";
import { useLoaderStore } from "@/stores/loader";
import { useAccountStore } from "@/stores/account";
import { useLicenceStore } from "@/stores/licence";
import { useUserStore } from "@/stores/user";
import Card from "@/components/dashboard/card";
import Trafic from "@/components/dashboard/trafic";
import Leads from "@/components/dashboard/leads";
import Congratulation from "@/components/dashboard/congratulation";
import Contacts from "@/components/dashboard/contacts";
import Captation from "@/components/dashboard/captation";
import Statistics from "@/components/dashboard/statistics";
import Displays from "@/components/dashboard/displays";

export default {
  name: "HomePage",
  components: {
    Captation,
    Contacts,
    DashboardLayout,
    Card,
    Trafic,
    Leads,
    Congratulation,
    Statistics,
    Displays,
  },
  data() {
    return {
      selectedDate: [moment().subtract(30, "d"), moment()],
    };
  },
  async mounted() {
      if(this.dashboardStore.start){
        this.selectedDate = [this.dashboardStore.start, this.dashboardStore.end];
      }
  },
  setup() {
    const dashboardStore = useDashboardStore();
    const licenceStore = useLicenceStore();
    const userStore = useUserStore();
    return {
      dashboardStore,
      licenceStore,
      userStore,
    };
  },
  watch: {
    selectedDate(newSelectedDate, oldSelectedDate) {
      this.dashboardStore.setDates({ selectedDate: newSelectedDate });
      this.updateDate();
    },
  },
  methods: {
    async updateDate() {

        const accountStore = useAccountStore();
        if(!accountStore.uuid){
            return;
        }

      await this.dashboardStore.getSummary({
        vm: this,
      });
      await this.dashboardStore.getPrediction({
        vm: this,
      });
      await this.dashboardStore.getCore({
        vm: this,
      });
      await this.dashboardStore.getDisplays({
        vm: this,
      });
      await this.dashboardStore.getCourses({
        vm: this,
      });
      await this.dashboardStore.getRepartition({
        vm: this,
      });
      /*await this.dashboardStore.getScoring({
        vm: this,
      });*/
    },
  },
};
</script>

<style scoped>
.datepick {
  --v-calendar-datepicker-icon-color: #7367f0;
  --v-calendar-active-bg-color: #7367f0;
  --v-calendar-active-text-color:#fff;
  --v-calendar-day-hover-bg-color: #c0bbf4;
  --v-calendar-range-bg-color: #eeedfd;
  --v-calendar-range-text-color: #666;
  margin-left: auto;
  margin-right: 0;
}

.v-calendar .input-field input {
  cursor: pointer !important;
}

</style>
