import { defineStore } from "pinia";

export const useLayoutStore = defineStore("layout", {
  state: () => {
    return {
      menu: {
        campagne: false,
        smartReply: false,
        contact: false,
        program: false,
        banniere: false,
        sms_setting: false,
        entreprise: false,
      },
    };
  },

  getters: {},

  actions: {
    openMenu(item) {
      this.menu[item] = !this.menu[item];
    },
    resetActive() {
      this.menu.contact = false;
      this.menu.program = false;
      this.menu.banniere = false;
      this.menu.sms_setting = false;
      this.menu.entreprise = false;
    },
    setActive(item) {
      this.menu[item] = true;
    },
  },
});
