<template>
  <div class="row">
    <div class="col-md-3">
      <ul>
        <li class="nav-item" @click="openMenu('Accueil', '/settings/chatbot')">
          <a
            class="btn btn-flat-secondary waves-effect d-flex align-items-center"
            :class="{ active: active === 'Accueil' }"
          >
            <span class=""
              ><vue-feather type="thumbs-up"></vue-feather> Accueil</span
            >
          </a>
        </li>
        <li
          class="nav-item"
          @click="openMenu('Visuel', '/settings/chatbot/visuel')"
        >
          <a
            class="btn btn-flat-secondary waves-effect d-flex align-items-center"
            :class="{ active: active === 'Visuel' }"
          >
            <span class=""
              ><vue-feather type="image"></vue-feather> Visuel</span
            >
          </a>
        </li>
        <li
          class="nav-item"
          @click="openMenu('Qualifications', '/settings/chatbot/qualification')"
        >
          <a
            class="btn btn-flat-secondary waves-effect d-flex align-items-center"
            :class="{ active: active === 'Qualifications' }"
          >
            <span class="">
              <vue-feather type="star"></vue-feather>Qualifications</span
            >
          </a>
        </li>
        <!--
        <li class="nav-item">
          <a
            class="btn btn-flat-secondary waves-effect d-flex align-items-center"
            :class="{ active: active === 'Branches' }"
          >
            <span
              @click="openMenu('Branches', '/settings/chatbot/branche')"
              class=""
              ><vue-feather type="map-pin"></vue-feather> Branches</span
            >
          </a>
        </li>
        <li class="nav-item">
          <a
            class="btn btn-flat-secondary waves-effect d-flex align-items-center"
            :class="{ active: active === 'Question' }"
          >
            <span
              @click="openMenu('Questions', '/settings/chatbot/question')"
              class=""
              ><vue-feather type="tag"></vue-feather> Questions</span
            >
          </a>
        </li>
        -->
        <li
          class="nav-item"
          @click="openMenu('Activations', '/settings/chatbot/activation')"
        >
          <a
            class="btn btn-flat-secondary waves-effect d-flex align-items-center"
            :class="{ active: active === 'Activations' }"
          >
            <span class=""
              ><vue-feather type="toggle-right"></vue-feather> Activations</span
            >
          </a>
        </li>
      </ul>
    </div>
    <div class="col-md-9">
      <router-view name="chatbot"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "settingChatbot",
  data() {
    return {
      active: "Accueil",
    };
  },
  methods: {
    openMenu(menu, target) {
      this.active = menu;
      this.$router.push(target);
    },
  },
};
</script>

<style scoped>
ul {
  padding-left: 0;
}
ul li {
  list-style: none;
}
.nav-item {
  display: flex;
  align-items: center;
}
.vue-feather {
  padding: 0;
  margin: 0 10px 0 0;
  display: inline;
}
.btn {
  width: 100%;
  height: 40px;
  line-height: 1.5;
}

.active {
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgb(115 103 240 / 70%);
  border-radius: 4px;
  color: #fff !important;
}
</style>
<style>
  .vue-feather__content {
    display: inline !important;;
  }
</style>