<template>
  <div>
    <DashboardLayout>
      <div class="row mb-2">
        <div class="col-lg-8">
          <h1>Settings</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <ul class="nav nav-tabs" role="tablist" style="margin-bottom:30px">
                <li class="nav-item">
                  <a
                    @click="$router.push('/settings')"
                    class="nav-link active"
                    id="general-tab"
                    data-bs-toggle="tab"
                    href="#generalTab"
                    aria-controls="general"
                    role="tab"
                    aria-selected="true"
                    ><vue-feather type="settings"></vue-feather> Général</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click="$router.push('/settings/chatbot')"
                    class="nav-link"
                    id="chatbot-tab"
                    data-bs-toggle="tab"
                    href="#chatbotTab"
                    aria-controls="chatbot"
                    role="tab"
                    aria-selected="false"
                    ><vue-feather type="twitch"></vue-feather> Personnalisation
                    de la chatbox</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click="$router.push('/settings/account')"
                    class="nav-link"
                    id="account-tab"
                    data-bs-toggle="tab"
                    href="#accountTab"
                    aria-controls="account"
                    role="tab"
                    aria-selected="false"
                    ><vue-feather type="eye-off"></vue-feather> Gestion des comptes</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click="$router.push('/settings/integration')"
                    class="nav-link"
                    id="integration-tab"
                    data-bs-toggle="tab"
                    href="#integrationTab"
                    aria-controls="integration"
                    role="tab"
                    aria-selected="false"
                    ><vue-feather type="cpu"></vue-feather> Intégration</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click="$router.push('/settings/lead')"
                    class="nav-link"
                    id="lead-tab"
                    data-bs-toggle="tab"
                    href="#leadTab"
                    aria-controls="lead"
                    role="tab"
                    aria-selected="false"
                    ><vue-feather type="award"></vue-feather> Lead score</a
                  >
                </li>
                <li class="nav-item" v-if="userStore.is_leadia_admin && licenceStore.chatbot_statut_type == 'trial' " >
                  <a
                          @click="$router.push('/settings/trial')"
                          class="nav-link"
                          id="licence-tab"
                          data-bs-toggle="tab"
                          href="#leadTab"
                          aria-controls="lead"
                          role="tab"
                          aria-selected="false"
                  ><vue-feather type="shield"></vue-feather> Licence/Trial</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <router-view name="setting"></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";

import { useAccountStore } from "@/stores/account";
import { useUserStore } from "@/stores/user";
import { useLicenceStore } from "@/stores/licence";

export default {
  name: "Setting",
  components: {
    DashboardLayout,
  },
  data() {
    return {};
  },
  setup() {
    const accountStore = useAccountStore();
    const userStore = useUserStore();
    const licenceStore = useLicenceStore();
    return {
      accountStore,
      userStore,
      licenceStore
    };
  },
  computed: {},
  async mounted() {},
  methods: {},
};
</script>

<style scoped>
.btn {
  width: 100%;
}
</style>
