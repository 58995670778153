<template>
  <div>
    <DashboardLayout>
      <div class="row mb-2">
        <div class="col-lg-8">
          <h1>Magic SMS</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="fixed">
                  <ul>
                    <li class="nav-item" @click="openMenu('dashboard', '/campagne/sms')">
                      <a
                              class="btn btn-flat-secondary waves-effect d-flex align-items-center"
                              :class="{ active: active === 'dashboard' }"
                      >
            <span class="">
                <vue-feather type="home"></vue-feather>Dashboard</span>
                      </a>
                    </li>
                    <li
                            class="nav-item"
                            @click="openMenu('templates', '/campagne/sms/templates')"
                    >
                      <a
                              class="btn btn-flat-secondary waves-effect d-flex align-items-center"
                              :class="{ active: active === 'templates' }"
                      >
            <span class="">
                <vue-feather type="grid"></vue-feather>Templates</span>
                      </a>
                    </li>
                    <li
                            class="nav-item"
                            @click="openMenu('historique', '/campagne/sms/historique')"
                    >
                      <a
                              class="btn btn-flat-secondary waves-effect d-flex align-items-center"
                              :class="{ active: active === 'historique' }"
                      >
            <span class="">
              <vue-feather type="calendar"></vue-feather>Historique</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col">
                  <router-view name="sms"></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";

import { useAccountStore } from "@/stores/account";

export default {
  name: "SmsSettings",
  components: {
    DashboardLayout,
  },
    data() {
        return {
            active: "dashboard",
        };
    },
    methods: {
        openMenu(menu, target) {
            this.active = menu;
            this.$router.push(target);
        },
    },
  setup() {
    const accountStore = useAccountStore();
    return {
      accountStore,
    };
  },
  computed: {},
  async mounted() {},
};
</script>


<style scoped>
.btn {
  width: 100%;
}
 ul {
   padding-left: 0;
 }
ul li {
  list-style: none;
}
.nav-item {
  display: flex;
  align-items: center;
}
.vue-feather {
  padding: 0;
  margin: 0 10px 0 0;
  display: inline;
}
.btn {
  width: 100%;
  height: 40px;
  line-height: 1.5;
}

.active {
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgb(115 103 240 / 70%);
  border-radius: 4px;
  color: #fff !important;
}
  .vue-feather__content {
    display: inline !important;;
  }
.fixed{
    width: 240px;
}

@media (max-width: 990px) {
    .fixed{
        width: 100%;
    }
}
</style>
