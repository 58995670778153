<template>
  <div>
    <div class="modal fade" ref="successModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body center modal-success">
            <img src="@/assets/images/success.png" class="mb-1" />
            <h3>Félicitations! 👏</h3>
            <p>Vos modifications ont été enregistrées</p>
            <button
              type="button"
              class="btn btn-primary"
              @click="actionSuccess()"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <DashboardLayout>
      <div class="row mb-2">
        <div class="col-lg-8">
          <h1>Programmes</h1>
        </div>
      </div>
      <form class="card" @submit.prevent="actionSave">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h2 v-if="$route.name === 'ProgramAdd'">
                Création d'un programme
              </h2>
              <h2 v-else>Modification d'un programme</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="mb-2">
                <label class="typo__label">Type de formation</label>
                <select v-model="category" class="form-control">
                  <option value="">Choisir un type de formation</option>
                  <option value="initial">Diplomante</option>
                  <option value="professional">Continue / pro</option>
                  <option value="tout">Tout</option>
                </select>
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.category.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="mb-2">
                <label>Nom de la formation</label>
                <input
                  class="form-control"
                  placeholder="Ajouter un nom de formation"
                  v-model="name"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.name.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="mb-2">
                <label>Ciblage</label>
                <multiselect :close-on-select="false"
                  track-by="code"
                  :options="optionsAdmission"
                  v-model="admission"
                  placeholder="Sélectionnez dans la liste"
                  label="name"
                  multiple
                  :taggable="false"
                  :show-labels="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="mb-2">
                <label>{{this.singularSectionName()}}</label>
                <multiselect :close-on-select="false"
                  track-by="id"
                  label="name"
                  :placeholder="'Choisir ' +singularSectionNameWithPronom()"
                  :options="optionsCampus"
                  v-model="campus"
                  multiple
                  :taggable="false"
                  :show-labels="false"
                >
                </multiselect>
              </div>
              <div class="mb-2">
                <label>Domaine de la formation</label>
                <multiselect :close-on-select="false"
                  track-by="id"
                  label="name"
                  placeholder="Choisir un type de formation"
                  :options="optionsDomain"
                  v-model="domain"
                  multiple
                  :taggable="true"
                  :show-labels="false"
                  tag-placeholder="Appuyer sur entrée pour créer ce domaine"
                  @tag="addTag($event)"
                >
                </multiselect>
              </div>
              <div class="mb-2">
                <label>Lien web</label>
                <input
                  class="form-control"
                  placeholder="http://demo.lead-ia.com/bts-sio.html"
                  v-model="url"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.url.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-2">
            <button
              type="submit"
              class="btn btn-primary waves-effect waves-float waves-light me-2"
            >
              Enregistrer
            </button>
            <button
              type="cancel"
              class="btn btn-outline-primary waves-effect waves-float waves-light"
              @click="$router.push('/program')"
            >
              Annuler
            </button>
          </div>
        </div>
      </form>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";
import { useProgramStore } from "@/stores/program";
import { useLayoutStore } from "@/stores/layout";
import { useAccountStore } from "@/stores/account";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { helpers, url, required } from "@vuelidate/validators";
import { Modal } from "bootstrap";
import { useLoaderStore } from "@/stores/loader";

export default {
  name: "ProgramDetail",
  components: {
    DashboardLayout,
    Multiselect,
  },
  data() {
    return {
      modalSuccess: null,
      name: "",
      category: "",
      url: "",
      optionsAdmission: [
        { name: "4e", code: "4e" },
        { name: "3e", code: "3e" },
        { name: "CAP/BEP", code: "CAP/BEP" },
        { name: "2nde", code: "2nde" },
        { name: "1ere Pro", code: "1ere Pro" },
        { name: "1ere", code: "1ere" },
        { name: "Terminale/BAC Pro", code: "Terminale/BAC Pro" },
        {
          name: "Terminale/BAC Technologique",
          code: "Terminale/BAC Technologique",
        },
        { name: "Terminale/BAC", code: "Terminale/BAC" },
        { name: "BAC+1", code: "BAC+1" },
        { name: "BAC+2", code: "BAC+2" },
        { name: "BAC+3", code: "BAC+3" },
        { name: "BAC+4", code: "BAC+4" },
        { name: "BAC+5", code: "BAC+5" },
      ],
      initialAdmission: [],
      admission: [],
      optionsCampus: this.programStore.sections,
      initialCampus: [],
      campus: [],
      optionsDomain: [],
      initialDomain: [],
      domain: [],
      sections: this.programStore.sections,
      domains: this.programStore.domains,
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage("Nom requis", required),
      },
      category: {
        required: helpers.withMessage("Type de formation requis", required),
      },
      url: {
        required: helpers.withMessage("Lien web requis", required),
        url: helpers.withMessage("Lien web doit être une url", url),
      },
    };
  },
  watch: {
    initialAdmission: {
      immediate: true,
      handler(values) {
        if (!values || this.optionsAdmission.length === 0) return;
        this.admission = this.optionsAdmission.filter((x) =>
          values.includes(x["code"])
        );
      },
    },
    initialCampus: {
      immediate: true,
      handler(values) {
        if (!values || this.optionsCampus.length === 0) return;
        this.campus = this.optionsCampus.filter((x) => {
          const y = values.filter((value) => {
            return value.section_id === x.id;
          });
          if (y.length > 0) {
            return true;
          }
        });
      },
    },
    initialDomain: {
      immediate: true,
      handler(values) {
        if (!values || this.optionsDomain.length === 0) return;
        this.domain = this.optionsDomain.filter((x) => {
          const y = values.filter((value) => {
            return value.domain_id === x.id;
          });
          if (y.length > 0) {
            return true;
          }
        });
      },
    },
  },
  computed: {},
  async mounted() {
    this.layoutStore.setActive("program");
    this.modalSuccess = new Modal(this.$refs.successModal);
    await this.programStore.getDomains({ vm: this });
    this.optionsDomain = this.programStore.domains;
    if (this.$route.name === "ProgramDetail") {
      await this.programStore.getCourse({
        id: this.$route.params.id,
        vm: this,
      });
      this.name = this.programStore.course.name;
      this.url = this.programStore.course.url;
      this.category = this.programStore.course.category;
      this.initialDomain = this.programStore.course.course_domains;
      this.initialCampus = this.programStore.course.section_courses;
      this.initialAdmission = this.programStore.course.admission;
    } else {
      this.programStore.course = {};
      this.admission = [];
      this.campus = [];
      this.domain = [];
      this.name = "";
      this.url = "";
      this.category = "";
    }
  },
  setup() {
    const programStore = useProgramStore();
    const layoutStore = useLayoutStore();
    const accountStore = useAccountStore();
    return {
      v$: useVuelidate(),
      programStore,
      layoutStore,
      accountStore
    };
  },
  methods: {
    singularSectionNameWithPronom() {
      if(this.accountStore.account.section_naming == "campus") return "un campus";
      if(this.accountStore.account.section_naming == "school") return "une école";
      if(this.accountStore.account.section_naming == "center") return "un centre de formation";
      return "un campus";
    },
      singularSectionName() {
          if(this.accountStore.account.section_naming == "campus") return "Campus";
          if(this.accountStore.account.section_naming == "school") return "École";
          if(this.accountStore.account.section_naming == "center") return "Centre de formation";
          return "Campus";
      },
    actionSuccess() {
      this.modalSuccess.hide();
      this.$router.push("/program");
    },
    async actionSave(e) {
      e.preventDefault();
      await this.v$.$validate();
        if (this.v$.$error) {
            this.$toast.open({
                message: "Un ou plusieurs champs requis ne sont pas renseignés.",
                type: "error",
                position: "top-right",
                duration: 4000,
                dismissible: true,
            });
            return;
        }
      const data = {
        admission: this.admission.map((item) => {
          return item.name;
        }),
        category: this.category,
        name: this.name,
        url: this.url,
        vm: this,
      };
      var course;
      if (this.$route.name === "ProgramDetail") {
        data["id"] = this.$route.params.id;
        course = await this.programStore.updateCourse(data);
      } else {
        course = await this.programStore.createCourse(data);
      }

        const loaderStore = useLoaderStore();
        await loaderStore.setLoader(true);

         this.initialDomain.forEach( async (dom) => {
             await this.programStore.deleteCourseDomain({
                id: dom.id,
                vm: this,
            });
        });
        this.initialCampus.forEach( async (dom) => {
            await this.programStore.deleteSectionCourse({
                id: dom.id,
                vm: this,
            });
        });

        await new Promise(r => setTimeout(r, 1000));

        this.domain.forEach( async (dom) => {
            await this.programStore.joinCourseDomain({
                course_id: course.id,
                domain_id: dom.id,
                vm: this,
            });
        });

        this.campus.forEach(async (campus) => {
            await this.programStore.joinSectionCourses({
                course_id: course.id,
                section_id: campus.id,
                vm: this,
            });
        });

        await loaderStore.setLoader(false);
      this.modalSuccess.show();
    },
    async addTag(newTag) {
      const domain = await this.programStore.createDomain({
        name: newTag,
        vm: this,
      });
      const tag = {
        name: newTag,
        id: domain.id,
      };
      this.optionsDomain.push(tag);
      this.domain.push(tag);
    },
  },
};
</script>

<style>
select,
input {
  margin-bottom: 15px;
}
h2 {
  margin-bottom: 20px;
}
.multiselect__tag {
  background-color: #9188f1 !important;
  color: #fff !important;
  font-size: 0.85rem;
}
.multiselect__element {
  font-size: 0.85rem;
}
.multiselect__option--highlight multiselect__option {
  background-color: #9188f1 !important;
  color: #fff !important;
}
.multiselect__input {
  font-size: 0.85rem !important;
}

.multiselect__option--highlight {
  background: #eeecfd !important;
  color: #7367f0 !important;
}

.multiselect__tag-icon {
  color: #fff !important;
}
.modal-success {
  padding: 30px 10px !important;
}
</style>
