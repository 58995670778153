import { defineStore } from "pinia";
import { useAuthStore } from "./auth";
import { useLoaderStore } from "./loader";
import axios from "axios";
import { useAccountStore } from "@/stores/account";

export const useUserStore = defineStore("user", {
  state: () => ({
    users: [],
    user: {},
    current_user: {},
    roles: [],
    is_leadia_admin: false,
  }),

  getters: {
    authGetter(state) {
      const authStore = useAuthStore();
      return {
        token: authStore.token,
        client: authStore.client,
        uid: authStore.uid,
      };
    },
    accountGetter(state) {
      const accountStore = useAccountStore();
      return {
        uuid: accountStore.uuid,
      };
    },
  },

  actions: {
    async getUsers(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "users.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$flashMessage.show({
            status: "error",
            title: "Error",
            text: "Une erreur est survenue lors de la requete",
            blockClass: "flashmessage-error",
            time: 10000,
          });
        });
      if (response) {
        this.users = response.data;
      }
      await loaderStore.setLoader(false);
    },
    async getUser(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "users/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .get(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.user = response.data.data;
      }
      await loaderStore.setLoader(false);
    },
    async getCurrentUser(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "users/current.json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .get(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.current_user = response.data.data;
        if (this.current_user.hasOwnProperty("email")) {
          this.is_leadia_admin =
            this.current_user["email"].includes("admin@lead-ia.com");
        }
      }
      await loaderStore.setLoader(false);
    },
    async updateCurrentUser(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const dataToSend = {};
      if (data.firstname) dataToSend["firstname"] = data.firstname;
      if (data.lastname) dataToSend["lastname"] = data.lastname;
      if (data.service) dataToSend["service"] = data.service;
      if (data.image) dataToSend["image"] = data.image;

      const url =
        process.env.VUE_APP_ROOT_API +
        "users/current.json?account_uuid=" +
        this.accountGetter.uuid;

      const response = await axios
        .put(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        this.current_user = response.data.data;
        this.user = response.data.data;

        data.vm.$toast.open({
          message: "Vos modifications sont enregistrées",
          type: "success",
          position: "bottom-right",
          duration: 2000,
          dismissible: true,
        });
      }
      await loaderStore.setLoader(false);
    },
    async updateUser(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const dataToSend = {};
      if (data.firstname) dataToSend["firstname"] = data.firstname;
      if (data.lastname) dataToSend["lastname"] = data.lastname;
      if (data.service) dataToSend["service"] = data.service;
      if (data.reporting == true || data.reporting == false)
        dataToSend["reporting"] = data.reporting;

      const url =
        process.env.VUE_APP_ROOT_API +
        "users/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;

      const response = await axios
        .put(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      if (response) {
        data.vm.$toast.open({
          message: "Vos modifications sont enregistrées",
          type: "success",
          position: "bottom-right",
          duration: 2000,
          dismissible: true,
        });
      }
      await loaderStore.setLoader(false);
    },
    async createUser(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "users.json?account_uuid=" +
        this.accountGetter.uuid;
      const dataToSend = {
        account_uuid: this.accountGetter.uuid,
      };
      if (data.firstname) dataToSend["firstname"] = data.firstname;
      if (data.lastname) dataToSend["lastname"] = data.lastname;
      if (data.email) dataToSend["email"] = data.email;
      if (data.equipe) dataToSend["equipe"] = data.equipe;
      if (data.ecole) dataToSend["ecole"] = data.ecole;
      if (data.role) dataToSend["role"] = data.role;
      if (data.service) dataToSend["service"] = data.service;
      if (data.statut) dataToSend["statut"] = data.statut;
      const response = await axios
        .post(url, dataToSend, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
      if (response) {
        if (response.data.status == 0) {
          data.vm.$toast.open({
            message: "Votre nouvel utilisateur a été ajouté avec succès.",
            type: "success",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        }
        if (response.data.status == 1) {
          data.vm.$toast.open({
            message: "Vous avez donné un accès à un utilisateur existant.",
            type: "warning",
            position: "top-right",
            duration: 10000,
            dismissible: true,
          });
        }
        return response.data.data;
      }
    },
    async deleteUser(data: any) {
      const loaderStore = useLoaderStore();
      await loaderStore.setLoader(true);
      const url =
        process.env.VUE_APP_ROOT_API +
        "users/" +
        data.id +
        ".json?account_uuid=" +
        this.accountGetter.uuid;
      const response = await axios
        .delete(url, {
          headers: {
            "token-type": "Bearer",
            uid: this.authGetter.uid,
            client: this.authGetter.client,
            "access-token": this.authGetter.token,
          },
        })
        .catch(function (error) {
          console.log(error);
          data.vm.$toast.open({
            message: "Une erreur est survenue lors de la requete",
            type: "error",
            position: "top-right",
            duration: 4000,
            dismissible: true,
          });
        });
      await loaderStore.setLoader(false);
    },
    async getRoles(data: any) {
      const response = await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "roles.json?account_uuid=" +
            this.accountGetter.uuid,
          {
            headers: {
              "token-type": "Bearer",
              uid: this.authGetter.uid,
              client: this.authGetter.client,
              "access-token": this.authGetter.token,
            },
          }
        )
        .catch(function (error) {
          console.log(error);
          data.vm.$flashMessage.show({
            status: "error",
            title: "Error",
            text: "Une erreur est survenue lors de la requete",
            blockClass: "flashmessage-error",
            time: 10000,
          });
        });
      if (response) {
        this.roles = response.data;
      }
    },
  },
  persist: {
    enabled: true,
  },
});
