import { defineStore } from "pinia";

export const useLoaderStore = defineStore("loader", {
  state: () => ({
    active: false,
  }),
  getters: {
    isActive(state) {
      return state.active;
    },
  },
  actions: {
    setLoader(value) {
      this.active = value;
    },
  },
});
